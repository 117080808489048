import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles({
  button: {
    background: '#E0E7EC',
    border: 'none',
    color: '#123B64',
    padding: '2px 16px',
    borderRadius: 12,
  }
})

const PopularTag = ({ labelTags, value, onClick }) => {
  const classes = useStyles()
  return (
    <button onClick={onClick} className={classes.button} >
      {`${labelTags} (${value})`}
    </button>
  );
};

export default PopularTag;
