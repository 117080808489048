import {
  Box,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import heroImage from "../../assets/images/blog/hero_search_background.jpg";
import SearchInput from "../../components/shared/inputs/SearchInput";
import React, { useState } from "react";
import { ExportIcon, OutlinedEditIcon } from "../../components/icons";
import {
  BlogTab,
  BlogTabs,
  ProfTab,
  ProfTabs,
} from "../../components/profile/profile-details/TabsDetailsblog";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    minHeight: 1800,
    marginBottom: 100,
  },
  heroSection: {
    backgroundImage: `url(${heroImage})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: 250,
  },
  overlay: {
    height: "100%",
    background:
      "linear-gradient(90deg,rgba(0,38,100,0.95) 0%,rgba(0,38,100,0.85) 50%,rgba(0,38,100,0.75) 100%)",
  },
  container: {
    paddingTop: 185,
    justifyContent: "center",
    //marginLeft:800,
  },
  title: {
    color: "#fff",
    marginBottom: -8,
    marginTop: -60,
    justifyContent: "center",
  },
  leftSide: {
    width: "50%",
    maxWidth: 460,
    justifyContent: "center",
    marginRight: 25,
  },
  rightSide: {
    width: "50%",
    maxWidth: 650,
    justifyContent: "center",
    marginLeft: 25,
  },

  subTitle: {
    fontSize: 20,
    lineHeight: "24px",
    color: "#fff",
    justifyContent: "center",
    padding: "0px 0",
    marginTop: -20,
    marginBottom: 10,
  },
  grid: {
    display: "flex",
    justifyContent: "center",
    width: 700,
    position: "absolute",
    marginTop: -850,
    marginLeft: 560,
    //marginBottom:-30,
  },
  grid1: {
    display: "flex",
    justifyContent: "center",
    //marginLeft:680,
    width: 700,
    marginLeft: -35,
  },
  alert: {
    borderLeft: "6px solid #123b64",
    background: "#f4f4f7",
    padding: "24px 24px 24px 16px",
  },
  title2: {
    fontSize: 24,
    fontWeight: 700,
    lineHeight: 1.25,
  },
});

function Blog() {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };
  const [value, setValue] = React.useState(0);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };

  const setAttributes = (index) => {
    return {
      id: `nav-tab-${index}`,
      "aria-controls": `nav-tabpanel-${index}`,
    };
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.heroSection}>
        <Box className={classes.overlay}>
          <Container className={classes.container}>
            <Typography className={classes.subTitle}>
              An open portal to 15168 datasets throughout Datasouk
            </Typography>
            <div
              style={{
                margin: "1px solid #1f20201a",
                border: "1px solid #f79f19",
              }}
            ></div>

            <BlogTabs
              value={value}
              onChange={handleChange1}
              aria-label="ant example"
            >
              <BlogTab label="Data Policy" {...setAttributes(1)} />
              <BlogTab label="Sharing Data" {...setAttributes(2)} />
              <BlogTab label="Using Data" {...setAttributes(3)} />
              <BlogTab label="Our work" {...setAttributes(4)} />
              <BlogTab label="About" {...setAttributes(5)} />
              <BlogTab label="Contact" {...setAttributes(6)} />
            </BlogTabs>

            {/* <Breadcrumbs style={{marginLeft:-100,}}/> */}

            <Grid className={classes.grid1} item xs={12} sm={6}>
              <Box className={classes.signInWrapper}>
                <Typography
                  variant="h1"
                  color="primary"
                  style={{ marginTop: 30, justifyContent: "center" }}
                >
                  Data Policy
                </Typography>
                <div
                  style={{
                    border: "1px solid #123b64",
                    marginTop: 20,
                    width: 500,
                  }}
                >
                  {" "}
                </div>

                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
                >
                  Key Datasouk Data Legislation and Policies
                </Typography>
                <div
                  style={{
                    border: "1px solid #adb5bd",
                    marginTop: 20,
                    width: 500,
                  }}
                >
                  {" "}
                </div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
                >
                  Datasouk Data Strategy
                </Typography>
                <div
                  style={{
                    border: "1px solid #adb5bd",
                    marginTop: 20,
                    width: 500,
                  }}
                >
                  {" "}
                </div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{
                    marginTop: 15,
                    marginLeft: 20,
                    "&:hover": {
                      color: "#abd",
                      cursor: "pointer",
                    },
                  }}
                >
                  Datasouk Data Glossary
                </Typography>
                <div
                  style={{
                    border: "1px solid #adb5bd",
                    marginTop: 20,
                    width: 500,
                  }}
                >
                  {" "}
                </div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
                >
                  <Link
                    to="/blogpage"
                    style={{ textDecoration: "none", color: "#123b64" }}
                  >
                    Smart Places Data Protection Policy
                  </Link>
                </Typography>
                <div
                  style={{
                    border: "1px solid #adb5bd",
                    marginTop: 20,
                    width: 500,
                  }}
                >
                  {" "}
                </div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
                >
                  Information Management Framework
                </Typography>
                <div
                  style={{
                    border: "1px solid #adb5bd",
                    marginTop: 20,
                    width: 500,
                  }}
                >
                  {" "}
                </div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
                >
                  Data Custodianship Policy
                </Typography>
                <div
                  style={{
                    border: "1px solid #adb5bd",
                    marginTop: 20,
                    width: 500,
                  }}
                >
                  {" "}
                </div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
                >
                  Open Data Policy
                </Typography>
                <div
                  style={{
                    border: "1px solid #adb5bd",
                    marginTop: 20,
                    width: 500,
                  }}
                >
                  {" "}
                </div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
                >
                  Data Governance Toolkit
                </Typography>
                <div
                  style={{
                    border: "1px solid #adb5bd",
                    marginTop: 20,
                    width: 500,
                  }}
                >
                  {" "}
                </div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
                >
                  Infrastructure Data Management Framework
                </Typography>
                <div
                  style={{
                    border: "1px solid #adb5bd",
                    marginTop: 20,
                    width: 500,
                  }}
                >
                  {" "}
                </div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20, cursor: "pointer" }}
                >
                  Data Quality Reporting Tool
                </Typography>
                <div
                  style={{
                    border: "1px solid #adb5bd",
                    marginTop: 20,
                    width: 500,
                  }}
                >
                  {" "}
                </div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{
                    marginTop: 15,
                    marginLeft: 20,
                    width: 500,
                    cursor: "pointer",
                  }}
                >
                  Datasouk Information Classification,
                  <span>Labelling and Handing Guideliness</span>
                </Typography>
                <div
                  style={{
                    border: "1px solid #adb5bd",
                    marginTop: 20,
                    width: 500,
                  }}
                >
                  {" "}
                </div>
              </Box>
            </Grid>

            <Grid className={classes.grid} item xs={12} sm={6}>
              <Box className={classes.signInWrapper}>
                <Typography
                  variant="h1"
                  color="primary"
                  style={{ marginTop: -50, justifyContent: "center" }}
                >
                  Data Policy
                </Typography>
                <Typography
                  variant="h4"
                  //color='primary'
                >
                  Datasouk is working to create a strong policy framework to
                  support safe and secure data use in .  We continually
                  prototype and test policies and frameworks on this site for
                  feedback.
                </Typography>
                <Box className={classes.alert}>
                  <Typography className={classes.title2}>
                    Have your say
                  </Typography>
                  <Typography style={{ marginTop: 12 }}>
                    You can visit us at{" "}
                    <Link to="https://datasouk.ai/" target="_blank">
                      https://datasouk.ai/
                    </Link>
                    We welcome all feedback and discussions on these issues. You
                    can email us at  :info@datasouk.ai
                  </Typography>
                </Box>

                <div style={{ border: "1px solid #adb5bd", marginTop: 20 }}>
                  {" "}
                </div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20 }}
                >
                  <Link>Datasouk Data Strategy</Link>
                  <IconButton style={{ marginLeft: 220 }}>
                    <ArrowForwardIcon color="primary" />
                  </IconButton>
                </Typography>

                <div
                  style={{ border: "1px solid #adb5bd", marginTop: 10 }}
                ></div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20 }}
                >
                  <Link>Datasouk Data Glossary</Link>
                  <IconButton style={{ marginLeft: 220 }}>
                    <ArrowForwardIcon color="primary" />
                  </IconButton>
                </Typography>
                <div
                  style={{ border: "1px solid #adb5bd", marginTop: 10 }}
                ></div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20 }}
                >
                  <Link to="/blogpage">
                    Smart places Data Protection policy
                  </Link>
                  <IconButton style={{ marginLeft: 190 }}>
                    <ArrowForwardIcon color="primary" />
                  </IconButton>
                </Typography>
                <div
                  style={{ border: "1px solid #adb5bd", marginTop: 10 }}
                ></div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20 }}
                >
                  <Link>Information Management Framework</Link>
                  <IconButton style={{ marginLeft: 180 }}>
                    <ArrowForwardIcon color="primary" />
                  </IconButton>
                </Typography>
                <div
                  style={{
                    border: "1px solid #adb5bd",
                    marginTop: 10,
                    //width:500,
                  }}
                ></div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20 }}
                >
                  <Link>Data Custodian Policy</Link>
                  <IconButton style={{ marginLeft: 330 }}>
                    <ArrowForwardIcon color="primary" />
                  </IconButton>
                </Typography>
                <div
                  style={{ border: "1px solid #adb5bd", marginTop: 10 }}
                ></div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20 }}
                >
                  <Link>Open Data Policy</Link>
                  <IconButton style={{ marginLeft: 380 }}>
                    <ArrowForwardIcon color="primary" />
                  </IconButton>
                </Typography>
                <div
                  style={{ border: "1px solid #adb5bd", marginTop: 10 }}
                ></div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20 }}
                >
                  <Link>Data Governance Toolkit</Link>
                  <IconButton style={{ marginLeft: 310 }}>
                    <ArrowForwardIcon color="primary" />
                  </IconButton>
                </Typography>
                <div
                  style={{ border: "1px solid #adb5bd", marginTop: 20 }}
                ></div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20 }}
                >
                  <Link>Infrastructure Data Management Framework</Link>
                  <IconButton style={{ marginLeft: 110 }}>
                    <ArrowForwardIcon color="primary" />
                  </IconButton>
                </Typography>
                <div
                  style={{ border: "1px solid #adb5bd", marginTop: 20 }}
                ></div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20 }}
                >
                  <Link>Data Quality Reporting Tool</Link>
                  <IconButton style={{ marginLeft: 280 }}>
                    <ArrowForwardIcon color="primary" />
                  </IconButton>
                </Typography>
                <div
                  style={{ border: "1px solid #adb5bd", marginTop: 20 }}
                ></div>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ marginTop: 15, marginLeft: 20 }}
                >
                  <Link>
                    Datasouk Information Classification,Labelling and Handling
                    Guideliness
                  </Link>
                  <IconButton style={{ marginLeft: 10 }}>
                    <ArrowForwardIcon color="primary" />
                  </IconButton>
                </Typography>
                <div
                  style={{ border: "1px solid #adb5bd", marginTop: 10 }}
                ></div>
                <Typography
                  variant="h5"
                  style={{ marginTop: 20 }}
                  color="Primary"
                >
                  <b style={{ fontSize: 23, marginLeft: 20 }}>Last updated </b>
                  25 Jul 2023{" "}
                </Typography>
              </Box>
            </Grid>

            <Box style={{ marginTop: 350, justifyContent: "center" }}>
              <div
                style={{
                  border: "1px solid #adb5bd",
                  marginTop: 10,
                  width: 2355,
                  marginLeft: -600,
                }}
              ></div>
              <Box style={{ marginLeft: 50 }}>
                <Typography variant="h3" style={{ marginTop: 10 }}>
                  Popular
                </Typography>
                <span>About Data.Datasouk</span>
                <Typography>
                  Key Datasouk Data Legislation and Policies
                </Typography>
                <span>Data.Datasouk Policy</span>
                <Typography>Data Skills</Typography>
                <span>
                  Twitter <ExportIcon />
                </span>
              </Box>
              <Box style={{ marginLeft: 500, marginTop: -160 }}>
                <Typography variant="h3" style={{ marginTop: 10 }}>
                  Open Data Portals
                </Typography>
                <span>API.Datasouk</span>
                <Typography>Environmental Data Portal ,SEED</Typography>
                <span>Datasouk Department of Education</span>
                <Typography>Datasouk Health</Typography>
                <span>
                  Transport for NSW <ExportIcon />
                </span>
                <Typography>
                  Datasouk Buraeu Of Crime Statistics and Research
                </Typography>
                <span>Spatial Collaboration Portal</span>
                <Typography>Datasouk Flood Portal</Typography>
                <span>ePlanning</span>
                {/* <Typography>
                            State Insurance Regulatory Authority
                        </Typography>
                        <span>
                        Transport for NSW <ExportIcon/>
                        </span> */}
              </Box>
              <Box style={{ marginLeft: 1000, marginTop: -250 }}>
                <Typography variant="h3">About This Site</Typography>
                <span>Accessibility</span>
                <Typography>Copyright</Typography>
                <span>Disclaimer</span>
                <Typography>Get in Touch</Typography>
                <span>
                  Privacy <ExportIcon />
                </span>
                <Typography>Site Search</Typography>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}

export default Blog;
