import React, { useContext, useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { makeStyles, Button, IconButton } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import CollectionModal from "../shared/modal/Modal";
import collectionImg from "../../assets/images/collection-img.png";
import { PinIcon, TrashBinIcon } from "../icons";
import { CollectionService } from "../../services/CollectionService";
import DataContext from "../../contexts/Context";

import TabContext from "../../contexts/TabContext";
const useStyles = makeStyles({
  root: {
    width: "100%",
    height: 245,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 4,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#F79F19",
      borderRadius: 2,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#808c9a4d",
      borderRadius: 2,
    },
    "&::-webkit-scrollbar-button:end:increment": {
      height: 20,
      display: "block",
    },
  },
  title: {
    "& .MuiTypography-body1": {
      fontSize: 14,
      fontWeight: 700,
      color: "#123b64",
      lineHeight: "150%",
    },
  },
  avatar: {
    width: 48,
    height: 48,
  },
  listItem: {
    padding: "7px 0",
    "&:hover div": {
      display: "flex",
    },
    "&:hover": {
      backgroundColor: "#F2F3F4",
    },
  },
  btnsWrapper: {
    display: "none",
    alignItems: "center",
  },
  save: {
    color: "#123b64",
    background: "white",
    width: 77,
    border: "1px solid #123b64",
    marginRight: 10,
    padding: "7px 10px",
    borderRadius: 8,
    fontWeight: 500,
    lineHeight: "150%",
    textTransform: "capitalize",
    "& path": {
      fill: "#123b64",
    },
    "&.MuiButton-contained": {
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
        background: "#123b64",
        color: "white",
        "& path": {
          fill: "#fff",
        },
      },
      "&:active": {
        boxShadow: "none",
      },
    },
  },
  deleteIcon: { marginRight: 10, marginLeft: 17, padding: 0 },
});

function CollectionsList(props) {
  const {
    collections,
    displayName,
    arangoNodeKey,
    type,
    Type,
    title,
    movePinNodesToCollecton,
    movePinCollToPinColl,
    copyNodesToCollection,
    copyPinCollToPinColl,
    selectedNodes,
    page,
    nodeType,
    handleSave,
} = props;

console.log(collections,type,arangoNodeKey,Type,nodeType,"qwert")
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [modalType, setModalType] = useState("save-collection");
  const [collection, setCollection] = useState({});
  const { setrecent, recent } = useContext(DataContext);
  const [typeName, setTypeName] = useState("");
  const [data, setData] = useState("");
const [result,setResult]= useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const {
    loggedInUser: { userId },
  } = useContext(DataContext);


  const {
searchNodeData,
  } = useContext(TabContext);
  console.log(searchNodeData,"searchNodeData")
  useEffect(() => {
    console.log(recent,"recent")
    const recentData = recent.recent
    const typeNameObject = recentData.find((item)=>
      item.id === arangoNodeKey
    )
    console.log(typeNameObject,arangoNodeKey,"typeNames")
    setTypeName(typeNameObject?.typeName)
   
  }, []);
useEffect(()=>{
  console.log(recent,"recent")
  const FilterData = searchNodeData
  const typeNameObjectResult = FilterData.find((item)=>
    item.id === arangoNodeKey
  )
  console.log(FilterData,arangoNodeKey,"typeNames")
  setData(typeNameObjectResult?.typeName)

},)
const formattedArangoNodeKey = `Nodes/${arangoNodeKey}`; 
const handleSaveCollection = (collection) => {
  if (typeof props.handleSave === 'function') {
    props.handleSave(collection);
  }
  // props.handleSave(collection);
    setCollection(collection);
  

    console.log(collection, "collection");
    if (title === "Move to collection") {
      selectedNodes.length > 0
        ? movePinNodesToCollecton(collection.id)
        : movePinCollToPinColl(collection.id);
    } else if (title === "Copy and Move to collection") {
      selectedNodes.length > 0
        ? copyNodesToCollection(collection.id)
        : copyPinCollToPinColl(collection.id);
    } else {
      const payloadData = {
        arangoNodeKey: formattedArangoNodeKey,
        displayName: displayName,
        type: typeName?typeName:data,
      };
      console.log(payloadData, "data");
      CollectionService.addNodeToCollection(collection.id, userId, payloadData).then(
        (response) => {
          console.log(response, "response");
          setOpen(true);
          setModalType("saveNodeToColl");
        }
      );
    }
  };

  const handleDelete = (collectionId) => {
    CollectionService.removeNodesFromCollection(collectionId, userId, {
      nodepinkey: [`Nodes/${arangoNodeKey}`],
    }).then((res) => {
      setOpen(true);
      setModalType("deleteNodeFromColl");
    });
  };

  return (
    <List className={classes.root}>
      <CollectionModal
        open={open}
        collection={collection}
        setOpen={setOpen}
        type={modalType}
      />
      {collections && collections.map((collection) => (
        <ListItem key={collection.id} className={classes.listItem}>
          <ListItemAvatar>
            <Avatar
              variant="rounded"
              className={classes.avatar}
              alt={collection.name}
              src={collectionImg}
            />
          </ListItemAvatar>
          <ListItemText className={classes.title} primary={collection.name} />
          <div className={classes.btnsWrapper}>
            <Button
              onClick={() => handleSaveCollection(collection,typeName)}
              className={classes.save}
              variant="contained"
              startIcon={<PinIcon />}
              disableRipple
            >
              Save
            </Button>

            {page === "digital publication" ? null : (
              <IconButton
                onClick={() => handleDelete(collection.id)}
                aria-label="delete"
                className={classes.deleteIcon}
              >
                <TrashBinIcon />
              </IconButton>
            )}
          </div>
        </ListItem>
      ))}
    </List>
  );
}

export default CollectionsList;

