import { makeStyles } from "@material-ui/styles"
import { Box, FormControl, InputLabel, TextField } from "@material-ui/core"

const useStyles = makeStyles({
    formControl: {
        width: '100%',
        "& label": {
            color: '#123b64',
            fontWeight: 500,
            fontSize: 14,
            marginBottom: 8
        }
    },
    tagsInput: {
        width: '100%',
        '& .MuiInputBase-input': {
            fontSize: 14,
            color: '#123b64',
            padding: '15.5px 16px'
        },
        "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 8,
                border: '1px solid #808C9A'
            },
            '& fieldset': {
                top: 0
            },
            "&.Mui-focused fieldset": {
                top: 0,
                borderColor: "#F79F19!important",
            }
        },
        
    },
})

const CustomTextField = (props) => {
    const {
        field,
        handleChange,
        label,
        placeholder
    } = props

    const classes = useStyles()
    return (
        <Box className={classes.formControl}>
            <InputLabel>{label}</InputLabel>
            <TextField
                {...field}
                onChange={(event) => handleChange(event)}
                variant="outlined"
                InputLabelProps={{ shrink: false }}
                className={classes.tagsInput}
                placeholder={placeholder}
                type='text'
            />
        </Box>
    )
}
export default CustomTextField
