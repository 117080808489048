import React from 'react';
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    input: {
        display: "none"
    },
}))

function UploadButton({name, children, ...props}) {
    const classes = useStyles()
    return (
        <div>
            <input
                accept="*"
                className={classes.input}
                id="contained-button-file"
                multiple
                name={name}
                type="file"
                {...props}
            />
            <label htmlFor="contained-button-file">
                {children}
            </label>
        </div>
    );
}

export default UploadButton;