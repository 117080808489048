import { useEffect, useState } from 'react';
import SliderPlaceholder from '../../shared/placeholder/SliderPlaceholder';
import Pagination from '../../filter-region/pagination/Pagination';
import { makeStyles, Box, Typography } from '@material-ui/core';
import CustomizedSelect from '../../shared/inputs/CustomizedSelect';
import SearchInput from '../../shared/inputs/SearchInput';
import Loader from '../../shared/loader/Loader';
import { HeadCells } from '../../../constants/currentjobs';
import CurrentJobsTable from './oreder-table/CurrentJobsTable';

const useStyles = makeStyles(theme => ({
    inputWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '30px 0',
        [theme.breakpoints.down('992')]: {
            flexDirection: 'column',
            margin: '16px 0 10px'
        }
    },
    numberOfOrder: {
        fontSize: 14,
        fontWeight: 700,
        marginRight: 8
    },
    selectWrapper: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 275,
        [theme.breakpoints.down('992')]: {
            order: 1
        }
    },
    searchInputBox: {
        maxWidth: 503,
        width: '100%',
        [theme.breakpoints.down('992')]: {
            marginBottom: 33
        }
    },
    box: {
        width: 154,
        marginTop: 0,
        "& .MuiInputBase-root": {
            marginTop: 0
        },
        '& .MuiSelect-root': {
            padding: '5.5px 14px'
        }
    }
}))

const CurrentJobs = (props) => {
    const classes = useStyles()
    const [pageNumberLimit] = useState(5)
    const [filteredRows, setFilteredRows] = useState([])
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(7)
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const numberOfPages = []
    const totalPage = filteredRows.length
    const [searchNodeName, setSearchNodeName] = useState('')
    const indexOfLastNode = currentPage * itemsPerPage;
    const indexOfFirstNode = indexOfLastNode - itemsPerPage;
    const currentNodes = filteredRows.slice(indexOfFirstNode, indexOfLastNode)
    const {
        options,
        rows,
        setLastOrdered,
        lastOrdered,
        isLoadingOptions,
        isLoadingYourOrder
    } = props

    useEffect(() => {
        setFilteredRows(rows)
    }, [rows])

    const onChange = async (e) => {
        const orderDate = e.target.value
        setLastOrdered(orderDate)
    }

    const handleChange = (e) => {
        const value = e.target.value
        setSearchNodeName(value);
        const filteredRows = rows.filter(node => {
            const nodeName = node.nodeInfo.displayName
            return nodeName.toLowerCase().indexOf(value.toLowerCase()) !== 0
        })
        setFilteredRows(filteredRows)
    };

    for (let i = 0; i <= Math.ceil(totalPage / itemsPerPage); i++) {
        numberOfPages.push(i)
    }

    const paginate = (pageNum) => {
        setCurrentPage(pageNum)
    }

    const nextPage = () => {
        if (numberOfPages.length === currentPage) {
            setCurrentPage(currentPage)
        } else {
            setCurrentPage(currentPage + 1)
        }
        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
        }
    }

    const prevPage = () => {
        if (currentPage === 1) {
            setCurrentPage(currentPage)
        } else {
            setCurrentPage(currentPage - 1)
        }
        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
        }
    }
 
    if (isLoadingOptions || isLoadingYourOrder) {
        return <Loader />
    }
    return (
        <div>
            {
                <>
                    <Box className={classes.inputWrapper}>
                        <Box className={classes.selectWrapper}>
                            <Typography color='primary' className={classes.numberOfOrder}>
                                {filteredRows.length} jobs placed in
                            </Typography>
                            <Box className={classes.box}>
                                <CustomizedSelect
                                    id="lastOrder"
                                    name="lastOrder"
                                    value={lastOrdered}
                                    options={options}
                                    handleChange={onChange}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.searchInputBox}>
                            <SearchInput
                                value={searchNodeName}
                                setValue={setSearchNodeName}
                                handleChange={handleChange}
                                placeholder="Search Jobs"
                            />
                        </Box>
                    </Box>
                    {
                        filteredRows.length > 0 ?
                            <>
                                <CurrentJobsTable
                                    options={options}
                                    headCells={HeadCells}
                                    currentRows={currentNodes}
                                    rows={filteredRows}
                                /> 
                                <Pagination
                                    numberOfPages={numberOfPages}
                                    currentPage={currentPage}
                                    paginate={paginate}
                                    nextPage={nextPage}
                                    prevPage={prevPage}
                                    maxPageNumberLimit={maxPageNumberLimit}
                                    minPageNumberLimit={minPageNumberLimit}
                                />
                            </> : <SliderPlaceholder text="You have not placed any Jobs in the past 3 months" />
                    }

                </>
            }
        </div>
    );
}

export default CurrentJobs;