import { makeStyles } from "@material-ui/core";

export const initialFormValues = {
  DataGovernanceFocus: 0,
  MetaDataManagementExperience: 0,
  FrequencyOfDataConsumption: 0,
  Background: 0
}

export const CATEGORY_NAME = {
  business: "business",
  geography: "geography",
  product: "product",
  dataDomain: "dataDomain"
}

export const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    [theme.breakpoints.up("768")]: {
      padding: "40px!important",
      boxShadow: "0px 10px 20px rgba(23, 87, 123, 0.2)",
    },
  },
  yourPreferencesBox: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 46,
    [theme.breakpoints.down("768")]: {
      flexDirection: "column",
    },
  },
  slidersWrapper: {
    maxWidth: 460,
    width: '100%',
    marginRight: 15,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("992")]: {
      marginRight: 25,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 700,
    lineHeight: 1.5,
    marginBottom: 25,
  },
  label: {
    lineHeight: 1.1,
    fontWeight: 500,
    marginBottom: 40,
  },
  secLabelWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 2,
  },
  secondaryLabel: {
    color: "#808C9A",
    fontSize: 14,
  },
  sliderBox: {
    [theme.breakpoints.down("768")]: {
      marginBottom: 20,
    },
  },
  categoriesWrapper: {
    marginLeft: 0,
    marginTop: 20,
    maxWidth: 650,
    width: '100%',
    [theme.breakpoints.up("992")]: {
      marginLeft: 35,
    },
    [theme.breakpoints.up("768")]: {
      marginLeft: 25,
    },
  },
  yellowButton: {
    width: 182,
    height: 48,
    [theme.breakpoints.up('sm')]: {
      width: 182,
    },
  },
  selectedCategories: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 20,
    background: "#c4c4c41a",
    maxWidth: 545,
    width: '100%',
    minHeight: 100
  },
  typography: {
    lineHeight: 1.1,
    marginBottom: 8
  },
  btnWrapper: {
    display: 'flex'
  },
  category: {
    margin: 4
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20
  },
  responsibility: {
    fontWeight: 700,
    marginRight: 6.5
  },

}));


