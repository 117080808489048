import {
  Box, Button, IconButton, Input, InputAdornment, InputLabel, Typography
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Clear from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/styles";
import { useOktaAuth } from "@okta/okta-react";
import { Form, Formik, useFormik } from "formik";
import { useContext, useState } from 'react';
import { Link,  useNavigate } from "react-router-dom";
import * as Yup from "yup";
import KeyIcon from "../../assets/images/key-icon.png";
import LockIcon from "../../assets/images/lock-icon.png";
import MessageIcon from "../../assets/images/message-icon.png";
import DataContext from '../../contexts/Context';
import { useLogin } from "../../services/custom-hooks/CustomHooks";
import Loader from './../shared/loader/Loader';
import "./SignInForm.scss";

const useStyles = makeStyles({
  input: {
    border: "1px solid #808C9A",
    boxSizing: "borderBox",
    borderRadius: 8,
    padding: "14px 20px",
  },
  header: {
    fontSize: 40,
    fontWeight: 900,
    marginBottom: 30,
  },
  forgot: {
    color: "#f79f19",
    marginTop: 10,
    fontSize: 18,
    position: "center",
    marginLeft: 200,
  },
});

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email format").required("Required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
});

export const SignInForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { getloginInUser } = useContext(DataContext)
  const [showPassword, setShowPassword] = useState(false);

  const { authState, oktaAuth } = useOktaAuth();

  const onSuccess = (data) => {
    const response = data.data.response
    if (response === "login successful") {
      getloginInUser(data.data)
      navigate("/home")
    }
  }
  const resetField = (field) => {
    formik.setFieldValue(field, "");
  };
  const { mutate: checkLogin, isError, isLoading } = useLogin(onSuccess)

  const onSubmit = (values) => {
    checkLogin(values);
  };
  
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: 'calc(100vh - 80px)' }}>
        <Loader smallLoader="small-loader" />
      </Box>
    )
  }

  const loginWithRedirect = () => {
    oktaAuth.signInWithRedirect({ originalUri: "/home" });
  }

  return (
    <Box>
      <Typography className={classes.header} variant='h1' color="primary">
        Welcome to Datasouk
      </Typography>
      <p className="have-account-text text-secondary-100 fw-bold">
        No account ?
        <Link
          to="/sign-up"
          className="ms-2 sign-in-link text-primary text-decoration-none"
        >
          Sign Up
        </Link>
      </p>

      <Button
        onClick={loginWithRedirect}
        fullWidth={true}
        className="btn-signin-sso text-upppercase fw-bold text-secondary-100"
        disableElevation
        disableFocusRipple
        disableTouchRipple
        variant="outlined"
      >
        <img src={KeyIcon} alt="LockIcon" />
        Sign in with sso
      </Button>

      <hr />

      <Formik initialValues={initialValues} validationSchema={validationSchema}>
        <Form
          autoComplete="off"
          className="signin-form"
          onSubmit={formik.handleSubmit}
        >
          <div className="form-group">
            <InputLabel htmlFor="sign-email">Email</InputLabel>
            <Input
              id="sign-email"
              name="email"
              type="email"
              className={classes.input}
              fullWidth={true}
              {...formik.getFieldProps("email")}
              startAdornment={
                <InputAdornment position="start">
                  <img src={MessageIcon} alt="MessageIcon" />
                </InputAdornment>
              }
            />
            {formik.touched.email && formik.errors.email ? (
              <p className="m-0 text-primary">{formik.errors.email}</p>
            ) : null}
          </div>

          <div className="form-group">
            <InputLabel htmlFor="password" className="text-secondary-100">
              Password
            </InputLabel>
            <Input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              fullWidth={true}
              className={classes.input}
              sx={{
                border: "1px solid lightgrey",
              }}
              {...formik.getFieldProps("password")}
              startAdornment={
                <InputAdornment position="start">
                  <img src={LockIcon} alt="" />
                </InputAdornment>
              }
              endAdornment={
                <InputAdornment position="end">
                  <InputAdornment position="end">
                    {formik.touched.password && formik.values.password ? (
                      <IconButton onClick={() => resetField("password")}>
                        <Clear />
                      </IconButton>
                    ) : (
                      ""
                    )}
                  </InputAdornment>
                  <InputAdornment
                    position="end"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </InputAdornment>
                </InputAdornment>
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <p className="m-0 text-primary">{formik.errors.password}</p>
            ) : null}
          </div>
          <>{isError &&
            <Box sx={{ color: "red" }}>
              Email or password is incorrect
            </Box>
          }</>
          <Button
            fullWidth={true}
            type="submit"
            className="btn-sign-in fw-bold bg-primary text-white"
          >
            Sign in
          </Button>
          <Link to="/reset" style={{ textDecoration: "#f79f19" }}>
            <Typography className={classes.forgot}>Forgot password?</Typography>
          </Link>
        </Form>
      </Formik>
    </Box >
  )
}
