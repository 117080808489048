import { makeStyles } from '@material-ui/core';
import * as yup from 'yup'

export const formValues = {
    displayName: "",
    description: "",
    tags: [],
   // vendorname: "",
}

export const validationSchema = yup.object({
    displayName: yup.string().required('Node name is required'),
    description: yup.string().required('Description is required'),
    tags: yup.array().min(1, "Provide at least one tag"),
  // vendorname: yup.string().required('Vendor Name is required'),
})

export const useStyles = makeStyles({
    container: {
        minHeight: "calc(100vh - 150px)",
        paddingBottom: 100
    },
    mainTitle: {
        margin: "60px 0 24px",
        lineHeight: 1.2,
    },
    shadowWrapper: {
        boxShadow: '0px 10px 20px rgba(23, 87, 123, 0.2)',
        marginBottom: 40,
        borderRadius: 4
    },
    title: {
        padding: '20px 40px',
        borderBottom: '1px solid #E0E7EC'
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '40px 40px 30px'
    },
    leftSide: {
        width: '100%',
        maxWidth: 460,
        marginRight: 25
    },
    rightSide: {
        width: '100%',
        maxWidth: 650,
        marginLeft: 25
    },
    radioWrapper: {
        display: "flex",
        flexDirection: "row",
        "& .MuiFormControlLabel-root": {
            marginRight: 50,
        },
    },
    suggested: {
        marginRight: 10,
        color: "#123b64b3",
        lineHeight: "110%",
    },
    tag: {
        lineHeight: "110%",
        color: "#123b64",
        fontWeight: 400,
        marginRight: 4,
    },
    buttonBox: {
        display: 'flex',
        justifyContent: "space-between",
        marginBottom: 100,
        '& button': {
            width: 219,
            borderRadius: 8
        }
    },
    saveButton: {
        marginRight: 15
    },

})