import { Box, Typography, makeStyles } from '@material-ui/core';
import cloud from '../../../assets/images/node-creation/cloud.png'
import arrowRight from '../../../assets/images/node-creation/righttArrow.png'
import arrowLeft from '../../../assets/images/node-creation/leftArrow.png'
import NodeIcon from './NodeIcon';

const useStyles = makeStyles({
    cloud: {
        marginBottom: 8
    },
    mainImg: {
        position: 'absolute',
        left: '35%'
    },
    link: {
        color: '#123b64',
        fontSize: 14,
        fontWeight: 500,
        '&:hover': {
            color: '#123b64'
        }
    },
    arrow: {
        position: 'absolute',
        left: '49%'
    },
    arrowLeft: {
        position: 'absolute',
        right: '49%'
    },
    paragraph: {
        fontWeight: 400
    }
})

function NodeHierarchyCard({ title, nodeType, arrow, stepNumber, readMore = false }) {
    const classes = useStyles()

    return (
        <Box sx={{ width: 400 }}>
            <Box mb={3.75} sx={{ textAlign: 'center', position: 'relative', height: 128 }}>
                <Box className={classes.mainImg}>
                    <NodeIcon nodeType={nodeType} stepNumber={stepNumber}/>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'center', height: '100%' }}>
                    <img className={classes.cloud} src={cloud} alt="cloud" />
                </Box>
            </Box>
            <Typography align='center' variant='h4' color='primary'>
                {title}
            </Typography>
            <Typography className={classes.paragraph} align='center' variant='h6' color='primary'>
              add the Data 
            </Typography>
            {
                readMore ?
                    <Box sx={{ textAlign: 'center' }}>
                        <a href='#' className={classes.link}>Read more</a>
                    </Box> : null
            }
            {
                arrow && arrow === "right" ?
                    <Box sx={{ position: 'relative', height: 103 }} mt={1}>
                        <img className={classes.arrow} src={arrowRight} alt="arrow" />
                    </Box> :
                    arrow && arrow === "left" ?
                        <Box sx={{ position: 'relative', height: 103 }} mt={1}>
                            <img className={classes.arrowLeft} src={arrowLeft} alt="arrow" />
                        </Box> : null
            }
        </Box>
    );
}

export default NodeHierarchyCard;