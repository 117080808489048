import React from "react";
import { InputBase, makeStyles, withStyles, InputAdornment } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { SearchIcon } from "../../icons";

const Input = withStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#F9F9F9",
    borderRadius: 8,
    height: 32,
    "& ::placeholder": {
      color: "#123b64!important",
      opacity: 1,
    },
    "&:hover": {
      background: "#E0E7EC",
    },
    "&:focus-within": {
      border: "1px solid #f79f19",
      background: "#F2F3F4",
    },
  },
  input: {
    height: 20,
    color: "#123b64",
    position: "relative",
    fontSize: 14,
    padding: "0 16px",
    caretColor: "#f79f19",
  },
}))(InputBase);

const useStyles = makeStyles({
  clearIcon: {
    marginRight: 16,
    width: 16,
    background: "transparent",
    cursor: "pointer",
    "& path": {
      color: "#123b64",
    },
  },
  forRole: {
    background: "#F2F3F4",
    border: "none",
  },
});

const SecondarySearchBar = ({ rows, setFilteredRows, searchText, setSearchText }) => {
  const classes = useStyles();

  const handleChange = (event) => {
    setSearchText(event.target.value);
    const res = rows.filter((item) =>
      item.name.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredRows(res);
  };

  const clearInputText = () => {
    setSearchText("");
  };

  return (
    <Input
      autoComplete="off"
      placeholder="Search your orders"
      onChange={handleChange}
      className={classes.forRole}
      value={searchText}
      id="bootstrap-input"
      endAdornment={
        <InputAdornment position="end">
          {searchText && (
            <ClearIcon onClick={clearInputText} className={classes.clearIcon} />
          )}
        </InputAdornment>
      }
      startAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

export default SecondarySearchBar;
