import { Box, makeStyles, Typography } from '@material-ui/core';
import KebabMenu from '../../popover/KebabMenu';
import { FavouriteCheckbox } from '../../inputs/CheckBox';
import TooltipContent from '../../full-node-card/TooltipContent';
import React from 'react';
import { useContext } from 'react';
import DataContext from '../../../../contexts/Context';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: "center",
        justifyContent: "space-between",
        width: "68px",
        height: 40,
        paddingRight: 12,
        borderRadius: 10,
        boxShadow: "0px 4px 10px rgba(1, 25, 54, 0.1)"
    },
    typography: {
        borderLeft: '2px solid #123b64',
        paddingLeft: 9
    }
})

function FavoriteBtn({ data }) {
    const classes = useStyles()
    const { checkedNodes, addFavCards } = useContext(DataContext)
    const { pathname } = useLocation()
    const position = { top: 60, right: -35, left: 'unset', bottom: 'unset' }
    const tooltipPosition = { top: 'unset', right: -35, left: 'unset', bottom: 60 }
    return (
        <Box className={classes.root}>
            <KebabMenu
                position={pathname === "/home" ? tooltipPosition : position}
                type="favorites"
                checked={checkedNodes[data.Id]}
                button={
                    <FavouriteCheckbox
                        style={{ marginRight: 20 }}
                        value={data.id}
                        checked={checkedNodes[data.id] === true}
                        onChange={(e) => addFavCards(data, e)}
                    />
                }
            >
                <TooltipContent />
            </KebabMenu>
            <Typography className={classes.typography}>
                {data.count ? data.count : checkedNodes[data.id] ? 1 : 0}
            </Typography>
        </Box>
    );
}

export default FavoriteBtn;