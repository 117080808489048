import {
    Box,
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableRow,
    Typography,
    withStyles,
  } from "@material-ui/core";
  import Collapse from "@material-ui/core/Collapse";
  import MuiTableCell from "@material-ui/core/TableCell";
  import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
  import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
  import React, { useState } from "react";
  import {
    SecondaryButton,
    SuccessButton,
    SuccessButton2,
    TextButton,
  } from "../../../../../shared/buttons/custom-buttons/CustomButtons";
  import {
    StyledChildTableRow,
    StyledTableRow,
  } from "../../../../../shared/table/semantics-table/table-details/TableDetails";
  import NewTermModal from "../popupformsoflogo/NewTermModal";
  import CustomModal from "../../logopages/popupformsoflogo/CustomModal";
  import NewTermModal1 from "../popupformsoflogo/NewTermModal1";
  import NewTermModal2 from "../popupformsoflogo/NewTermModal2";
  import Tooltip from "../../../../../shared/tooltip/Tooltip";
import ApproveCustomRow from "./ApproveCustomRow";
  
  const TableCell = withStyles({
    root: {
      borderBottom: "none",
    },
  })(MuiTableCell);
  
  const useRowStyles = makeStyles({
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: "#F6F8F9",
        overflow: "invisible",
      },
    },
    tableCell: {
      "& p": {
        maxHeight: 64,
        overflow: "hidden",
        display: "-webkit-box",
        "-webkit-line-clamp": 2,
        "-webkit-box-orient": "vertical",
      },
    },
    columnName: {
      display: "flex",
    },
    selectWrapper: {
      width: 120,
      marginLeft: "auto",
      "& .MuiSelect-root": {
        padding: "5.5px 16px",
      },
    },
    iconButton: {
      marginLeft: -4,
    },
    formControlLabel: {
      "& .MuiCheckbox-root": {
        padding: 0,
        marginRight: 15,
      },
    },
    button: {
      color: "#123B64",
      left: 600,
    },
    textButton: {
      fontWeight: 700,
      justifyContent: "left",
      "& .MuiButton-startIcon": {
        marginRight: -240,
      },
    },
    addNewTerm: {
      background: "#E0E7EC!important",
      // width:"flex"
    },
    // successButton2: {
    //   fontSize: 12,
    //   fontWeight: 400,
    //   marginLeft: 5,
    // },
  });
  
  const ApproveRow = (props) => {
    const {
      row,
      columns,
      page,
      index,
      semanticData,
      updateStatus,
      onChange,
      getSemanticsData,
      getDescriptionData,
      getClassificationData,
      getSemanticsDataset,
    } = props;
  
    const [open, setOpen] = useState(true);
    // const [selectedColumn, setSelectedColumn] = useState("");
    const [show1, setShow1] = useState(false);
    const classes = useRowStyles();
  
    const setSuitableData = (status, value) => {
      // if (status === "Status") {
      //   if (value === "Accepted" || value === "accepted") {
      //     return <SuccessButton>Accepted</SuccessButton>;
      //   } else if (value === "Rejected" || value === "rejected") {
      //     return <SecondaryButton>Rejected</SecondaryButton>;
      //   }
      // }
    };
  
    const setStyle = (colName) => {
      if (colName === "Status") {
        if (page === "classification") {
          return { minWidth: 20 };
        } else {
          return { minWidth: 142 };
        }
      } else {
        return { minWidth: "unset" };
      }
    };
  
    // const handleCheckboxChange = ( selectedCol) => {
    //   setSelectedColumn(selectedCol);
    // };
  
   
    // const areAllCheckboxesSelected = () => {
    //   const allColumnNames = columns.map((column) => column.id);
     
    // };
  
    return (
      <React.Fragment>
        <StyledTableRow>
          {columns.map((column) => {
            const value = row[column.id];
  
            return (
              <TableCell
                style={setStyle(column.id)}
                className={classes.tableCell}
                key={column.id}
                colSpan={column.id === "Status" ? 2 : 1}
              >
                {column.id === "columnName" ? (
                  <Box className={classes.columnName}>
                    <IconButton
                      className={classes.iconButton}
                      aria-label="expand row"
                      size="small"
                      onClick={() => setOpen(!open)}
                    >
                      {open ? (
                        <KeyboardArrowUpIcon
                          style={{ fontSize: 16 }}
                          color="primary"
                        />
                      ) : (
                        <KeyboardArrowDownIcon
                          style={{ fontSize: 16 }}
                          color="primary"
                        />
                      )}
                    </IconButton>
                    <Box sx={{ marginLeft: 19 }}>
                    <Typography>{value}</Typography>
                  </Box>
                  </Box>
                ) : column.id === "Description" ? (
                  <Box className={classes.columnName}>
                    <Box sx={{ marginLeft: 9 }}>
                      <Tooltip
                        variant="subtitle2"
                        maxHeight={84}
                        maxWidth={310}
                        fontSize={14}
                        title={row.Description}
                      />
                    </Box>
                  </Box>
                ) : column.id === "description" ? (
                  <Box className={classes.columnName}>
                    <Box sx={{ marginLeft: 9 }}>
                      <Tooltip
                        variant="subtitle2"
                        maxHeight={84}
                        maxWidth={310}
                        fontSize={14}
                        title={row.suggestedNames
                          .map((name) => name.Description)
                          .join(", ")}
                      />
                    </Box>
                  </Box>
                ) : column.id === "name" ? (
                  <Box className={classes.columnName}>
                    <Box sx={{ marginLeft: 9 }}>
                      <Typography color="primary">
                        <Tooltip
                          variant="subtitle2"
                          maxHeight={84}
                          maxWidth={310}
                          fontSize={14}
                          title={row.name}
                        />
                      </Typography>
                    </Box>
                  </Box>
                ) : column.id === "recommendedName" ? (
                  <Box sx={{ display: "flex" }}>
                    <Typography color="primary">
                      {row.recommendedName || row.name}
                    </Typography>
                    {row.Source ? (
                      <SuccessButton2 className={classes.successButton2}>
                        {row.Source}
                      </SuccessButton2>
                    ) : null}
                  </Box>
                ) : column.id === "Status" ? (
                  <>{setSuitableData(column.id, value)}</>
                ) : (
                  value
                )}
              </TableCell>
            );
          })}
        </StyledTableRow>
        {row.suggestedNames ? (
          <TableRow>
            <TableCell
              style={{ padding: 0  }}
              colSpan={page !== "classification" ? 5 : 6}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Table aria-label="purchases">
                  <TableBody>
                    {row.suggestedNames.map((suggestedName, i) => (
                      <ApproveCustomRow
                        key={i}
                        index={index}
                        suggestedNameIndex={i}
                        columnName={row.columnName}
                        keyProp={row.key}
                        SuggestedName={suggestedName}
                        updateStatus={updateStatus}
                        page={page}
                      />
                    ))}
                    <StyledChildTableRow className={classes.addNewTerm}>
                      <TableCell style={{ width: 490 }} />
                      <TableCell colSpan={page !== "classification" ? 4 : 5}>
                        <CustomModal
                          setOpen={setShow1}
                          open={show1}
                        >
                        </CustomModal>
                      </TableCell>
                    </StyledChildTableRow>
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        ) : null}
      </React.Fragment>
    );
  };
  export default ApproveRow;
  