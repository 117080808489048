import { forwardRef } from "react";
import Loader from '../../shared/loader/Loader';
import PaginationWrapper from "./PaginationWrapper";

export const SearchResultsNodeList = forwardRef((props, ref) => {
    const {
        setSelectedNodes,
        selectedNodes,
        totalPageNumber,
        isInprogress,
        onPageChange,
        currentPage,
        setCardData,
        isListView,
        nodes=[],
        tab
    } = props

    if (tab && tab.paging) {
        var { totalNumberOfPages = 1 } = tab?.paging
    }
    const paginate = (pageNum) => {
        onPageChange(pageNum)
        ref && ref.current.scrollIntoView({ behaivor: "smooth" })
    }

    if (tab && tab.loading) {
        return <Loader />
    }

    return (
        <>
            {
                nodes.length ?
                    <PaginationWrapper
                        totalPageNumber={totalPageNumber}
                        isNodeAvailable={nodes.length}
                        setSelectedNodes={setSelectedNodes}
                        selectedNodes={selectedNodes}
                        isInprogress={isInprogress}
                        setCardData={setCardData}
                        currentPage={currentPage}
                        isListView={isListView}
                        paginate={paginate}
                        nodes={nodes}
                    /> :
                    <PaginationWrapper
                        totalPageNumber={totalNumberOfPages}
                        isNodeAvailable={tab?.data?.length}
                        setCardData={setCardData}
                        currentPage={currentPage}
                        isListView={isListView}
                        paginate={paginate}
                        nodes={tab?.data}
                    />
            }
        </>
    )
}) 
