import { Box, Chip, Typography, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles({
    objectiveBox: {
        maxWidth: 508,
        width: '100%',
        minHeight: 48,
        border: '1px solid #808C9A',
        padding: '6px 12px',
        borderRadius: 8,
        marginTop: 10,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center'
    },
    chip: {
        fontSize: 14,
        color: '#123b64',
        background: '#E0E7EC',
        margin: 4,
        borderRadius: 12,
        height: 28
    },
    clearIcon: {
        color: '#123b64',
        width: 14,
        height: 14,
        marginRight: 10
    },
})

function ObjectiveInput({checkedObjectives=[], setCheckedObjectives}) {
    const classes = useStyles()

    const handleDelete = (name) => {
        const isExistObjectives = checkedObjectives.find(objective => objective.name === name)
        if(isExistObjectives) {
            const filteredObjectives = checkedObjectives.filter(objective => objective.name !== name)
            setCheckedObjectives(filteredObjectives)
        }
    }

    return (
        <Box className={classes.objectiveBox}>
            {
                checkedObjectives.length ?
                checkedObjectives.map((objective, index) => (
                        <Chip
                            key={index}
                            className={classes.chip}
                            label={objective.name}
                            deleteIcon={<ClearIcon className={classes.clearIcon} />}
                            onDelete={() => handleDelete(objective.name)}
                        />
                    )) : <Typography variant="body2" color="primary">Select objectives</Typography>
            }
        </Box>
    );
}

export default ObjectiveInput;