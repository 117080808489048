import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import rocket from "../../../../../assets/images/node-creation/rocket.png";
import nodeIcon from "../../../../../assets/images/node-creation/node.png";
import codeIcon from "../../../../../assets/images/node-creation/codeIcon.png";
import infraStructure from "../../../../../assets/images/node-creation/infrastructure.png";
import metaData from "../../../../../assets/images/node-creation/metaDataIcon.png";
import CreateNewRole from "../../../../navbar/nav-dropdown-input/logopagedesign/logopages/popupformsoflogo/CreateNewRole";
import CustomModal from "../../../../navbar/nav-dropdown-input/logopagedesign/logopages/popupformsoflogo/CustomModal";
import { ApiIcon, DataProductIcon, DataSetIcon } from "../../../../icons";
import { YellowButton } from "../../../../shared/buttons/custom-buttons/CustomButtons";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useStyles, NEW_NODE } from "../../../../my-work/node-creation/new-node/newNodeDetails"
import { NODE_TYPE } from "../../../../../pages/node-creating/nodeCreation";
import NodeHierarchyCard from "../../../../shared/nodeCreationHierarchy/NodeHierarchyCard";
import { Link } from "react-router-dom";

function Model({ newNodeType, setNewNodeType }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { api, dataProduct, dataSet ,model} = NEW_NODE;
  const { api: Api, dataProduct: DataProduct, dataSet: DataSet } = NODE_TYPE;

  const handleNodeTitle = () => {
    let title;
    if (newNodeType === DataProduct) {
      title = dataProduct.title;
    } else if (newNodeType === DataSet) {
      title = dataSet.title;
    } else if (newNodeType === Api) {
      title = api.title;
    }
    return title;
  };

  const handleNodeDescription = () => {
    let description;
    if (newNodeType === DataProduct) {
      description = dataProduct.description;
    } else if (newNodeType === DataSet) {
      description = dataSet.description;
    } else {
      description = api.description;
    }
    return description;
  };

  return (
    <Box mb={12.5}>
      <CustomModal setOpen={setOpen} open={open}>
        <CreateNewRole
          newNode={newNodeType}
          handleClose={() => setOpen(false)}
        />
      </CustomModal>
      <Box mt={5.5} mb={8.75} sx={{ display: "flex" }}>
        {/* left side box */}
        <Box className={classes.stepsWrapper}>
          <Box sx={{ position: "relative", height: 103 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box className={classes.circle}>1</Box>
              <Typography variant="h3" color="primary">
                Select Node
              </Typography>
            </Box>
            <Box className={classes.verticalLine} />
          </Box>
          <Box sx={{ position: "relative", height: 103 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box className={classes.circle}>2</Box>
              <Typography variant="h3" color="primary">
                Registration
              </Typography>
            </Box>
            <Box className={classes.verticalLine} />
          </Box>
          <Box sx={{ position: "relative", height: 103 }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box className={classes.circle}>3</Box>
              <Typography variant="h3" color="primary">
                Curate
              </Typography>
            </Box>
            <Box className={classes.verticalLine} />
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box className={classes.circle}>4</Box>
            <Typography variant="h3" color="primary">
              Publish
            </Typography>
          </Box>
        </Box>
        {/* right side box */}
        <Box className={classes.nodeTypeWrapper}>
          <Box>
            <Typography variant="h3" color="primary">
              {handleNodeTitle()}
            </Typography>
            <Typography
              className={classes.description}
              variant="body2"
              color="primary"
            >
              {handleNodeDescription()}
            </Typography>
            {/* Node Type Button wrapper */}
            <Box
              mt={2}
              sx={{ display: "flex", flexWrap: "wrap", marginBottom: "auto" }}
            >
              <Box
                mr={2}
                mb={3}
                onClick={() => setNewNodeType(DataProduct)}
                className={
                  newNodeType === DataProduct
                    ? classes.activeNodeTypeButton
                    : classes.nodeTypeButton
                }
              >
                <Box className={classes.iconWrapper}>
                  <DataProductIcon />
                </Box>
                <Typography variant="h3">Data Product</Typography>
              </Box>
              <Box
                mr={2}
                mb={3}
                onClick={() => setNewNodeType(DataSet)}
                className={
                  newNodeType === DataSet
                    ? classes.activeNodeTypeButton
                    : classes.nodeTypeButton
                }
              >
                <Box className={classes.iconWrapper}>
                  <DataSetIcon />
                </Box>
                <Typography variant="h3">Feature Set</Typography>
              </Box>
            
                <Box
                  mb={3}
                  onClick={() => setNewNodeType(Api)}
                  className={
                    newNodeType === Api
                      ? classes.activeNodeTypeButton
                      : classes.nodeTypeButton
                  }
                >
                  <Box className={classes.iconWrapper}>
                    <ApiIcon />
                  </Box>
                  <Typography variant="h3" >
                    Model
                  </Typography>
                </Box>
             
            </Box>
          </Box>
          <YellowButton
            onClick={() => {
              newNodeType == model ? setOpen(true) : setOpen(false);
            }}
            className={classes.yellowButton}
            endIcon={<ArrowForwardIosIcon />}
          >
            Start Create
          </YellowButton>
        </Box>
      </Box>
      {/* How does it work */}
      <Box>
        <Typography
          variant="h1"
          color="primary"
          align="center"
          className={classes.title}
        >
          How Does It Work?
        </Typography>
        <Box className={classes.nodeHierarchyWrpper}>
          <Box mr={2}>
            <Box mb={7.5}>
              <NodeHierarchyCard
                title="Start Create Node"
                nodeType={nodeIcon}
                stepNumber={rocket}
                arrow="right"
              />
            </Box>
            <NodeHierarchyCard
              title="Code"
              nodeType={codeIcon}
              arrow="right"
              readMore={true}
              stepNumber={2}
            />
          </Box>
          <Box ml={2} mt={19.25}>
            <Box mb={7.5}>
              <NodeHierarchyCard
                title="Data and Metadata"
                nodeType={metaData}
                arrow="left"
                readMore={true}
                stepNumber={1}
              />
            </Box>
            <NodeHierarchyCard
              title="Infrastructure"
              nodeType={infraStructure}
              readMore={true}
              stepNumber={3}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default Model;
