import React from 'react';
import ShortNodeCard from '../../shared/short-node-card/ShortNodeCard';
import Slider from 'react-slick';
import NextArrow from '../next-arrow/NextArrow';
import PrevArrow from '../prev-arrow/PrevArrow';
import './node-slider.scss';
import { useLocation } from 'react-router-dom';

const ShortNodeCardSlider = ({ data }) => {

    const { pathname } = useLocation()

    const sliderSettings = {
        dots: true,
        infinite: data.length > 6,
        speed: 500,
        slidesToShow: pathname === "/home" ? 3 : 2,
        slidesToScroll: pathname === "/home" ? 3 : 2,
        rows: 3,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    }

    return (
        <div className='card-slider-wrapper'>
            <Slider {...sliderSettings}>
                {
                    data.map((cardItem, index) => (
                        <div key={index} className="slide-container py-2 container">
                            <div className="row gx-4 gy-3">
                                <div className="col-sm-12 saved-card-container" >
                                    <ShortNodeCard
                                        key={index}
                                        data={cardItem.label ? cardItem.label : cardItem} />
                                </div>
                            </div>
                        </div>
                    )
                    )
                }
            </Slider >
        </div>
    )
}

export default ShortNodeCardSlider;

