import React from 'react';
import { Box, InputBase, makeStyles, withStyles, InputAdornment } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear';

const Input = withStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#fff',
        border: '1px solid #123b64',
        borderRadius: 8,
        height: 48,
        paddingLeft: 16,
        '& ::placeholder': {
            color: '#123b64!important',
            opacity: 1
        },
        "&:focus-within": {
            border: '1px solid #f79f19',
        }
    },
    input: {
        height: 20,
        color: '#123b64',
        position: 'relative',
        fontSize: 14,
        padding: '14px 16px',
        caretColor: '#f79f19'
    },
}))(InputBase);

const useStyles = makeStyles({
    clearIcon: {
        width: 20,
        background: 'transparent',
        cursor: 'pointer',
        '& path': {
            color: '#123b64'
        }
    },
    searchIconWrapper: {
        width: 48,
        height: 46,
        background: '#F79F19',
        borderRadius: '0 8px 8px 0',
        marginLeft: 20,
        padding: '14px 15px',
        cursor: 'pointer',
        "&:hover": {
            background: '#C6811A'
        }
    },
    inputAdornment: {
        marginLeft: 10,
        '& svg': {
            stroke: '#123b64',
            opacity: 0.5
        }
    }
})


const SearchInput = ({value, setValue, handleChange, placeholder}) => {
    const classes = useStyles();
   
    return (
        <Input
            autoComplete='off'
            placeholder={placeholder}
            onChange={handleChange}
            value={value}
            id="bootstrap-input"
            endAdornment={
                <InputAdornment position="end">
                    {
                        value && <ClearIcon onClick={() => setValue('')} className={classes.clearIcon} />
                    }
                    <Box className={classes.searchIconWrapper}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.5 16.5L12.875 12.875M8.16667 14.8333C11.8486 14.8333 14.8333 11.8486 14.8333 8.16667C14.8333 4.48477 11.8486 1.5 8.16667 1.5C4.48477 1.5 1.5 4.48477 1.5 8.16667C1.5 11.8486 4.48477 14.8333 8.16667 14.8333Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </Box>
                </InputAdornment>
            }
        />
    );
}

export default SearchInput;