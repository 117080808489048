import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    paper: {
        width: 328,
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        padding: '40px 15px',
        boxShadow: '0px 4px 20px rgba(1, 25, 54, 0.1)',
        textAlign: 'center',
        color: '#123b64',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.up('578')]: {
            width: 458,
            padding: '50px 0',
        }
    },
    title: {
        fontSize: 18,
        lineHeight: 1.5,
        fontWeight: 700,
        marginBottom: 20,
        [theme.breakpoints.up('578')]: {
            fontSize: 22,
            marginBottom: 16,
        }
    },
    paragraph: {
        width: 244,
        lineHeight: 1.4,
        marginBottom: 24,
        [theme.breakpoints.up('578')]: {
            width: 391
        }
    },
    buttonswrapper: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.down('578')]: {
            flexDirection: 'column'
        }
    },
    cancelBtn: {
        color: '#123b64',
        background: '#fff',
        width: 182,
        border: '1px solid #123b64',
        marginRight: 15,
        padding: '12px 30px',
        borderRadius: 12,
        fontWeight: 700,
        lineHeight: '150%',
        textTransform: 'capitalize',
        '&.MuiButton-contained': {
            boxShadow: 'none',
            '&:hover': {
                boxShadow: 'none',
                background: '#F2F3F4',
            },
            '&:active': {
                boxShadow: 'none'
            }
        },
        [theme.breakpoints.down('578')]: {
            width: '100%',
            marginRight: 0,
            order: 1
        }
    },
    deleteBtn: {
        color: '#fff',
        background: '#F79F19',
        width: 182,
        padding: '12px 30px',
        borderRadius: 12,
        fontWeight: 700,
        lineHeight: '150%',
        textTransform: 'capitalize',
        '&.MuiButton-root:hover': {
            backgroundColor: '#F79F19'
        },
        [theme.breakpoints.down('578')]: {
            marginBottom: 20,
            width: '100%',
        }
    },
}));

function DeleteModal({handleClose,collections}) {
    const classes = useStyles();
    const [searchParams] = useSearchParams();
    const key = searchParams.get("key");
    useEffect(() => {
        console.log("collectionname", key); // Debugging line
        // getNodeTags(key);
        //getOptions();
      }, []);
    

    return (
        <div className={classes.paper}>
            <h2 className={classes.title} id="transition-modal-title">Are you sure?</h2>
            <p className={classes.paragraph} id="transition-modal-description">
                This pin will be permanently removed from Collection .
                This action cannot be undone.
            </p>
            <Box className={classes.buttonswrapper} >
                <Button onClick={handleClose} disableRipple className={classes.cancelBtn}>
                    Cancel
                </Button>
                <Button onClick={handleClose} disableRipple className={classes.deleteBtn}>
                    Delete node
                </Button>
            </Box>
        </div>
    );
}

export default DeleteModal;