import { IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import ShareIcon from "./ShareIcon";

const useStyles = makeStyles((theme) => ({
  shareWrapper: {
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: 410,
    padding: "20px 15px 15px",
    boxShadow: "0px 2px 20px rgba(23, 87, 123, 0.2)",
    borderRadius: 16,
  },
  title: {
    width: 268,
    textAlign: "center",
    fontSize: 22,
    color: "#123b64",
    marginBottom: 20,
    fontWeight: 700,
    lineHeight: "33px",
  },
  iconButton:{
    position: "relative",
    display: "flex",
    marginRight:330,
    padding: 0,
    "& svg": {
      width: 18,
      height: 25,
    }
  }
}));

const Share = ({ data, type, setOpenShare }) => {
  const classes = useStyles();

  return (
    <div className={classes.shareWrapper}>
       <IconButton onClick={() => setOpenShare(false)} className={classes.iconButton}>
                <CloseIcon  />
              </IconButton>
      <Typography className={classes.title} color="primary" variant="h5">
        Share this with your Community
      </Typography>
     
      <ShareIcon setOpenShare={setOpenShare} type={type} nodeData={data} />
    </div>
  );
};

export default Share;
