import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import CollapsibleRow from "./CollapsibleRow";

const useStyles = makeStyles({
  tableContainer: {
    boxShadow: "0px 10px 20px rgba(23, 87, 123, 0.2)",
  },
});

export default function TeamTable({ rows }) {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer} component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Team name</TableCell>
            <TableCell>Participants</TableCell>
            <TableCell>Created on</TableCell>
            <TableCell>Last modified on</TableCell>
            <TableCell>Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <CollapsibleRow key={row.key} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
