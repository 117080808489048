import { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
    },
    dropdown: {
        position: 'absolute',
        top: props => props.type === 'avatar' ? 44 : props.position ? props.position.top : 22,
        right: props => props.type === 'avatar' ? 0 : props.position ? props.position.right : -10,
        left: props => props.position ? props.position.left : 'unset',
        bottom: props => props.position ? props.position.bottom : 'unset',
        zIndex: 99,
        width: 'max-content'
    },
    btnWrapper: {
        width: props => props.type === 'avatar' || props.type === "favorites" ? 40 : 'max-content',
        marginLeft: props => props.marginLeft ? props.marginLeft : "unset"
    },
}));


const KebabMenu = ({ children, button, checked, ...props }) => {
    const classes = useStyles(props);
    const [open, setOpen] = useState(false)
    const { pathname } = useLocation()

    useEffect(() => {
        if (pathname === "/profile") {
            setOpen(false)
        }
    }, [pathname])

    const handleClick = (event) => {
        event.stopPropagation()
        setOpen((prev) => !prev);
    };

    const handleFavoriteClick = (event) => {
        event.stopPropagation()
        if (checked && open) {
            setOpen(false)
        } else if (!!!checked) {
            setOpen(true)
        }
    }

    const handleClickAway = () => {
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div className={classes.root}>
                <div
                    className={classes.btnWrapper}
                    onClick={props.type === "favorites" ? (e) => handleFavoriteClick(e) : (e) => handleClick(e)}
                >
                    {button}
                </div>
                {open ?
                    <div className={classes.dropdown}>
                        {children}
                    </div> : null}
            </div>
        </ClickAwayListener>
    );
}
export default KebabMenu