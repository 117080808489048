import { Box, Container, makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { NodeCreationService } from "../../../services/NodeCreationService";
import { NodeList } from "../../filter-region/node-list/NodeList";
import { FilterIcon, TrashBinIcon } from "../../icons";
import {
  CircleButton,
  OutlinedButton,
} from "../buttons/custom-buttons/CustomButtons";
import ListViewTypeButton from "../buttons/list-view-button/ListViewTypeButton";
import ErorrPage from "../error/Erorr";
import SearchInput from "../inputs/SearchInput";
import Loader from "../loader/Loader";
import SliderPlaceholder from "../placeholder/SliderPlaceholder";
import { CustomTooltip } from "../tooltip/NodeTooltip";
import { findNodeIcon } from "./sourcedataseticons";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "calc(100vh - 150px)",
    paddingBottom: 100,
    paddingTop: 40,
  },
  header: {
    marginBottom: 30,
  },
  validation: {
    marginBottom: 60,
  },
  rightSideContent: {
    display: "flex",
    alignItems: "center",
  },
  tooltip: {
    fontSize: 12,
    minWidth: 108,
    textAlign: "center",
    color: "#123b64",
    padding: "7px 5px",
    borderRadius: 6,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: 20,
  },
  buttonsource: {
    background: "#ffffff",
    border: "none",
    color: "#123B64",
    padding: "2px 16px",
    width: "500px",
    height: "265px",
    borderRadius: 12,
    marginLeft: "100px",
    marginBottom: "100px",

    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "background 0.3s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      background: "#E0E7EC",
    },
  },

  button: {
    width: 219,
    "& span": {
      fontWeight: 700,
    },
  },
}));

const SourceDataset = () => {
  const apiHandler = () => {
    window.open("https://pkware.alationcatalog.com/sources/", "_blank");
  };

  const classes = useStyles();
  const [searchNodeName, setSearchNodeName] = useState("");
  const [isListView, setIsListView] = useState(false);
  const [isError, setIsError] = useState(false);
  const [nodes, setNodes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchedNodes, setSearchedNodes] = useState([]);
  const scrollRef = useRef(null);
  const { key, nodeName } = useParams();
  const [userData, setUserData] = useState([]);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearchNodeName(e.target.value);
    const filteredNodes = nodes.filter((node) => {
      return node.displayName
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    setSearchedNodes(filteredNodes);
  };

  // const sourceSelection = () => {
  //   const payloadData = {
  //     key: nodes.source,
  //     pagination: {
  //       pageNumber: 1,
  //       pageSize: 9,
  //     },
  //   };
  //   return NodeCreationService.getDatabasesFromSource(payloadData)
  //     .then((response) => {
  //       if (response.data) {
  //          setUserData(response.data);
  //         navigate(`/data_set_samplecard/${nodeName}/${key}`)
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching more from user:", error);
  //     });
  // };
  // console.log("njhvv",nodes)

  useEffect(() => {
    NodeCreationService.getDatasetSourcesystems()
      .then((res) => {
        console.log("fredfed",res);
        setNodes(res.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsError(true);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    setSearchedNodes(nodes);
  }, [nodes]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <ErorrPage />;
  }

  return (
    <Container ref={scrollRef} className={classes.root}>
      <Box>
        <Typography
          className={classes.header}
          variant="h1"
          color="primary"
          align="center"
        >
          {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
        </Typography>
        <Typography
          className={classes.validation}
          variant="h3"
          color="primary"
          align="center"
        >
          Please select any of the Sources,you may find Databases related to
          them!!
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 45,
          }}
        >
          <Box sx={{ maxWidth: 415, width: "100%" }}>
            <SearchInput
              value={searchNodeName}
              setValue={setSearchNodeName}
              handleChange={handleChange}
            />
          </Box>
          <Box className={classes.rightSideContent}>
            <CustomTooltip
              arrow
              title={
                <Typography className={classes.tooltip}>
                  Create New Dataset
                </Typography>
              }
            >
              <CircleButton
                onClick={apiHandler}
                style={{ margin: "0 8px" }}
                disableRipple
              >
                <AddIcon />
              </CircleButton>
            </CustomTooltip>
            <CustomTooltip
              arrow
              title={
                <Typography className={classes.tooltip}>Open filter</Typography>
              }
            >
              <CircleButton style={{ margin: "0 8px" }} disableRipple>
                <FilterIcon />
              </CircleButton>
            </CustomTooltip>
            <CustomTooltip
              arrow
              title={
                <Typography className={classes.tooltip}>Delete</Typography>
              }
            >
              <CircleButton style={{ margin: "0 8px" }} disableRipple>
                <TrashBinIcon />
              </CircleButton>
            </CustomTooltip>
            <Box ml={4}>
              <ListViewTypeButton
                handleListViewChange={setIsListView}
                isListView={isListView}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {nodes.length ? (
  <ul>
    
    {nodes.map((node, index) => (
      <Link   style={{ textDecoration: "none", color: "#123B64" }}
      to={`/data_set_samplecard/${nodeName}/${key}?source=${node.source}`}>
      <button  className={classes.buttonsource} key={index}>
      <Box style={{ marginBottom: '300px',margin: '8px', textAlign: 'left' }}>
        <img
          src={findNodeIcon(node.source)}
          alt={node.source}
          style={{ float: 'left', width: '100px', height: '100px', marginRight: '15px' }}
        />

                  <strong style={{ fontSize: "30px" }}>{node.source}</strong>

                  <strong>
                    {" "}
                    <p style={{ fontSize: "11px" }}>{node.description}</p>
                  </strong>
                </Box>
              </button>
            </Link>
          ))}
        </ul>
      ) : (
        <SliderPlaceholder error={true} text="No data found" />
      )}
      <Box className={classes.buttonWrapper}>
        <Link
          style={{ textDecoration: "none", color: "#123B64" }}
          to={`/dataentryform/${nodeName}/${key}`}
        >
          <OutlinedButton className={classes.button} disableRipple>
            Back
          </OutlinedButton>
        </Link>
      </Box>
    </Container>
  );
};

export default SourceDataset;
