import _debounce from "lodash/debounce";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { TAB_TYPES } from "../../constants/tab";
import DataContext from "../../contexts/Context";
import TabContext from "../../contexts/TabContext";
import api from "../../core/api";
import { SearchService } from "../../services/SearchService";
import { TAB_CLEAR } from "../../state/constants/tab";
import NavbarDropdownInput from "../navbar/nav-dropdown-input/NavbarDropdownInput";
import Navbar from "../navbar/Navbar";
import { filterHelper,createSearchPayloadForFilterbar } from "../../constants/filter-helper";
import {
  createSearchErrorPayload,
  createSearchLoadedPayload,
  createSearchLoadingPayload,
  SEARCH_NODE_PAYLOAD,
} from "../../constants/searchNodes";
import NavbarMenu from "../shared/inputs/NavbarMenu";
import SliderPlaceholderTags from "../shared/placeholder/SliderPlaceholderPopularTags";
import SliderPlaceholder from "../shared/placeholder/SliderPlaceholder";
import Loader from "../shared/loader/Loader";


export const fetchRecentSearches = () => {
  return SearchService.fetchRecentSearches();
};
const getRecentSearch = () => {
  return SearchService.fetchRecentSearches();
};
const getPopularTagsCounts = () => {
  return SearchService.fetchPopularTagsCount();
};
const getNodeTypeOptions = () => {
  return SearchService.fetchNodeTypeOptions();
};
const getMyRecentTags = () => {
  return SearchService.fetchMyRecentTags();
};

function AppLayout() {
  const {
    addSearchTabHandler,
    payload,
    searchTerm,
    selectedFilterTypes,
    updatePayload,
    upadateSearchPayload,
    setSearchTerm,
    dispatch,
    setActiveTab,
    nodeType,
    setNodeType,
    isListView,
    setIsListView,
    isNewSearch,
    setIsNewSearch,
    isNodeTypeFilter,
    setIsNodeTypeFilter,
    setSelectedFilterTypes,

  } = useContext(TabContext);

  const [searchText, setSearchText] = useState("");
  const { setRelatedSearches } = useContext(DataContext);
  const [nodeTypesOptions, setNodeTypesOptions] = useState([]);
  const [tags, setTags] = useState([]);
  const [popularTags, setPopularTags] = useState([]);
  const [recentSearches, setRecentSearches] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [autoCompleteResults, setAutoCompleteResults] = useState([]);
  const [isGraphOpen, setIsGraphOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const [firstPopularTagClick, setFirstPopularTagClick] = useState(true);
  const [popularTagClicked, setPopularTagClicked] = useState(false);
  const [isFirstPopularTagClick, setIsFirstPopularTagClick] = useState(true);
  const [loading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [selectedNodeType, setSelectedNodeType] = useState([]);
  const currentInputValue = searchParams.get("nodeName") || "";


  const updateRecentSearches = (searchText) => {
    // Check if the search text is not already in recent searches
    if (!recentSearches.includes(searchText)) {
      // Add the new search text to recent searches
      setRecentSearches((prevSearches) => [searchText, ...prevSearches]);
    }
  };

  // const updateRecentSearches = (searchText, selectedNodeTypes) => {
  //   // Check if the search text is not already in recent searches
  //   if (!recentSearches.includes(searchText)) {
  //     // Add the new search text with selected filters to recent searches
  //     setRecentSearches((prevSearches) => [
  //       { text: searchText, nodeTypes: selectedNodeTypes },
  //       ...prevSearches,
  //     ]);
  //   }
  // };

 // This useEffect hook is triggered when the component mounts.
// It checks if there's a "nodeName" in the search parameters and calls prepareSearchFilters with that nodeName if found.
useEffect(() => {
  if (searchParams.get("nodeName")) {
    prepareSearchFilters(searchParams.get("nodeName"));
  }
}, []);

// This useEffect hook is triggered whenever the currentInputValue changes.
// It updates the searchText state with the currentInputValue.
useEffect(() => {
  setSearchText(currentInputValue);
}, [currentInputValue]);

// This useEffect hook is triggered whenever the pathname changes.
// It resets various state variables and dispatches actions if the new pathname is not "/tabs".
// Otherwise, it sets isNodeTypeFilter to true.
useEffect(() => {
  if (pathname !== "/tabs") {
      setIsListView(false);
      setNodeType(nodeTypesOptions);
      dispatch({ type: TAB_CLEAR });
      setIsNodeTypeFilter(false);
      setSelectedFilterTypes([]);
      setAutoCompleteResults([]);
      setSelectedNodeType([]);
      updatePayload({});
  } else {
      setIsNodeTypeFilter(true);
  }
}, [pathname]);

// This useEffect hook is triggered whenever the pathname is "/tabs" and payload.filters exist and isGraphOpen is false.
// It filters out certain filter fields from the payload and constructs a new filterPayload to be used in searchNodes function.
useEffect(() => {
  if (pathname === "/tabs" && payload.filters && !isGraphOpen) {
      const filters = payload.filters.filter(
          (filter) =>
          filter.fieldName !== "doc.typeName" &&
          filter.fieldName !== "doc.nodesClassification"
      );

      const filterPayload = setDefaultFilterPayload();
      const selectedNodeTypes = nodeType.map((type) => {
          return { label: "nodeType", value: type };
      });

      const selectedFilters = filterHelper(
          selectedNodeTypes,
          "nodeType",
          filters
      );
      
      filterPayload.filters = [...filterPayload.filters, ...selectedFilters]; // Assign selectedFilters directly

      searchNodes(filterPayload, searchText);
  }
}, []);

// This useEffect hook is triggered whenever the payload changes and isNodeTypeFilter is true.
// It calls upadateSearchPayload with the updated payload.
useEffect(() => {
  if (isNodeTypeFilter) {
      upadateSearchPayload(payload);
  }
}, [payload]);


// useEffect(() => {
//   if (isNodeTypeFilter && !isFirstPopularTagClick) {
//     upadateSearchPayload(payload);
//   }
// }, [payload, isFirstPopularTagClick]);

  

  const searchNodes = (payload, searchTerm,popularpayload) => {
    console.log(payload, "1234");
    const loadingPaload = createSearchLoadingPayload();
    addSearchTabHandler(loadingPaload);

    // if (isNodeTypeFilter) {
    updatePayload(payload);
    console.log(payload, "paysss");
    // }
    setSearchTerm(searchTerm);
  };

  const searchGraphNodes = (text) => {
    setIsNewSearch(!isNewSearch);
    const loadingPaload = createSearchLoadingPayload();
    addSearchTabHandler(loadingPaload);

    SearchService.fetchGraphSeachNodes(text ? text : searchTerm)
      .then((res) => {
        return createSearchLoadedPayload({
          data: res.data,
          reqTime: res.responseTime,
          nodeTitle: text ? text : searchTerm,
        });
      })
      .catch(() => {
        return createSearchErrorPayload();
      })
      .then((payload) => {
        addSearchTabHandler(payload);
      });
  };


  

  // const fetchPopularTagNodes = (popularTag,tag) => {

  //   console.log(popularTag,tag,"popular tag")
  //   setIsNewSearch(!isNewSearch);
  //   const loadingPaload = createSearchErrorPayload();
  //   addSearchTabHandler(loadingPaload);
  //   const popularpayload = {
  //     key: popularTag,
  //     pagination: {
  //       pageNumber: 1,
  //       pageSize: 9
  //     }
  //   };
  //   console.log(payload, "payload after fetching");

  //   return SearchService.fetchPopularTags(popularTag,popularpayload)
  //     .then((res) => {
  //       console.log(res,payload, "popular tag");
  //       let nodes = res.data.data? res.data.data: [];
  //       nodes = nodes.filter(
  //         (node) =>
  //           node.Type === "Data Set" ||
  //           node.Type === "Schema" ||
  //           node.Type === "Business Term"
  //       );

  //       return createSearchLoadedPayload({
  //         reqTime: res.responseTime,
  //         data: nodes,
  //         nodeTitle: popularTag,
  //       });
  //     })
  //     .catch(() => {
  //       return createSearchErrorPayload();
  //     })
  //     .then((payload) => {
  //       addSearchTabHandler(payload);
  //     });
  // };
  // const fetchPopularTagNodes = (popularTag) => {
  
  //   setIsNewSearch(!isNewSearch);
  //   const loadingPayload = createSearchLoadingPayload();
  //   addSearchTabHandler(loadingPayload);

  
  //   const popularPayload = {
  //     key: popularTag,
  //     pagination: {
  //       pageNumber: 10,
  //       pageSize: pathname === "/tabs" ? (isListView ? 24 : 9) : 9,
  //     }
  //   };
    
  //    searchNodes(popularPayload);
  //   return SearchService.fetchPopularTags(popularTag, popularPayload)
    

  //     .then((res) => {
  //       let nodes = res.data.data ? res.data.data : [];
  //       console.log(nodes,"pas")

      
  //       // nodes = nodes.filter(
  //       //   (node) =>
  //       //     node.typeName === "Data Set" ||
  //       //     node.typeName === "Schema" ||
  //       //     node.typeName === "Business Term"
  //       // );
  //       // nodes = nodes.filter((node) => {
  //       //   const allowedTypes = ["Data Set", "DataProduct", "Business Term"];
  //       //   return allowedTypes.includes(node.typeName);
  //       // });
        
  //       console.log(nodes,"pas")
  
  //       const payloadData = createSearchLoadedPayload({
  //         reqTime: res.responseTime,
  //         data: nodes,
  //         nodeTitle: popularTag,
  //         paging: res.data.paging,

         
        
  //       });
  // console.log(payloadData,"pas")
  
  //       // Add the payload to the search tabs
  //       addSearchTabHandler(payloadData);
  //     })
   
  //     .catch(() => {
  //       const errorPayload = createSearchErrorPayload();
  //       addSearchTabHandler(errorPayload);
  //     });
  // };
  const fetchPopularTagNodes = (popularTag) => {
    // Set loading state to true
    setIsLoading(true);
  
    const loadingPayload = createSearchLoadingPayload();
    addSearchTabHandler(loadingPayload);
  
    const popularPayload = {
      key: popularTag,
      pagination: {
        pageNumber: 10,
        pageSize: pathname === "/tabs" ? (isListView ? 24 : 9) : 9,
      },
    };
  
    // Perform the search and fetch data
    return SearchService.fetchPopularTags(popularTag, popularPayload)
      .then((res) => {
        let nodes = res.data.data ? res.data.data : [];
  
        // Process the nodes as needed
        // ...
  
        const payloadData = createSearchLoadedPayload({
          reqTime: res.responseTime,
          data: nodes,
          nodeTitle: popularTag,
          paging: res.data.paging,
        });
  
        // Add the payload to the search tabs
        addSearchTabHandler(payloadData);
  
        // Set loading state to false after successful data fetch
        setIsLoading(false);
      })
      .catch(() => {
        // Handle errors and set loading state to false
        const errorPayload = createSearchErrorPayload();
        addSearchTabHandler(errorPayload);
        setIsLoading(false);
      });
  };
  
  
  const handlePopularTag = (tag) => {
    // Navigate to tabs
    navigateToTabs(tag);
  
    // Fetch and display popular tag nodes
    fetchPopularTagNodes(tag);
  
    // Get related searches
    getRealtedSearches(tag);
  };
  const fetchRelatedTagNodes = (relatedTag) => {
    setIsNewSearch(!isNewSearch);
    const loadingPaload = createSearchLoadingPayload();
    addSearchTabHandler(loadingPaload);
    return SearchService.fetchRelatedTags(relatedTag)
      .then((res) => {
        return createSearchLoadedPayload({
          reqTime: res.responseTime,
          data: res.data ? res.data : [],
          nodeTitle: relatedTag,
        });
      })
      .catch(() => {
        return createSearchErrorPayload();
      })
      .then((payload) => {
        addSearchTabHandler(payload);
      });
  };

  const handleRelatedTag = (tag) => {
    navigateToTabs(tag);
    fetchRelatedTagNodes(tag);
    getRealtedSearches(tag);
  };
  //=============================working code of Recent Clicks=========================

  const onRecentClick = (text) => {
    updateRecentSearches(text);
    const originalNodeType = [...nodeType]; // Create a copy of the original nodeType
    // setNodeType([]); // Clear the nodeType
    if (isGraphOpen) {
      searchGraphNodes(text);
    } else {
      if (pathname === "/tabs") {
        setIsNodeTypeFilter(true);
      }
      prepareSearchFilters(text, originalNodeType); // Pass the copied nodeType as an argument
    }
    setNodeType(originalNodeType); // Restore the original nodeType
    setSearchText(text);
    getRealtedSearches(text);
    navigateToTabs(text);
  };



  const handleSubmit = (event) => {
    event.preventDefault(event);

    if (pathname !== '/tabs') {
      setSelectedNodeType([]);
    }


    prepareSearchFilters(searchText, selectedNodeType);

   
    
    if (isGraphOpen) {
      searchGraphNodes(searchText);
    } else {
      setIsNodeTypeFilter(true);
      prepareSearchFilters(searchText, selectedNodeType);
    }
    getRealtedSearches(searchText);
    navigateToTabs();
    updateRecentSearches(searchText);

    // setSearchText("");
    setSearchText(searchText);
  };



  // const handlePopularTag = (tag) => {
  //   console.log("Navigating to tabs:", tag);
  //   navigateToTabs(tag);
  
  //   console.log("Fetching popular tag nodes for:", tag);
  //   fetchPopularTagNodes(tag);
  
  //   console.log("Getting related searches for:", tag);
  //   getRealtedSearches(tag);
  // };
 

  // useQuery("popular-tags-count", getPopularTagsCounts, {
  //   onSuccess: (res) => setPopularTags(res.data.data),
  // });

  useQuery("popular-tags-count", getPopularTagsCounts, {
    onSuccess: (res) => {
      console.log("Popular tags count:", res.data);
      setPopularTags(res.data);
    },
    onError: (error) => {
      console.error("Error fetching popular tags count:", error);
    },
  });
  
  

  // const handlePopularTag = async (tag,popularTag) => {
  //   console.log("Handling popular tag:", tag,popularTag);
  
  //   // Navigate to tabs
  //   navigateToTabs(tag);
  
  //   // Fetch and display popular tag nodes
  //   try {
  //     // Modify the payload as needed for fetching popular tag nodes
  //     const popularPayload = {
  //       key: tag,
  //       pagination: {
  //         pageNumber: 1,
  //         pageSize: 9
  //       }
  //     };
  
  //     console.log(popularPayload,"popularPayload")
  //     await fetchPopularTagNodes(tag, popularPayload);
  //     await getRealtedSearches(tag);
  //   } catch (error) {
  //     // Handle errors here
  //     console.error("Error in handlePopularTag:", error);
  //   }
  // };
  
  
  // const handlePopularTag = (tag) => {
  //   navigateToTabs(tag);
  
  //   if (!popularTagClicked) {
 
  //     setIsFirstPopularTagClick(false);
  //   } else {
  //   
  //     fetchPopularTagNodes(tag);
  //     getRealtedSearches(tag);
  //   }
  
  //  
  //   setPopularTagClicked(true);
  // };
  


  const prepareSearchFilters = (searchText, selectedNodeTypes = []) => {
    const filters = [
      {
        fieldName: "doc.name",
        fieldValue: searchText,
        stringFunction: "STARTS_WITH",
        searchType: "SEARCH",
      },
      {
        fieldName: "doc.name",
        fieldValue: searchText,
        stringFunction: "NGRAM_MATCH",
        searchType: "SEARCH",
        threshold: 0.4,
        condition: "or",
      },
      {
        fieldName: "doc.nodesClassification",
        fieldValue: "private",
        searchType: "FILTER",
        op: "not like",
      },
      {
        fieldName: "doc.typeName",
        fieldValue: "Data Set,ACG,API,API Endpoint,API Parameter,Application,Bucket,Business Process,Business Term,Column,Data Attribute,Data Product,Data Quality Metric,Data Quality Rule,Data Sharing Agreement,Data Use,Data domain,Database,Directory,Feature,Feature Set,Field,File,ForeignKey,Line of Business,ML Model,Model Version,Product,Route,Schema,SensitiveType,SensitiveTypeGroup,Server,Service,Table,container",
        searchType: "FILTER",
        op: "in",
        condition: "and",
      },
    ];

    // Include "doc.typeName" filter only if there are selected node types
    if (selectedNodeTypes.length > 0) {
      const typeNamesFilter = {
        fieldName: "doc.typeName",
        fieldValue: selectedNodeTypes.join(","),
        searchType: "FILTER",
        op: "in",
        condition: "and",
      };
      filters.push(typeNamesFilter);
    } 
  
    const filterPayload = {
      filters,
      pagination: {
        pageNumber: 1,
        pageSize: 9,
      },
    };

    setActiveTab(TAB_TYPES.FILTER);
    searchNodes(filterPayload, searchText);
  };

  // ============================================================================


  const filterNodes = () => {
    const selectedNodeTypes = nodeType.map((type) => {
      return { label: "nodeType", value: type };
    });

    return filterHelper(selectedNodeTypes, "nodeType");
  };

  const setDefaultFilterPayload = (text = searchText) => {
    const filterPayload = { ...SEARCH_NODE_PAYLOAD };
    // update pagination size depend on isListView value
    if (pathname === "/tabs") {
      filterPayload.pagination.pageSize = isListView ? 24 : 9;
    }
    //update fieldValue to search text
    filterPayload.filters = filterPayload.filters.map((filter) => {
      // if (filter.fieldName !== "doc.nodesClassification")
      if (
        filter.fieldName !== "doc.nodesClassification" &&
        filter.fieldName !== "doc.typeName"
      ) {
        return { ...filter, fieldValue: text };
      } else {
        return filter;
      }
    });
    return filterPayload;
  };

  const navigateToTabs = (text = searchText) => {
    navigate({
      pathname: `/tabs`,
      search: `?nodeName=${text}`,
    });
  };

  async function fetchAutoComplateData(value) {
    setAutoCompleteResults([]);
    try {
      const reponse = await api.post(`/api/nodes/autoComplete?value=${value}`);
      setAutoCompleteResults(reponse.data.length > 0 ? reponse.data : []);
    } catch (e) {
      console.log(e);
    }
  }

  const delayAutoComplate = useRef(
    _debounce((q) => fetchAutoComplateData(q), 500)
  ).current;

  const handleChangeText = (e) => {
    const text = e.target.value;
    setSearchText(text);
    delayAutoComplate(text);
  };

  const getRealtedSearches = useCallback(
    (text) => {
      SearchService.fetchRelatedSearches(text ? text : searchText).then((res) =>
        setRelatedSearches(res.data)
      );
    },
    [searchText, setRelatedSearches]
  );

  useQuery("nodeType-options", getNodeTypeOptions, {
    onSuccess: (res) => {
      const filteredOptions = res.data
        .filter((item) => item !== "Data Set")
        .sort();
      setNodeTypesOptions(["Data Set", ...filteredOptions]);
    },
  });

  // useQuery("popular-tags-count", getPopularTagsCounts, {
  //   onSuccess: (res) => setPopularTags(res.data),
  // });

  const {isLoading} = useQuery("recent-searches", getRecentSearch, {
    onSuccess: (res) => setRecentSearches(res.data),
  });

  useQuery("recent-tags", getMyRecentTags, {
    onSuccess: (res) => setTags(res.data),
  });

  const children = useMemo(
    () => (
      <Outlet
        context={{
          recentSearches,
          isRecentSearchLoading: isLoading,
          getRealtedSearches,
          isGraphOpen,
          popularTags,
        }}
      />
    ),
    [recentSearches, isLoading,popularTags]
  );
  const handleNodeTypesChange = (selectedNodeTypes) => {
    setSelectedNodeType(selectedNodeTypes);
  };

  return (
    <>
      <Navbar
        searchText={searchText}
        isFocused={isFocused}
        isGraphOpen={isGraphOpen}
        setSelectedNodeType={setSelectedNodeType}
        setSearchText={setSearchText}
      >
        <NavbarMenu
          options={nodeTypesOptions}
          isFocused={isFocused}
          setSelectedNodeType={setSelectedNodeType}
          searchNodes={searchNodes}
          searchText={searchText}
          setSearchText={setSearchText}
          prepareSearchFilters={prepareSearchFilters}
         
          onNodeTypesChange={(selectedNodeTypes) => {
            setSelectedNodeType(selectedNodeTypes); 
          
          }}
        />
        <NavbarDropdownInput
          popularTags={popularTags}
          recentSearches={recentSearches}
          handleRecentClick={onRecentClick}
          handleRelatedTag={handleRelatedTag}
          handlePopularTag={handlePopularTag}
          setIsGraphOpen={setIsGraphOpen}
          isGraphOpen={isGraphOpen}
          setIsFocused={setIsFocused}
          isFocused={isFocused}
          setSearchText={setSearchText}
          searchText={searchText}
          autoCompleteResults={autoCompleteResults}
          onHandleChange={handleChangeText}
          handleSubmit={handleSubmit}
          setIsNodeTypeFilter={setIsNodeTypeFilter}
          tags={tags}
          updateRecentSearches={updateRecentSearches}
        />
      </Navbar>

      {children}
    </>
  );
}

export default AppLayout;
