import * as yup from 'yup'
import { makeStyles } from '@material-ui/styles'

export const BEST_PRODUCT = 'What content category best describes your product?'
export const DESCRIPTION = 'How this product is important to your business?'
export const DATA_VALUME = 'Estimated data volume'
export const DATA_TYPE = 'Data type'
export const REFRESH_FREQUENCY = 'Refresh frequency'

export const initialValues = {
  [BEST_PRODUCT]: '',
  [DESCRIPTION]: '',
  [DATA_VALUME]: '',
  [DATA_TYPE]: '',
  [REFRESH_FREQUENCY]: ''
}

export const useStyles = makeStyles((theme) => ({
//   root: {
//     minHeight: 1000,
//   },
//   header: {
//     margin: '50px 0 40px'
//   },
//   paper: {
//     boxShadow: '0px 4px 20px rgba(1, 25, 54, 0.1)',
//     borderRadius: 4,
//     marginBottom: 40
//   },
//   paperTitle: {
//     borderBottom: '1px solid #E0E7EC'
//   },
//   buttonsWrapper: {
//     display: 'flex',
//     justifyContent: 'space-between'
//   },

//     rightSide: {
//       width: '100%',
//       maxWidth: 650,
//       marginLeft: 25
//   },
  
// }));
container: {
  minHeight: "calc(100vh - 150px)",
  paddingBottom: 100
},
paperTitle: {
       borderBottom: '1px solid #E0E7EC'
  },
  root: {
         minHeight: 1000,
      },
      header: {
        margin: '50px 0 40px'
      },
      paper: {
        boxShadow: '0px 4px 20px rgba(1, 25, 54, 0.1)',
        borderRadius: 4,
        marginBottom: 40
      },
     

shadowWrapper: {
  boxShadow: '0px 10px 20px rgba(23, 87, 123, 0.2)',
  marginBottom: 40,
  borderRadius: 4
},
title: {
  padding: '20px 40px',
  borderBottom: '1px solid #E0E7EC'
},
wrapper: {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '40px 40px 30px'
},
leftSide: {
  width: '100%',
  maxWidth: 460,
  marginRight: 25
},
rightSide: {
  width: '100%',
  maxWidth: 650,
  marginLeft: 25
},




buttonsWrapper: {
       display: 'flex',
       justifyContent: 'space-between'
     },
}));


export const validationSchema = yup.object({
  [BEST_PRODUCT]: yup.string().required('Value is requred'),
  [DESCRIPTION]: yup.string().required("Description is required"),
  [DATA_VALUME]: yup.string().required('Value is required'),
  [DATA_TYPE]: yup.string().required("Value is required"),
  [REFRESH_FREQUENCY]: yup.string().required("Value is required")
});    
