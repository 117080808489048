// import { Avatar, Box, Typography, makeStyles } from '@material-ui/core';
// import face from '../../../assets/images/faces/face19.jpg'
// import phone from '../../../assets/images/collection-card/phone.png'
// import computer from '../../../assets/images/collection-card/computer.png'
// import laptop from '../../../assets/images/collection-card/laptop.png'

// const useStyles = makeStyles({
//     avatar: {
//         marginRight: 10
//     },
//     notification: {
//         width: 238
//     },
//     leftSide: {
//         width: 170,
//         height: 94,
//         marginRight: 2,
//         '& img': {
//             borderRadius: '16px 0 0 16px',
//         }
//     },
//     img: {
//         width: '100%',
//         height: '100%'
//     }
// })

// function Notification({ notification }) {
//     const classes = useStyles()
//     return (
//         <Box>
//             <Box mb={1} sx={{ display: 'flex' }}>
//                 <Avatar className={classes.avatar} src={face} alt='user' />
//                 <Typography className={classes.notification} color='primary' variant='h6'>
//                     {notification.notification}
//                 </Typography>
//             </Box>
//             <Box mb={0.5} sx={{ display: "flex" }}>
//                 <Box className={classes.leftSide}>
//                     <img className={classes.img} src={phone} alt="phone" />
//                 </Box>
//                 <Box>
//                     <Box sx={{width: 116, height: 46, borderRadius: '0 16px 0 0', marginBottom: 2}}>
//                         <img className={classes.img} src={laptop} alt="laptop" />
//                     </Box>
//                     <Box sx={{width: 116, height: 46, borderRadius: '0 0 16px 0'}}>
//                         <img className={classes.img} src={computer} alt="computer" />
//                     </Box>
//                 </Box>
//             </Box>
//             <Typography color='primary' variant='h6'></Typography>
//         </Box>
//     );
// }

// export default Notification;
import { Avatar, Box, Typography, makeStyles } from "@material-ui/core";
import face from "../../../assets/images/faces/face19.jpg";
import phone from "../../../assets/images/collection-card/phone.png";
import computer from "../../../assets/images/collection-card/computer.png";
import laptop from "../../../assets/images/collection-card/laptop.png";
import { useEffect, useState,forwardRef } from "react";
import { SearchService } from "../../../services/SearchService";
import FullNodeCardLogo from "../full-node-card/full-node-card-logo/FullNodeCardLogo";
import Pagination from "../../filter-region/pagination/Pagination";

const useStyles = makeStyles({
  avatar: {
    marginRight: 10,
    marginTop: 50,
  },
  notification: {
    width: 238,
    marginTop: 50,
  },
  leftSide: {
    width: "100%",
    height: 250,
    marginRight: 2,
    marginTop: 10,
    marginBottom: 20,
    marginLeft: 60,
  },
  img: {
    width: 150,
    height: "30%",
  },
});

function Notification ({notification }) {
  // export const Notification = forwardRef((notification, ref,props) => {
   const classes = useStyles();
  // const {
  
  //   nodesPerPage,
  //   showSaved,
    
  // } = props;

  //const [notifications, setNotifications] = useState([])
  const apiUrl = process.env.REACT_APP_API_URL;
  // const numberOfPages = [];
  // const [pageNumberLimit] = useState(7);
  // const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(7);
  // const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  // const [currentPage, setCurrentPage] = useState(1);
  // const [currentNodes, setCurrentNodes] = useState([]);
   const [imgStatus, setImgStatus] = useState(true);
  // const numberOfNodes = notification?.length;
  const handleErrorImge = () => {
    setImgStatus(false);
  };
   const [img, setImg] = useState(true);
  const handleErrorNodetype = () => {
    setImg(false);
  };
   const [notifications, setNotifications] = useState([]);
  // const [isLoading, setIsLoading] = useState(true);
  // const [userData,setUserData] =useState([])
  
  // for (let i = 1; i <= Math.ceil(numberOfNodes / nodesPerPage); i++) {
  //   numberOfPages.push(i);
  // }
  // const paginate = (pageNum) => {
  //   setCurrentPage(pageNum);
  //   ref && ref.current.scrollIntoView({ behaivor: "smooth" });
  // };

  // const nextPage = () => {
  //   if (numberOfPages.length === currentPage) {
  //     setCurrentPage(currentPage);
  //   } else {
  //     setCurrentPage(currentPage + 1);
  //   }
  //   if (currentPage + 1 > maxPageNumberLimit) {
  //     setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
  //     setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
  //   }
  //   ref && ref.current.scrollIntoView({ behaivor: "smooth" });
  // };

  // const prevPage = () => {
  //   if (currentPage === 1) {
  //     setCurrentPage(currentPage);
  //   } else {
  //     setCurrentPage(currentPage - 1);
  //   }
  //   if ((currentPage - 1) % pageNumberLimit === 0) {
  //     setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
  //     setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
  //   }
  //   ref && ref.current.scrollIntoView({ behaivor: "smooth" });
  // };

  // useEffect(() => {
  //   const indexOfLastNode = currentPage * nodesPerPage;
  //   const indexOfFirstNode = indexOfLastNode - nodesPerPage;
  //   const slicedNodes = notification.slice(indexOfFirstNode, indexOfLastNode);
  //   setCurrentNodes(slicedNodes);
  // }, [currentPage, showSaved, numberOfNodes]);


  // useEffect(() => {
  //   SearchService.fetchNotificatioon()
  //     .then((res) => {
  //       setNotifications(res.data);
  //       setIsLoading(false);
  //     })
  //     .catch(() => setIsLoading(false));
  // }, []);


//   useEffect(() => {
//     const payloadData = {
//           pagination: {
//             pageNumber: 1,
//             pageSize: 9,
//           },
//         };
//     SearchService.fetchNotificatioon(payloadData)
    
//         .then(res => {
//           console.log(res,"response")
//             setNotifications(res.data);
//         })
//         .catch(error => {
//             console.error("Error fetching notifications:", error);
//         })
//         .finally(() => {
//             setIsLoading(false);
//         });
// }, []);


const getNotification = () => {
  const payloadData = {
    
      pageNumber: 1,
      pageSize: 15
  };
  return SearchService.fetchNotificatioon(payloadData)
    .then((response) => {
      if (response.data) {
        setNotifications(response.data);
      }
    })
    .catch((error) => {
      console.error("Error fetching more from user:", error);
    });
};
useEffect(() => {
  getNotification();
}, []);


  return (
    <Box>
      <Box mb={1} sx={{ display: "flex" }}>
        <Avatar className={classes.avatar} src={face} alt="user" />
        <Typography
          className={classes.notification}
          color="primary"
          variant="h6"
        >
          {/* <img src={notification.image}  />  */}
          {notification.notification}
        </Typography>
      </Box>
      {/* {
          imgStatus ? */}
      {imgStatus ? (
        <Box
          mb={0.5}
          sx={{
            width: 116,
            height: 46,
            borderRadius: "0 16px 0 0",
            marginBottom: 2,
          }}
        >
          <Box className={classes.leftSide}>
            <img
              className={classes.img}
              onError={handleErrorImge}
              src={`${apiUrl}/getPinImage?file=${notification?.pinCover}`}
            />
          </Box>
        </Box>
      ) : (
        <Box
          mb={0.5}
          sx={{
            width: 116,
            height: 46,
            borderRadius: "0 16px 0 0",
            marginBottom: 2,
          }}
        >
          <Box className={classes.leftSide}>
            <FullNodeCardLogo Type={notification.nodeType} size={30} />
          </Box>
        </Box>
      )}
      <Typography color="primary" variant="h6"></Typography>
      {/* {notification.length > nodesPerPage && (
        <Pagination
          numberOfPages={numberOfPages}
          currentPage={currentPage}
          paginate={paginate}
          nextPage={nextPage}
          prevPage={prevPage}
          maxPageNumberLimit={maxPageNumberLimit}
          minPageNumberLimit={minPageNumberLimit}
        />
      )} */}
    </Box>
  );
}

export default Notification;
