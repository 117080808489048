import React, { useContext, useState, useEffect } from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { makeStyles, Button, IconButton } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import CollectionModal from "../shared/modal/Modal";
import collectionImg from "../../assets/images/collection-img.png";
import { PinIcon, TrashBinIcon } from "../icons";
import { CollectionService } from "../../services/CollectionService";
import DataContext from "../../contexts/Context";

import TabContext from "../../contexts/TabContext";
import { useParams } from "react-router-dom";
const useStyles = makeStyles({
  root: {
    width: "100%",
    height: 245,
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      width: 4,
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#F79F19",
      borderRadius: 2,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#808c9a4d",
      borderRadius: 2,
    },
    "&::-webkit-scrollbar-button:end:increment": {
      height: 20,
      display: "block",
    },
  },
  title: {
    "& .MuiTypography-body1": {
      fontSize: 14,
      fontWeight: 700,
      color: "#123b64",
      lineHeight: "150%",
    },
  },
  avatar: {
    width: 48,
    height: 48,
  },
  listItem: {
    padding: "7px 0",
    "&:hover div": {
      display: "flex",
    },
    "&:hover": {
      backgroundColor: "#F2F3F4",
    },
  },
  btnsWrapper: {
    display: "none",
    alignItems: "center",
  },
  save: {
    color: "#123b64",
    background: "white",
    width: 77,
    border: "1px solid #123b64",
    marginRight: 10,
    padding: "7px 10px",
    borderRadius: 8,
    fontWeight: 500,
    lineHeight: "150%",
    textTransform: "capitalize",
    "& path": {
      fill: "#123b64",
    },
    "&.MuiButton-contained": {
      boxShadow: "none",
      "&:hover": {
        boxShadow: "none",
        background: "#123b64",
        color: "white",
        "& path": {
          fill: "#fff",
        },
      },
      "&:active": {
        boxShadow: "none",
      },
    },
  },
  deleteIcon: { marginRight: 10, marginLeft: 17, padding: 0 },
});

function CollectionsListadd(props) {
  const {
    collections,
    setCollections,
    setAllData,
    
    page,
    allData,
} = props;

//console.log(collections,type,arangoNodeKey,Type,nodeType,"qwert")
  const classes = useStyles();
  const {  key } = useParams();
  const [open, setOpen] = useState(false);
  const [userData, setUserData] = useState([]);
  const [modalType, setModalType] = useState("save-collection");
  const [collection, setCollection] = useState({});

 
  const apiUrl = process.env.REACT_APP_API_URL;
  const {
    loggedInUser: { userId },
  } = useContext(DataContext);


  const {
searchNodeData,
  } = useContext(TabContext);
  console.log(searchNodeData,"searchNodeData")


// const getMore = () => {
//   const payloadData = {
//     key:key ,
//     pagination: {
//       pageNumber: 1,
//       pageSize: 9,
//     },
//   };
 
//   return CollectionService.fetchCollectionData(payloadData)
//     .then((response) => {
//       if (response.data) {
//         setUserData(response.data);
//         console.log("/.,nmbv",response)
//       }
//     })
//     .catch((error) => {
//       console.error("Error fetching more from user:", error);
//     });
// };
// useEffect(()=>{
//   getMore()
// },[key])
 
// const handleSaveCollection = (collection) => {
//   console.log(collection,"collectionlist")
//   if (typeof props.handleSave === 'function') {
//     props.handleSave(collection);
//   }
//   // props.handleSave(collection);
   
//     const payloadData = {
//         // arangoNodeKey: "PinCollections/57189056",
//         // displayName: "displayName",
//         // type: "collections",
//         displayName: collection.name,
//         arangokey: `PinCollection/${collection.id}`,
//         type: "collection"
        
//       };
//       console.log(payloadData, "data");
//       CollectionService.addCollectionToCollection(key,  payloadData).then(
//         (response) => {
//           console.log(response, "response");
//           // setCollections(response)
//           //console.log("'lkjhvc",key)
//           setOpen(true);
          
//           setModalType("saveNodeToColl");
//           setAllData(!allData)


//         }
//       );
  
    
//   };

const handleSaveCollection = (collection) => {
  console.log(collection, "collectionlist");

  // Check if props.handleSave is a function and call it if it is
  if (typeof props.handleSave === 'function') {
    props.handleSave(collection);
  }

  const payloadData = {
    displayName: collection.name,
    arangokey: `PinCollection/${collection.id}`,
    type: "collection",
  };

  console.log(payloadData, "data");

  // Check if the collection already exists before adding it
  if (checkIfCollectionExists(collection.id)) {
    console.log("Collection already exists");
    // Handle the case where the collection already exists, e.g., show an error message.
    return;
  }

  CollectionService.addCollectionToCollection(key, payloadData).then(
    (response) => {
      console.log(response, "response");
      setOpen(true);
      setModalType("saveNodeToColl");
      setAllData(!allData);
    }
  );
};

// Function to check if the collection already exists
const checkIfCollectionExists = (collectionId) => {
  // Implement logic to check if the collection with the given ID already exists
  // For example, you can check against a list of existing collection IDs
  // Return true if it exists, false otherwise
  // Replace this logic with your actual check
  const existingCollections = []; // Replace with your list of existing collections
  return existingCollections.includes(collectionId);
};

 
  

  const handleDelete = (collectionId) => {
    CollectionService.removeCollectionsFromCollection(collectionId, userId, {
      nodepinkey: [`PinCollection/${key}`],
    }).then((res) => {
      setOpen(true);
      
      setModalType("deleteNodeFromColl");
    });
  };

  return (
    <List className={classes.root}>
      <CollectionModal
        open={open}
        collection={collection}
        setOpen={setOpen}
        type={modalType}
      />
      {collections && collections.map((collection) => (
        <ListItem key={collection.id} className={classes.listItem}>
          <ListItemAvatar>
            <Avatar
              variant="rounded"d
              className={classes.avatar}
              alt={collection.name}
              src={collectionImg}
            />
          </ListItemAvatar>
          <ListItemText className={classes.title} primary={collection.name} />
          <div className={classes.btnsWrapper}>
            <Button
              onClick={() => handleSaveCollection(collection)}
              className={classes.save}
              variant="contained"
              startIcon={<PinIcon />}
              disableRipple
            >
              Save
            </Button>

            {page === "digital publication" ? null : (
              <IconButton
                onClick={() => handleDelete(collection.id)}
                aria-label="delete"
                className={classes.deleteIcon}
              >
                <TrashBinIcon />
              </IconButton>
            )}
          </div>
        </ListItem>
      ))}
    </List>
  );
}

export default CollectionsListadd;
