import React from 'react';
import Slider from 'react-slick';
import NextArrow from '../next-arrow/NextArrow';
import PrevArrow from '../prev-arrow/PrevArrow';
import '../short-card-slider/node-slider.scss';
import FullNodeCard from '../../shared/full-node-card/FullNodeCard';
import { useLocation } from 'react-router-dom';

const FullNodeCardSlider = ({ data }) => {
    const { pathname } = useLocation()

    const sliderSettings = {
        dots: true,
        infinite: data?.length > 2,
        speed: 500,
        slidesToShow: pathname === "/home" ? 3 : 2,
        slidesToScroll: pathname === "/home" ? 3 : 2,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: data?.length > 2,
                    dots: true
                }
            },
        ]
    }
    const cardItems = !data ? [] : data.map((cardItem, index) => {
        return (
            <div key={index}>
                {
                    data.length ?
                        <div className="py-2 container">
                            <div className="row gx-4 gy-3">
                                <div className="col-sm-12 saved-card-container">
                                    <FullNodeCard
                                        data={cardItem}
                                    />
                                </div>
                            </div>
                        </div> : null
                }
            </div>
        )
    })

    return (
        <div className='card-slider-wrapper' style={{ marginBottom: 40 }}>
            <Slider {...sliderSettings}>
                {cardItems}
            </Slider>
        </div>
    )
}

export default FullNodeCardSlider;

