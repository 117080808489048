import React from 'react';
import { Button, makeStyles, Box, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    iconWrapper: {
        marginBottom: 18
    },
    typography: {
        fontWeight: 700,
        lineHeight: "23px"
    },
    button: {
        width: 200,
        height: 152,
        padding: 28,
        border: 'none',
        color: '#FFF',
        borderRadius: 10,
        cursor: 'pointer',
        "& .MuiButton-label": {
            display: "flex",
            justifyContent: 'center',
            flexDirection: "column"
        },
        [theme.breakpoints.down('578')]: {
            width: '100%'
        }
    },
}))

function DownloadButton({name, ...props}) {
    const classes = useStyles()
    return (
        <Button {...props} className={classes.button} color="secondary" variant="contained" component="span">
            <Box className={classes.iconWrapper}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.6 16.0001L21.3333 21.3334H18.1333L16 18.2854L13.8667 21.3334H10.6667L14.4 16.0001L10.6667 10.6667H13.8667L16 13.7147L18.1333 10.6667H20V5.33341H6.66667V26.6667H25.3333V10.6667H21.3333L17.6 16.0001ZM4 3.98941C4 3.25875 4.596 2.66675 5.332 2.66675H21.3333L28 9.33342V27.9907C28.0012 28.1658 27.968 28.3395 27.9021 28.5017C27.8362 28.6639 27.739 28.8116 27.6161 28.9363C27.4931 29.061 27.3468 29.1602 27.1855 29.2284C27.0242 29.2965 26.8511 29.3322 26.676 29.3334H5.324C4.97384 29.331 4.63869 29.1909 4.39096 28.9434C4.14322 28.6959 4.00279 28.3609 4 28.0107V3.98941Z" fill="white" />
                </svg>
            </Box>
            <Typography className={classes.typography}>{name} (.xls)</Typography>
        </Button>
    );
}

export default DownloadButton;