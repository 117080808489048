import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import Breadcrumbs from "../../../shared/breadcrumb/Breadcrumbs";
import CustomizedRadio from "../../../shared/inputs/CustomizedRadio";
import SharePopover from "../../../../components/shared/popover/SharePopover";
import { NodeCreationService } from "../../../../services/NodeCreationService";
import Loader from "../../../shared/loader/Loader";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { BackBtn } from "../../../shared/buttons/custom-buttons/CustomButtons";
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import {
  useStyles,
  SECURTY_CLASSIFACTION,
  TOXIC_COMBINATION,
  PII,
} from "./Curate-privacy";
import Sharenode from "../../../share/Shareregistration";

function CuratePrivacy(data) {
  const classes = useStyles();
  const { key, nodeName } = useParams();
  const [isLoadingRefFreq, setIsLoadingRefFreq] = useState(true);
  const [formValues, setFormValues] = useState({
    [SECURTY_CLASSIFACTION]: "",
    [TOXIC_COMBINATION]: "",
    [PII]: "",
  });
  const [openShareContent, setOpenShareContent] = useState(null);
  const [isSubmissionSuccessful, setIsSubmissionSuccessful] = useState(false); // State to trigger navigation
  const navigate = useNavigate();

  useEffect(() => {
    NodeCreationService.getCuratePrivacyValues(key).then((res) => {
      const hasData = Object.keys(res.data).length;
      if (hasData) {
        const formValues = {
          [SECURTY_CLASSIFACTION]: res.data.securityClassification,
          [TOXIC_COMBINATION]: res.data.inforedToxicCombination,
          [PII]: res.data.pII,
        };
        setFormValues(formValues);
      }
      setIsLoadingRefFreq(false);
    });
  }, [key]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const payload = [];

    Object.keys(formValues).forEach((item) => {
      let emptyObj = {};
      emptyObj.attributeName = item;
      emptyObj.attributeValue = formValues[item];
      payload.push(emptyObj);
    });

    NodeCreationService.createAttributes(payload, key)
      .then(() => {
        console.log("API call succeeded, setting success state...");
        setIsSubmissionSuccessful(true); // Update success state
      })
      .catch((err) => {
        console.log("Error occurred in API:", err);
      });
  };

  const setBackPage = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isSubmissionSuccessful) {
      console.log("Navigating to new page...");
      navigate(`/curatesecurity/${nodeName}/${key}`); // Trigger navigation on successful submission
    }
  }, [isSubmissionSuccessful, nodeName, key, navigate]);

  if (isLoadingRefFreq) {
    return <Loader />;
  }

  return (
    <div>
      <Container className={classes.root}>
        <Box mb={2.5} style={{ textDecoration: 'none', color: '#123b64' }}>
          <IconButton
            onClick={setBackPage}
            className={classes.icon}
            style={{ color: '#63ADA6' }}
          >
            <ArrowBackIosIcon style={{ fontSize: '13px' }} />
          </IconButton>

          <Link to="/home" style={{ textDecoration: 'none', fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
            Home
          </Link>
          {' / '}
          <Link to={`/curatecontext/${nodeName}/${key}`} style={{ textDecoration: 'none', fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
            Curatecontext
          </Link>
          {' / '}
          <Link to={`/Curateprivacy/${nodeName}/${key}`} style={{ textDecoration: 'none', fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
            Curateprivacy
          </Link>
          {' / '}
          <span style={{ fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>{nodeName}</span>
        </Box>
        <Box>
          <Typography
            variant="h1"
            color="primary"
            className={classes.header}
            align="center"
          >
            {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
          </Typography>
          <Box className={classes.paper}>
            <Box className={classes.paperTitle} px={5} py={2.5}>
              <Typography variant="h5" color="primary">
                2.1 Curate/ Privacy
              </Typography>
            </Box>
            <Box className={classes.paperBody} p={5}>
              <form
                id="curate-privacy-form"
                style={{ maxWidth: 508 }}
                onSubmit={onSubmit}
              >
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.text}
                >
                  Security Classification
                </Typography>
                <Box pb={1.75}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      className={classes.radioWrapper}
                      value={formValues[SECURTY_CLASSIFACTION]}
                      name={SECURTY_CLASSIFACTION}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="public"
                        control={<CustomizedRadio />}
                        label="Public"
                      />
                      <FormControlLabel
                        value="private"
                        control={<CustomizedRadio />}
                        label="Private"
                      />
                      <FormControlLabel
                        value="confidential"
                        control={<CustomizedRadio />}
                        label="Confidential"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.text}
                >
                  Infored toxic Combination:
                </Typography>
                <Box>
                  <FormControl component="fieldset">
                    <RadioGroup
                      className={classes.radioWrapper}
                      value={formValues[TOXIC_COMBINATION]}
                      name={TOXIC_COMBINATION}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="true"
                        control={<CustomizedRadio />}
                        label="True"
                      />
                      <FormControlLabel
                        value="false"
                        control={<CustomizedRadio />}
                        label="False"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
                <Typography
                  variant="h6"
                  color="primary"
                  className={classes.text}
                >
                  PII
                </Typography>
                <Box>
                  <FormControl component="fieldset">
                    <RadioGroup
                      className={classes.radioWrapper}
                      value={formValues[PII]}
                      name={PII}
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="true"
                        control={<CustomizedRadio />}
                        label="True"
                      />
                      <FormControlLabel
                        value="false"
                        control={<CustomizedRadio />}
                        label="False"
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>

        <Box className={classes.buttonsWrapper}>
          <Link
            to={`/curatecontext/${nodeName}/${key}`}
            style={{ textDecoration: "none" }}
          >
            <BackBtn>Back</BackBtn>
          </Link>
          <SharePopover
            anchorEl={openShareContent}
            setAnchorEl={setOpenShareContent}
            popoverButton={
              <div style={{ paddingLeft: 500, marginBottom: 20 }}>
                <BackBtn type="submit">Assign</BackBtn>
              </div>
            }
          >
            <Sharenode setOpenShare={setOpenShareContent} data={data} />
          </SharePopover>
          <BackBtn form="curate-privacy-form" type="submit">
            Next
          </BackBtn>
        </Box>
      </Container>
    </div>
  );
}

export default CuratePrivacy;
