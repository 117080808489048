import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BackBtn } from "../../../shared/buttons/custom-buttons/CustomButtons";
import CustomizedRadio from "../../../shared/inputs/CustomizedRadio";
import CustomizedSelect from "../../../shared/inputs/CustomizedSelect";
import Breadcrumbs from "../../../shared/breadcrumb/Breadcrumbs";
import { NodeCreationService } from "../../../../services/NodeCreationService";
import { getTransformedData } from "./curateSecurty";
import { useFormik } from "formik";
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import {
  initialValues,
  validationSchema,
  ACCESS_RULES,
  RETENTION_PERIOD,
  ENCYRPTION,
  BACKUP_FREQUENCY,
  ACCESS_RESTRICTIONS,
  useStyles
} from "./curateSecurty";
import CreatableMultiSelect from "../../../shared/inputs/CreatableMultiSelect";
import TextAreaInputVendor from "../../../shared/inputs/TextAreaInputVendor";


function Modelform() {
  const classes = useStyles();
  const { key, nodeName, selectedNodeName } = useParams();
  const [formValues, setFormValues] = useState(initialValues)
  const [accessRoleOptions, setAccessRuleOptions] = useState([])
  const [retentionPeriodOptions, setRetentionPeriodOptions] = useState([]);
  const [backupFreqOptions, setBackupFreqOptions] = useState([]);
  const [accessRestrOptions, setAccessRestrOptions] = useState([]);
  const [filteredTags, setFilteredTags] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    NodeCreationService.fetchSelectOptions('AccessRules')
      .then(res => {
        const transformedData = getTransformedData(res.data[0])
        setAccessRuleOptions(transformedData)
      })

    NodeCreationService.fetchSelectOptions('RetentionPeriod')
      .then(res => {
        const transformedData = getTransformedData(res.data[0])
        setRetentionPeriodOptions(transformedData)
      })

    NodeCreationService.fetchSelectOptions('DataBackupFrequency')
      .then(res => {
        const transformedData = getTransformedData(res.data[0])
        setBackupFreqOptions(transformedData)
      })

    NodeCreationService.fetchSelectOptions('DataAccessRestrictions')
      .then(res => {
        const transformedData = getTransformedData(res.data[0])
        setAccessRestrOptions(transformedData)
      })

    NodeCreationService.getCurateSecurityValues(key)
      .then(res => {
        const hasData = Object.keys(res.data).length
        if (hasData > 1) {
          const formValue = {
            [ACCESS_RULES]: res.data.accessRules,
            [RETENTION_PERIOD]: res.data.retentionPeriod,
            [ENCYRPTION]: res.data.encryption,
            [BACKUP_FREQUENCY]: res.data.dataBackupFrequency,
            [ACCESS_RESTRICTIONS]: res.data.dataAccessRestrictions
          }
          setFormValues(formValue)
        }
      })
  }, [key]);

  const onSubmit = (values) => {
    const payload = []
    Object.keys(values).forEach(item => {
      let payloadItem = {}
      payloadItem.attributeName = item
      payloadItem.attributeValue = values[item]
      payload.push(payloadItem)
    })

    NodeCreationService.createAttributes(payload, key)
      .then(() => {
        navigate(`/responsibilitymodel/${nodeName}/${key}`)
      })
      .catch(() => {
        console.log('error is occured in api')
      })
  }

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })
   const handleTagsChange = (e, value) => {
    const tags = []
    value.forEach(item => {
      if (item.trim().length) {
        tags.push(item)
      }
    })

    const filtredValues = options.filter(option => {
      return !value.some(tag => tag === option.name)
    })

    setFilteredTags(filtredValues)
    setFieldValue("tags", tags)
  }
  const options =[
  {"id":"1","name":"tag"}
  ]
  const { handleSubmit, setFieldValue, handleChange, values } = formik

  return (
    <div>
      <Container className={classes.root}>
        <Breadcrumbs />
        <Typography variant="h1" color="primary" className={classes.header} align="center">
        {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
        </Typography>
        <Box className={classes.paper}>
          <Box className={classes.paperTitle} px={5} py={2.5}>
            <Typography variant="h5" color="primary">2.2 Curate/ ModelForm</Typography>
          </Box>
          <Box className={classes.paperBody} p={5}>
            <form className={classes.form} id="curateSecurity-form" onSubmit={handleSubmit}>
            
              
             <Box mb={1}>
              <CreatableMultiSelect
                label="Input Parameters"
                options={options}
                name="tags"
                placeholder="Add Parameters"
                formik={formik}
                handleChange={handleTagsChange}
              />
            </Box>
            <Box mb={1}>
              <CreatableMultiSelect
                label="Output Parameters"
                options={filteredTags}
                name="tags"
                placeholder="Add Parameters"
                formik={formik}
                handleChange={handleTagsChange}
              />
            </Box>
            <Box mb={1}>
              <CreatableMultiSelect
                label="Performance Metrics"
                options={filteredTags}
                name="tags"
                placeholder="Add Parameters"
                formik={formik}
                handleChange={handleTagsChange}
              />
            </Box>
              
             <Box mb={2.5} 
            style={{marginTop:10,}}>
              <TextAreaInputVendor
                id="vendorname"
                name="vendorname"
                value={values.vendorname}
                label="Benefits"
                placeholderText="Enter text"
                //onChange={handleChange}
                formik={formik}
              />
            </Box>
            <Box mb={2.5} 
            style={{marginTop:10,}}>
              <TextAreaInputVendor
                id="vendorname"
                name="vendorname"
                value={values.vendorname}
                label="Impacts"
                placeholderText="Enter text"
                //onChange={handleChange}
                formik={formik}
              />
            </Box>
            </form>
          </Box>
        </Box>
        <Box className={classes.buttonsWrapper}>
          <Link to={`/curateprivacymodel/${nodeName}/${key}`} style={{ textDecoration: "none" }}>
            <BackBtn>Back</BackBtn>
          </Link>
          {/* <BackBtn form="curateSecurity-form" type="submit">Next</BackBtn> */}
          <Link to={`/responsibilitymodel/${nodeName}/${key}`} style={{ textDecoration: "none" }}>
            <BackBtn>Next</BackBtn>
          </Link>
        </Box>
      </Container>
    </div>
  );
}

export default Modelform;
