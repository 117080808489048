import { Container, makeStyles, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import NodeCreater from "../../components/my-work/node-creation/new-node/NodeCreater";
import InProgressNodes from "../../components/my-work/node-creation/InProgressNodes";
import MyWorkTabs from "../../components/my-work/MyWorkTabs";
import { NODE_TYPE } from "./nodeCreation";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 150px)",
  },
  title: {
    margin: "50px 0 24px",
    lineHeight: 1.2,
  },
  description: {
    marginBottom: 50,
  },
}));

const options = [
  {
    label: "New Object",
    value: "newNode",
  },
  {
    label: "In Progress Object",
    value: "inProgressNodes",
  },
];

function NodeCreationSteps(props) {
  const classes = useStyles();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [newNodeType, setNewNodeType] = useState(NODE_TYPE.dataProduct)

  const updatDimensions = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updatDimensions);
    return () => window.removeEventListener("resize", updatDimensions);
  }, []);

  return (
    <Container className={classes.container}>
      <Typography
        variant="h1"
        color="primary"
        align="center"
        className={classes.title}
      >
        Getting Started
      </Typography>
      <Typography
        className={classes.description}
        variant="subtitle1"
        color="primary"
        align="center"
      >
        A guided (step-by-step) process for well-designed, richly described, strongly governed, high-quality, and operationally reliable Data Products
      </Typography>
      <MyWorkTabs
        screenWidth={screenWidth}
        setSelectedIndex={setSelectedIndex}
        selectedIndex={selectedIndex}
        isDuality={true}
        tabPanelContents={{
          contentOne: <NodeCreater newNodeType={newNodeType} setNewNodeType={setNewNodeType} />,
          contentTwo: <InProgressNodes newNodeType={newNodeType} />,
        }}
        tabLabel={options}
      />
    </Container>
  );
}

export default NodeCreationSteps;
