import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const useLightTooltipStyle = makeStyles({
    lightTooltip: {
        padding: props => props.name === "author" ? '20px!important' : "7px 15px!important",
        backgroundColor: props => props.name === "card" ? "#123b64" : '#fff',
        color: props => props.name === "card" ? "#fff" : "#123b64",
        fontSize: props => props.name === "icon-button" ? '12px!important' : '14px!important',
        borderRadius: props => props.name === "icon-button" ? '6px!important' : '12px!important',
        margin: 0,
        boxShadow: '0px 10px 20px rgba(23, 87, 123, 0.2)'
    }
})

export default function NodeTooltip(props) {
    const classes = useLightTooltipStyle(props)
    return (
        <Tooltip
            classes={{ tooltip: classes.lightTooltip }}
            {...props}
        >
            {props.children}
        </Tooltip>
    );
}


// ---------------------------------------------------------
const useCustomStyles = makeStyles((theme) => ({
    arrow: {
        color: '#DFE6EB',
    },
    tooltip: {
        maxWidth: 366,
        margin: '5px 0',
        backgroundColor: props => props.arrow ? '#DFE6EB' : '#fff',
        borderRadius: props => props.arrow ? '6px!important' : 16,
        padding: '0px!important',
        '& p': {
            padding: '7px 15px!important'
        }
    },
}));

export function CustomTooltip({ children, bottomStart, ...props }) {
    const classes = useCustomStyles(props);
    return (
        <Tooltip
            placement={bottomStart && bottomStart}
            classes={classes}
            {...props}
        >
            {children}
        </Tooltip>
    );
}
