import { Box, Container, Grid, IconButton, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import TabContext from "../../../../contexts/TabContext";
import { NodeCreationService } from "../../../../services/NodeCreationService";
import { TAB_CLEAR } from "../../../../state/constants/tab";
import { EyeIcon } from "../../../icons";
import Breadcrumbs from "../../../shared/breadcrumb/Breadcrumbs";
import {
  BackBtn,
  PrimaryButton,
} from "../../../shared/buttons/custom-buttons/CustomButtons";
import {
  useStyles,
  defaultSelectedObjectives,
} from "./details/serviceLevelObjectives";
import SelectObjectives from "../../../shared/inputs/select-objectives/SelectObjectives";
import { nodeDetailTab, NEW_NODE_TYPES } from "../../../../constants/tab";
import ObjectiveSlider from "./details/ObjectiveSlider";
import Loader from "../../../shared/loader/Loader";
import FilterButton from "../../../filter-region/FilterButton";
import Sharenode from "../../../share/Shareregistration";
import SharePopover from "../../../../components/shared/popover/SharePopover";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";


const riskLevel = { name: "riskLevel", valueType: "percentage", value: 0 };

function ServiceLevelObjectives(data) {
  const classes = useStyles();
  const [isCreatedObjectives, setIsCreatedObjectives] = useState(true);
  const { addTabHandler, dispatch } = useContext(TabContext);
  const [formValues, setFormValues] = useState({});
  const [openShareContent, setOpenShareContent] = useState(null);
  const [checkedObjectives, setCheckedObjectives] = useState([]);
  const [objectiveOptions, setObjectiveOptions] = useState([]);
  const [applyedObjectives, setAppledObjectives] = useState([]);
  const [isLoadingOptions, setIsLoadingOptions] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const navigate = useNavigate();
  const { key, nodeName } = useParams();

  const handleAppliedObjectives = (objectives = defaultSelectedObjectives) => {
    NodeCreationService.getObjectivesOptions()
      .then((res) => {
        setObjectiveOptions(res.data);

        const filteredValues = res.data.filter((objective) => {
          return objectives.some((item) => item.name === objective.name);
        });
        setCheckedObjectives(filteredValues);
        setAppledObjectives(filteredValues);
        setIsLoadingOptions(false);
      })
      .catch(() => setIsLoadingOptions(false));
  };

  useEffect(() => {
    NodeCreationService.fetchServiceLevelObjectives(key)
      .then((res) => {
        const hasFormValues = Object.keys(res.data).length;
        if (hasFormValues) {
          let formData = {};
          res.data.forEach((item) => {
            formData[item.name] = item;
          });

          setFormValues(formData);
          handleAppliedObjectives(res.data);
        } else {
          setFormValues({ riskLevel });
          handleAppliedObjectives();
        }
        setIsLoadingData(false);
      })
      .catch(() => setIsLoadingData(false));
  }, [key]);

  const clickHandler = () => {
    dispatch({ type: TAB_CLEAR });
    const newNode = true;
    const newTabDetail = nodeDetailTab(
      key,
      nodeName,
      newNode,
      NEW_NODE_TYPES.dataProduct
    );
    

    addTabHandler(newTabDetail);
    navigate({
      pathname: "/tabs",
      search: `?nodeName=${nodeName}&nodeType=dataProduct&key=${key}`,
    });
  };

  const onSubmit = (values) => {
    if (!values.riskLevel) {
      values.riskLevel = riskLevel;
    }

    let payload = [];
    for (let key in values) {
      payload.push(values[key]);
    }

    NodeCreationService.addServiceLevelObjectives(key, payload)
      .then(() => {
        clickHandler();
        setIsCreatedObjectives(true);
      })
      .catch(() => {
        setIsCreatedObjectives(false);
      });
  };

  const formik = useFormik({
    initialValues: formValues,
    onSubmit,
    enableReinitialize: true,
  });
  const setBackPage = () => {
   
    navigate(-1)
  };

  const { values, setFieldValue, handleSubmit } = formik;

  useEffect(() => {
    let formData = {};

    if (applyedObjectives.length) {
      applyedObjectives.forEach((objective) => {
        const { name, valueType } = objective;
        const value = values[name]?.value ? values[name]?.value : 0;

        formData[objective.name] = { name, valueType, value };
      });
      if (values.riskLevel) {
        const { riskLevel } = values;
        formData = { ...formData, riskLevel };
      }
    }
    setFormValues(formData);
  }, [applyedObjectives]);

  if (isLoadingOptions || isLoadingData) {
    return <Loader />;
  }

  return (
    <Container className={classes.container}>
      <Box mb={2.5}>
      <Box mb={2.5} style={{ textDecoration: 'none', color: '#123b64' }}>
  <IconButton
    onClick={setBackPage}
    className={classes.icon}
    style={{ color: '#63ADA6' }}
  >
    <ArrowBackIosIcon style={{ fontSize: '13px' }} />
  </IconButton>

  <Link to="/home" style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
    Home
  </Link>
  {' / '}
  <Link  to={`/responsibility/${nodeName}/${key}`} style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
  Curateresponsibility
  </Link>
  {' / '}
  <Link  to={`/servicedataproduct/${nodeName}/${key}`} style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
  Servicelevelobjectives
  </Link>
  {' / '}
  <span style={{ fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>{nodeName}</span>
</Box>
      </Box>
      <Grid className={classes.gridContainer} container>
        <Grid xs={3} item />
        <Grid xs={6} item>
          <Typography align="center" variant="h1" color="primary">
            {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
          </Typography>
        </Grid>
        <Grid className={classes.gridItem} xs={3} item>
          <FilterButton popperName="Open new node details">
            <PrimaryButton
              form="service-level-objectives"
              type="submit"
              className={classes.primaryButton}
              startIcon={<EyeIcon />}
            >
              Preview
            </PrimaryButton>
          </FilterButton>
        </Grid>
      </Grid>
      {!isCreatedObjectives ? (
        <Typography
          className={classes.errorIndex}
          align="center"
          variant="h4"
          color="secondary"
        >
          Something went wrong please try again!
        </Typography>
      ) : null}
      <Box className={classes.paper}>
        <Box px={5} py={2.5} sx={{ borderBottom: "1px solid #E0E7EC" }}>
          <Typography variant="h5" color="primary">
            Service level objectives
          </Typography>
        </Box>
        <Box p={5}>
          <form onSubmit={handleSubmit} id="service-level-objectives">
            <Box
              mb={5}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box sx={{ width: "100%", maxWidth: 508 }}>
                <SelectObjectives
                  checkedObjectives={checkedObjectives}
                  setObjectiveOptions={setObjectiveOptions}
                  setCheckedObjectives={setCheckedObjectives}
                  objectiveOptions={objectiveOptions}
                  setAppledObjectives={setAppledObjectives}
                />
              </Box>
              <Box className={classes.recomendationBox}>
                <Typography variant="body2" color="primary">
                  We recommend that you choose the following objectives:
                </Typography>
                <Typography className={classes.typography} color="primary">
                Conformity, Consistency, Completeness, Timeliness,Validity 
                </Typography>
              </Box>
            </Box>
            <Box
              pb={2.5}
              mb={5}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                borderBottom: "1px solid #E0E7EC",
              }}
            >
              {applyedObjectives.length
                ? applyedObjectives.map((objective) => {
                    return (
                      <Box
                        key={objective.name}
                        mb={2.5}
                        sx={{ maxWidth: 508, width: "100%" }}
                      >
                        <ObjectiveSlider
                          id={objective.name}
                          name={objective.name}
                          value={+values[objective.name]?.value}
                          valueType={objective.valueType}
                          label={objective.name}
                          setFieldValue={setFieldValue}
                          color="#123b64"
                        />
                      </Box>
                    );
                  })
                : null}
            </Box>
            <Box>
              <Typography variant="h5" color="primary">
                Risk
              </Typography>
              <Box mt={2.5} sx={{ width: "100%", maxWidth: 508 }}>
                <ObjectiveSlider
                  id="riskLevel"
                  name="riskLevel"
                  value={+values.riskLevel?.value}
                  valueType="percentage"
                  label="Risk Level"
                  setFieldValue={setFieldValue}
                  color="#123b64"
                />
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
      <Box className={classes.buttonWrapper}>
        <Link
          to={`/responsibility/${nodeName}/${key}`}
          style={{ textDecoration: "none" }}
        >
          <BackBtn disableRipple>Back</BackBtn>
        </Link>
        <SharePopover
          anchorEl={openShareContent}
          setAnchorEl={setOpenShareContent}
          popoverButton={
            <div style={{ paddingLeft: 500 }}>
              <BackBtn type="submit">Assign</BackBtn>
            </div>
          }
        >
          <Sharenode setOpenShare={setOpenShareContent} data={data} />
        </SharePopover>
      </Box>
    </Container>
  );
}

export default ServiceLevelObjectives;
