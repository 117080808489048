import React from 'react';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { Box, makeStyles, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


const useStyles = makeStyles({
    dropDownButton: {
        minWidth: 328,
        maxWidth: 992,
        width: '100%',
        boxShadow: '0px 4px 10px rgba(23, 87, 123, 0.1)',
        padding: '14px 16px',
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: 'unset',
        textTransform: 'unset'
    },
    paper: {
        maxWidth: 992,
        width: '100%',
        boxShadow: '0px 4px 10px rgba(0, 87, 123, 0.1)!important',
        top: '1px!important',
        borderTop: '1px solid #E0E7EC',
        "& .MuiList-padding": {
            padding: "10px 0",
            "& .Mui-selected": {
                color: "#f79f19",
                background: 'unset'
            }
        }
    },
    menuItem: {
        fontSize: 14,
        padding: '10px 16px'
    },
    popper: {
        width: '100%',
        zIndex: 99,
        maxWidth: props => {
            if(props.cart === "cart") {
                return 
            }
        }
    }
})

export default function DropDownButton({ options, setSelectedIndex, selectedIndex, screenWidth, ...props }) {
    const classes = useStyles(props)
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);

    const handleClick = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <Box className={classes.dropDownButtonBox} alignItems="center">
            <Button disableRipple className={classes.dropDownButton} ref={anchorRef} onClick={handleClick}>
                <Typography variant='body2' color='primary'>{options[selectedIndex].label}</Typography>
                <Box>
                    {
                        open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                    }
                </Box>
            </Button>
            <Box sx={{maxWidth: 992, width: "100%",}}>
                <Popper
                    className={classes.popper}
                    style={{ maxWidth: screenWidth > 768 ? screenWidth - 48 : screenWidth -  32, width: "100%", zIndex: 99, }}
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper square className={classes.paper}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                            <MenuItem
                                                className={classes.menuItem}
                                                key={option.value}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index)}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box>
        </Box>
    );
}
