import { Box, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { TextButton } from '../../buttons/custom-buttons/CustomButtons';
import CustomizedSelect from '../../inputs/CustomizedSelect';
import MultiSelect from '../../inputs/MultiSelect';
import SelectRole from '../../inputs/SelectRole';
import {
    getBusinessOptions,
    getDataDomainOptions,
    getGeographyOptions,
    getProductOptions,
    getRegisteredUsers,
    getRoleOptions, useStyles
} from './MatrixTeamDetails';

const MatrixTeamForm = (props) => {
    const classes = useStyles()
    const {
        setState,
        contextNumber,
        requestType,
        formFields,
        setFormFields,
        dataDomainOptions,
        setDataDomainOptions,
        isContextAdded,
        isContexUpdated,
        isTeamCreated,
        editContextData,
        isAllFieldFilled,
        setOpen,
        index,
        isSubmited
    } = props
    const [roleOptions, setRoleOptions] = useState([])
    const [registeredUsers, setRegisteredUsers] = useState([])
    const [geograhphyOptions, setGeographyOptions] = useState([])
    const [productsOptions, setProductsOptions] = useState([])
    const [businessOptions, setBusinessOptions] = useState([])

    const { isLoading: roleFetching,  } = useQuery('role-options', getRoleOptions, {
        onSuccess: res => {
            const copyRoles = res.data.map(role => {
                return {
                    value: role,
                    label: role
                }
            })
            setRoleOptions(copyRoles)
        }
    })

    const { isLoading: usersFetching } = useQuery('registred-users', getRegisteredUsers, {
        onSuccess: res => {
            let filteredUsers = res.data.filter(user => user.FirstName)
            const registeredUsers = filteredUsers.map(user => {
                return {
                    id: user.id,
                    name: user.FirstName
                }
            })
            setRegisteredUsers(registeredUsers)
        }
    })

    const { isLoading: businessFetching } = useQuery('business-options', getBusinessOptions, {
        onSuccess: res => {
            setBusinessOptions(res.data.response)
        }
    })

    const { isLoading: dataDomainFetching } = useQuery('dataDomain-optionss', getDataDomainOptions, {
        onSuccess: data => {
            const dataDomain = data.data.response.map(item => {
                return {
                    label: item.name,
                    value: item.key
                }
            })
            setDataDomainOptions(dataDomain)
        }
    })
    const { isLoading: geographyFetching } = useQuery('geograpy-options', getGeographyOptions, {
        onSuccess: data => {
            setGeographyOptions(data.data.response)
        }
    })
    const { isLoading: productsFetching } = useQuery('product-options', getProductOptions, {
        onSuccess: data => {
            setProductsOptions(data.data.response)
        }
    })

    const handleBusinessChange = (event, value) => {
        if (!value.length) {
            setFormFields(prev => {
                return {
                    ...prev,
                    business: [],
                    errors: {
                        ...prev.errors,
                        business: 'At least one business must be selected'
                    }
                }
            })
        } else {
            setFormFields(prev => {
                return {
                    ...prev,
                    business: [
                        ...value
                    ],
                    errors: {
                        ...prev.errors,
                        business: null
                    }
                }
            })
        }
    }

    const handleDatadomainChange = (event) => {
        let { value } = event.target
        setFormFields(prev => {
            return {
                ...prev,
                dataDomain: [{ name: '', key: value }],
                errors: {
                    ...prev.errors,
                    dataDomain: null
                }
            }
        })
    }

    const handleGeographyChange = (event, value) => {
        if (!value.length) {
            setFormFields(prev => {
                return {
                    ...prev,
                    geography: [],
                    errors: {
                        ...prev.errors,
                        geography: 'At least one geography must be selected'
                    }
                }
            })
        }
        else {
            setFormFields(prev => {
                return {
                    ...prev,
                    geography: [
                        ...value
                    ],
                    errors: {
                        ...prev.errors,
                        geography: null
                    }
                }
            })
        }
    }

    const handleProductsChange = (event, value) => {
        if (!value.length) {
            setFormFields(prev => {
                return {
                    ...prev,
                    product: [],
                    errors: {
                        ...prev.errors,
                        product: 'At least one product must be selected'
                    }
                }
            })
        } else {
            setFormFields(prev => {
                return {
                    ...prev,
                    product: [
                        ...value
                    ],
                    errors: {
                        ...prev.errors,
                        product: null
                    }
                }
            })
        }
    }

    const prevIsValid = () => {
        const isEmpty = formFields.roles.some(item => item.role === "" || item.users.length === 0)
        if (isEmpty) {
            let roles = formFields.roles
            formFields?.roles.forEach((item, index) => {
                if (item.role === '') {
                    roles[index].errors.role = "Role is required"
                }
                if (item.users.length === 0) {
                    roles[index].errors.users = "At least one user must be selected"
                }
                setFormFields(prev => {
                    return {
                        ...prev,
                        roles: roles
                    }
                })
            })
        }
        return !isEmpty
    }

    const addRole = () => {
        let defaultRole = {
            role: '',
            users: [],
            errors: {
                role: '',
                users: ''
            }
        }
        if (prevIsValid()) {
            setFormFields(prev => {
                return {
                    ...prev,
                    roles: [
                        ...prev.roles,
                        defaultRole
                    ]
                }
            })
        }
    }

    const deleteRole = (index) => {
        const roles = formFields.roles.filter((role, roleIndex) => roleIndex !== index)
        setFormFields(prev => {
            return {
                ...prev,
                roles: roles
            }
        })
    }

    useEffect(() => {
        if (requestType === "update") {
            setFormFields(editContextData)
        } else {
            const formFields = {
                dataDomain: [{ name: '', key: '' }],
                business: [],
                geography: [],
                product: [],
                roles: [
                    {
                        role: '',
                        users: [],
                        errors: { role: '', users: '' }
                    }
                ],
                errors: { business: null, dataDomain: '', geography: null, product: null }
            }
            setFormFields(formFields)
        }
        if (isTeamCreated === 200 || isContextAdded === 200 || isContexUpdated === 200) {
            if (isSubmited) {
                setState(false)
                if (requestType === "create-team") {
                    setOpen(false)
                }
            }
        }
    }, [
        productsOptions,
        dataDomainOptions,
        geograhphyOptions,
        businessOptions,
        isTeamCreated,
        isContextAdded,
        isContexUpdated
    ])
   
    if (roleFetching || usersFetching || businessFetching || dataDomainFetching || geographyFetching || productsFetching) {
        return (
            <Box p={5} sx={{ width: 588 }}>
                <Typography color="secondary">Loading...</Typography>
            </Box>
        )
    }

    return (
        <form>
            <Typography className={classes.typography}>{contextNumber[index]} Context</Typography>
            <Typography variant='body2' className={classes.body2}>
                Pick categories for your combination
            </Typography>
            <Box className={classes.contextInutsWrapper}>
                <Box style={{ margin: '26px 0 20px' }}>
                    <Typography className={classes.label} variant='body2'>Business</Typography>
                    <MultiSelect
                        id="business"
                        name="business"
                        placeholder="Add business"
                        handleChange={handleBusinessChange}
                        defaultValue={formFields.business}
                        options={businessOptions}
                        label="Business"
                    />
                    {
                        formFields?.errors && formFields.errors.business ?
                            <Typography color='secondary'>
                                {formFields.errors.business}
                            </Typography> : null
                    }
                </Box>
                <Box sx={2.5}>
                    <CustomizedSelect
                        name="dataDomain"
                        id="dataDomain"
                        value={formFields.dataDomain ? formFields.dataDomain[0].key : ''}
                        defaultValue="Select data domain"
                        options={dataDomainOptions}
                        label="Data Domain"
                        handleChange={handleDatadomainChange}
                    />
                    {
                        formFields?.errors && formFields.errors.dataDomain ?
                            <Typography color='secondary'>
                                {formFields.errors.dataDomain}
                            </Typography> : null
                    }
                </Box>
                <Box style={{ marginBottom: 20 }}>
                    <Typography className={classes.label} variant='body2'>Geography</Typography>
                    <MultiSelect
                        id="geography"
                        name="geography"
                        placeholder="Add geography"
                        handleChange={handleGeographyChange}
                        defaultValue={formFields.geography}
                        options={geograhphyOptions}
                        label="geography"
                    />
                    {
                        formFields?.errors && formFields.errors.geography ?
                            <Typography color='secondary'>
                                {formFields.errors.geography}
                            </Typography> : null
                    }
                </Box>
                <Box style={{ marginBottom: 20 }}>
                    <Typography className={classes.label} variant='body2'>Products</Typography>
                    <MultiSelect
                        id="products"
                        name="products"
                        placeholder="Add products"
                        handleChange={handleProductsChange}
                        defaultValue={formFields.product}
                        options={productsOptions}
                        label="products"
                    />
                    {
                        formFields?.errors && formFields.errors.product ?
                            <Typography color='secondary'>
                                {formFields.errors.product}
                            </Typography> : null
                    }
                </Box>
            </Box>
            <Box className={classes.inputsWrapper}>
                <Typography className={classes.body2}>Pick multiple roles</Typography>
                {
                    formFields?.roles && formFields.roles.map((field, index) => {
                        return (
                            <Box mb={2.5} key={index}>
                                <SelectRole
                                    deleteRole={deleteRole}
                                    users={registeredUsers}
                                    roleOptions={roleOptions}
                                    label="Role"
                                    field={field}
                                    setFormFields={setFormFields}
                                    formFields={formFields}
                                    index={index}
                                    type="matrix-team"
                                    requestType={requestType}
                                />
                                {
                                    (formFields.roles[index].errors.role && formFields.roles[index].errors.users) ?
                                        <Typography color='secondary'>Role and users are required!</Typography> :
                                        formFields.roles[index].errors.role ?
                                            <Typography color='secondary'>{formFields.roles[index].errors.role}</Typography> :
                                            formFields.roles[index].errors.users ?
                                                <Typography color='secondary'>{formFields.roles[index].errors.users}</Typography> : null
                                }
                            </Box>
                        )
                    })
                }
            </Box>
            <TextButton
                onClick={addRole}
                disableRipple
                className={classes.addNewRoles}
                startIcon={<AddIcon />}
            >
                Add new roles
            </TextButton>
            <Typography color="secondary">{isAllFieldFilled}</Typography>
        </form>
    );
}

export default MatrixTeamForm;