import BiggerThanIcon from './../../../assets/images/biggerthanicon.png';
function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ 
                ...style, 
                display: "flex", 
                justifyContent: 'center', 
                alignItems: 'center', 
                background: "#E0E7EC", 
                width: '48px', 
                height: '48px', 
                color: '#123B64', 
                borderRadius: '12px',
                right: -45 
            }}
            onClick={onClick}
        >
            <img src={BiggerThanIcon} alt="BiggerThanIcon" />
        </div>
    );
}

export default NextArrow;