import { useState } from 'react';
import { useStyles } from './MatrixTeamDetails'
import { Box, Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TextButton } from '../../buttons/custom-buttons/CustomButtons';
import MatrixTeamBuilder from './MatrixTeamBuilder'
import { YellowButton } from '../../buttons/custom-buttons/CustomButtons';
import AddIcon from '@material-ui/icons/Add';

function MatrixTeamWrapper(props) {
    const {
        setState,
        teamId,
        requestType,
        getNewContext,
        editContextData,
        contextName,
        updateContext,
        setOpen,
        contextNumber,
        setIsTeamLoading
    } = props
    const classes = useStyles()

    const [isApplied, setIsApplied] = useState(false)
    const [contexts, setCotexts] = useState([1])
    const [contextTitle, setContextTitle] = useState(contextNumber ? [contextNumber] : [1])
    const [isAllFieldFilled, setIsAllFieldFilled] = useState(null)
    const [isSubmited, setIsSubmited] = useState(false)

    const addNewContext = () => {
        setIsApplied(true)
    }

    const addContextTwo = () => {
        setCotexts(prev => {
            return [...prev, +prev.length + 1]
        })
        setContextTitle(prev => {
            return [...prev, +prev + 1]
        })
    }

    const handleSubmit = () => {
        setIsApplied(true)
        setIsSubmited(true)
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.titleWrapper}>
                <Typography className={classes.title}>Matrix Team builder</Typography>
                <IconButton className={classes.IconButton} onClick={() => setState(false)} disableRipple>
                    <CloseIcon />
                </IconButton>
            </Box>
            {
                contexts.map((context, index) => {
                    return (
                        <MatrixTeamBuilder
                            key={index}
                            setState={setState}
                            teamId={teamId}
                            requestType={requestType}
                            getNewContext={getNewContext}
                            editContextData={editContextData}
                            contextName={contextName}
                            updateContext={updateContext}
                            setOpen={setOpen}
                            contextNumber={contextTitle}
                            isApplied={isApplied}
                            setIsApplied={setIsApplied}
                            lastContext={contexts.length}
                            index={index}
                            context={context}
                            setIsAllFieldFilled={setIsAllFieldFilled}
                            isAllFieldFilled={isAllFieldFilled}
                            addNewContext={addNewContext}
                            addContextTwo={addContextTwo}
                            isSubmited={isSubmited}
                            setIsTeamLoading={setIsTeamLoading}
                        />
                    )
                })
            }
            <Box px={5} pb={5}>
                <Box>
                    <TextButton
                        onClick={addNewContext}
                        disableRipple
                        className={classes.textButton1}
                        startIcon={<AddIcon />}
                    >
                        Add new responsibility
                    </TextButton>
                </Box>
                <YellowButton
                    onClick={handleSubmit}
                    className={classes.yellowButton}
                    startIcon={
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.619741 6.49748C0.184741 6.35248 0.180575 6.11831 0.628075 5.96914L16.5339 0.667478C16.9747 0.520812 17.2272 0.767478 17.1039 1.19914L12.5589 17.1041C12.4339 17.545 12.1797 17.56 11.9931 17.1416L8.99807 10.4016L13.9981 3.73498L7.33141 8.73498L0.619741 6.49748Z" fill="white" />
                        </svg>
                    }
                >
                    Apply
                </YellowButton>
            </Box>
        </Box>
    );
}

export default MatrixTeamWrapper;