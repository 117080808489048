import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles({
    container: {
        minHeight: "calc(100vh - 150px)",
        paddingTop: 30,
    },
    paper: {
        boxShadow: "0px 4px 20px rgba(1, 25, 54, 0.1)",
        marginBottom: 40,
    },
    header: {
        padding: "20px 40px",
        borderBottom: "1px solid #E0E7EC",
    },
    title: {
        fontWeight: 700,
    },
    main: {
        padding: 40,
    },
    chipTitle: {
        fontWeight: 500,
        marginBottom: 15,
    },
    chipsWrapper: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        marginLeft: -4,
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',

    },
    button: {
        width: 219,
        marginTop: 20,
        "& span": {
            fontWeight: 700,
        },
    },
    outlinedButton: {
        marginRight: 15
    },
    chip: {
        height: 24,
        borderRadius: 12,
        color: "#123b64",
        margin: "0 8px 8px 0",
        "& .MuiChip-deleteIcon": {
            width: 14,
            height: 14,
            color: "#123b64",
            marginRight: 8,
        },
    },
});