import { useState } from 'react';
import { Box, makeStyles, Typography, IconButton } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import SearchBar from '../../components/shared/inputs/SearchBar';
import SidebarFacets from '../../components/sidebar/sidebar-facets/SidebarFacets'
import CollectionFilters from './CollectionFilters';

const useStyles = makeStyles(theme => ({
    wrapper: {
        padding: "40px 32px 30px",
    },
    titleWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 30,
    },
    title: {
        fontSize: 22,
        fontWeight: 700,
        color: '#fff'
    },
    closeBtn: {
        "& svg": {
            width: 21,
            height: 21,
            fill: "#fff",
        },
    },
    buttonWrapper: {
        padding: "20px 32px",
        textAlign: "center",
    },
    buttonGroup: {
        background: "#fff",
        borderRadius: 8,
        height: 32,
        padding: 1,
    },
    filtersButton: {
        width: "50%",
        height: 30,
        background: (showFilters) => (showFilters ? "#123b64" : "#fff"),
        borderRadius: 8,
        color: (showFilters) => (showFilters ? "#fff" : "#123b64"),
        border: "none",
        fontWeight: 500,
    },
    facetsButton: {
        width: "50%",
        height: 30,
        background: (showFilters) => (showFilters ? "#fff" : "#123b64"),
        borderRadius: 8,
        color: (showFilters) => (showFilters ? "#123b64" : "#fff"),
        border: "none",
        fontWeight: 500,
    },
}))

function CollectionSidebar({ setOpenSidebar }) {
    const [showFilters, setShowFilters] = useState(true);
    const classes = useStyles(showFilters)
    return (
        <Box sx={{ height: '100%', width: 380 }}>
            <Box className={classes.wrapper}>
                <Box className={classes.titleWrapper}>
                    <Typography className={classes.title}>Filters and Facets</Typography>
                    <IconButton
                        onClick={() => setOpenSidebar(false)}
                        className={classes.closeBtn}
                        disableRipple
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box>
                    <SearchBar />
                </Box>
            </Box>
            <Box className={classes.buttonWrapper}>
                <Box className={classes.buttonGroup}>
                    <button
                        className={classes.filtersButton}
                        onClick={() => setShowFilters(true)}
                    >
                        Filters
                    </button>
                    <button
                        className={classes.facetsButton}
                        onClick={() => setShowFilters(false)}
                    >
                        Facets
                    </button>
                </Box>
            </Box>
            <Box className={classes.sidebarFiltersWrapper}>
                {showFilters ? (
                    <CollectionFilters />
                ) : (
                    <SidebarFacets />
                )}
            </Box>
        </Box>
    );
}

export default CollectionSidebar;