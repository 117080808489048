import { Box, makeStyles, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { TextButton } from './../../shared/buttons/custom-buttons/CustomButtons';
import AddIcon from '@material-ui/icons/Add';
import CustomizedSelect from '../../shared/inputs/CustomizedSelect';
import CustomModal from '../../shared/modal/CustomModal';
import CreateTeam from './create-team/CreateTeam';
import SimpleTable from './collapse/collapse-content/SimpleTable';
import { CustomTooltip } from '../../shared/tooltip/NodeTooltip';
import Warning from '../../shared/warning/Warning';
import CustomDrawer from '../../shared/drawer/CustomDrawer';
import MatrixTeamWrapper from '../../shared/team-builder/matrix-team-builder/MatrixTeamWrapper';
import TeamPlaceholder from './collapse/collapse-content/TeamPlaceholder';
import FilterButton from '../../filter-region/FilterButton';
import SimpleTeamBuilder from '../../shared/team-builder/simple-team-builder/SimpleTeamBuilder';
import { InfoIcon, TrashBinIcon } from '../../icons';
import MatrixTable from './collapse/collapse-content/MatrixTable';

// Custom styles using Material-UI's makeStyles hook
const useStyles = makeStyles({
    root: {
        color: '#123b64',
        width: '100%'
    },
    title: {
        fontWeight: 500,
        lineHeight: '11px',
        marginBottom: 16
    },
    addRoles: {
        width: '100%',
        maxWidth: 700,
        height: 410,
        marginBottom: 10,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 4
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#F79F19',
            borderRadius: 2,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#808c9a4d',
            borderRadius: 2
        },
        '&::-webkit-scrollbar-button:end:increment': {
            height: 16,
            display: 'block'
        },
        '&::-webkit-scrollbar-button:start:increment': {
            height: 16,
            display: 'block'
        }
    },
    customCollWrapper: {
        marginBottom: 10
    },
    textButton: {
        fontWeight: 700,
        '& .MuiButton-startIcon': {
            marginRight: 3,
        }
    },
    label: {
        fontWeight: 500,
        lineHeight: 1.1,
        marginBottom: 16
    },
    radioWrapper: {
        display: 'flex',
        flexDirection: 'row',
        '& .MuiFormControlLabel-root': {
            marginRight: 36
        }
    },
    box: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    labelWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20
    },
    responsibility: {
        fontWeight: 700,
        marginRight: 6.5
    },
    drawerWrapper: {
        display: 'flex',
        justifyContent: 'end',
        marginBottom: 20
    },
    trashBinIcon: {
        width: 15,
        height: 15
    }
})

const SectionAddRoles = (props) => {
    const {
        team,
        setTeamName,
        teamName,
        teamOptions,
        deleteTeam,
        getUpdatedSimpleData,
        deleteContext,
        handleEditTeam,
        getTeamOptions,
        getNewContext,
        updateContext,
        handleEditContext,
        editContextData,
        isTeamAvailable = true,
        setIsTeamLoading,
        setNewTeam,
        contextName,
        teamType,
        teamId,
        teamRoles,
        isTeamLoading
    } = props
    const classes = useStyles()
    const [open, setOpen] = useState(false)
    const [teams, setTeams] = useState([])
    const [state, setState] = useState(false)
    const [openCollapse, setOpenCollapse] = useState(false);

    const convertToArr = (obj) => {
        const arr = []
        let role = {}
        Object.keys(obj).forEach((key, index) => {
            if (index % 3 === 0 && index > 0) {
                arr.push({ ...role })
                role = {}
            }
            role[key] = obj[key]
        })
        if (Object.keys(role).length !== 0) {
            arr.push(role)
        }
        return arr
    }

    useEffect(() => {
        if (team && team.roles) {
            const simpleTeams = convertToArr(team.roles)
            setTeams(simpleTeams)
        } else {
            setTeams([])
        }
    }, [team, teamRoles])

    return (
        <Box className={classes.root}>
            <Box mb={2.5}>
                <Box mb={1.25}>
                    <CustomizedSelect
                        name="teamName"
                        id="teamName"
                        value={teamName}
                        defaultValue="Select team"
                        options={teamOptions}
                        label="Team"
                        handleChange={(e) => setTeamName(e.target.value)}
                    />
                    {
                        !isTeamAvailable && !teamId ?
                            <Typography style={{ marginTop: 8 }} variant='h6' color='secondary'>Team is required</Typography>
                            : null
                    }
                </Box>
                <Box mb={2.5}>
                    <TextButton
                        disableRipple
                        className={classes.textButton}
                        startIcon={<AddIcon />}
                        onClick={() => setOpen(true)}
                    >
                        Create new team
                    </TextButton>
                </Box>
                <CustomModal open={open} setOpen={setOpen}>
                    <CreateTeam
                        getTeamOptions={getTeamOptions}
                        setOpen={setOpen}
                        setNewTeam={setNewTeam}
                        getNewContext={getNewContext}
                        getUpdatedSimpleData={getUpdatedSimpleData}
                    />
                </CustomModal>
            </Box>
            <Box>
                <Box className={classes.labelWrapper}>
                    <Typography variant="body1" className={classes.responsibility}>Responsibility</Typography>
                    <CustomTooltip
                        bottomStart="bottom-start"
                        title={<Warning />}
                    >
                        <InfoIcon />
                    </CustomTooltip>
                </Box>
                {
                    teamType === "matrix" && team && team.length > 0 ?
                        <Box className={classes.drawerWrapper}>
                            <Box sx={{ marginRight: 16 }} >
                                <TextButton
                                    disableRipple
                                    className={classes.textButton}
                                    startIcon={<AddIcon />}
                                    onClick={() => setState(true)}
                                >
                                    Add new responsibility
                                </TextButton>
                            </Box>
                            <Box onClick={() => deleteTeam()}>
                                <FilterButton popperName="Delete All">
                                    <TrashBinIcon className={classes.trashBinIcon} />
                                </FilterButton>
                            </Box>
                        </Box> : null
                }
            </Box>
            <Box className={classes.addRoles}>
                {
                    isTeamLoading ? <Typography variant='h5' color='secondary'>Loading...</Typography> :
                        teamName && teamType === "simple" && teams.length ?
                            teams.map((teamGroup, index) => {
                                return (
                                    <Box key={index} mb={1.25}>
                                        <SimpleTable
                                            deleteTeam={deleteTeam}
                                            teamGroup={teamGroup}
                                            handleEditTeam={handleEditTeam}
                                            setState={setState}
                                        />
                                    </Box>
                                )
                            }) : teamName && team.length && teamType === "matrix" ?
                                <MatrixTable
                                    handleEditContext={handleEditContext}
                                    editContextData={editContextData}
                                    setOpenCollapse={setOpenCollapse}
                                    openCollapse={openCollapse}
                                    deleteContext={deleteContext}
                                    updateContext={updateContext}
                                    contextName={contextName}
                                    teamId={teamId}
                                    data={team}
                                />
                                : teamName ?
                                    <TeamPlaceholder
                                        getUpdatedSimpleData={getUpdatedSimpleData}
                                        getNewContext={getNewContext}
                                        setIsTeamLoading={setIsTeamLoading}
                                        teamType={teamType}
                                        teamId={teamId}
                                    />
                                    : null
                }

            </Box>
            <CustomDrawer state={state} setState={setState} anchor='right'>
                {
                    teamType === "matrix" ?
                        <MatrixTeamWrapper
                            teamId={teamId}
                            setState={setState}
                            getNewContext={getNewContext}
                            contextNumber={team?.length + 1}
                            requestType="add-context"
                            setIsTeamLoading={setIsTeamLoading}
                        /> :
                        <SimpleTeamBuilder
                            teamRoles={teamRoles}
                            setState={setState}
                            page="edit-collection"
                            getUpdatedSimpleData={getUpdatedSimpleData}
                        />
                }
            </CustomDrawer>
        </Box>

    );
}

export default SectionAddRoles;