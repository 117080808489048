import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Box, Container, IconButton, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import TextAreaInput from "../../../shared/inputs/TextAreaInput";
import CustomizedSelect from "../../../shared/inputs/CustomizedSelect";
import { BackBtn } from "../../../shared/buttons/custom-buttons/CustomButtons";
import { NodeCreationService } from "../../../../services/NodeCreationService";
import Loader from "../../../shared/loader/Loader";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { 
  BEST_PRODUCT, 
  DATA_TYPE, 
  DATA_VALUME, 
  REFRESH_FREQUENCY, 
  DESCRIPTION, 
  initialValues,
  validationSchema,
  useStyles
} from "./curate-details";
import Breadcrumbs from "../../../shared/breadcrumb/Breadcrumbs";
import CategoryCreation from "../../../your-profile/CategoryCreation";
import SelectObjectives from "../../../shared/inputs/select-objectives/SelectObjectives";
import ObjectiveSlider from "../service-level-objectives/details/ObjectiveSlider";
import CustomizedInput from "../../../shared/inputs/CustomizedInput";
import SelectAttributes from "../../../shared/inputs/select-objectives/SelectAttributes";

function CurateContextdataset() {
  const classes = useStyles();
  const { key, nodeName, selectedNodeName } = useParams();
  const [productOptions, setProductOptions] = useState([]);
  const [dataTypeOptions, setDataTypeOptions] = useState([]);
  const [refreshFreqOptions, setRefreshFreqOptions] = useState([])
  const [dataVolumeOptions, setDataVolumeOptions] = useState([])
  const [initialFormValues, setInitialFormValues] = useState(initialValues)
  const [isLoadingProduct, setIsLoadingProduct] = useState(true)
  const [isLoadingDataType, setIsLoadingDataType] = useState(true)
  const [isLoadingRefFreq, setIsLoadingRefFreq] = useState(true)
  const [isLoadingDataVolume, setIsLoadingDataVolume] = useState(true)
  const [checkedObjectives, setCheckedObjectives] = useState([])
  const [objectiveOptions, setObjectiveOptions] = useState([])
  const [applyedObjectives, setAppledObjectives] = useState([])
  const [isLoadingOptions, setIsLoadingOptions] = useState(true)
  const [isLoadingData, setIsLoadingData] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    getProductOptions()
    getDataTypeOptions()
    getRefFreqOptions()
    getDataVolumeOptions()
    NodeCreationService.getCurateContextValuesdataset(key)
    .then(res => {
      const hasData = Object.keys(res.data).length
      if (hasData) {
        const formValues = {
          [BEST_PRODUCT]: res.data.whatContentCategoryBestDescribesYourProduct,
          [DESCRIPTION]: res.data.howThisProductIsImportantToYourBusiness,
          [DATA_VALUME]: res.data.estimatedDataVolume,
          [DATA_TYPE]: res.data.dataType,
          [REFRESH_FREQUENCY]: res.data.refreshFrequency
      }
        setInitialFormValues(formValues)
      }
    })
  }, [key]);


  const getProductOptions = () => {
    NodeCreationService.fetchSelectOptions('WhatContextCategoryBestDescribesYourProduct')
      .then(res => {
        const transformedData = res.data[0].map(item => {
          return { label: item, value: item }
        })
        setProductOptions(transformedData)
        setIsLoadingProduct(false)
      })
      .catch(() => {
        setIsLoadingProduct(false)
      })
  }

  const getDataTypeOptions = () => {
    NodeCreationService.fetchSelectOptions('DataType')
      .then(res => {
        const transformedData = res.data[0].map(item => {
          return { label: item, value: item }
        })
        setDataTypeOptions(transformedData)
        setIsLoadingDataType(false)
      })
      .catch(() => {
        setIsLoadingDataType(false)
      })
  }

  const getRefFreqOptions = () => {
    NodeCreationService.fetchSelectOptions('RefreshFrequency')
      .then(res => {
        const transformedData = res.data[0].map(item => {
          return { label: item, value: item }
        })
        setRefreshFreqOptions(transformedData)
        setIsLoadingRefFreq(false)
      })
      .catch(() => {
        setIsLoadingRefFreq(false)
      })
  }

  const getDataVolumeOptions = () => {
    NodeCreationService.fetchSelectOptions('EstimatedDataVolume')
      .then(res => {
        const transformedData = res.data[0].map(item => {
          return { label: item, value: item }
        })
        setDataVolumeOptions(transformedData)
        setIsLoadingDataVolume(false)
      })
      .catch(() => {
        setIsLoadingDataVolume(false)
      })
  }

  const onSubmit = (values) => {
    const payload = []
    Object.keys(values).forEach(item => {
      let payloadItem = {}
      payloadItem.attributeName = item
      payloadItem.attributeValue = values[item]
      payload.push(payloadItem)
    })
    NodeCreationService.createAttributes(payload, key)
    .then(() => {
      navigate(`/curateprivacydataset/${selectedNodeName}/${nodeName}/${key}`)
    })
    .catch(() => {
      console.log('error is occured in api')
    })
  }

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  });
  const setBackPage = () => {
   
    navigate(-1)
  };

  const { values, handleChange, handleSubmit ,setFieldValue } = formik

  if (isLoadingProduct || isLoadingDataType || isLoadingRefFreq || isLoadingDataVolume) {
    return <Loader />
  }

  return (
    <div>
      <Container className={classes.root}>
      <Box mb={2.5} style={{ textDecoration: 'none', color: '#123b64' }}>
  <IconButton
    onClick={setBackPage}
    className={classes.icon}
    style={{ color: '#63ADA6' }}
  >
    <ArrowBackIosIcon style={{ fontSize: '13px' }} />
  </IconButton>

  <Link to="/home" style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
    Home
  </Link>
  {' / '}
  <Link to={`/classificationdataset/${selectedNodeName}/${nodeName}/${key}`} style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
  Classificationdataset
  </Link>
  {' / '}
  <Link to={`/curatecontextdataset/${selectedNodeName}/${nodeName}/${key}`} style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
  Curatecontextdataset
  </Link>
  {' / '}
  <span style={{ fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>{nodeName}</span>
</Box>
        <Typography variant="h1" color="primary" className={classes.header} align="center">
        {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
        </Typography>
        <Box className={classes.paper}>
          <Box className={classes.paperTitle} px={5} py={2.5}>
            <Typography variant="h5" color="primary">2. Curate/ Context</Typography>
          </Box>
          <Box className={classes.shadowwrapper} p={5}>
            <form id="curate-form" className={classes.wrapper} onSubmit={handleSubmit}>
            <Box className={classes.leftSide}>
              <Box mb={2.5}>
                <CustomizedSelect
                  id="bestProduct"
                  label={BEST_PRODUCT}
                  options={productOptions}
                  name={BEST_PRODUCT}
                  value={values[BEST_PRODUCT]}
                  handleChange={handleChange}
                  defaultValue="Select from the list"
                  formik={formik}
                />
              </Box>
              <Box mb={2.5}>
                <TextAreaInput
                  id="importans of product"
                  label={DESCRIPTION}
                  placeholderText="Enter text"
                  name={DESCRIPTION}
                  value={values[DESCRIPTION]}
                  onChange={handleChange}
                  formik={formik}
                />
              </Box>
              <Box mb={2.5}>
                <CustomizedSelect
                  id="dataVolume"
                  label={DATA_VALUME}
                  options={dataVolumeOptions}
                  name={DATA_VALUME}
                  value={values[DATA_VALUME]}
                  handleChange={handleChange}
                  defaultValue="Select data volume"
                  formik={formik}
                />
              </Box>
              <Box mb={2.5}>
                <CustomizedSelect
                  id="dataType"
                  label={DATA_TYPE}
                  options={dataTypeOptions}
                  name={DATA_TYPE}
                  value={values[DATA_TYPE]}
                  handleChange={handleChange}
                  defaultValue="Select data type"
                  formik={formik}
                />
              </Box>
              <Box>
                <CustomizedSelect
                  id="refreshFrequency"
                  label={REFRESH_FREQUENCY}
                  options={refreshFreqOptions}
                  name={REFRESH_FREQUENCY}
                  value={values[REFRESH_FREQUENCY]}
                  handleChange={handleChange}
                  defaultValue="Select refresh frequency"
                  formik={formik}
                />
              </Box>
              </Box>
              <Box className={classes.rightSide}> 
              <Typography variant="h5" color="primary"> Apply the Attributes </Typography>  
           <Box sx={{ width: '100%', maxWidth: 508 }}>
                <SelectAttributes
                  checkedObjectives={checkedObjectives}
                  setObjectiveOptions={setObjectiveOptions}
                  setCheckedObjectives={setCheckedObjectives}
                  objectiveOptions={objectiveOptions}
                  setAppledObjectives={setAppledObjectives}
                />
              </Box>
              <Box pb={2.5} mb={5} 
              >
              {
                applyedObjectives.length ? applyedObjectives.map(objective => {
                  return (
                    <Box key={objective.name} mb={2.5} sx={{ maxWidth: 508, width: '100%' ,marginTop:10,}}>
                  
                     <CustomizedInput
                id={objective.name}
                name={objective.name}
                label={objective.name}
                value={+values[objective.name]?.value}
                onChange={setFieldValue}
                placeholderText="Enter text"
                formik={formik}
              />
                    </Box>
                  )
                }) : null
              }
            </Box>
          </Box>
            </form>
           
          </Box>
        </Box>
        <Box className={classes.buttonsWrapper}>
          <Link to={`/classificationdataset/${selectedNodeName}/${nodeName}/${key}`} style={{ textDecoration: "none" }}>
            <BackBtn>Back</BackBtn>
          </Link>
          <BackBtn form="curate-form" type="submit">Next</BackBtn>
        </Box>
      </Container>
    </div>
  );
}

export default CurateContextdataset;
