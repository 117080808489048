import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({
    container: {
        minHeight: "calc(100vh - 150px)",
        paddingTop: 32,
    },
    wrapper: {
        boxShadow: "0px 4px 20px rgba(1, 25, 54, 0.1)",
        borderRadius: 4
    },
    topSideWrapper: {
        padding: '20px 40px',
        borderBottom: '1px solid #E0E7EC',
        display: 'flex',
        justifyContent: 'space-between'
    },
    button: {
        width: 219,
        height: 48,
        '& .MuiButton-startIcon': {
            marginRight: 12,
            marginLeft: -6
        }
    },
    saveButton: {
        width: 219,
        height: 48,
        marginRight: 15
    }
})