import React, { createContext, useEffect, useState } from "react";
import { SearchService } from "../services/SearchService";
import { CollectionService } from "../services/CollectionService";

const DataContext = createContext();

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);
  const [isCartLoading, setIsCartLoading] = useState(true);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [checked, setChecked] = useState({});
  const [favCards, setFavCards] = useState([]);
  const [checkedNodes, setCheckedNodes] = useState({});
  const [savedCards, setSavedCards] = useState([]);
  const [checkedSavedCards, setCheckedSavedCards] = useState({});
  const [favCollections, setFavCollections] = useState([]);
  const [checkedCollections, setCheckedCollections] = useState({});
  const [loggedInUser, setLoggedInUser] = useState("");
  const [dataSetPayload, setDataSetPayload] = useState([]);
  const [recent, setrecent] = useState({
    trending: [],
    recent: [],
  });
  const [relatedSearches, setRelatedSearches] = useState([]);
  const [userImageDate, setUserImageDate] = useState("");
  const [loading, setLoading] = useState(true);
  let signedInUser = localStorage.getItem("logged-in-user");

  const addToCart = (node) => {
    SearchService.addNodeToCart(loggedInUser.email, node.id)
      .then(() => {
        return SearchService.fetchNodesFromCart(loggedInUser.email);
      })
      // .catch(() => {
      //   alert("Node is not added to cart");
      // });
  };

  const addAllNodestoCart = (arr) => {
    let notExistingCards = arr.filter((card) => {
      return cart.every((item) => {
        return item.Id !== card.id;
      });
    });
    setCart([...cart, ...notExistingCards]);
  };

  const removeFromCart = (node) => {
    let cartCopy = [...cart];

    if (node) {
      let removedData = { nodekeys: [node.key] };
      SearchService.removeNodeFromCart(loggedInUser.email, removedData).then(
        (res) => {
          if (res.status === 200) {
            cartCopy = cartCopy.filter((item) => item.id !== node.id);
            setCart(cartCopy);
          }
        }
      );
    } else {
      let selectedNodeKeys = selectedNodes.map((nodes) => nodes.id);
      let removedData = { nodekeys: selectedNodeKeys };
      SearchService.removeNodeFromCart(loggedInUser.email, removedData).then(
        () => {
          let filteredNodes = cart.filter(
            (node) =>
              !selectedNodes.find((selectedNode) => selectedNode.id === node.id)
          );
          let obj = filteredNodes.reduce((prev, key) => {
            return Object.assign(prev, { [key.id]: false });
          }, {});
          setCart(filteredNodes);
          setChecked(obj);
        }
      );
    }
  };

  const removeAllCart = () => {
    SearchService.removeAllNodesFromCart(loggedInUser.email).then((res) => {
      if (res.status === 200) {
        setCart([]);
      }
    });
  };

  const removeSelectedCards = () => {
    let filteredNodes = cart.filter(
      (node) =>
        !selectedNodes.find((selectedNode) => selectedNode.id === node.id)
    );
    let obj = filteredNodes.reduce((prev, key) => {
      return Object.assign(prev, { [key.id]: false });
    }, {});
    setCart(filteredNodes);
    setChecked(obj);
  };

  //**************** Node favourites methods *************
  // const addFavCards = (node) => {
  //   let copyFavCarts = [...favCards.graphData];
  //   let copyCheckedNodes = checkedNodes;
  //   let existingFavCards = favCards.graphData.find(
  //     (item) => item.id === node.id
  //   );
  //   const payload = { keys: [node.id] };

  //   if (!existingFavCards) {
  //     SearchService.addNodeToFavCards(
  //       loggedInUser.email,
  //       loggedInUser.userId,
  //       payload
  //     ).then(() => {
  //       copyCheckedNodes[node.id] = true;
  //       setCheckedNodes(copyCheckedNodes);
  //       if (node.count) {
  //         node.count = Number(node.count) + 1;
  //       } else {
  //         node.count = 1;
  //       }
  //       setFavCards((prev) => {
  //         return [...prev.graphData, node];
  //       });
  //       return SearchService.fetchFavoriteNodes(loggedInUser.email)
  //         .then((response) => {
  //           setFavCards(response.data);
  //           setLoading(false);
  //         })
  //         .catch(() => setLoading(false));
  //     });
  //   } else {
  //     SearchService.removeNodeFromFav(
  //       loggedInUser.email,
  //       loggedInUser.userId,
  //       node.id
  //     ).then(() => {
  //       delete copyCheckedNodes[node.id];
  //       setCheckedNodes(copyCheckedNodes);
  //       copyFavCarts = copyFavCarts.filter((item) => item.id !== node.id);
  //       setFavCards(copyFavCarts);
  //       node.count = Number(node.count) - 1;
  //       return SearchService.fetchFavoriteNodes(loggedInUser.email).then(
  //         (response) => {
  //           setFavCards(response.data);
  //         }
  //       );
  //     });
  //   }
  // };



  
//////working code/////////////////////////////////////////
  // const addFavCards = (node) => {
  //   let copyCheckedNodes = { ...checkedNodes };
  //   let existingFavCardData = favCards.data.find((item) => item.id === node.id);
  //   let existingFavCardGraphData = favCards.graphData.find((item) => item.id === node.id);
  //   const payload = { keys: [node.id] };
  
  //   if (!existingFavCardData || !existingFavCardGraphData) {
  //     SearchService.addNodeToFavCards(loggedInUser.email, loggedInUser.userId, payload)
  //       .then(() => {
  //         copyCheckedNodes[node.id] = true;
  //         setCheckedNodes(copyCheckedNodes);
  //         if (node.count) {
  //           node.count = Number(node.count) + 1;
  //         } else {
  //           node.count = 1;
  //         }
  
  //         // Update the favCards state to include the new node
  //         setFavCards((prev) => {
  //           const updatedData = [...prev.data];
  //           const updatedGraphData = [...prev.graphData];
  
  //           if (!existingFavCardData) {
  //             updatedData.push(node);
  //           }
  //           if (!existingFavCardGraphData) {
  //             updatedGraphData.push(node);
  //           }
  
  //           return { ...prev, data: updatedData, graphData: updatedGraphData };
  //         });
  
  //         setLoading(false);
  //       })
  //       .catch(() => setLoading(false));
  //   } else {
  //     SearchService.removeNodeFromFav(loggedInUser.email, loggedInUser.userId, node.id)
  //       .then(() => {
  //         delete copyCheckedNodes[node.id];
  //         setCheckedNodes(copyCheckedNodes);
  
  //         // Update the favCards state to remove the deleted node
  //         setFavCards((prev) => {
  //           const updatedData = prev.data.filter((item) => item.id !== node.id);
  //           const updatedGraphData = prev.graphData.filter((item) => item.id !== node.id);
  
  //           return { ...prev, data: updatedData, graphData: updatedGraphData };
  //         });
  
  //         node.count = Number(node.count) - 1;
  //         setLoading(false);
  //       })
  //       .catch(() => setLoading(false));
  //   }
  // };
  
  
  // const addFavCards = (node) => {
  //   let copyCheckedNodes = checkedNodes;
  //   let existingFavCards = favCards.graphData.find((item) => item.id === node.id);
  //   const payload = { keys: [node.id] };
  //   const datapayload ={
  //     key:loggedInUser.email,
  //      pagination:{"pageNumber":1,"pageSize":9}
  //   }

  
  //   if (!existingFavCards) {
  //     SearchService.addNodeToFavCards(
  //       loggedInUser.email,
  //       loggedInUser.userId,
  //       payload
  //     ).then(() => {
  //       copyCheckedNodes[node.id] = true;
  //       setCheckedNodes(copyCheckedNodes);
  //       if (node.count) {
  //         node.count = Number(node.count) + 1;
  //       } else {
  //         node.count = 1;
  //       }
  
  //       // Update the favCards state to include the new node and existing favorites
  //       setFavCards((prev) => ({
  //         ...prev,
  //         graphData: [...prev.graphData, node],
  //       }));
  
  //       // Set the icon to filled for the added node
  //       node.isFavorite = true;
      
  //       // Fetch both graphData and data and merge them
  //       SearchService.fetchFavoriteNodes(datapayload)
  //         .then((response) => {
  //           setFavCards(response.data);
  //           setLoading(false);
  //         })
  //         .catch(() => setLoading(false));
  //     });
  //   } else {
  //     SearchService.removeNodeFromFav(
  //       loggedInUser.email,
  //       loggedInUser.userId,
  //       node.id
  //     ).then(() => {
  //       delete copyCheckedNodes[node.id];
  //       setCheckedNodes(copyCheckedNodes);
  
  //       // Update the favCards state to remove the deleted node
  //       setFavCards((prev) => ({
  //         ...prev,
  //         graphData: prev.graphData.filter((item) => item.id !== node.id),
  //       }));
  
  //       // Set the icon to outlined for the removed node
  //       node.isFavorite = false;
  
  //       node.count = Number(node.count) - 1;
  
  //       // Fetch both graphData and data and merge them
  //       SearchService.fetchFavoriteNodes(loggedInUser.email).then(
  //         (response) => {
  //           setFavCards(response.graphData);
  //         }
  //       );
  //     });
  //   }
  // };
  
  

  const addFavCards = (node) => {
    let copyCheckedNodes = checkedNodes;
  
    // Ensure that favCards is initialized with an empty graphData array
    const existingGraphData = favCards?.graphData || [];
  
    let existingFavCards = existingGraphData.find((item) => item.id === node.id);
    const payload = { keys: [node.id] };
  
    if (!existingFavCards) {
      SearchService.addNodeToFavCards(
        loggedInUser.email,
        loggedInUser.userId,
        payload
      ).then(() => {
        copyCheckedNodes[node.id] = true;
        setCheckedNodes(copyCheckedNodes);
  
        if (node.count) {
          node.count = Number(node.count) + 1;
        } else {
          node.count = 1;
        }
  
        // Update the favCards state to include the new node and existing favorites
        setFavCards((prev) => ({
          ...prev,
          graphData: [...existingGraphData, node],
        }));
  
        // Set the icon to filled for the added node
        node.isFavorite = true;
  
        // Fetch both graphData and data and merge them
        SearchService.fetchFavoriteNodes({
          key: loggedInUser.email,
          // pagination: { pageNumber: 1, pageSize: 9 },
        })
          .then((response) => {
            setFavCards(response.data);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      });
    } else {
      SearchService.removeNodeFromFav(
        loggedInUser.email,
        loggedInUser.userId,
        node.id
      ).then(() => {
        delete copyCheckedNodes[node.id];
        setCheckedNodes(copyCheckedNodes);
  
        // Update the favCards state to remove the deleted node
        setFavCards((prev) => ({
          ...prev,
          graphData: existingGraphData.filter((item) => item.id !== node.id),
        }));
  
        // Set the icon to outlined for the removed node
        node.isFavorite = false;
  
        node.count = Number(node.count) - 1;
  
        // Fetch both graphData and data and merge them
        SearchService.fetchFavoriteNodes({
          key: loggedInUser.email,
          // pagination: { pageNumber: 1, pageSize: 9 },
        }).then((response) => {
          setFavCards(response.data);
        });
      });
    }
  };
  
  // const addFavCards = (node) => {
  //   let copyCheckedNodes = checkedNodes;
  //   let existingFavCards = favCards.graphData.find((item) => item.id === node.id);
  //   const payload = { keys: [node.id] };

  //   if (!existingFavCards) {
  //     SearchService.addNodeToFavCards(
  //       loggedInUser.email,
  //       loggedInUser.userId,
  //       payload
  //     ).then(() => {
  //       copyCheckedNodes[node.id] = true;
  //       setCheckedNodes(copyCheckedNodes);
  //       if (node.count) {
  //         node.count = Number(node.count) + 1;
  //       } else {
  //         node.count = 1;
  //       }

  //       // Update the favCards state to include the new node and existing favorites
  //       setFavCards((prev) => ({
  //         ...prev,
  //         graphData: [...prev.graphData, node],
  //       }));

  //       // Fetch both graphData and data and merge them
  //       SearchService.fetchFavoriteNodes(loggedInUser.email)
  //         .then((response) => {
  //           setFavCards(response.data);
  //           setLoading(false);
  //         })
  //         .catch(() => setLoading(false));
  //     });
  //   } else {
  //     SearchService.removeNodeFromFav(
  //       loggedInUser.email,
  //       loggedInUser.userId,
  //       node.id
  //     ).then(() => {
  //       delete copyCheckedNodes[node.id];
  //       setCheckedNodes(copyCheckedNodes);

  //       // Update the favCards state to remove the deleted node
  //       setFavCards((prev) => ({
  //         ...prev,
  //         graphData: prev.graphData.filter((item) => item.id !== node.id),
  //       }));

  //       node.count = Number(node.count) - 1;

  //       // Fetch both graphData and data and merge them
  //       SearchService.fetchFavoriteNodes(loggedInUser.email).then(
  //         (response) => {
  //           setFavCards(response.data);
  //         }
  //       );
  //     });
  //   }
  // };
  
  //****************Collection favourit methods *************
  const addToFavCollection = (collection) => {
    let copyFavCollections = [...favCollections];
    let copyCheckedColl = checkedCollections;
    let existingFavColl = favCollections.find(
      (coll) => coll.key === collection.key
    );
    const data = { keys: [collection.key] };
    if (!existingFavColl) {
      copyFavCollections.push(collection);
      copyCheckedColl[collection.key] = true;
      setFavCollections(copyFavCollections);
      setCheckedCollections(copyCheckedColl);
      CollectionService.addCollectionToFovirite(
        loggedInUser.email,
        loggedInUser.userId,
        data
      )
        .then(() => {
          CollectionService.fetchFavoriteCollection(loggedInUser.email);
        })
        .catch(() => {
          copyFavCollections = copyFavCollections.filter(
            (coll) => coll.key !== collection.key
          );
          delete copyCheckedColl[collection.key];
        });
    } else {
      CollectionService.removeCollectionFromFavorite(
        loggedInUser.email,
        loggedInUser.userId,
        collection.key
      )
        .then(() => {
          copyFavCollections = copyFavCollections.filter(
            (coll) => coll.key !== collection.key
          );
          delete copyCheckedColl[collection.key];
          setFavCollections(copyFavCollections);
          setCheckedCollections(copyCheckedColl);
          CollectionService.fetchFavoriteCollection(loggedInUser.email);
        })
        .catch(() => {
          alert("Collection is not removed from favorite");
        });
    }
  };

  // ************** Saved for later logic ********************
  const addSavedCards = (node) => {
    let copyCheckedSavedNodes = checkedSavedCards;
    let existingSavedCards = savedCards.find((item) => item.id === node.id);
    const data = { nodekeys: [node.key] };
    if (!existingSavedCards) {
      copyCheckedSavedNodes[node.id] = true;
      setSavedCards([...savedCards, node]);
      setCheckedSavedCards(copyCheckedSavedNodes);
      SearchService.saveForLater(data, loggedInUser.email).catch(() => {
        const filteredNodes = savedCards.filter(
          (savedCard) => savedCard.id !== node.id
        );
        delete copyCheckedSavedNodes[node.id];
        setSavedCards(filteredNodes);
        setCheckedSavedCards(copyCheckedSavedNodes);
      });
    } else {
      let filteredNodes = savedCards.filter((item) => item.id !== node.id);
      delete copyCheckedSavedNodes[node.id];
      setSavedCards(filteredNodes);
      setCheckedSavedCards(copyCheckedSavedNodes);
      SearchService.removeNodesFromSavedForLater(
        loggedInUser.email,
        data
      ).catch(() => {
        copyCheckedSavedNodes[node.id] = true;
        setSavedCards([...savedCards, node]);
        setCheckedSavedCards(copyCheckedSavedNodes);
      });
    }
  };

  const addAllToSavedCards = (nodes, allSelected) => {
    const nodeIds = nodes.map((node) => node.id);
    const data = { nodekeys: nodeIds };

    if (allSelected) {
      SearchService.saveForLater(data, loggedInUser.email).then(() => {
        let emptyObj = {};
        nodes.forEach((item) => {
          emptyObj[item.id] = true;
        });
        setSavedCards([...nodes]);
        setCheckedSavedCards(emptyObj);
      });
    } else {
      setSavedCards([]);
      setCheckedSavedCards({});
      SearchService.removeNodesFromSavedForLater(
        loggedInUser.email,
        data
      ).catch(() => {
        setSavedCards([...nodes]);
        let emptyObj = {};
        nodes.forEach((item) => {
          emptyObj[item.id] = true;
          setCheckedSavedCards(emptyObj);
        });
      });
    }
  };

  const removeFromSavedCards = (node) => {
    let copyCheckedSavedCards = checkedSavedCards;
    let data = { nodekeys: [node.key] };
    const filteredNodes = savedCards.filter((item) => item.id !== node.id);
    delete copyCheckedSavedCards[node.id];
    setCheckedSavedCards(copyCheckedSavedCards);
    setSavedCards(filteredNodes);

    SearchService.removeNodesFromSavedForLater(loggedInUser.email, data).catch(
      () => {
        setSavedCards([...savedCards, node]);
        copyCheckedSavedCards[node.id] = true;
      }
    );
  };

  const removeAllFromSavedCards = () => {
    const nodeIds = savedCards.map((savedCard) => savedCard.key);
    const data = { nodekeys: nodeIds };
    SearchService.removeNodesFromSavedForLater(loggedInUser.email, data)
      .then(() => {
        setSavedCards([]);
        setCheckedSavedCards({});
      })
      .catch(() => {
        alert("Saved nodes are not deleted");
      });
  };

  
  const addSavedNodeToCart = (nodes) => {
    const isArr = Array.isArray(nodes);
  
    if (isArr) {
      const filteredCart = nodes.filter((node) => {
        return !cart.find((item) => item.id === node.id);
      });
  
      if (filteredCart.length > 0) {
        setCart([...cart, ...filteredCart]);
        const nodeIds = filteredCart.map((node) => node.id);
        const data = { keys: nodeIds };
  
        SearchService.moveSaveForLaternodeAToCart(loggedInUser.email, data)
          .catch(() => {
            alert("Nodes are not sent to cart");
          });
      } else {
        alert("This node is already exist inside cart");
      }
    } else {
      const existingNode = cart.find((item) => item.id === nodes.id);
  
      if (!existingNode) {
        setCart([...cart, nodes]);
        SearchService.moveSaveForLaternodeAToCart(loggedInUser.email, {
          keys: [nodes.key],
        })
          .catch(() => {
            const filteredNodes = cart.filter((item) => item.id !== nodes.id);
            setCart(filteredNodes);
            alert("Nodes are not sent to cart");
          });
      } else {
        alert("This node is already exist inside cart");
      }
    }
  };
  

  // get logged in user
  const getloginInUser = (user) => {
    setLoggedInUser(user);
    localStorage.setItem("logged-in-user", JSON.stringify(user));
  };


  useEffect(() => {
    let user = JSON.parse(signedInUser);
    if (user) {
      // fetch nodes in cart
      SearchService.fetchNodesFromCart(user.email)
        .then((res) => {
          setCart(res.data);
          setIsCartLoading(false);
        })
        .catch(() => {
          setIsCartLoading(false);
        });
  
      // fetch favorite nodes with pagination
      const pagination = {
        pageNumber: 1,
        pageSize: 9,
      };
  
     const payload = {
  key: user.email,

};

      SearchService.fetchFavoriteNodes(payload)
        .then((response) => {
          console.log(response,"res")
          setFavCards(response.data);
          setLoading(false);
          if (response.data.length) {
            let emptyObj = response.data.reduce((prev, current) => {
              prev[current.id] = true;
              return prev;
            }, {});
            setCheckedNodes(emptyObj);
          }
        })
        .catch(() => setLoading(false));
    }
  
    if (user) {
      setLoggedInUser(user);
    }
  }, [signedInUser]);
  

  // useEffect(() => {
  //   let user = JSON.parse(signedInUser);
  //   if (user) {
  //     // fetch nodes in cart
  //     SearchService.fetchNodesFromCart(user.email)
  //       .then((res) => {
  //         setCart(res.data);
  //         setIsCartLoading(false);
  //       })
  //       .catch(() => {
  //         setIsCartLoading(false);
  //       });
  //     // fetch favorite nodes
  //     SearchService.fetchFavoriteNodes(user.email)
  //       .then((response) => {
  //         setFavCards(response.data);
  //         setLoading(false);
  //         if (response.data.length) {
  //           let emptyObj = response.data.reduce((prev, current) => {
  //             prev[current.id] = true;
  //             return prev;
  //           }, {});
  //           setCheckedNodes(emptyObj);
  //         }
  //       })
  //       .catch(() => setLoading(false));
  //   }
  //   if (user) {
  //     setLoggedInUser(user);
  //   }
  // }, [signedInUser]);

  return (
    <DataContext.Provider
      value={{
        cart,
        setCart,
        setSavedCards,
        savedCards,
        setCheckedSavedCards,
        checkedSavedCards,
        setSelectedNodes,
        selectedNodes,
        checked,
        setChecked,
        setLoggedInUser,
        loggedInUser,
        setrecent,
        recent,
        setRelatedSearches,
        relatedSearches,
        setCheckedCollections,
        checkedCollections,
        setFavCollections,
        favCollections,
        setUserImageDate,
        userImageDate,
        dataSetPayload,
        setDataSetPayload,
        loading,
        isCartLoading,
        favCards,
        checkedNodes,
        getloginInUser,
        addToFavCollection,
        addToCart,
        addAllNodestoCart,
        removeFromCart,
        removeAllCart,
        addSavedNodeToCart,
        removeSelectedCards,
        addFavCards,
        addSavedCards,
        removeFromSavedCards,
        addAllToSavedCards,
        removeAllFromSavedCards,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;









