import React from 'react';
import './pagination.scss';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const Pagination = (props) => {

    const {
        paginate,
        currentPage,
        prevPage,
        nextPage,
        numberOfPages,
        maxPageNumberLimit,
        minPageNumberLimit,
    } = props

    return (
        <div className='pagination-wrapper d-flex justify-content-center aligns-item-center w-100'>
            <nav className='align-self-center'>
                <ul className="pagination">
                    <li className="page-item d-flex align-items-center">
                        <button disabled={currentPage === 1} onClick={prevPage} className="btn-prev d-flex align-items-center border-0 bg-white color-secondary-100 fw-bold">
                            <ArrowBackIosIcon />
                        </button>
                    </li>
                    {
                        numberOfPages.map(num => {
                            if (num < maxPageNumberLimit + 1 && num > minPageNumberLimit) {
                                return (
                                    <li onClick={() => paginate(num)} className="hidden-xs page-item d-flex me-2" id={num} key={num}>
                                        <button className={`${num === currentPage ? 'pagination-btn-active' : ''} pagination-btn align-self-center border-0`}>{num}</button>
                                    </li>
                                )
                            } else {
                                return null
                            }
                        })
                    }
                    <li className="page-item d-flex align-items-center">
                        <button disabled={currentPage === numberOfPages.length} onClick={nextPage} className="btn-next d-flex align-items-center  border-0 bg-white color-secondary-100 fw-bold">
                            <ArrowForwardIosIcon />
                        </button>
                    </li>
                </ul>
            </nav>
        </div>
    );
};


export default Pagination;


