import KebabMenu from "../shared/popover/KebabMenu";
import { Box, Avatar, Typography } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import DataContext from "../../contexts/Context";
import { useOktaAuth } from "@okta/okta-react";

const ImageFrame = () => {
  const navigate = useNavigate();
  const position = { right: 0, left: "unset", top: 44, bottom: "unset" };
  const { loggedInUser, userImageDate, setLoggedInUser } =
    useContext(DataContext);
  const [imgUrl, setImgUrl] = useState();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    setImgUrl(
      `${apiUrl}/getProfileImage?userId=${loggedInUser?.userId}&date=${userImageDate}`
    );
  }, [userImageDate]);

  const handleLogout = () => {
    const { authStateManager, signOut } = oktaAuth;
    if (authStateManager._authState.isAuthenticated) {
      signOut();
    }
    localStorage.removeItem("logged-in-user");
    navigate("/sign-in");
    setLoggedInUser("");
  };

  return (
    <KebabMenu
      position={position}
      button={<Avatar style={{ cursor: "pointer" }} alt="user" src={imgUrl} />}
    >
      <Box className="kebab-menu-content">
        <div onClick={() => navigate("/profile")} className="link">
          <div>
            <span style={{ marginRight: 10 }}>
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.601562 0.820312H8.60156V8.82031H0.601562V0.820312ZM0.601562 10.8203H8.60156V18.8203H0.601562V10.8203ZM10.6016 0.820312H18.6016V8.82031H10.6016V0.820312ZM10.6016 10.8203H18.6016V18.8203H10.6016V10.8203ZM12.6016 2.82031V6.82031H16.6016V2.82031H12.6016ZM12.6016 12.8203V16.8203H16.6016V12.8203H12.6016ZM2.60156 2.82031V6.82031H6.60156V2.82031H2.60156ZM2.60156 12.8203V16.8203H6.60156V12.8203H2.60156Z"
                  fill="#123B64"
                />
              </svg>
            </span>
            <Typography variant="body2" component="span">
              Profile
            </Typography>
          </div>
        </div>
        <div onClick={handleLogout} className="link">
          <span style={{ marginRight: 10 }}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 18H6V20H18V4H6V6H4V3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21V18ZM6 11H13V13H6V16L1 12L6 8V11Z"
                fill="#123B64"
              />
            </svg>
          </span>
          <Typography variant="body2" component="span">
            Log out
          </Typography>
        </div>
      </Box>
    </KebabMenu>
  );
};
export default ImageFrame;
