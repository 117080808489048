// import { Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from "@material-ui/core";
// import Avatar from "@material-ui/core/Avatar";
// import CloseIcon from "@material-ui/icons/Close";
// import axios from "axios";
// import { useFormik } from "formik";
// import React, { useContext, useEffect, useRef, useState } from "react";
// import { EmailIcon } from "../../icons";
// import CustomModal from "../../navbar/nav-dropdown-input/logopagedesign/logopages/popupformsoflogo/CustomModal";
// import Messagesent from "../../navbar/nav-dropdown-input/logopagedesign/logopages/popupformsoflogo/Teamspopup";
// import { TextButton } from "../buttons/custom-buttons/CustomButtons";
// import AutoComplate from "../inputs/AutoComplate";
// import CustomizedInput from "../inputs/CustomizedInput";
// import TextAreaInput from "../inputs/TextAreaInput";
// import { CopyIcon, SendIcon } from "../../icons";
// import DataContext from "../../../contexts/Context";
// import emailjs from "@emailjs/browser";
// import { Input } from "../../node-viwer/node-viewer-tab-panel-children/overview/adding-tags/CustomInput";

// const useStyles = makeStyles(() => ({
//   root: {
//     background: '#FFF',
//     borderRadius: 16,
//     width: 420,
//     height: 600
//   },
//   modalHeader: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     padding: 20
//   },
//   avatarBox: {
//     display: 'flex',
//     alignItems: 'center'
//   },
//   iconButton: {
//     padding: 0,
//     "& svg": {
//       width: 16,
//       height: 20
//     }
//   },
//   list: {
//     padding: 0,
//     "& li": {
//       padding: "0 0 0 8px",

//     }
//   },
//   listItemText: {
//     margin: 0
//   },
//   urlBox: {
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'end'
//   },
//   avatar: {
//     backgroundColor: "#55ACEE",
//   }
// }))

// const initialValues = {
//   users: []
// }
// const Mail = ({ setOpenMailModal, url }) => {
//   //const Mail = () => {
//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault(); // prevents the page from reloading when you hit “Send”

//     emailjs
//       .sendForm(
//         "service_3yl62gn",
//         "template_vuo9qtl",
//         form.current,
//         "CoJzlW4dKEY2GXq_a"
//       )
//       .then(
//         (result) => {
//           // show the user a success message
//         },
//         (error) => {
//           // show the user an error
//         }
//       );
//   };

//   const classes = useStyles();
//   const [options, setOptions] = useState([]);
//   const [open, setOpen] = useState(false);
//   const [isLoadingUsers, setIsLoadingUsers] = useState(true)
//   const apiUrl = process.env.REACT_APP_API_URL;
//   const { loggedInUser } = useContext(DataContext)

//   const onSubmit = (values) => {
//     console.log('values...')
//   }

//   useEffect(() => {
//     const fetchPosts = async () => {
//       const res = await axios.get(`${apiUrl}/getAllregisterUserDetails`);
//       const tranformedUsers = res?.data.map((user) => {
//         return {
//           id: user.id,
//           name: user.Email
//         }
//       })
//       setOptions(tranformedUsers);
//       setIsLoadingUsers(false)
//     };
//     fetchPosts();
//   }, [apiUrl]);

//   const formik = useFormik({
//     initialValues,
//     onSubmit
//   });

//   const { setFieldValue } = formik

//   const handleMultiSelectChange = (event, value) => {
//     setFieldValue("users", value);
//   };

//   const copyUrl = () => {
//     navigator.clipboard.writeText(url)
//   }

//   return (
//     <div>
//       <Box className={classes.root}>
//         <CustomModal setOpen={setOpen} open={open}>
//           <Messagesent handleClose={() => {
//             // setOpen(false)
//             // setOpenModal(false)
//             // setOpenMailModal(null)
//           }}
//           />
//         </CustomModal>
//         <form ref={form} onSubmit={sendEmail}>
//           <Box className={classes.modalHeader}>
//             <Box className={classes.avatarBox}>
//               <IconButton onClick={() => setOpenMailModal(false)} className={classes.iconButton}>
//                 <CloseIcon />
//               </IconButton>
//               <List className={classes.list}>
//                 <ListItem>
//                   <ListItemAvatar>
//                     <Avatar className={classes.avatar}>
//                       <EmailIcon />
//                     </Avatar>
//                   </ListItemAvatar>
//                   <ListItemText
//                     className={classes.listItemText}
//                     primary="EMAIL"
//                     secondary="Datanomist"
//                   />
//                 </ListItem>
//               </List>
//             </Box>
//             <TextButton type="submit" startIcon={<SendIcon />} value="Send">
//               Send
//             </TextButton>
//           </Box>
//           <Box p={2.5}>
//             {
//               isLoadingUsers ?
//                 <Typography variant="h3" color="primary">Loading...</Typography> :
//                 <>
//                   <Box mb={4}>
//                     <Typography color="primary" variant="body1">
//                       Share url
//                     </Typography>
//                     <Box className={classes.urlBox}>
//                       <Box id="url"
//                         name="url">{url}</Box>

//                       <IconButton onClick={copyUrl}>
//                         <CopyIcon />
//                       </IconButton>
//                     </Box>
//                   </Box>

//                   <Typography color="primary" variant="h6" >From:</Typography>
//                   <Input
//                     id="users"
//                     //name="users"
//                     options={options}
//                     handleChange={handleMultiSelectChange}
//                     placeholder="Select users Mail"
//                     defaultValue={[loggedInUser.email]}
//                     label="From :"
//                     formik={formik}
//                     name="from_email"
//                   />
//                   <Box mb={2.5}>
//                     <Typography color="primary" variant="h6" >To:</Typography>
//                     <AutoComplate
//                       id="users"
//                       //name="users"
//                       options={options}
//                       handleChange={handleMultiSelectChange}
//                       placeholder="Select users Mail"
//                       defaultValue={[]}
//                       label="To :"
//                       formik={formik}
//                       name="user_email"
//                     />
//                   </Box>
//                   <Box mb={2.5} >
//                     <CustomizedInput
//                       id="subject"
//                       name="subject"
//                       value={formik.values.nameYourTeam}
//                       onChange={formik.handleChange}
//                       label="Subject"
//                       placeholderText="Enter Subject"
//                       formik={formik}
//                     />
//                   </Box >
//                   <Box>
//                     <TextAreaInput
//                       id="message"
//                       name="message"
//                       //value={message}
//                       //onChange={handleChange}
//                       placeholderText="Type a message here"
//                       formik={formik}
//                       label="Message"
//                     />
//                   </Box>
//                 </>
//             }
//           </Box>
//         </form>
//       </Box>
//     </div>
//   );

// }
// export default Mail;

import { Box, IconButton, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import CloseIcon from "@material-ui/icons/Close";
import axios from "axios";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import { EmailIcon } from "../../icons";
import CustomModal from "../../navbar/nav-dropdown-input/logopagedesign/logopages/popupformsoflogo/CustomModal";
import Messagesent from "../../navbar/nav-dropdown-input/logopagedesign/logopages/popupformsoflogo/Teamspopup";
import { TextButton } from "../buttons/custom-buttons/CustomButtons";
import AutoComplate from "../inputs/AutoComplate";
import CustomizedInput from "../inputs/CustomizedInput";
import TextAreaInput from "../inputs/TextAreaInput";
import { CopyIcon, SendIcon } from "../../icons";
import DataContext from "../../../contexts/Context";
import emailjs from "@emailjs/browser";
import { Input } from "../../node-viwer/node-viewer-tab-panel-children/overview/adding-tags/CustomInput";

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFF',
    borderRadius: 16,
    width: 420,
    height: 650
  },
  modalHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 20
  },
  avatarBox: {
    display: 'flex',
    alignItems: 'center'
  },
  iconButton: {
    padding: 0,
    "& svg": {
      width: 16,
      height: 20
    }
  },
  list: {
    padding: 0,
    "& li": {
      padding: "0 0 0 8px",

    }
  },
  listItemText: {
    margin: 0
  },
  urlBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end'
  },
  avatar: {
    backgroundColor: "#55ACEE",
  }
}))

const initialValues = {
  users: []
}
const Mail = ({ setOpenMailModal, url, setOpenModal }) => {
  //const Mail = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”

    emailjs
      // .sendForm(
      //   "service_3yl62gn",
      //   "template_vuo9qtl",
      //   form.current,
      //   "CoJzlW4dKEY2GXq_a"
      // )
      .sendForm(
        // "service_3yl62gn",
        // "template_vuo9qtl",
        "service_nqqgzmm",
        "template_70g9ubu",
         form.current,
        // "CoJzlW4dKEY2GXq_a"
        "-ZqRrsEvNKhNTw7H7"
      )
      .then(
        (result) => {
          // show the user a success message
        },
        (error) => {
          // show the user an error
        }
      );
  };

  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true)
  const apiUrl = process.env.REACT_APP_API_URL;
  const { loggedInUser } = useContext(DataContext)

  const onSubmit = (values) => {
    console.log('values...')
  }

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(`${apiUrl}/getAllregisterUserDetails`);
      const tranformedUsers = res?.data.map((user) => {
        return {
          id: user.id,
          name: user.Email
        }
      })
      setOptions(tranformedUsers);
      setIsLoadingUsers(false)
    };
    fetchPosts();
  }, [apiUrl]);

  const formik = useFormik({
    initialValues,
    onSubmit
  });

  const { setFieldValue } = formik

  const handleMultiSelectChange = (event, value) => {
    setFieldValue("users", value);
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(url)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Box className={classes.root}>
        <CustomModal setOpen={setOpen} open={open}>
          <Messagesent handleClose={() => {
            setOpen(false)
            // setOpenModal(false)
            setOpenMailModal(false)
          }}
          />
        </CustomModal>
        <form ref={form} onSubmit={sendEmail}>
          <Box className={classes.modalHeader}>
            <Box className={classes.avatarBox}>
              <IconButton onClick={() => setOpenMailModal(false)} className={classes.iconButton}>
                <CloseIcon />
              </IconButton>
              <List className={classes.list}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      <EmailIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.listItemText}
                    primary="EMAIL"
                    secondary="Datanomist"
                  />
                </ListItem>
              </List>
            </Box>
            <TextButton type="submit" startIcon={<SendIcon />} value="Send" onClick={handleClickOpen}>
              Send
            </TextButton>
          </Box>
          <Box p={2.5}>
            {
              isLoadingUsers ?
                <Typography variant="h3" color="primary">Loading...</Typography> :
                <>
                  <Box mb={4}>
                    <Typography color="primary" variant="body1">
                      Share url
                    </Typography>
                    <Box className={classes.urlBox}>
                      <Box id="url"
                        name="url">{url}</Box>

                      <IconButton onClick={copyUrl}>
                        <CopyIcon />
                      </IconButton>
                    </Box>
                  </Box>

                  <Typography color="primary" variant="h6" >From:</Typography>
                  <Input
                    id="users"
                    //name="users"
                    options={options}
                    handleChange={handleMultiSelectChange}
                    placeholder="Select users Mail"
                    defaultValue={[loggedInUser.email]}
                    label="From :"
                    formik={formik}
                    name="from_email"
                  />
                  <Box mb={2.5}>
                    <Typography color="primary" variant="h6" >To:</Typography>
                    <AutoComplate
                      id="users"
                      //name="users"
                      options={options}
                      handleChange={handleMultiSelectChange}
                      placeholder="Select users Mail"
                      defaultValue={[]}
                      label="To :"
                      formik={formik}
                      name="user_email"
                    />
                  </Box>
                  <Box mb={2.5} >
                    <CustomizedInput
                      id="subject"
                      name="subject"
                      value={formik.values.nameYourTeam}
                      onChange={formik.handleChange}
                      label="Subject"
                      placeholderText="Enter Subject"
                      formik={formik}
                    />
                  </Box >
                  <Box>
                    <TextAreaInput
                      id="message"
                      name="message"
                      //value={message}
                      //onChange={handleChange}
                      placeholderText="Type a message here"
                      formik={formik}
                      label="Message"
                    />
                  </Box>
                </>
            }
          </Box>
        </form>
      </Box>
    </div>
  );

}
export default Mail;



