import { Box, makeStyles, Typography } from "@material-ui/core";
import * as moment from "moment";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../contexts/Context";
import Share from "../../share/Share";
import { OverlayButton } from "../buttons/custom-buttons/CustomButtons";
import TooltipContent from "../full-node-card/TooltipContent";
import { FavouriteCheckbox } from "../inputs/CheckBox";
import KebabMenu from "../popover/KebabMenu";
import SharePopover from "../popover/SharePopover";
import pen from "./../../../assets/images/collection-card/pen.png";
import share from "./../../../assets/images/collection-card/share.png";
import curate from "./../../../assets/images/collection-card/curateicon.PNG";
import rectangle from '../../../assets/images/collection-card/Rectangle.png'
import rectangleTwo from '../../../assets/images/collection-card/Rectangle2.png'
import rectangleThree from '../../../assets/images/collection-card/Rectangle3.png'
import { GovernmentIcon, PrivateIcon, PublicIcon } from "../../icons";

const useStyles = makeStyles({
  root: {
    width: 292,
    color: "#123b64",
    borderRadius: 16,
    boxShadow: " 0px 2px 20px rgba(23, 87, 123, 0.2)",
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#464d6133",
    borderRadius: "16px 16px 0 0",
    display: "none",
    padding: 16,
  },
  overlayButtonWrapper: {
    display: "flex",
    justifyContent: "end",
    "& button": {
      margin: 5,
      opacity: 0.8,
    },
  },
  rectanglesWrapper: {
    display: "flex",
    maxHeight: 222,
    borderRadius: "16px 16px 0 0",
    borderBottom: "2px solid #DBDEE7",
    position: "relative",
    "&:hover": {
      "& #overlay": {
        display: "block",
      },
    },
  },
  title: {
    color: "#123B64",
    fontSize: 18,
    lineHeight: "27px",
    fontWeight: 700,
    cursor: 'pointer'
  },
  pinWrapper: {
    display: "flex",
    alignItems: "center",
  },
  pin: {
    fontSize: 14,
    fontWeight: 700,
  },
  dot: {
    width: 4,
    height: 4,
    background: "#123b64e6",
    margin: "0 7px",
    borderRadius: "50%",
  },
  imgWrapper: {
    width: '100%',
    minHeight: 222,
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '2px solid #DBDEE7',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '16px 16px 0 0',
    '& img': {
      height: '100%',
    }
  },
  imgBox: {
    display: 'flex'
  },
  rectangle: {
    borderRight: '2px solid #DBDEE7',
  },
  rectangleTwo: {
    '& img': {
      width: 118
    }
  },
  rectangleThree: {
    borderBottom: '2px solid #DBDEE7',
    '& img': {
      width: 118
    }
  }
});

function CollectionCard({ data, setSelectedCols, selectedCols }) {
  const classes = useStyles();
  const [openShareContent, setOpenShareContent] = useState(false);
  const date = new Date(data?.lastmodifiedon);
  const { addToFavCollection, checkedCollections } = useContext(DataContext);
  const numberOfPins = data?.pinNodes ? data?.pinNodes?.length : data?.numberofpins;
  const navigate = useNavigate()
  const [imgStatus, setImgStatus] = useState(true)
  const apiUrl = process.env.REACT_APP_API_URL;
  const position = { top: 50, right: -14, left: 'unset', bottom: 'unset' }

  const handleChange = (collection) => {
    addToFavCollection(collection);
  };

  const isSelected = selectedCols && selectedCols.some((id) => id === data._key || id === data.key);

  const handelSelectCollection = (event, collectionId) => {
    if (event.target.id !== "image" && event.target.tagName !== "INPUT") {
      if (selectedCols) {
        const isCollSelected = selectedCols.some(id => id === collectionId);
        let selectedCollectionIds = [...selectedCols];
        if (isCollSelected) {
          selectedCollectionIds = selectedCollectionIds.filter(id => id !== collectionId)
        } else {
          selectedCollectionIds.push(collectionId);
        }
        setSelectedCols(selectedCollectionIds);
      }
    }
  };

  const openCollection = () => {
    navigate(`/my-collections/${data?.displayName}/${data?.key}`)
  }

  const handleErrorImge = () => {
    setImgStatus(false)
  }

  const goSemantics = (event) => {
    event.stopPropagation()
    navigate(`/my-collections/${data?.displayName}/semantics/${data?.key}`)
  }

  const goEditSection = (event) => {
    event.stopPropagation()
    navigate(`/my-collections/edit-section/${data?.key}`)
  }

  return (
    <div
      style={isSelected ? { border: "1px solid #f79f19" } : { border: "none" }}
      onClick={(e) => handelSelectCollection(e, data?._key ? data?._key : data?.key)}
      className={classes.root}
      id="collection"
    >
      <Box className={classes.rectanglesWrapper} 
       onClick={openCollection}>
        {
          imgStatus ?
            <Box className={classes.imgWrapper}>
              <img onError={handleErrorImge} src={`${apiUrl}/getPinImage?file=${data?.cover}`} alt="rectangle" />
            </Box> :
            <Box className={classes.imgBox}>
              <Box className={classes.rectangle}>
                <img src={rectangle} alt="collection" />
              </Box>
              <Box>
                <Box className={classes.rectangleThree}>
                  <img src={rectangleThree} alt="collection" />
                </Box>
                <Box className={classes.rectangleTwo}>
                  <img src={rectangleTwo} alt="collection" />
                </Box>
              </Box>
            </Box>
        }
        <Box id="overlay" className={classes.overlay}>
          <Box className={classes.overlayButtonWrapper}>
            <>
              <OverlayButton onClick={(e) => goSemantics(e)} disableRipple>
                <img id="image" src={curate} alt="curate" />
              
              </OverlayButton>
              <OverlayButton disableRipple>
                <KebabMenu
                  position={position}
                  checked={checkedCollections[data?.key]}
                  button={
                    <FavouriteCheckbox
                      checked={checkedCollections[data?.key] === true}
                      onChange={() => handleChange(data)}
                    />
                    
                  }

                >
                  <TooltipContent />{" "}<span>Fav</span>
                </KebabMenu>
              </OverlayButton>
              <SharePopover
                setAnchorEl={setOpenShareContent}
                anchorEl={openShareContent}
                popoverButton={
                  <OverlayButton disableRipple>
                    <img id="image" src={share} alt="share" />
                  
                  </OverlayButton>
                }
              >
                <Share setOpenShare={setOpenShareContent} type="collection" data={data} />
              </SharePopover>
              <OverlayButton onClick={(e) => goEditSection(e)} disableRipple>
                <img id="image" src={pen} alt="pen" />
               
              </OverlayButton>
            </>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 15 }}>
        <Box>
          <Typography className={classes.title} onClick={openCollection}>
            {data?.displayName}
          </Typography>
          <Box className={classes.pinWrapper}>
            <Typography className={classes.pin} component="span">
              {numberOfPins} pin
            </Typography>
            <Box className={classes.dot} />
            <Typography style={{ fontSize: 14 }} component="span">
              {moment(date).format("D MMM YY")}
            </Typography>
          </Box>
        </Box>
        {data?.classification === "governed" || data?.classification === "Governed" ?
          <GovernmentIcon />
          : data?.classification === "public" ?
            <PublicIcon /> : <PrivateIcon />
        }
      </Box>
    </div>
  );
}

export default CollectionCard;
