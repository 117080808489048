import { useState } from "react";
import { Container, Typography, makeStyles } from "@material-ui/core";
import ListViewButtonTwo from "../../shared/buttons/ListViewButtonTwo";
import ShortNodeCardWrapper from "../short-card-slider-wrapper/ShortNodeCardWrapper";
import FullNodeCardSliderWrapper from "../full-card-slider-wrapper/FullNodeCardSliderWrapper";
import "./home.scss";

const useStyles = makeStyles((theme) => ({
  home: {
    minHeight: "calc(100vh - 150px)",
    paddingBottom: 100,
    paddingTop: 40,
    [theme.breakpoints.between("xs", "sm")]: {
      paddingTop: 15,
      paddingBottom: 50,
    },
  },
  nodeListWrapper: {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    marginTop: 25,
    marginBottom: 40,
    paddingLeft: 16,
    paddingRight: 16,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      fontWeight: 900,
      lineHeight: "120%",
      marginBottom: 15,
    },
  },
  trendingSearchBox: {
    marginBottom: 50,
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
  },
}));

function RecommendedNodes({ recent }) {
  const classes = useStyles;
  const [isListView, setIsListView] = useState(false);

  return (
    <Container maxWidth="xl" className={classes.home}>
      <div className="list-view-btn-wrapper">
        <Typography className={classes.title} variant="h1" color="primary">
          Recommended for you
        </Typography>
        <div className="list-view-btn">
          <ListViewButtonTwo
            setShowSaved={setIsListView}
            showSaved={isListView}
          />
        </div>
      </div>
      <div className="node-slider">
        {isListView ? (
          <ShortNodeCardWrapper cardItems={recent} />
        ) : (
          <FullNodeCardSliderWrapper cardItems={recent} />
        )}
      </div>
      {/* --------------mobile version----------------- */}
      {/* <Box className={classes.nodeListWrapper}>
          <>
            <Box mb={6.25}>
              <NodeList
                nodesPerPage={3}
                showSaved={isListView}
                nodes={recent.trending}
              />

            </Box>
            <Box mb={6.25}>
              <Typography className={classes.title} color="primary">
                Recently viewed
              </Typography>
              <NodeList
                nodesPerPage={3}
                showSaved={isListView}
                nodes={recent.recent}
              />
            </Box>
            <Box className={classes.trendingSearchBox}>
              <Typography className={classes.title} color="primary">
                Trending searches
              </Typography>
              <NodeList
                nodesPerPage={3}
                showSaved={isListView}
                nodes={recent.trending}
              />
            </Box>
          </>
        </Box> */}
    </Container>
  );
}

export default RecommendedNodes;
