import { useLocation } from 'react-router-dom'
import { IoMdArrowDropleftCircle, IoMdArrowDroprightCircle } from "react-icons/io";

function NextArrow(props) {
    const {pathname} = useLocation()

    const { style, onClick, className } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          color: "#123B64",
          borderRadius: "50%",
          top: pathname === "/my-collections" ? -5 : -11,
          right: -35
        }}
        onClick={onClick}
      >
        <IoMdArrowDroprightCircle size={30} />
      </div>
    );
  }
  
  function PrevArrow(props) {
    const { style, onClick, className } = props;
    const {pathname} = useLocation()
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          color: "#123B64",
          borderRadius: "50%",
          top: pathname === "/my-collections" ? -5 : -11,
          left: -35 
        }}
        onClick={onClick}
      >
        <IoMdArrowDropleftCircle size={30} color="#123B64" />
      </div>
    );
  }

export const SETTINGS = {
    dots: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 2,
    vertical: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          dots: false,
          infinite: false,
          slidesToShow: 3,
          slidesToScroll: 2,
          vertical: false,
          arrows: false,
          variableWidth: true,
        }
      }
    ]
  }; 