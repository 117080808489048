import React from 'react';
import { findNodeIcon } from "../../../../constants/node-type";

import './full-node-card-logo.scss'

const FullNodeCardLogo = ({ Type, page }) => {

   
    const iconName = findNodeIcon(Type)
    return (
        <div className="database-wrapper position-relative">
            <div className={`card-icon-wrapper d-flex justify-content-center align-items-center rounded-circle ${page === "node-viewer" ? 'node-type-wrapper' : ''}`}>
                <img
                    src={iconName}
                    alt="database-icon"
                    // style={{
                    //     width: '50px', 
                    //     height: '50px',
                        
                    // }}
                />
            </div>
            <div className="database-btn-wrapper w-100 d-none d-lg-flex justify-content-center">
                <button className="database-btn">
                    {Type}
                </button>
            </div>
        </div>
    );
};


export default FullNodeCardLogo;