import NodeCard from "../../shared/node/NodeCard";
import Grid from '@material-ui/core/Grid';

export const NodeCardWrapper = (props) => {
    const { nodes, showSaved, setSelectedNodes, selectedNodes, isClickable, isInprogress } = props

    return (
        <Grid container spacing={3} >
            {nodes.map((node, index) => {
                return (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={showSaved ? 4 : 6}
                        lg={isClickable || isInprogress ? 6 : 4}
                        key={index}
                    >
                        <NodeCard
                            isInprogress={isInprogress}
                            showShortCard={showSaved}
                            node={node}
                            setSelectedNodes={setSelectedNodes}
                            selectedNodes={selectedNodes}
                            currentNodes={nodes}
                        />
                    </Grid>
                );
            })}
        </Grid>
    )
}