import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Fade from "@material-ui/core/Fade";
import Grow from "@material-ui/core/Grow";
import ShareBtn from "./ShareBtn";
import Alert from "@material-ui/lab/Alert";
import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles({
  sneckBar: {
    position: "absolute",
    top: 56,
    left: "83%",
    display: "block",
  },
  alert: {
    width: 113,
    background: "#fff",
    color: "#123b64",
    boxShadow: "0px 2px 8px rgba(12, 8, 21, 0.2)",
    padding: "6px 15px",
    "& .MuiAlert-message": {
      padding: 0,
    },
  },
});

export default function ShareSnackBar({ Copy }) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    open: false,
    Transition: Fade,
  });

  function GrowTransition(props) {
    return <Grow {...props} />;
  }

  const handleClick = (Transition) => () => {
    setState({
      open: true,
      Transition,
    });
    Copy();
  };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };

  return (
    <div>
      <ShareBtn handleClick={handleClick(GrowTransition)} name="copy" />
      <Snackbar
        autoHideDuration={500}
        className={classes.sneckBar}
        open={state.open}
        onClose={handleClose}
        TransitionComponent={state.Transition}
        key={state.Transition.name}
      >
        <Alert icon={false} className={classes.alert} >
        <Typography variant="h6" color="primary">
                Link Copied
              </Typography>
        </Alert>
      </Snackbar>
    </div>
  );
}
