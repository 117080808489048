import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormGroup, FormControlLabel, Box } from '@material-ui/core'
import { CustomCheckbox } from '../../inputs/CheckBox';
import { TextButton } from '../../buttons/custom-buttons/CustomButtons';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 170,
        overflowY: 'auto',
        marginTop: 6,
        backgroundColor: theme.palette.background.paper,
        '&::-webkit-scrollbar': {
            width: 4
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#F79F19',
            borderRadius: 2,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#808c9a4d',
            borderRadius: 2
        }
    },
    formControlLabel: {
        marginLeft: -9,
        '& .MuiTypography-body1': {
            fontSize: 14,
            color: '#123b64',
        }
    },
    textButtonWrapper: {
        textAlign: 'end',
        marginTop: 15
    }
}));

const UserList = (props) => {
    const {
        users,
        formFields,
        setFormFields,
        setCheckedUsers,
        checkedUsers,
        field,
        index,
        type
    } = props
    const classes = useStyles();

    const handleChange = (checkedUser) => {
        let copyFormFields = type === "matrix-team" ? [...formFields.roles] : [...formFields]
        let copySelectedUsers = [...field.users]
        let copyCheckedUsers = checkedUsers
        let isSelected = copySelectedUsers.find(user => user.id === checkedUser.id)
        if (isSelected) {
            copySelectedUsers = copySelectedUsers.filter(user => user.id !== checkedUser.id)
            copyFormFields[index]['users'] = copySelectedUsers
            delete copyCheckedUsers[checkedUser.id]
            copyFormFields = copyFormFields.map(item => {
                return {
                    ...item,
                    errors: {
                        ...item.errors,
                        users: copyFormFields[index].users.length > 0 ? null : "At least one user must be selected"
                    }
                }
            })
        } else {
            copySelectedUsers.push(checkedUser)
            copyFormFields[index]['users'] = copySelectedUsers
            copyFormFields = copyFormFields.map(item => {
                return {
                    ...item,
                    errors: {
                        ...item.errors,
                        users: copySelectedUsers.length > 0 ? null : "At least one user must be selected"
                    }
                }
            })
            copyCheckedUsers[checkedUser.id] = true
        }
        type === "matrix-team" ?
            setFormFields(prev => {
                return {
                    ...prev,
                    roles: [...copyFormFields]
                }
            }) : setFormFields(copyFormFields)
        setCheckedUsers(copyCheckedUsers)
    }
    return (
        <Box>
            <Box className={classes.textButtonWrapper} style={{ padding: '0 6px' }}>
                <TextButton>Select all</TextButton>
            </Box>
            <FormControl component="fieldset" className={classes.root}>
                <FormGroup>
                    {users && users.map(user => {
                        return (
                            <FormControlLabel
                                label={user.name}
                                className={classes.formControlLabel}
                                key={user.id}
                                control={<CustomCheckbox
                                    checked={checkedUsers[user.id] === true}
                                    onChange={() => handleChange(user)}
                                />}
                            />
                        )
                    })}
                </FormGroup>
            </FormControl>
        </Box>

    );
}
export default UserList 