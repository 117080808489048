import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { BackBtn } from "../../../shared/buttons/custom-buttons/CustomButtons";
import CustomizedRadio from "../../../shared/inputs/CustomizedRadio";
import CustomizedSelect from "../../../shared/inputs/CustomizedSelect";
import Breadcrumbs from "../../../shared/breadcrumb/Breadcrumbs";
import { NodeCreationService } from "../../../../services/NodeCreationService";
import { getTransformedData } from "./curateSecurty";
import SharePopover from "../../../../components/shared/popover/SharePopover";
import { useFormik } from "formik";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  RadioGroup,
  Typography,
  Button
} from "@material-ui/core";
import {
  initialValues,
  validationSchema,
  ACCESS_RULES,
  RETENTION_PERIOD,
  ENCYRPTION,
  BACKUP_FREQUENCY,
  ACCESS_RESTRICTIONS,
  useStyles,
} from "./curateSecurty";
import Sharenode from "../../../share/Shareregistration";


function CurateSecurity(data) {
  const classes = useStyles();
  const { key, nodeName } = useParams();
  const [accessRoleOptions, setAccessRuleOptions] = useState([])
  const [retentionPeriodOptions, setRetentionPeriodOptions] = useState([]);
  const [backupFreqOptions, setBackupFreqOptions] = useState([]);
  const [accessRestrOptions, setAccessRestrOptions] = useState([]);
  const [openShareContent, setOpenShareContent] = useState(null);
  const [formValues, setFormValues] = useState(initialValues);
  const navigate = useNavigate();
 

  useEffect(() => {
    // NodeCreationService.fetchAccessRules().then((res) => {
    //   const transformedData = getTransformedData(res.data[0]);
    //   setAccessRuleOptions(transformedData);
    // });
    NodeCreationService.fetchAccessRules()
      .then(res => {
        const transformedData = getTransformedData(res.data[0])
        setAccessRuleOptions(transformedData)
      })
   

    NodeCreationService.fetchSelectOptions("RetentionPeriod").then((res) => {
      const transformedData = getTransformedData(res.data[0]);
      setRetentionPeriodOptions(transformedData);
    });

    NodeCreationService.fetchSelectOptions("DataBackupFrequency").then(
      (res) => {
        const transformedData = getTransformedData(res.data[0]);
        setBackupFreqOptions(transformedData);
      }
    );

    NodeCreationService.fetchSelectOptions("DataAccessRestrictions").then(
      (res) => {
        const transformedData = getTransformedData(res.data[0]);
        setAccessRestrOptions(transformedData);
      }
    );
    NodeCreationService.getCurateSecurityValues(key).then((res) => {
      const hasData = Object.keys(res.data).length;
      if (hasData) {
        const formValue = {
          [ACCESS_RULES]: res.data.accessRules,
          [RETENTION_PERIOD]: res.data.retentionPeriod,
          [ENCYRPTION]: res.data.encryption,
          [BACKUP_FREQUENCY]: res.data.dataBackupFrequency,
          [ACCESS_RESTRICTIONS]: res.data.dataAccessRestrictions,
        };
        setFormValues(formValue);
      }
    });
  }, []);

  const onSubmit = (values) => {
    const payload = [];
    Object.keys(values).forEach((item) => {
      let payloadItem = {};
      payloadItem.attributeName = item;
      payloadItem.attributeValue = values[item];
      payload.push(payloadItem);
    });

    NodeCreationService.createAttributes(payload, key)
      .then(() => {
        navigate(`/responsibility/${nodeName}/${key}`);
      })
      .catch(() => {
        alert("error is occured in api");
      });
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  const setBackPage = () => {
   
    navigate(-1)
  };

  const { handleSubmit, handleChange, values } = formik;
  const handleAddItem = () => {
    window.open("http://162.241.222.29:6080/index.html#!/service/2/policies/0", "_blank");
  };

  return (
    <div>
      <Container className={classes.root}>
      <Box mb={2.5} style={{ textDecoration: 'none', color: '#123b64' }}>
  <IconButton
    onClick={setBackPage}
    className={classes.icon}
    style={{ color: '#63ADA6' }}
  >
    <ArrowBackIosIcon style={{ fontSize: '13px' }} />
  </IconButton>

  <Link to="/home" style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
    Home
  </Link>
  {' / '}
  <Link to={`/curateprivacy/${nodeName}/${key}`} style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
  Curateprivacy 
  </Link>
  {' / '}
  <Link  to={`/Curatesecurity/${nodeName}/${key}`} style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
  Curatesecurity
  </Link>
  {' / '}
  <span style={{ fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>{nodeName}</span>
</Box>
        <Typography
          variant="h1"
          color="primary"
          className={classes.header}
          align="center"
        >
          {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
        </Typography>
        <Box className={classes.paper}>
          <Box className={classes.paperTitle} px={5} py={2.5}>
            <Typography variant="h5" color="primary">
              2.2 Curate/ Security
            </Typography>
          </Box>
          <Box className={classes.paperBody} p={5}>
            <form
              className={classes.form}
              id="curateSecurity-form"
              onSubmit={handleSubmit}
            >
              {/* <Box mb={2.5}>
              <CustomizedSelect
                  id="accessRules"  
                  label={ACCESS_RULES}
                  options={accessRoleOptions}
                  name={ACCESS_RULES}
                  value={values[ACCESS_RULES]}
                  handleChange={handleChange}
                  defaultValue="Select a policy from the list"
                  formik={formik}
                  />
                   
                
              </Box> */}
               <form onSubmit={formik.handleSubmit}>
      <Box mb={2.5}>
        <CustomizedSelect
          id="accessRules"
          label={ACCESS_RULES}
          options={accessRoleOptions}
          name={ACCESS_RULES}
          value={values[ACCESS_RULES]}
          handleChange={handleChange}
          defaultValue="Select a policy from the list"
          formik={formik}
        />
      </Box>
      <Box mb={2.5}>
        <Button variant="contained" color="primary" onClick={handleAddItem}>
          
          + Add a new policy
        </Button>
      </Box>
    </form>
              <Box mb={2.5}>
                <CustomizedSelect
                  id="retentionPeriod"
                  label={RETENTION_PERIOD}
                  options={retentionPeriodOptions}
                  name={RETENTION_PERIOD}
                  value={values[RETENTION_PERIOD]}
                  handleChange={handleChange}
                  defaultValue="Select from the list"
                  formik={formik}
                />
              </Box>
              <Typography variant="h6" color="primary" className={classes.text}>
                Encryption
              </Typography>
              <Box pb={1.75}>
                <FormControl component="fieldset">
                  <RadioGroup
                    className={classes.radioWrapper}
                    value={values[ENCYRPTION]}
                    name={ENCYRPTION}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="true"
                      control={<CustomizedRadio />}
                      label="True"
                    />
                    <FormControlLabel
                      value="false"
                      control={<CustomizedRadio />}
                      label="False"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box mb={2.5}>
                <CustomizedSelect
                  id="dataBackupFreq"
                  label={BACKUP_FREQUENCY}
                  options={backupFreqOptions}
                  name={BACKUP_FREQUENCY}
                  value={values[BACKUP_FREQUENCY]}
                  handleChange={handleChange}
                  defaultValue="Select from the list"
                  formik={formik}
                />
              </Box>
              <Box>
                <CustomizedSelect
                  id="accessRestriction"
                  label={ACCESS_RESTRICTIONS}
                  options={accessRestrOptions}
                  name={ACCESS_RESTRICTIONS}
                  value={values[ACCESS_RESTRICTIONS]}
                  handleChange={handleChange}
                  defaultValue="Select from the list"
                  formik={formik}
                />
              </Box>
            </form>
          </Box>
        </Box>
        <Box className={classes.buttonsWrapper}>
          <Link
            to={`/curateprivacy/${nodeName}/${key}`}
            style={{ textDecoration: "none" }}
          >
            <BackBtn>Back</BackBtn>
          </Link>
          <SharePopover
            anchorEl={openShareContent}
            setAnchorEl={setOpenShareContent}
            popoverButton={
              <div style={{ paddingLeft: 500, marginBottom: 20 }}>
                <BackBtn type="submit">Assign</BackBtn>
              </div>
            }
          >
            <Sharenode setOpenShare={setOpenShareContent} data={data} />
          </SharePopover>
          <BackBtn form="curateSecurity-form" type="submit">
            Next
          </BackBtn>
        </Box>
      </Container>
    </div>
  );
}

export default CurateSecurity;
