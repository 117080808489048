import { Box, Container, FormControl, FormControlLabel, RadioGroup, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BackBtn } from "../../../shared/buttons/custom-buttons/CustomButtons";
import CreatableMultiSelect from "../../../shared/inputs/CreatableMultiSelect";
import CustomizedInput from "../../../shared/inputs/CustomizedInput";
import TextAreaInput from "../../../shared/inputs/TextAreaInput";
import Loader from "../../../shared/loader/Loader";
import DataContext from "../../../../contexts/Context";
import { SearchService } from "../../../../services/SearchService";
import { formValues, useStyles, validationSchema } from "./NodeRegistrationDetails";
import { NodeCreationService } from "../../../../services/NodeCreationService";
import CategoryCreation from "../../../your-profile/CategoryCreation";
import CustomizedRadio from "../../../shared/inputs/CustomizedRadio";
import TextAreaInputVendor from "../../../shared/inputs/TextAreaInputVendor";

function ModelRegistration({ details }) {
  const classes = useStyles();
  const { nodeType, title } = details
  const [options, setOptions] = useState([]);
  const [filteredTags, setFilteredTags] = useState([])
  const [categories, setCategories] = useState([]);
  const [tableData, setTableData] = useState([])
  const [categoryError, setCategoryError] = useState(false);
  const { loggedInUser } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true)
  const [initialValues, setInitialValues] = useState(formValues)
  const navigate = useNavigate()
  const { key } = useParams();

  useEffect(() => {
    NodeCreationService.getDataProductData(key)
      .then(res => {
        const { description, name, tags, categories } = res.data[0]
        setInitialValues({ description, displayName: name, tags })
        setTableData(categories)
        setIsLoading(false)
      })

    SearchService.fetchTags()
      .then(res => {
        const tags = res.data[0].map(tag => {
          return { name: tag, value: tag }
        }).filter(tag => tag.name !== null)
        setOptions(tags)
      })
  }, [key]);

  useEffect(() => {
    setFilteredTags(options)
  }, [options])

  const onSubmit = (values) => {
    if (tableData.length) {
      values.nodeName = values.displayName
      delete values.displayName
      let payload = {
        ...values,
        nodeId: key,
        userId: loggedInUser.userId,
        type: nodeType,
        categories: tableData
      }
      NodeCreationService.createDataProduct(payload)
        .then(() => {
          nodeType === "Data Product" ?
            navigate(`/model_samplecard/${values.nodeName}/${key}`) :
            navigate(`/dataentryform/${values.nodeName}/${key}`)
            
            //navigate(`/data_product_samplecard/${values.nodeName}/${key}`) 
        })
    } else { setCategoryError(true) }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  });

  const { values, setFieldValue, handleChange, handleSubmit } = formik;

  const handleTagsChange = (e, value) => {
    const tags = []
    value.forEach(item => {
      if (item.trim().length) {
        tags.push(item)
      }
    })

    const filtredValues = options.filter(option => {
      return !value.some(tag => tag === option.name)
    })

    setFilteredTags(filtredValues)
    setFieldValue("tags", tags)
  }

  const deleteContext = (contextName) => {
    console.log(contextName);
};

  if (isLoading) { return <Loader /> }

  return (
    <Container className={classes.container}>
      <Typography variant="h1" color="primary" align="center" className={classes.mainTitle}>
        {values.displayName?.charAt(0).toUpperCase() + values?.displayName.slice(1)}
      </Typography>
      <Box className={classes.shadowWrapper}>
        <Box className={classes.title}>
          <Typography variant="h5" color="primary">
            {title}
          </Typography>
        </Box>
        <form id="dataproduct-form" onSubmit={handleSubmit} className={classes.wrapper}>
          <Box className={classes.leftSide}>
            <Box mb={2.5}>
              <CustomizedInput
                id="displayname"
                name="displayName"
                label="Name section"
                value={values.displayName}
                onChange={handleChange}
                placeholderText="Enter name"
                formik={formik}
              />
            </Box>
            <Box mb={2.5}>
              <TextAreaInput
                id="description"
                name="description"
                value={values.description}
                label="Description"
                placeholderText="Enter text"
                onChange={handleChange}
                formik={formik}
              />
            </Box>
            <Box mb={1}>
              <CreatableMultiSelect
                label="Tags (maximum 20)"
                options={filteredTags}
                name="tags"
                placeholder="Add tags"
                formik={formik}
                handleChange={handleTagsChange}
              />
            </Box>
            <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
              {/* <Typography component="span" className={classes.suggested} variant="body2">
                Enter/Select a Tag
              </Typography> */}
              {nodeType === "Data Product" ? 
              <FormControl component="fieldset"
              style={{marginTop:10,}}>
                 <Typography variant="h6" color="primary" className={classes.text}>
                Encryption
                </Typography>
                <RadioGroup
                  className={classes.radioWrapper}
                  aria-label="classification"
                name="classification"
                value={values.classification}
                onChange={handleChange}
                >
                  <FormControlLabel
                    value="internal"
                    control={<CustomizedRadio />}
                    label="Internal"
                  />
                  <FormControlLabel
                    value="external"
                    control={<CustomizedRadio />}
                    label="External"
                  />
                </RadioGroup>
              </FormControl>
                :<></>}
            </Box>
            {
            values.classification === "external" ?
            <Box mb={2.5} 
            style={{marginTop:10,}}>
              <TextAreaInputVendor
                id="vendorname"
                name="vendorname"
                value={values.vendorname}
                label="Vendor Name"
                placeholderText="Enter text"
                //onChange={handleChange}
                formik={formik}
              />
            </Box>: null }
          
          </Box>
          <Box className={classes.rightSide}>
            <CategoryCreation
              tableData={tableData}
              setTableData={setTableData}
              categories={categories}
              setCategories={setCategories}
              categoryError={categoryError}
              setCategoryError={setCategoryError}
              deleteContext={deleteContext}
            />
          </Box>
        </form>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link 
        //to={nodeType === "Data Product" ? '/dataproduct' : '/dataset'} 
        style={{ textDecoration: "none" }}>
          <BackBtn>Back</BackBtn>
        </Link>
        <BackBtn type="submit" form="dataproduct-form">Next</BackBtn>
      </Box>
    </Container>
  );
}

export default ModelRegistration;