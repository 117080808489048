import LessThanIcon from './../../../assets/images/lessthanicon.png';

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: "flex",
                justifyContent: 'center',
                alignItems: 'center',
                background: "#E0E7EC",
                width: '48px',
                height: '48px',
                color: '#123B64 !important',
                borderRadius: '12px',
                left: -45
            }}
            onClick={onClick}
        >
            <img src={LessThanIcon} alt="LessThanIcon" />
        </div>
    );
}

export default PrevArrow;