import { useState, useEffect } from 'react';
import NodeGraph from '../../components/shared/d3-graph/force-directed-graph/NodeGraph';
import { SearchService } from '../../services/SearchService';
import { useParams } from 'react-router-dom';
import { Box, makeStyles, Typography } from '@material-ui/core';
import ErorrPage from '../../components/shared/error/Erorr';

const useStyles = makeStyles({
    root: {
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    graphWrapper: {
        width: '100vw',
        height: '100vh'
    }
})

function NodeGraphPage() {
    const classes = useStyles()
    const [graphData, setGraphData] = useState({})
    const [loading, setLoading] = useState(true)
    const [ isError, setIsError ] = useState(false)
    const { id } = useParams()

    useEffect(() => {
        SearchService.fetchNodeGraphData(id, 3)
            .then(res => {
                const nodes = res.data.nodes.map(node => {
                    return {
                        ...node,
                        module: node.path
                    }
                })
                const graphdata = {
                    nodes,
                    links: res.data.links
                }
                setGraphData(graphdata)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
                setIsError(true)
            })
    }, [])

    if(isError){
        return <ErorrPage />
    }

    return (
        <Box sx={{minHeight: '100vh'}}>
            {
                loading ? <Typography variant='h2' color='primary'>Loading...</Typography> :
                    <Box className={classes.graphWrapper}>
                        <NodeGraph
                            graphData={graphData}
                            backgroundColor="#fff"
                            linkColor="#123b64"
                            labelColor="#123b64"
                        />
                    </Box>
            }
        </Box>
    );
}

export default NodeGraphPage;