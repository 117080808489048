import { Box, Typography, makeStyles, IconButton } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import YourSelectedNodeList from './details/YourSelectedNodeList';

const useStyles = makeStyles({
    wrapper: {
        background: '#fff',
        boxShadow: '0px 4px 20px rgba(1, 25, 54, 0.1)',
        borderRadius: 4,
        maxWidth: 700,
        width: '100%'
    },
    noNodesText: {
        fontSize: 20,
        fontWeight: 700,
        lineHeight: 1.5,
        padding: '45px 0 53px'
    }
})

function YourSelectedNodes({ selectedNodes, setSelectedNodes, setShowPopup }) {
    const classes = useStyles()
    return (
        <Box className={classes.wrapper}>
            <Box sx={{ borderBottom: '1px solid #E0E7EC', display:'flex', justifyContent: 'space-between' }} px={5} py={2.5}>
                <Typography variant='h5' color='primary'>Your selected nodes</Typography>
                <IconButton onClick={() => setShowPopup(false)} style={{ padding: 0 }} disableRipple>
                    <CloseIcon color='primary' />
                </IconButton>
            </Box>
            <Box sx={{ padding: '0 40px 20px 40px' }}>
                {
                    selectedNodes.length ? 
                    <YourSelectedNodeList nodes={selectedNodes} setSelectedNodes={setSelectedNodes} /> :
                        <Typography className={classes.noNodesText} align="center" color='primary'>
                            You have not selected any items to create a node
                        </Typography>
                }
            </Box>
        </Box>
    );
}

export default YourSelectedNodes;