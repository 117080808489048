import { Box, Container, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useContext, useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { classificationService } from "../../services/custom-hooks/CustomHooks";
import Breadcrumbs from "../../components/shared/breadcrumb/Breadcrumbs";
import { OutlinedButton } from "../../components/shared/buttons/custom-buttons/CustomButtons";
import CustomModal from "../../components/shared/modal/CustomModal";
import NewTermModal from "../../components/navbar/nav-dropdown-input/logopagedesign/logopages/popupformsoflogo/NewTermModal";
import SemanticsTable3 from "../../components/navbar/nav-dropdown-input/logopagedesign/logopages/SemanticsTable3";
import { FilterIcon } from "../../components/icons";
import { NodeCreationService } from "../../services/NodeCreationService";
import DataContext from "../../contexts/Context";
import Loader from "../../components/shared/loader/Loader";
import SemanticsTable from "../../components/navbar/nav-dropdown-input/logopagedesign/logopages/SemanticsTable";
import SliderPlaceholder from "../../components/shared/placeholder/SliderPlaceholder";
import CollectionSidebar from "../digital-publication/CollectionSidebar";
import CustomDrawer from "../../components/shared/drawer/CustomDrawer";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";


const useStyles = makeStyles({
  container: {
    minHeight: "calc(100vh - 150px)",
    paddingTop: 32,
    paddingBottom: 100,
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: 219,
    "& span": {
      fontWeight: 700,
    },
  },
  wrapper: {
    boxShadow: "0px 4px 20px rgba(1, 25, 54, 0.1)",
    borderRadius: 4,
  },
  box: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 40px",
    borderBottom: "1px solid #E0E7EC",
  },
  tableWrapper: {
    padding: "40px 40px 20px",
  },
});

const columns = [
  { id: "columnName", name: "Column Name" },
  { id: "Term", name: 'Term' },
  //{ id: "recommendedName", name: 'Recommend' },
  { id: "Description", name: 'Description' },
   { id: "confidence_score", name: 'ConfidenceSCore' },
    { id: "Status", name: 'Status' },
 
]

function ClassificationDataset() {
  const classes = useStyles();
  const [openModal, setOpenModal] = useState(false);
  const [termStatus, setTermStatus] = useState("");
  const [columnName, setColumnName] = useState("");
  const [semanticData, setSemanticData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { key, nodeName, selectedNodeName } = useParams();
  const { dataSetPayload } = useContext(DataContext);
  const navigate = useNavigate();
  const [openSidebar, setOpenSidebar] = useState(false);


  const getSemanticsClassification = () => {
    return  NodeCreationService.addNodeClassification({ columns: dataSetPayload })

      .then(res => {
        //console.log(res,"responsess")
        setSemanticData(res.data)
        setIsLoading(false)
      }).catch(() => setIsLoading(false))
  } 

  useEffect(() => {
    window.scrollTo(0, 0);
    getSemanticsClassification()
  }, [])
 
  const addNewTerm = (recommendedName) => {
    return classificationService
      .addNewTerm(columnName, recommendedName, termStatus)
      .then((res) => {
        if (res.status === 200) {
          let newArr = semanticData.map((obj) => {
            if (obj.columnName === columnName) {
              return { ...obj, recommendedName: recommendedName };
            }
            return obj;
          });
          setSemanticData(newArr);
          setOpenModal(false);
        }
      });
  };
  console.log("semants: ", semanticData);

  const getTermDetails = (colName, status) => {
    setColumnName(colName);
    setTermStatus(status);
    setOpenModal(true);
  };
  const setBackPage = () => {
   
    navigate(-1)
  };

  const updateStatus = (
    colName,
    recName,
    status,
    index,
    confidenceScore,
    nameIndex,
    collapse
  ) => {
    return classificationService
      .classificationStatusUpdate(colName, recName, status)
      .then((res) => {
        let copySemanticsData = [...semanticData];
        let suggestedNames;
        if (collapse && res.status === 200) {
          copySemanticsData[`${index}`].Status = status;
          copySemanticsData[`${index}`].recommendedName = recName;
          copySemanticsData[`${index}`].confidenceScore = confidenceScore;
          suggestedNames = copySemanticsData[`${index}`].suggestedNames.map(
            (item, i) => {
              if (i === nameIndex) {
                if (status === "accepted") {
                  return { ...item, Status: "accepted" };
                } else {
                  return { ...item, Status: "Rejected" };
                }
              } else {
                return { ...item, Status: "Rejected" };
              }
            }
          );
          copySemanticsData[`${index}`].suggestedNames = suggestedNames;
        } else if (res.status === 200) {
          copySemanticsData[`${index}`].Status = status;
        }
        setSemanticData(copySemanticsData);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container className={classes.container}>
      <CustomModal open={openModal} setOpen={setOpenModal}>
        <NewTermModal
          page="classification"
          addNewTerm={addNewTerm}
          setOpenModal={setOpenModal}
        />
      </CustomModal>
      <Box mb={2.5} style={{ textDecoration: 'none', color: '#123b64' }}>
  <IconButton
    onClick={setBackPage}
    className={classes.icon}
    style={{ color: '#63ADA6' }}
  >
    <ArrowBackIosIcon style={{ fontSize: '13px' }} />
  </IconButton>

  <Link to="/home" style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
    Home
  </Link>
  {' / '}
  <Link to={`/descriptiondataset/${selectedNodeName}/${nodeName}/${key}`} style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
  Description
  </Link>
  {' / '}
  <Link style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
  Semantics
  </Link>
  {' / '}
  <span style={{ fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>{nodeName}</span>
</Box>
      <Typography
        style={{ marginBottom: 24 }}
        align="center"
        variant="h1"
        color="primary"
      >
        {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
      </Typography>
      <Box className={classes.wrapper} mb={5}>
        <Box className={classes.box}>
          <Typography variant="h5" color="primary">
            Dataset / Classification
          </Typography>
          <CustomDrawer state={openSidebar} setState={setOpenSidebar} anchor="left">
        <CollectionSidebar setOpenSidebar={setOpenSidebar} />
      </CustomDrawer>
          <IconButton onClick={() => setOpenSidebar(true)} style={{ padding: 0 }}>
            <FilterIcon />
          </IconButton>
        </Box>
        <Box className={classes.tableWrapper}>
        {semanticData.length > 0 ? (
          <SemanticsTable
            semanticData={semanticData}
            getTermDetails={getTermDetails}
            updateStatus={updateStatus}
            getSemanticsClassification={getSemanticsClassification}
            columns={columns}
            page="classification"
          />
          ) : ( <SliderPlaceholder error={true} text="No data found" />
          )}
        </Box>
      </Box>
      <Box className={classes.buttonWrapper}>
        <Link
          style={{ textDecoration: "none", color: "#123B64" }}
          to={`/descriptiondataset/${selectedNodeName}/${nodeName}/${key}`}
        >
          <OutlinedButton className={classes.button} disableRipple>
            Back
          </OutlinedButton>
        </Link>
        <Box>
          <Link
            style={{ textDecoration: "none", color: "#123B64" }}
            to={`/curatecontextdataset/${selectedNodeName}/${nodeName}/${key}`}
          >
            <OutlinedButton
              style={{ marginRight: 15 }}
              className={classes.button}
            >
              Next
            </OutlinedButton>
          </Link>
        </Box>
      </Box>
    </Container>
  );
}

export default ClassificationDataset;
