import React, { useState, useContext } from "react";
import { List, ListItem, Box } from "@material-ui/core";
import ShareSnackBar from "../share/share-content/ShareSnackBar";
import ShareBtn from "../share/share-content/ShareBtn";
import Messanger from "../shared/messanger/Messanger";
import CustomModal from "../navbar/nav-dropdown-input/logopagedesign/logopages/popupformsoflogo/CustomModal";
import DataContext from "../../contexts/Context";
import Mail from "../shared/messanger/Mail";
import { useParams, useSearchParams } from "react-router-dom";

export default function ShareIcon({ nodeData, type, setOpenShare }) {
  let data = {
    url: "",
    shareType: "",
    users: [],
    shareHolder: "",
    shareHolderId: "",
    shareUsing: "",
    message: "",
    key:"",
  };
  const [initialValues, setInitialValues] = useState(data);
  const [mailModal, setOpenMailModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [url, setUrl] = useState("");
  const { displayName, id, 
    key
   } = nodeData;
  //const [searchParams] = useSearchParams();
  //const key = searchParams.get("key");
  //const { key, nodeName } = useParams();
  const { loggedInUser } = useContext(DataContext);

  const handleSendMessage = (name) => {
    const nodeName = displayName.replace("<b>", "");
    setInitialValues((prev) => {
      return {
        ...prev,
        shareType: name, 
        shareHolder: loggedInUser.email,
        shareHolderId: loggedInUser.userId,
        key:id,
      };
    });

    if (type) {
      const url = `http://${window.location.host}/my-collections/${nodeName}/${key}`;
      setInitialValues((prev) => {
        return {
          ...prev,
          shareUsing: "PinCollection",
          url: url,
          key:id,
        };
      });
    } else {
      const url = `http://${window.location.host}/tabs?key=${id}`;
      setInitialValues((prev) => {
        return {
          ...prev,
          shareUsing: "Nodes",
          url: url,
          key:id,
        };
      });
    }
    setOpenModal(true);
  };

  const Copy = () => {
    if (type === "collection") {
      const url = `http:/${window.location.host}/my-collections/${displayName}/${key}`;
      //navigator.clipboard.writeText(url);
      navigator.clipboard
      .writeText(url)
      .then(() => {
        //alert("successfully copied");
      })
      .catch(() => {
        alert("something went wrong");
      });
    } else {
      const nodeTitle = displayName?.replace("<b>", "");
      const url = `http:/${window.location.host}/tabs?key=${id}`;
      //navigator.clipboard.writeText(url);
      navigator.clipboard
      .writeText(url)
      .then(() => {
        //alert("successfully copied");
      })
      .catch(() => {
        alert("something went wrong");
      });
    }
  };

  const handleSendEmail = () => {
    const nodeName = displayName.replace("<b>", "");
    let url;
    if (type) {
      url = `http://${window.location.host}/my-collections/${nodeName}/${key}`;
    }
    url = `http://${window.location.host}/tabs?key=${id}`;
    setUrl(url);
    setOpenMailModal(true);
  };

  return (
    <Box>
      <CustomModal setOpen={setOpenModal} open={openModal}>
        <Messanger
          setOpenShare={setOpenShare}
          initialValues={initialValues}
          setOpenModal={setOpenModal}
        />
      </CustomModal>
      <CustomModal setOpen={setOpenMailModal} open={mailModal}>
        <Mail setOpenMailModal={setOpenMailModal} url={url} />
      </CustomModal>
      <List>
        <ListItem>
          <ShareBtn handleClick={handleSendMessage} name="team" />
          <ShareBtn handleClick={handleSendMessage} name="slack" />
          <ShareBtn handleClick={handleSendEmail} name="email" />
          <ShareSnackBar Copy={Copy} />
        </ListItem>
      </List>
    </Box>
  );
}
