import { makeStyles, Box, Button, FormControl, FormGroup, FormControlLabel, RadioGroup } from "@material-ui/core";
import { useState } from "react";
import SearchBar from "../../shared/inputs/SearchBar"
import clsx from 'clsx';
import { CustomCheckbox } from "../../shared/inputs/CheckBox";
import { TextButton } from "../../shared/buttons/custom-buttons/CustomButtons";
import CustomizedRadio from "../../shared/inputs/CustomizedRadio";
import { useEffect } from "react";

const useStyles = makeStyles(theme => ({
    root: {
        width: 350,
        boxShadow: '0px 2px 20px rgba(23, 87, 123, 0.2)',
        color: '#123b64',
        zIndex: 999,
        background: '#fff',
        borderRadius: 16
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px 15px 0 15px',
        background: '#fff',
        borderRadius: '16px 16px 0 0'
    },
    allCollections: {
        width: '100%',
        marginTop: 15,
    },
    createCollection: {
        padding: 16,
        boxShadow: '0px -5px 30px rgba(0, 0, 0, 0.1)',
        borderRadius: '0 0 16px 16px'
    },
    btn: {
        padding: 6,
        borderRadius: 8,
        width: 120,
        textTransform: 'capitalize',
    },
    applyBtn: {
        background: '#123b64',
        color: '#fff',
        marginRight: 15,
        '&:hover': {
            backgroundColor: '#123b64'
        }
    },
    clearBtn: {
        background: '#fff',
        color: '#123b64',
        border: '1px solid #123b64'
    },
    listWrapper: {
        width: '100%',
        height: 170,
        overflowY: 'auto',
        marginTop: 6,
        '&::-webkit-scrollbar': {
            width: 4
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#F79F19',
            borderRadius: 2,
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: '#808c9a4d',
            borderRadius: 2
        },
        "&::-webkit-scrollbar-button:end:increment": {
            height: 10,
            display: "block",
        },
        "&::-webkit-scrollbar-button:start:increment": {
            height: 16,
            display: "block",
        }
    },
    formControlLabel: {
        marginLeft: -9,
        marginBottom: 2,
        '& .MuiTypography-body1': {
            fontSize: 14,
            color: '#123b64',
        }
    },
    textButtonWrapper: {
        textAlign: 'end',
        marginTop: 15
    },
    label: {
        marginBottom: 20,
        marginLeft: -9
    }
}))

const TooltipContent = (props) => {
    const classes = useStyles()
    const [searchText, setSearText] = useState('')
    const {
        options,
        type, name,
        checkedValues,
        setCheckedValues,
        setSelectedCategories,
        selectedCategories,
        handleApply
    } = props
    const [filteredOptions, setFilteredOptions] = useState(options)
    
    const handleChange = (e) => {
        const { value } = e.target
        let copyCheckedCategories = selectedCategories
        let isExist = checkedValues.find(item => item?.name === value)
        if (isExist) {
            let fileterValues = checkedValues.filter(checkedValue => checkedValue.name !== value)
            setCheckedValues(fileterValues)
            copyCheckedCategories[value] = false
        } else {
            let newValue = options.find(option => option.name === value)
            setCheckedValues([...checkedValues, newValue])
            copyCheckedCategories[value] = true
        }
        setSelectedCategories(copyCheckedCategories)
    }

    const handleRadioChange = (e) => {
        const { value } = e.target
        let newChecked = options.find(checkedValue => checkedValue.name === value)
        setCheckedValues(newChecked)
    }

    const handleClear = () => {
        setCheckedValues([])
        setSelectedCategories({})
    }

    const handleSearch = (e) => {
        const targetValue = e.target.value
        setSearText(targetValue)
        const filtered = options.filter(item => {
            return item.name.toLowerCase().indexOf(targetValue.toLowerCase()) !== -1
        })
        setFilteredOptions(filtered)
    }

    const handleSelectAll = () => {
        let obj = {}
        options.forEach(item => {
            obj[item.name] = true
        })
        setCheckedValues(options)
        setSelectedCategories(obj)
    }

    useEffect(() => {
        if (!searchText) {
            setFilteredOptions(options)
        }
    }, [searchText])

    return (
        <Box className={classes.root}>
            <Box className={classes.wrapper}>
                <SearchBar
                    value={searchText}
                    setValue={setSearText}
                    handleChange={handleSearch}
                    placeholderText='Search categories'
                />
                <Box className={classes.allCollections}>
                    {
                        type !== "radio" ?
                            <Box className={classes.textButtonWrapper} style={{ padding: '0 6px' }}>
                                <TextButton onClick={handleSelectAll} >Select all</TextButton>
                            </Box>
                            : null
                    }
                    <FormControl component="fieldset" className={classes.listWrapper}>
                        {
                            type === "radio" ?
                                <RadioGroup value={checkedValues ? checkedValues.name : checkedValues} onChange={handleRadioChange}>
                                    {filteredOptions.length > 0 && filteredOptions.map((option) => {
                                        return (
                                            <FormControlLabel
                                                value={option.name}
                                                className={classes.label}
                                                key={option.key}
                                                control={<CustomizedRadio />}
                                                label={option.name}
                                            />
                                        )
                                    })}
                                </RadioGroup> :
                                <FormGroup>
                                    {filteredOptions.length > 0 && filteredOptions.map((option) => {
                                        return (
                                            <FormControlLabel
                                                className={classes.formControlLabel}
                                                key={option.key}
                                                control={<CustomCheckbox
                                                    value={option.name}
                                                    checked={selectedCategories[option.name] === true}
                                                    onChange={handleChange}
                                                />}
                                                label={option.name}
                                            />
                                        )
                                    })}
                                </FormGroup>
                        }
                    </FormControl>
                </Box>
            </Box>
            <Box className={classes.createCollection}>
                <Button
                    disableRipple
                    onClick={() => handleApply(name)}
                    className={clsx(classes.applyBtn, classes.btn)}
                >
                    Apply
                </Button>
                {
                    type !== "radio" ?
                        <Button
                            disableRipple
                            onClick={handleClear}
                            className={clsx(classes.clearBtn, classes.btn)}
                        >
                            Clear
                        </Button>
                        : null
                }
            </Box>
        </Box>

    );
}

export default TooltipContent;



