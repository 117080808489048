import { Button, IconButton, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import greaterthan from "../../../../../../assets/imageslogo/greaterthan1.png";
import { NODE_TYPE } from "../../../../../../pages/node-creating/nodeCreation";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    width: "700px",
    height: "340px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: "50px 0",
    boxShadow: "0px 4px 20px rgba(1, 25, 54, 0.1)",
    color: "#123b64",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  title: {
    position: "absolute",
    color: "#123b64",
    top: 20,
    left: 20,
    padding: 0,
    lineHeight: 1.5,
    fontWeight: 700,
  },

  closeBtn: {
    position: "absolute",
    color: "#123b64",
    top: 20,
    right: 20,
    padding: 0,
  },
  data: {
    position: "absolute",
    left: 20,
    right: 20,
    fontSize: 18,
  },
  startbtn: {
    color: "#fff",
    background: "#F79F19",
    width: "182px",
    height: "48px",
    marginLeft: "0px",
    borderRadius: 12,
    fontWeight: 700,
    lineHeight: "150%",
    textTransform: "capitalize",
    position: "absolute",
    top: 268,
    left: 25,
    "&.MuiButton-root:hover": {
      backgroundColor: "#F79F19",
    },
  },
  img: {
    height: 15,
    width: 10,
    marginLeft: 10,
  },
}));

function GotoCreation({ handleClose, id, newNode }) {
  const classes = useStyles();
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const handleStartCreate = () => {
    if (newNode === NODE_TYPE.dataProduct) {
      navigate(`/node-registration/${id}/1`)
    } else {
      navigate(`/dataset-registration/${id}`)
    } 
    if (newNode === NODE_TYPE.api)
{
  navigate(`/model-registration/${id}`)
} 
 }

  return (
    <div className={classes.paper}>
      <form>
        <div className={classes.title}>Data and Metadata</div>
        <IconButton onClick={handleClose} className={classes.closeBtn} disableRipple>
          <CloseIcon />
        </IconButton>
        <Typography className={classes.data}>
        Data is a collection of information gathered by observations, measurements, 
        research, or analysis. They may consist of facts, numbers, names, figures,
         or even descriptions of things. Data is organized in the form of graphs,
          charts, or tables. Metadata is data about the data or documentation about 
          the information that is required by the users. Metadata is used for building,
           maintaining, managing, and using the data warehouses. Metadata allows users access 
           to help understand the content and find data.
        </Typography>
        <Button onClick={handleStartCreate} className={classes.startbtn}>
          Start Create
          <img src={greaterthan} className={classes.img} />
        </Button>
      </form>
    </div>
  );
}

export default GotoCreation;
