import axios from "axios";
import api from "../core/api";
const apiUrl = process.env.REACT_APP_API_URL;
export const NodeCreationService = {
  createAttributes: (data, key) => {
    return api.post(`/createAttributes?key=${key}`, data);
  },
  // addNodeSemantics: (key) => {
  //   return axios.post(`${apiUrl}/nodeSemantics`,

  //     {
  //       "columns": [
  //         "33698803"
  //       ]

  //   });
  // },
  addNodeSemantics: (data) => {
    return api.post(`/nodeSemantics`, data);
  },
  addNodeDescription: (data) => {
    return api.post(`/nodeDescription`, data);
  },
  addNodeClassification: (data) => {
    return api.post(`/nodeClassification`, data);
  },
  // fetchClassification: (key) => {
  //   return axios.post(`${apiUrl}/nodeClassification`, {

  //       "columns": [
  //           "33698803"
  //       ]
  //   });
  // },

  createRelation: (data, key) => {
    return api.post(`/createRelation?key=${key}`, data);
  },
  fetchDataSources: () => {
    return api.get(`/getDataSources`);
  },
  fetchDatasetNodes: (key, source) => {
    return api.get(`api/graph/getDataSetsReports?key=Technology/${key}`);
  },
  fetchSelectOptions: (optionName) => {
    return api.get(`/selectFields?key=${optionName}`);
  },
  fetchAccessRules: () => {
    return api.get(`/api/nodes/getAllPolicies`);
  },
  createNewNode: (nodeName, description, userId, nodeType) => {
    return api.post(
      `/createNode?nodeName=${nodeName}&type=${nodeType}&userId=${userId}&description=${description}`
    );
  },
  addServiceLevelObjectives: (key, data) => {
    return api.post(`/serviceLevelObjective?key=${key}`, data);
  },
  fetchServiceLevelObjectives: (key) => {
    return api.get(`/getServiceLevelObjective?key=${key}`);
  },
  getObjectivesOptions: () => {
    return api.get(`/getObjectives`);
  },
  addObjective: (data) => {
    return api.post(`/addingObjectives`, data);
  },
  fetchDataset: (dbId, source) => {
    return api.post(
      `/dataSetGraphPicker?databaseId=Technology/${dbId}&source=${source}&linearFlag=false`
    );
  },
  //   fetchDataset: (dbName) => {
  //     return api.post(`/dataSetPicker?databaseName=${dbName}`)
  // },

  getCurateContextValues: (key) => {
    return api.get(`/getCurateContext?key=${key}`);
  },
  getCuratePrivacyValues: (key) => {
    return api.get(`/getCuratePrivacy?key=${key}`);
  },
  getCurateSecurityValues: (key) => {
    return api.get(`/getcurateSecurity?key=${key}`);
  },
  createDataProduct: (data) => {
    return api.post(`/createProduct`, data);
  },
  getDataProductData: (id) => {
    return api.get(`/getProduct?key=${id}`);
  },

  getDatasetSourcesystems: () => {
    return api.get(`getGraphDataSources`);
  },
  getDatabasesFromSource: (payload) => {
    return api.post(`/api/graph/getGraphDatabaseNodesFromSource`, payload);
  },
  addTeamToNode: (key, data) => {
    return api.post(`updateTeamDetailsInNode?key=${key}`, data);
  },
  getDataProductTeamDetails: (id) => {
    return api.get(`/getDataProductTeamDetails?nodeId=${id}`);
  },
  getCurateContextValuesdataset: (key) => {
    return api.get(`/getCurateContext?key=${key}`);
  },
  getCuratePrivacyValuesdataset: (key) => {
    return api.get(`/getCuratePrivacy?key=${key}`);
  },
  getCurateSecurityValuesdataset: (key) => {
    return api.get(`/getcurateSecurity?key=${key}`);
  },
  fetchDataEntryFormData: (key) => {
    return api.get(`/getDataEntryDetails?key=${key}`);
  },
  getPublishNode: (key) => {
    return api.get(`/publishNodes?key=${key}`);
  },

  getInprogressNodes: (data) => {
    return api.post(`/api/nodes/inprogressNodes`, data);
  },
  addColumnNameandRecommendedterm: (key, columnName, recommededName) => {
    return api.post(
      `/addNewTermManualInSemantics?key=${key}&columnName=${columnName}&recommededName=${recommededName}`
    );
  },
  addTermnameandDescription: (key, termName, recommededDesc) => {
    return api.post(
      `/addNewTermManualInDescription?key=${key}&termName=${termName}&recommededDesc=${recommededDesc}`
    );
  },
  addSensitivedatatype: (key, columName, sensitiveData) => {
    return api.post(
      `/addNewTermManualInClasssification?key=${key}&columnName=${columName}&recommededName=${sensitiveData}`
    );
  },
};
