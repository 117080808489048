import "./ResetPassword.scss";
import {
  Button,
  Input,
  InputLabel,
  InputAdornment,
  Typography,
  Box,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import MessageIcon from "./../../assets/images/message-icon.png";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

const useStyles = makeStyles({
  header: {
    fontSize: 30,
    lineHeight: "100%",
    fontWeight: 800,
    marginBottom: 40,
    marginTop: 10,
  },
  height: {
    minHeight: 850,
  },
  label: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "11px",
    marginBottom: 8,
    marginLeft: 15,
  },

  input: {
    border: "1px solid #808C9A",
    boxSizing: "borderBox",
    borderRadius: 8,
    padding: "14px 20px",
    fontSize: 14,
    width: 550,
    marginLeft: 15,
    marginRight: 5,
    lineHeight: "140%",
  },

  btn: {
    fontSize: 14,
    fontWeight: 900,
    padding: "17px 0",
    lineHeight: "100%",
    borderRadius: 12,
    width: 550,
    marginLeft: -25,
    marginTop: 15,
  },
});

export const ResetPassword = () => {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("Required"),
    }),
    onSubmit: (values) => {
    },
  });

  return (
    <div className={classes.height}>
      <Box className="box">
        <div className="reset-password text-center">
          <Typography
            className={`${classes.header} text-secondary-100 text-center`}
          >
            Reset Password
          </Typography>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <div className="form-group text-start">
              <InputLabel
                className={`${classes.label} text-secondary-100`}
                htmlFor="reset-email"
              >
                Email
              </InputLabel>
              <Input
                id="reset-email"
                name="email"
                type="email"
                fullWidth={true}
                className={classes.input}
                {...formik.getFieldProps("email")}
                startAdornment={
                  <InputAdornment position="start">
                    <img src={MessageIcon} alt="" />
                  </InputAdornment>
                }
              />
              {formik.touched.email && formik.errors.email ? (
                <p className="m-0 text-primary">{formik.errors.email}</p>
              ) : null}
            </div>
            <Button
              fullWidth={true}
              type="submit"
              className={`${classes.btn} bg-primary text-white`}
            >
              Send me the link
            </Button>
          </form>
          <Link
            to="/sign-in"
            className="signin-link text-primary text-decoration-none text-center"
          >
            Sign In
          </Link>
        </div>
      </Box>
    </div>
  );
};
export default ResetPassword;

