import * as yup from 'yup'
import { makeStyles } from '@material-ui/styles'

export const SECURTY_CLASSIFACTION = "Security Classification"
export const RETENTION_PERIOD = 'Privacy retention period'
export const TOXIC_COMBINATION = 'Infored toxic Combination'
export const PII = 'PII'

export const initialValues = {
  [SECURTY_CLASSIFACTION]: 'public',
  [RETENTION_PERIOD]: '',
  [TOXIC_COMBINATION]: 'false',
  [PII]: 'false'
}

export const validationSchema = yup.object({
  [RETENTION_PERIOD]: yup
    .string().required("Description is required"),
});

export const useStyles = makeStyles(() => ({
  root: {
    minHeight: 'calc(100vh - 150px)',
    paddingTop: 30,
    paddingBottom: 100
  },
  header: {
    margin: '40px 0'
  },
  paper: {
    boxShadow: '0px 4px 20px rgba(1, 25, 54, 0.1)',
    borderRadius: 4,
    marginBottom: 40
  },
  paperTitle: {
    borderBottom: '1px solid #E0E7EC'
  },
  buttonsWrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  radioWrapper: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: 20,
    "& .MuiFormControlLabel-root": {
      marginRight: 50,
    },
  },
  text: {
    paddingBottom: 10,
    lineHeight: 1
  }
}
));  