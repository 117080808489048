
import { SignUpForm } from "../../components/sign-up-form/SignUpForm";
import './SignUp.scss';
import datasouckImage from '../../assets/auth1.svg';
import { Grid, makeStyles, Box } from "@material-ui/core";
import banner from '../../assets/auth2.svg'
import mlsouklogo from '../../assets/mlsouklogo.png'

// Custom CSS styles for the SignUp component
const useStyles = makeStyles({
    imgWrapper: {
        backgroundImage: `url(${banner})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        marginTop: '0PX!important',
        "& img": {
            width: '100%',
            height: 'calc(100vh - 81px)',
            minHeight: 600
        }
    },
    grid: {
        display: 'flex',
        justifyContent: 'center'
    },
    signInWrapper: {
        padding: 30,
        maxWidth: 600,
        width: '100%'
    }
})

// SignUp functional component
export const SignUp = () => {
    const classes = useStyles()
    return (
        <Grid container>
            <Grid item xs={12} sm={6} className={classes.imgWrapper}>
                <img src={datasouckImage} alt="BannerImage" />
                {/* <img src={mlsouklogo} alt="BannerImage" /> */}
            </Grid>
            {/* Grid item for sign-up form, takes up 12 columns on small screens and 6 columns on medium screens */}
            <Grid className={classes.grid} item xs={12} sm={6}>
                <Box className={classes.signInWrapper}>
                    {/* Rendering SignUpForm component */}
                    <SignUpForm />
                </Box>
            </Grid>
        </Grid>
    )
} 