import { makeStyles } from "@material-ui/core";
import { SearchService } from "../../../../services/SearchService";
import { TeamService } from "../../../../services/TeamService";

export const useStyles = makeStyles({
  root: {
    width: 588,
    background: "#fff",
    color: "#123b64",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "40px 40px 30px",
  },
  title: {
    fontSize: 22,
    fontWeight: 700,
  },
  typography: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: "159%",
    marginBottom: 14,
  },
  body2: {
    fontWeight: 500,
    marginBottom: 20,
    lineHeight: 1.1,
  },
  label: {
    lineHeight: 1.1,
    fontWeight: 500,
    marginBottom: 8,
  },
  inputsWrapper: {
    marginBottom: 40,
  },
  addNewRoles: {
    marginBottom: 20,
  },
  decoration: {
    height: 38,
    background: "#E0E7EC",
    marginBottom: 20,
  },
  yellowButton: {
    width: 182,
    height: 48,
    marginTop: 20,
  },
  IconButton: {
    padding: "0 16px",
    "&:hover": {
      background: "transparent",
    },
  },
  delete1: {
    marginLeft: 480,
    marginTop: -40,
    marginBottom: 30,
  },
  delete: {
    marginLeft: 480,
    marginTop: -10,
    marginBottom: -20,
  },
  textButton: {
    fontWeight: 700,
    marginBottom: 25,
    "& .MuiButton-startIcon": {
      marginRight: 3,
    },
  },
  textButton1: {
    fontWeight: 700,
    marginBottom: 25,
    // backgroundColor:"",
    background: "#123B64",
    width: 200,
    height: 40,
    borderRadius: 10,
    color: "#ffff",
    "&:hover": {
      background: "#123b64",
    },
  },
});

export const getRoleOptions = () => {
  return SearchService.fetchRoleOptions();
};

export const getRegisteredUsers = () => {
  return SearchService.fetchRegistredUsers();
};

export const getBusinessOptions = () => {
  return TeamService.fetchBusiness();
};

export const getDataDomainOptions = () => {
  return TeamService.fetchDataDomain();
};

export const getGeographyOptions = () => {
  return TeamService.fetchGeography();
};

export const getProductOptions = () => {
  return TeamService.fetchProducts();
};
