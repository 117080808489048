import { Container, makeStyles, Typography, Box } from '@material-ui/core';
import React from 'react';
import Breadcrumbs from '../../components/shared/breadcrumb/Breadcrumbs';
import NodeViewerAccardion from '../../components/node-viwer/node-viewer-accardion/NodeViewerAccardion';
import { Link } from 'react-router-dom';
import { OutlinedButton } from '../../components/shared/buttons/custom-buttons/CustomButtons';

// Custom styles defined using Material-UI's makeStyles
const useStyles = makeStyles({
    privacyPolicy: {
        minHeight: 'calc(100vh - 150px)',
        padding: '40px 0 100px'
    },
    title: {
        fontSize: 40,
        fontWeight: 900,
        color: '#123b64',
        lineHeight: '120%',
        margin: '50px 0 20px'
    },
    lastUpdated: {
        color: '#3B5C7D',
        fontSize: 14,
        marginBottom: 40
    },
    accordionwrapper:{
        marginBottom: 40
    },
    accordionContent: {
        color: "#123b64",
        marginTop: 23
    }
})

// Functional component for Security Policy page
const SecurityPolicy = () => {
    const classes = useStyles() // Applying styles defined above
    return (
        <Container className={classes.privacyPolicy}>
            <Breadcrumbs /> {/* Component for rendering breadcrumbs */}
             {/* Link to navigate back */}
            <Link
            style={{ textDecoration: "none", color: "#123B64" }}
            to="/cart/checkout-details"
          >
        <OutlinedButton style={{ 
    width: 219,
    "& span": {
      fontWeight: 700,
    },marginLeft:1060,marginBottom:-50, // Inline styling for button position
  }} disableRipple>
            Back 
        </OutlinedButton> </Link> {/* Title for the page */}
            <Typography className={classes.title}>Security Policy</Typography> {/* Last updated information */}
            <Typography variant='body2' className={classes.lastUpdated}>Last updated: June 12th, 2023</Typography>
            {/* Accordion components to display sections */}
            <Box className={classes.accordionwrapper}>
                 {/* Accordion for Introduction section */}
                <NodeViewerAccardion accardionTitle='1.Introduction'>
                    {/* Content for Introduction section */}
                    <Typography className={classes.accordionContent}>
                    This Security Policy is meant to help you understand what information 
                    we collect, why we collect it, and how you can update, manage, export, and delete your ...
                    </Typography>
                </NodeViewerAccardion>
            </Box>
            <Box className={classes.accordionwrapper}>
                <NodeViewerAccardion accardionTitle='2.What information do you collect?'>
                    <Typography className={classes.accordionContent}>
                    Datasouk enables users to download the list of websites filtered by the technology. Currently, 
                    it is a new tool and provides several benefits.
                    </Typography>
                </NodeViewerAccardion>
            </Box>
            <Box className={classes.accordionwrapper}>
                <NodeViewerAccardion accardionTitle='3.Security Policy'>
                    <Typography className={classes.accordionContent}>
                    We maintain reasonable physical, electronic and procedural safeguards to protect your information.
                     Whenever you access your account information, we offer the use of a secure server. 
                     Once your information is in our possession, we adhere to our security guidelines to protect it against unauthorized access.
                      However, by using the Platform, the users accept the inherent security implications of data transmission over the internet and the World Wide Web which cannot always be guaranteed as completely secure, and therefore, there would always remain certain
                       inherent risks regarding use of the Platform.
                     Users are responsible for ensuring the protection of login and password records for their account. 
                    </Typography>
                </NodeViewerAccardion>
            </Box>
            <Box className={classes.accordionwrapper}>
                <NodeViewerAccardion accardionTitle='4.What you can Access?'>
                    <Typography className={classes.accordionContent}>
                    You can access your information, including your name, address, payment options, profile information,
                     Prime membership, household settings, and purchase history in the "Your Account" section of the website or mobile application.
                      Click here for a list of examples that you can access.
                     To request access to personal information that is not available through Your Account you can submit a request here.
                    </Typography>
                </NodeViewerAccardion>
            </Box>
        {/* Additional accordion components for other sections with similar structure */}
         </Container>
    );
};

export default SecurityPolicy;