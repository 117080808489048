import NodeRegistration from "../../components/navbar/Dataproductpages/node-registration/NodeRegistration";

function DatasetRegistration() {
  const details = {
    header: "Build your Dataset",
    title: "1. Register New Dataset",
    nodeType: 'Data Set'
  }

  return <NodeRegistration details={details} />
}

export default DatasetRegistration;
