import { Pagination } from "@material-ui/lab";
import SliderPlaceholder from '../../shared/placeholder/SliderPlaceholder';
import { NodeCardWrapper } from "./NodeCardWrapper";
import { Box } from "@material-ui/core"

const PaginationWrapper = (props) => {
    const {
        setSelectedNodes,
        selectedNodes,
        isNodeAvailable,
        setCardData,
        nodes,
        isListView,
        currentPage,
        paginate,
        totalPageNumber,
        isInprogress
    } = props

    return (
        <>
            {
                isNodeAvailable ?
                    <>
                        <NodeCardWrapper
                            setSelectedNodes={setSelectedNodes}
                            selectedNodes={selectedNodes}
                            showSaved={isListView}
                            nodes={nodes}
                            setCardData={setCardData}
                            isInprogress={isInprogress}
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }} my={4}>
                            {
                                totalPageNumber > 1 &&
                                <Pagination
                                    color="primary"
                                    shape="rounded"
                                    page={currentPage}
                                    onChange={(e, page) => paginate(page)}
                                    count={totalPageNumber}
                                    variant="text"
                                />
                            }
                        </Box>
                    </> :
                    <SliderPlaceholder error={true} text="No data found" />
            }
        </>
    )
}

export default PaginationWrapper