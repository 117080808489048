import Slider from '@material-ui/core/Slider';
import { makeStyles, withStyles } from '@material-ui/core/styles';


const CustomSlider = withStyles({
    root: {
        height: 4,
        padding: '4px 0'
    },
    thumb: {
        height: 12,
        width: 12,
        backgroundColor: '#f79f19',
        marginTop: -4,
        marginLeft: -6,
        '&:focus, &:hover, &:active': {
            boxShadow: 'inherit',
        },
    },
    valueLabel: {
        left: -9,
    },
    track: {
        height: 4,
        borderRadius: 2,
        color: '#f79f19'
    },
    rail: {
        height: 4,
        borderRadius: 2,
        opacity: 1
    },
})(Slider);

const useStyles = makeStyles({
    slider: {
        "& .MuiSlider-rail": {
            background: props => props.color
        }
    }
})

export default function CustomizedSlider(props) {
    const classes = useStyles(props)
    const { value, setFieldValue, name, id, valueLabelComponent, valueType } = props

    const onChange = (value) => {
        if(valueType) {
            setFieldValue(name, {name, valueType, value })
        } else {
            setFieldValue(name, value)
        }
    }

    
    return (
        <CustomSlider
            id={id}
            ValueLabelComponent={valueLabelComponent}
            valueLabelDisplay="auto"
            onChange={(event, newValue) => onChange(newValue)}
            value={value}
            name={name}
            className={classes.slider}
        />
    );
}

