import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "relative",
    width: "400px",
    height: "200px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    padding: "50px 0",
    boxShadow: "0px 4px 20px rgba(1, 25, 54, 0.1)",
    color: "#123b64",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  Context: {
    lineHeight: 2.5,
    fontstyle: "normal",
    fontWeight: 700,

    // padding: '30px 10px 0px 40px',
    color: "#123B64",
    marginLeft: -10,
    marginTop: -20,
  },

  doneBtn: {
    color: "#fff",
    background: "#F79F19",
    width: "182px",
    height: "48px",
    marginLeft: "-5px",
    borderRadius: 12,
    fontWeight: 700,
    lineHeight: "150%",
    textTransform: "capitalize",
    position: "absolute",
    marginTop: 35,
    "&.MuiButton-root:hover": {
      backgroundColor: "#F79F19",
    },
  },
}));

function Messagesent({ handleClose }) {
  const classes = useStyles();

  const [show2, setShow2] = useState(false);

  return (
    <div className={classes.paper}>
      <div className={classes.Context}>Message sent successfully!! </div>
      <Button disableRipple onClick={handleClose} className={classes.doneBtn}>
        Close
      </Button>
    </div>
  );
}

export default Messagesent;
