import MuiTableCell from "@material-ui/core/TableCell";
import { withStyles, makeStyles, Box } from "@material-ui/core";
import {
  SecondaryButton,
  SuccessButton,
} from "../../../../../shared/buttons/custom-buttons/CustomButtons";
import CustomizedSelect from "../../../../../shared/inputs/CustomizedSelect";
import { useState } from "react";
import { StyledChildTableRow } from "../../../../../shared/table/semantics-table/table-details/TableDetails";
import { useEffect } from "react";

const useStyles = makeStyles({
  selectWrapper: {
    width: 120,
    marginLeft: "auto",
    "& .MuiSelect-root": {
      padding: "5.5px 16px",
    },
  },
});
const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const options = [
  { label: "Accepted", value: "Accepted" },
  { label: "Rejected", value: "Rejected" },
];

const setSuitableData = (page, status, confidenceScore) => {
  if (page === "classification") {
    return confidenceScore;
  } else {
    return (
      <>
        {status === "accepted" ? (
          <SuccessButton>Accepted</SuccessButton>
        ) : status === "Rejected" ? (
          <SecondaryButton>Rejected</SecondaryButton>
        ) : null}
      </>
    );
  }
};
// classification
const CustomRow = ({
  SuggestedName,
  columnName,
  updateStatus,
  index,
 
  page,
  suggestedNameIndex,
  keyProp,
  //keyDes,
}) => {
  const classes = useStyles();
  const [action, setAction] = useState(
    SuggestedName.Status ? SuggestedName.Status : ""
  );

  useEffect(() => {
    if (SuggestedName.Status) {
      if (
        SuggestedName.Status === "Accepted" ||
        SuggestedName.Status === "accepted"
      ) {
        setAction("Accepted");
      } else if (
        SuggestedName.Status === "Rejected" ||
        SuggestedName.Status === "rejected"
      ) {
        setAction("Rejected");
      } else {
        setAction("");
      }
    }
  }, [SuggestedName]);

  const handleChange = (e, recName, confidenceScore) => {
    setAction(e.target.value);
    let collapse = true;
    updateStatus(
      columnName,
      recName,
      e.target.value,
      index,
      confidenceScore,
      suggestedNameIndex,
      collapse,
      keyProp
    );
    
  };
  return (
    <StyledChildTableRow>
      <TableCell
        style={page !== "classification" ? { width: 320 } : { width: 250 }}
      />
      <TableCell
        style={page !== "classification" ? { width: 260 } : { width: 210 }}
        component="th"
        scope="row"
      >
        {page !== "classification" ? SuggestedName.name : null}
      </TableCell>
      <TableCell
        style={page !== "classification" ? { width: 195 } : { width: 210 }}
      >
        {page !== "classification"
          ? SuggestedName.confidence_score
          : SuggestedName.name}
      </TableCell>
      <TableCell
        style={page !== "classification" ? { width: 200 } : { width: 170 }}
      >
        {setSuitableData(
          page,
          SuggestedName.Status,
          SuggestedName.confidence_score
        )}
      </TableCell>
      {page === "classification" ? (
        <TableCell style={{ width: 142 }}>
          {SuggestedName.Status === "Accepted" ? (
            <SuccessButton>Accepted</SuccessButton>
          ) : SuggestedName.Status === "Rejected" ? (
            <SecondaryButton>Rejected</SecondaryButton>
          ) : null}
        </TableCell>
      ) : null}
      <TableCell>
        <Box className={classes.selectWrapper}>
          {SuggestedName.confidence_score != 0 ? (
            <CustomizedSelect
              id="action"
              name="action"
              value={action}
              options={options}
              defaultValue="Action"
              handleChange={(e) =>
                handleChange(
                  e,
                  SuggestedName.name,
                  SuggestedName.confidence_score
                )
              }
            />
          ) : null}
        </Box>
      </TableCell>
    </StyledChildTableRow>
  );
};

export default CustomRow;
