import { Box, IconButton, Typography, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import DownloadButton from '../../../shared/buttons/upload-button/DownloadButton';
import UploadButton from '../../../shared/buttons/upload-button/UploadButton';

// Styles for the component using makeStyles hook from Material-UI
const useStyles = makeStyles(theme => ({
    // Styling for the root/container element of the component
    root: {
        width: 500,
        background: '#fff',
        boxShadow: '0px 4px 20px rgba(1, 25, 54, 0.1)',
        borderRadius: 4,
        color: '#123b64',
        [theme.breakpoints.down('578')]: {
            width: 328
        }
    },
    // Styling for the title wrapper
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 30px',
        borderBottom: '1px solid #E0E7EC',
        [theme.breakpoints.down('578')]: {
            padding: '18px 15px'
        }
    },
    // Styling for the title
    title: {
        fontWeight: 700,
        lineHeight: '24px'
    },
    // Styling for the main content box
    box: {
        padding: '30px 40px 40px',
        [theme.breakpoints.down('578')]: {
            padding: '25px 15px 30px'
        }
    },
    // Styling for typography elements
    typography: {
        fontWeight: 500,
        lineHeight: 1.1,
        marginBottom: 30,
        [theme.breakpoints.down('578')]: {
            marginBottom: 10
        }
    },
    // Additional styling for a specific typography element
    typography2: {
        fontWeight: 500,
        lineHeight: '21px',
        marginBottom: 16
    },
    // Styling for the button wrapper
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('578')]: {
            flexDirection: 'column',
        }
    },
    // Styling for the Excel download/upload button
    exelButton: {
        width: '100%',
        marginRight: 10,
        [theme.breakpoints.down('578')]: {
            marginBottom: 20,
            marginRight: 0
        },
        '& label': {
            width: '100%'
        }
    },
    // Styling for the CSV download/upload button
    cvsButton: {
        width: '100%',
        marginLeft: 10,
        '& label': {
            width: '100%'
        },
        [theme.breakpoints.down('578')]: {
            marginLeft: 0
        }
    },
    // Styling for the generic button element
    button: {
        width: 200,
        height: 152,
        padding: 28,
        border: 'none',
        color: '#FFF',
        borderRadius: 10,
        cursor: 'pointer',
        "& .MuiButton-label": {
            display: "flex",
            justifyContent: 'center',
            flexDirection: "column"
        },
        [theme.breakpoints.down('578')]: {
            width: '100%'
        }
    },
    // Styling for the icon wrapper
    iconWrapper: {
        marginBottom: 18
    },
}))

// Functional component DataExport
const DataExport = ({ setOpen, type, downloadFile, uploadFile }) => {
    const classes = useStyles(); // Applying styles using the useStyles hook

    // Render component JSX
    return (
        <div className={classes.root}>
            {/* Title section with close button */}
            <Box className={classes.titleWrapper}>
                <Typography className={classes.title}>Data export</Typography>
                <IconButton onClick={() => setOpen(false)} style={{ padding: 0 }} disableRipple>
                    <CloseIcon />
                </IconButton>
            </Box>
            {/* Main content section */}
            <Box className={classes.box}>
                {/* Description */}
                <Typography className={classes.typography} variant='body2'>
                    Select the file format to export the data
                </Typography>
                <Typography className={classes.typography} variant='body2'>Choose format:</Typography>
                <Box>
                    {/* Conditional rendering based on 'type' prop */}
                    {type === "import" ?
                        // Render download buttons for import type
                        <Box className={classes.buttonWrapper}>
                            <Box className={classes.exelButton}>
                                <DownloadButton onClick={downloadFile} name="EXEL" />
                            </Box>
                            <Box className={classes.cvsButton}>
                                <DownloadButton onClick={downloadFile} name="CVS" />
                            </Box>
                        </Box> :
                        // Render upload buttons for other types
                        <Box className={classes.buttonWrapper}>
                            <Box className={classes.exelButton}>
                                <UploadButton onChange={uploadFile} name="EXEL">
                                    <Button className={classes.button} color="secondary" variant="contained" component="span">
                                        <Box className={classes.iconWrapper}>
                                            {/* SVG icon for Excel */}
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.6 16.0001L21.3333 21.3334H18.1333L16 18.2854L13.8667 21.3334H10.6667L14.4 16.0001L10.6667 10.6667H13.8667L16 13.7147L18.1333 10.6667H20V5.33341H6.66667V26.6667H25.3333V10.6667H21.3333L17.6 16.0001ZM4 3.98941C4 3.25875 4.596 2.66675 5.332 2.66675H21.3333L28 9.33342V27.9907C28.0012 28.1658 27.968 28.3395 27.9021 28.5017C27.8362 28.6639 27.739 28.8116 27.6161 28.9363C27.4931 29.061 27.3468 29.1602 27.1855 29.2284C27.0242 29.2965 26.8511 29.3322 26.676 29.3334H5.324C4.97384 29.331 4.63869 29.1909 4.39096 28.9434C4.14322 28.6959 4.00279 28.3609 4 28.0107V3.98941Z" fill="white" />
                                            </svg>
                                        </Box>
                                        <Typography className={classes.typography}>EXEL (.xls)</Typography>
                                    </Button>
                                </UploadButton>
                            </Box>
                            <Box className={classes.cvsButton}>
                                <UploadButton onChange={uploadFile} name="CVS">
                                    <Button className={classes.button} color="secondary" variant="contained" component="span">
                                        <Box className={classes.iconWrapper}>
                                            {/* SVG icon for CSV */}
                                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M17.6 16.0001L21.3333 21.3334H18.1333L16 18.2854L13.8667 21.3334H10.6667L14.4 16.0001L10.6667 10.6667H13.8667L16 13.7147L18.1333 10.6667H20V5.33341H6.66667V26.6667H25.3333V10.6667H21.3333L17.6 16.0001ZM4 3.98941C4 3.25875 4.596 2.66675 5.332 2.66675H21.3333L28 9.33342V27.9907C28.0012 28.1658 27.968 28.3395 27.9021 28.5017C27.8362 28.6639 27.739 28.8116 27.6161 28.9363C27.4931 29.061 27.3468 29.1602 27.1855 29.2284C27.0242 29.2965 26.8511 29.3322 26.676 29.3334H5.324C4.97384 29.331 4.63869 29.1909 4.39096 28.9434C4.14322 28.6959 4.00279 28.3609 4 28.0107V3.98941Z" fill="white" />
                                            </svg>
                                        </Box>
                                        <Typography className={classes.typography}>CVS (.xls)</Typography>
                                    </Button>
                                </UploadButton>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
        </div>
    );
}
export default DataExport;