import React from "react";
import FilterButton from "./../../filter-region/FilterButton";
import "./meta-data-quality.scss";

const MetaDataQuality = ({ metaDataStyle, metaDataQuality }) => {
  const metadataColor = () => {
    if (metaDataQuality < 50) {
      return "red-chevron";
    } else if (metaDataQuality >= 50 && metaDataQuality < 80) {
      return "yellow-chevron";
    } else {
      return "green-chevron";
    }
  };

  return (
    <div
      className={`meta-data-quality-wrapper d-flex justify-content-center align-items-baseline ${metaDataStyle}`}
    >
      <FilterButton popperName="Metadata quality">
                <div className="chevron d-flex align-items-center">
                    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={metadataColor()}  d="M5.5 9.00195L1.5 5.00195L5.5 1.00195" stroke="#F79F19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <span className="meta-data-percentage secondary-100">{metaDataQuality}%</span>
                    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path className={metadataColor()} d="M1.5 9.00195L5.5 5.00195L1.5 1.00195" stroke="#F79F19" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </div>
            </FilterButton>
    </div>
  );
};

export default MetaDataQuality;
