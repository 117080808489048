import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/styles';

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#FFF",
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: '0px 2px 10px rgba(1, 25, 54, 0.11)',
        fontSize: 12,
        borderRadius: 8,
        textAlign: 'center',
        padding: "4px 8px 0 8px",
        height: 26,
        minWidth: 40,
        margin: '12px 0'
    },
    arrow: {
        color: '#fff',
    },
  }))(Tooltip);

  export const normalLabelComponent = (props) => {
    const { children, open, value } = props;

    return (
        <LightTooltip arrow open={open} enterTouchDelay={0} placement="top" title={`${value}%`}>
            {children}
        </LightTooltip>
    );
  }