import { makeStyles } from '@material-ui/core'
import loader from '../../../assets/images/loader/loader.gif'

const useStyles = makeStyles({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: "calc(100vh - 150px)"
  }
})

const Loader = (props) => {
  const classes = useStyles(props)

  return (
    <div className={classes.loader}>
      <img src={loader} alt="loader" />
    </div>
  );
};

export default Loader;
