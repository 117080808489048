import React from 'react';
import { makeStyles, Grid } from '@material-ui/core'
import CollectionCards from './cards/CollectionCards';

const useStyles = makeStyles(theme => ({
    root: {
        margin: '0 0 44px -16px',
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'center',
            marginLeft: 0,
            marginBottom: 20
        }
    }
}))

const CollectionCardWrapper = ({ collectionNodes, showShortCollectionCard, currentPage }) => {
    const classes = useStyles()
    return (
        <Grid container spacing={2} className={classes.root}>
            {
                collectionNodes && collectionNodes.map((collectionNode, index) => (
                    <Grid md={3} item key={index}>
                        <CollectionCards
                            showShortCollectionCard={showShortCollectionCard}
                            data={collectionNode}
                            currentPage={currentPage}
                        />
                    </Grid>
                ))
            }
        </Grid>
    );
}

export default CollectionCardWrapper;