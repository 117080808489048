import { Box, makeStyles } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';
import { NodeCreationService } from '../../../services/NodeCreationService';
import { NODE_TYPE } from '../../../pages/node-creating/nodeCreation';
import Loader from '../../shared/loader/Loader';
import { SearchResultsNodeList } from '../../filter-region/node-list/SearchResultsNodeList';

const DEFAULT_PAYLOAD = {
    key: "",
    pagination: {
        pageNumber: 1,
        pageSize: 8
    }
}

const useStyles = makeStyles({
    ProgressCardBox: {
        width: '100%',
        display: "flex",
        flexWrap: 'wrap',
        marginTop: 45,
        marginBottom: 68,
    },
    nodeBox: {
        margin: 16,
        maxWidth: 580,
        width: '100%'
    }
})

function InProgressNodes({ newNodeType }) {
    const classes = useStyles()
    const [inProgressNodes, setInProgressNodes] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [payload, setPayload] = useState({})
    const [totalPageNumber, setTotalPageNumber] = useState(1)
    const scrollRef = useRef(null)
    const { dataProduct, dataSet } = NODE_TYPE

    useEffect(() => {
        DEFAULT_PAYLOAD.key = newNodeType === dataProduct ? dataProduct : dataSet
        setPayload(DEFAULT_PAYLOAD)
    },[newNodeType])

    useEffect(() => {
        if (Object.keys(payload).length) {
            setIsLoading(true)
            NodeCreationService.getInprogressNodes(DEFAULT_PAYLOAD)
                .then(res => {
                    console.log("res: ", res.data);
                    setInProgressNodes(res.data.data)
                    setTotalPageNumber(res.data.paging.totalNumberOfPages)
                    setIsLoading(false)
                }).catch(() => setIsLoading(false))
        }

    }, [payload])

    console.log(inProgressNodes);

    if (isLoading) {
        return <Loader />
    }

    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
        setPayload({ ...payload, pagination: { ...payload.pagination, pageNumber} })
    }

    return (
        <Box ref={scrollRef} className={classes.ProgressCardBox}>
            {
                <SearchResultsNodeList
                    isInprogress={true}
                    nodes={inProgressNodes}
                    onPageChange={onPageChange}
                    totalPageNumber={totalPageNumber}
                    ref={scrollRef}
                />
            }
        </Box>
    );
}

export default InProgressNodes;