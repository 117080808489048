import { Box, Container, Grid, IconButton, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import TabContext from "../../contexts/TabContext";
import { useStyles } from "./details"
import { TeamService } from "../../services/TeamService";
import SectionAddRoles from "../../components/add-section/section-roles/SectionAddRoles";
import Breadcrumbs from "../../components/shared/breadcrumb/Breadcrumbs";
import { NodeCreationService } from "../../services/NodeCreationService";
import { OutlinedButton, PrimaryButton } from "../../components/shared/buttons/custom-buttons/CustomButtons";
import { EyeIcon, FilterIcon } from "../../components/icons";
import { NEW_NODE_TYPES, nodeDetailTab } from "../../constants/tab";
import CollectionSidebar from "../digital-publication/CollectionSidebar";
import CustomDrawer from "../../components/shared/drawer/CustomDrawer";

function ResponsibilityModel() {
  const classes = useStyles();
  const { addTabHandler } = useContext(TabContext);
  const [isTeamAvailable, setIsTeamAvailable] = useState(true)
  const [isTeamLoading, setIsTeamLoading] = useState(false)
  const [teamOptions, setTeamOptions] = useState([]);
  const [teamId, setTeamId] = useState("");
  const [teamType, setTeamType] = useState("");
  const [editContextData, setEditContextData] = useState({});
  const [contextName, setContextName] = useState("");
  const [team, setTeam] = useState([]);
  const [teamRoles, setTeamRoles] = useState(null);
  const [newTeam, setNewTeam] = useState("");
  const [teamName, setTeamName] = useState("");
  const { key, nodeName, selectedNodeName } = useParams();
  const [openSidebar, setOpenSidebar] = useState(false);
  const navigate = useNavigate();

  const deleteTeam = () => {
    TeamService.delete(teamId).then((res) => {
      setTeamName("");
      if (teamType === "matrix") {
        setTeam([]);
      }
    });
  };

  const handleEditTeam = () => {
    TeamService.edit(teamId).then((res) => {
      if (res.data && res.data.length > 0) {
        setTeamRoles(res.data);
      }
    });
  };

  const getUpdatedSimpleData = (data) => {
    let simpleTeam = { teamId: teamId };
    let obj = {};
    let roles = data.roles;
    roles.forEach((item) => {
      obj[item.role] = item.users;
    });
    simpleTeam.roles = obj;
    setTeam(simpleTeam);
    if (!teamName) {
      setTeamName(newTeam);
    }
  };

  const deleteContext = (context) => {
    TeamService.deleteContext(context, teamId).then((res) => {
      let filteredTeam = team.filter((item) => item.context !== context);
      if (team.length === 1) {
        setNewTeam("");
      }
      setTeam(filteredTeam);
    });
  };

  const getNewContext = (data) => {
    let max = 0;
    let roles = {};
    let copyMatrixTeam = [...team];
    if (copyMatrixTeam.length > 0) {
      let contexts = copyMatrixTeam.map((item) => item.context);
      contexts.forEach((item) => {
        let num = +item[item.length - 1];
        max = Math.max(max, num);
      });
      data.context = `Context${max + 1}`;
    } else {
      data.context = "Context1";
    }
    data.products = data.product;
    delete data.product;
    data.roles.forEach((item) => {
      roles[item.role] = item.users;
    });
    let arr = [roles];
    data.roles = arr;
    copyMatrixTeam.push(data);
    setTeam(copyMatrixTeam);
    if ((!teamName || teamName !== newTeam) && newTeam) {
      setTeamName(newTeam);
    }
  };

  const handleEditContext = (context) => {
    TeamService.editContext(context, teamId).then((res) => {
      let copyRoles = res.data.roles;
      setContextName(res.data.context);
      let obj = {};
      copyRoles = copyRoles.map((item) => {
        let keys = Object.keys(item);
        let values = Object.values(item);
        return {
          role: keys[0],
          users: values[0],
          errors: {
            role: null,
            users: null,
          },
        };
      });
      obj.dataDomain = res.data["Data Domain"];
      obj.business = res.data.Business;
      obj.geography = res.data.Geography;
      obj.product = res.data.Products;
      obj.errors = {
        business: null,
        dataDomain: null,
        geography: null,
        product: null,
      };
      obj.roles = copyRoles;
      setEditContextData(obj);
    });
  };

  const getTeamOptions = () => {
    TeamService.fetchTeamOptions().then((res) => {
      let copyTeamOptions = [...res.data];
      copyTeamOptions = copyTeamOptions.map((item) => {
        return {
          value: item._key,
          label: item.displayName,
          type: item.teamStructure,
        };
      });
      setTeamOptions(copyTeamOptions);
    });
  };

  const updateContext = (context) => {
    let copyMatrixTeam = [...team];
    let contextName = context.context[0].contextName;
    let copyProducts = context.context[0].product;
    let newRoles = {};
    delete context.teamId;
    delete context.context[0].product;
    let updatedContext = context.context[0];
    updatedContext.teamId = teamId;
    updatedContext.context = contextName;
    updatedContext.products = copyProducts;
    updatedContext.roles.forEach((item) => {
      newRoles[item.role] = item.users;
    });
    let arr = [newRoles];
    updatedContext.roles = arr;
    copyMatrixTeam = copyMatrixTeam.map((item) => {
      return item.context === updatedContext.context ? updatedContext : item;
    });
    setTeam(copyMatrixTeam);
  };

  useEffect(() => {
    if (teamOptions.length) {
      NodeCreationService.getDataProductTeamDetails(key)
        .then(res => {
          if (res.data.length) {
            const teamID = res.data[0].teamId.slice(6)
            let selectedTeam = teamOptions.find(option => option.value === teamID);
            setTeamName(selectedTeam.label)
          }
        })
    }

  }, [key, teamOptions])

  useEffect(() => {
    if (teamName) {
      let selectedTeam = teamOptions.find(option => option.label === teamName)
      const { type: teamType, value: teamID } = selectedTeam;
      setTeamType(teamType);
      setTeamId(teamID);

      if (teamType === "matrix") {
        TeamService.fetchMatrixTeam(teamID)
          .then((res) => {
            setTeam(res.data);
            setTeamRoles([]);
            setIsTeamLoading(false)
          })
          .catch(() => setIsTeamLoading(false))
      }

      if (teamType === "simple") {
        TeamService.fetchSimpleTeam(selectedTeam.value)
          .then((res) => {
            setTeam(res.data);
            setTeamRoles([]);
            setIsTeamLoading(false)
          })
          .catch(() => setIsTeamLoading(false))
      }
    } else {
      getTeamOptions();
    }
  }, [teamName]);

  const handlePreviewNode = () => {
    if (teamName) {
      const newNode = true
      const newTabDetail = nodeDetailTab(key, nodeName, newNode, NEW_NODE_TYPES.dataSet, selectedNodeName)
      addTabHandler(newTabDetail);
      const paylaod = [{ name: teamName, roleName: 'team', teamId }]
      NodeCreationService.addTeamToNode(key, paylaod)
        .then(() => {
          setIsTeamAvailable(true)
          navigate({
            pathname: "/tabs",
            search: `?selectedNodeName=${selectedNodeName}&nodeName=${nodeName}&nodeType=dataset&key=${key}`,
          });
        })
    } else {
      setIsTeamAvailable(false)
    }
  }

  return (
    <Container className={classes.container}>
      <Breadcrumbs />
      <Typography
        style={{ marginBottom: 24 }}
        align="center"
        variant="h1"
        color="primary"
      >
        {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
      </Typography>
      <Box className={classes.wrapper} mb={5}>
        <Box className={classes.topSideWrapper}>
          <Typography variant="h5" color="primary">
            2.3 Curate / Responsibilities
          </Typography>
          <CustomDrawer state={openSidebar} setState={setOpenSidebar} anchor="left">
        <CollectionSidebar setOpenSidebar={setOpenSidebar} />
      </CustomDrawer>
          <IconButton onClick={() => setOpenSidebar(true)} style={{ padding: 0 }}>
            <FilterIcon />
          </IconButton>
        </Box>
        <Box p={5}>
          <Box sx={{ maxWidth: 716 }}>
            <SectionAddRoles
              setTeamName={setTeamName}
              isTeamAvailable={isTeamAvailable}
              teamName={teamName}
              teamOptions={teamOptions}
              teamType={teamType}
              teamId={teamId}
              getUpdatedSimpleData={getUpdatedSimpleData}
              deleteTeam={deleteTeam}
              deleteContext={deleteContext}
              handleEditTeam={handleEditTeam}
              team={team}
              getTeamOptions={getTeamOptions}
              getNewContext={getNewContext}
              handleEditContext={handleEditContext}
              editContextData={editContextData}
              contextName={contextName}
              updateContext={updateContext}
              teamRoles={teamRoles}
              setNewTeam={setNewTeam}
              isTeamLoading={isTeamLoading}
              setIsTeamLoading={setIsTeamLoading}
            />
          </Box>
        </Box>
      </Box>
      <Box mb={12.5} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Link
          style={{ textDecoration: "none", color: "#123B64" }}
          to={`/apiform/${nodeName}/${key}`}
        >
          <OutlinedButton className={classes.button} disableRipple>
            Back
          </OutlinedButton>
        </Link>
        <Box>
          {/* <OutlinedButton className={classes.saveButton} disableRipple>
            Save
          </OutlinedButton> */}
          <Link
          style={{ textDecoration: "none", color: "#123B64" }}
          to={`/servicemodel/${nodeName}/${key}`}
        >
          <OutlinedButton className={classes.button} disableRipple>
            Next
          </OutlinedButton>
        </Link>
        </Box>
      </Box>
    </Container>
  );
}

export default ResponsibilityModel;
