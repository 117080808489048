import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import {
  Box,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import CustomizedSelect from "../../components/shared/inputs/CustomizedSelect";
import CustomizedRadio from "../../components/shared/inputs/CustomizedRadio";
import Breadcrumbs from "../../components/shared/breadcrumb/Breadcrumbs";
import { NodeCreationService } from "../../services/NodeCreationService";
import { getTransformedData } from "../../components/navbar/Dataproductpages/curate-security/curateSecurty";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {
  initialValues,
  validationSchema,
  FREQUENCY,
  ENCRYPTION,
  OFFERED_AS,
  TYPE,
  useStyles,
} from "./dataentryDetails";
import { BackBtn } from "../../components/shared/buttons/custom-buttons/CustomButtons";
import TextAreaInputVendor from "../../components/shared/inputs/TextAreaInputVendor";

function DataEntryForm() {
  const classes = useStyles();
  const { key, nodeName } = useParams();
  const [formValues, setFormValues] = useState(initialValues);
  const [offeredOptions, setOfferedOptions] = useState([]);
  const [FreqOptions, setFreqOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    NodeCreationService.fetchSelectOptions("Type").then((res) => {
      const transformedData = getTransformedData(res.data[0]);
      setTypeOptions(transformedData);
    });

    NodeCreationService.fetchSelectOptions("OfferedAs").then((res) => {
      const transformedData = getTransformedData(res.data[0]);
      setOfferedOptions(transformedData);
    });

    NodeCreationService.fetchSelectOptions("RefreshFrequency").then((res) => {
      const transformedData = getTransformedData(res.data[0]);
      setFreqOptions(transformedData);
    });

    NodeCreationService.fetchDataEntryFormData(key).then((res) => {
      if (Object.keys(res.data).length) {
        const values = {
          [ENCRYPTION]: res.data.encryption,
          [TYPE]: res.data.type,
          [FREQUENCY]: res.data.frequency,
          [OFFERED_AS]: res.data.offeredAs,
        };
        setFormValues(values);
      }
    });
  }, [key]);

  const onSubmit = (values) => {
    const payload = [];
    Object.keys(values).forEach((item) => {
      let payloadItem = {};
      payloadItem.attributeName = item;
      payloadItem.attributeValue = values[item];
      payload.push(payloadItem);
    });

    NodeCreationService.createAttributes(payload, key)
      .then(() => {
        navigate(`/data_set_source/${nodeName}/${key}`);
      })
      .catch(() => {
        console.log("error is occured in api");
      });
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  const setBackPage = () => {
   
    navigate(-1)
  };

  const { handleSubmit, handleChange, values } = formik;

  return (
    <Container className={classes.root}>
  <Box mb={2.5} style={{ textDecoration: 'none', color: '#123b64' }}>
  <IconButton
    onClick={setBackPage}
    className={classes.icon}
    style={{ color: '#63ADA6' }}
  >
    <ArrowBackIosIcon style={{ fontSize: '13px' }} />
  </IconButton>

  <Link to="/home" style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
    Home
  </Link>
  {' / '}
  <Link   to={`/dataset-registration/${key}`} style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
  Datasetregistration
  </Link>
  {' / '}
  <Link   to={`/dataentryform/${nodeName}/${key}`} style={{ textDecoration: 'none',fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>
  dataentryform
  </Link>
  {' / '}
  <span style={{ fontSize: '13px', color: '#63ADA6', fontWeight: 'bold' }}>{nodeName}</span>
</Box>
      <Typography
        variant="h1"
        color="primary"
        className={classes.header}
        align="center"
      >
        {nodeName.charAt(0).toUpperCase() + nodeName.slice(1)}
      </Typography>
      <Box className={classes.paper}>
        <Box className={classes.paperTitle} px={5} py={2.5}>
          <Typography variant="h5" color="primary">
            2.Data Entry Form
          </Typography>
        </Box>
        <Box className={classes.paperBody} p={5}>
          <form
            className={classes.form}
            id="dataentry-form"
            onSubmit={handleSubmit}
          >
            <Typography variant="h6" color="primary" className={classes.text}>
              Encryption
            </Typography>
            <Box pb={1.75}>
              <FormControl component="fieldset">
                <RadioGroup
                  className={classes.radioWrapper}
                  value={values[ENCRYPTION]}
                  name={ENCRYPTION}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="internal"
                    control={<CustomizedRadio />}
                    label="Internal"
                  />
                  <FormControlLabel
                    value="external"
                    control={<CustomizedRadio />}
                    label="External"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box mb={2.5}>
              <CustomizedSelect
                id="type"
                label={TYPE}
                options={typeOptions}
                name={TYPE}
                value={values[TYPE]}
                handleChange={handleChange}
                defaultValue="Select from the list"
                formik={formik}
              />
            </Box>
            <Box mb={2.5}>
              <CustomizedSelect
                id="dataBackupFreq"
                label={FREQUENCY}
                options={FreqOptions}
                name={FREQUENCY}
                value={values[FREQUENCY]}
                handleChange={handleChange}
                defaultValue="Select from the list"
                formik={formik}
              />
            </Box>
            <Box>
              <CustomizedSelect
                id="accessRestriction"
                label={OFFERED_AS}
                options={offeredOptions}
                name={OFFERED_AS}
                value={values[OFFERED_AS]}
                handleChange={handleChange}
                defaultValue="Select from the list"
                formik={formik}
              />
            </Box>
            {values[ENCRYPTION] === "external" ? (
              <Box mb={2.5} style={{ marginTop: 10 }}>
                <TextAreaInputVendor
                  id="vendorname"
                  name="vendorname"
                  value={values.vendorname}
                  label="Vendor Name"
                  placeholderText="Enter text"
                  //onChange={handleChange}
                  formik={formik}
                />
              </Box>
            ) : null}
          </form>
        </Box>
      </Box>
      <Box className={classes.buttonsWrapper}>
        <Link
          to={`/dataset-registration/${key}`}
          style={{ textDecoration: "none" }}
        >
          <BackBtn>Back</BackBtn>
        </Link>
        <BackBtn form="dataentry-form" type="submit">
          Next
        </BackBtn>
      </Box>
    </Container>
  );
}

export default DataEntryForm;
