export const FilterAttributes = {
  nodeType: {
    type: "doc.type.name",
    condition: "in",
  },
  tags: {
    type: "doc._id",
    condition: "and",
  },
  // curated: {
  //   type: "doc.identifier",
  //   condition: "like",
  // },
  certified: {
    type: "doc.certified",
    condition: "in",
  },
  // frequency: {
  //   type: "doc.attributes[*].value",
  //   condition: "in",
  // },
  freshness: {
    type: "doc.updatedOn",
    condition: "in",
    
  },
  averageRating: {
    type: "doc.avgRating",
    condition: "eq",
    typeConverter: "TO_STRING",
  },
  status: {
    type: "doc.status",
    condition: "in",
  },
};
