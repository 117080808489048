import React, { useState, useContext } from "react";
import { List, ListItem, Box } from "@material-ui/core";
import ShareSnackBar from "../share/share-content/ShareSnackBar";
import ShareBtn from "../share/share-content/ShareBtn";
import Messanger from "../shared/messanger/Messanger";
import CustomModal from "../navbar/nav-dropdown-input/logopagedesign/logopages/popupformsoflogo/CustomModal";
import DataContext from "../../contexts/Context";
import Mail from "../shared/messanger/Mail";
import { useSearchParams } from "react-router-dom";

export default function ShareIcon({ nodeData, type, setOpenShare }) {
  let data = {
    url: "",
    shareType: "",
    users: [],
    shareHolder: "",
    shareHolderId: "",
    shareUsing: "",
    key:"",
    message: ''
  }
  const [initialValues, setInitialValues] = useState(data)
  const [mailModal, setOpenMailModal] = useState(false);
  const [openModal, setOpenModal] = useState(false)
  const [searchParams] = useSearchParams();
  const [url, setUrl] = useState('')
  //const { displayName, id, key } = nodeData
  const key = searchParams.get("key");
  const { loggedInUser } = useContext(DataContext)

  const handleSendMessage = (name) => {
    //const nodeName = displayName.replace("<b>", "")
    setInitialValues(prev => {
      return {
        ...prev,
        shareType: name,
        shareHolder: loggedInUser.email,
        shareHolderId: loggedInUser.userId,
        //shareUsing: Nodes,	
    key:key

      }
    })

    if (type) {
      const url = `http://${window.location.host}/my-collections`
      setInitialValues(prev => {
        return {
          ...prev,
          shareUsing: 'PinCollection',
          url: url,
          key:key
        }
      })
    } else {
      const url = `http://${window.location.host}/node-registration/1`
      setInitialValues(prev => {
        return {
          ...prev,
          shareUsing: 'Nodes',
          url: url,
          key:key
        }
      })
    }
    setOpenModal(true)
  }

  const Copy = () => {
    if (type === "collection") {
      const url = `http:/${window.location.host}/my-collections`;
      navigator.clipboard.writeText(url);
    } else {
      //const nodeTitle = displayName?.replace("<b>", "");
      const url = `http:/${window.location.host}/node-registration/1`;
      navigator.clipboard.writeText(url);
    }
  };

  const handleSendEmail = () => {
    //const nodeName = displayName.replace("<b>", "")
    let url
    if(type) {
      url = `http://${window.location.host}/my-collections`
    } 
    url = `http://${window.location.host}/node-registration/1`
    setUrl(url)
    setOpenMailModal(true)
  }

  return (
    <Box>
      <CustomModal setOpen={setOpenModal} open={openModal}>
        <Messanger
          setOpenShare={setOpenShare}
          initialValues={initialValues}
          setOpenModal={setOpenModal}
        />
      </CustomModal>
      <CustomModal setOpen={setOpenMailModal} open={mailModal}>
        <Mail setOpenMailModal={setOpenMailModal} url={url} />
      </CustomModal>
      <List>
        <ListItem>
            <ShareBtn handleClick={handleSendMessage} name="team" />
            <ShareBtn handleClick={handleSendMessage} name="slack" />
            <ShareBtn handleClick={handleSendEmail} name="email" />
            <ShareSnackBar Copy={Copy} />
        </ListItem>
      </List>
    </Box>
  );
}