import React from 'react';
import ShortCollectionCard from "../../../../../shared/cards/ShortCollectionCard";
import CollectionCard from "../../../../../shared/cards/CollectionCard";

const CollectionCards = ({ data, showShortCollectionCard, showOnlyPen, currentPage }) => {
    return (
        <>
            {
                showShortCollectionCard ? <ShortCollectionCard data={data} /> :
                    <CollectionCard currentPage={currentPage} data={data} />
            }
        </>
    );
}

export default CollectionCards;
