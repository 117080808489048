import { makeStyles } from "@material-ui/core"
import * as yup from 'yup'

export const initialValues = { name: '', valueType: '' }

export const validationSchema = yup.object({
    name: yup.string().required('Objective name is required'),
    valueType: yup.string().required('Scale value is required')
})

export const useStyles = makeStyles({
    root: {
        maxWidth: 568,
        width: '100%',
        height: 461,
        background: '#fff',
        boxShadow: '0px 4px 20px rgba(1, 25, 54, 0.1)',
        borderRadius: 4,
        color: '#123b64'
    },
    titleWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 30px',
        borderBottom: '1px solid #E0E7EC'
    },
    title: {
        fontWeight: 700,
        lineHeight: '24px'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxHeight: 395,
        height: '100%', 
        padding: 30 
    },
    yellowButton: {
        width: 182
    }
})