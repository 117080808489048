import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import teams from "../../../assets/images/teams-icon.png";
import { SearchService } from "../../../services/SearchService";
import { CopyIcon, SendIcon, SlackIcon, SlackIconWithColor } from "../../icons";
import CustomModal from "../../navbar/nav-dropdown-input/logopagedesign/logopages/popupformsoflogo/CustomModal";
import Messagesent from "../../navbar/nav-dropdown-input/logopagedesign/logopages/popupformsoflogo/Teamspopup";
import { TextButton } from "../buttons/custom-buttons/CustomButtons";
import AutoComplate from "../inputs/AutoComplate";
import TextAreaInput from "../inputs/TextAreaInput";
import { useStyles, validationSchema } from "./MessangerDetails";
import CloseIcon from "@material-ui/icons/Close";

const Messanger = ({ initialValues, setOpenModal, setOpenShare }) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  const onSubmit = async (values) => {
    if (values.shareType === "Slack") {
      values.users.forEach((user) => {
        let data = {
          text: `Name: ${user.name} \n${values.message} \n${values.url}`,
        };
        return axios
          .post(
            //"https://hooks.slack.com/services/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",
            "https://hooks.slack.com/services/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",
            JSON.stringify(data)
          )
          .then(() => {
            setOpen(true);
          });
      });
      setOpen(true);
    } else {
      values?.users?.forEach(async (user) => {
        let data = {
          text: `Name: ${user.name} ${values.message} ${values.url}`,
        };
        await axios.post(
          process.env.REACT_APP_TEAMS_URL,
          JSON.stringify(data),
          {
            withCredentials: false,
            transformRequest: [
              (data, headers) => {
                delete headers.post["Content-Type"];
                return data;
              },
            ],
          }
        );
      });
      setOpen(true);
    }

    delete values.message;
    values.users = values?.users?.map((user) => user.id);
    SearchService.sendMessage(values);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { values, handleChange, setFieldValue, handleSubmit } = formik;
  const { url, shareType, message } = values;

  const handleMultiSelectChange = (event, value) => {
    setFieldValue("users", value);
  };

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get(`${apiUrl}/getAllregisterUserDetails`);
      const tranformedUsers = res?.data.map((user) => {
        return {
          id: user.id,
          name: user.FirstName,
        };
      });
      setOptions(tranformedUsers);
      setIsLoadingUsers(false);
    };
    fetchPosts();
  }, [apiUrl]);

  const copyUrl = () => {
    //navigator.clipboard.writeText(url ? url : 'some text')
    navigator.clipboard
    .writeText(url)
    .then(() => {
      //alert("successfully copied");
    })
    .catch(() => {
      alert("something went wrong");
    });
  }
  const [toggle, setToggle] = useState(true)
  const handleToggle = () => {
    setToggle(!toggle);
  };
  return (
    <div>
      <Box className={classes.root}>
        <CustomModal setOpen={setOpen} open={open}>
          <Messagesent
            handleClose={() => {
              setOpen(false);
              setOpenModal(false);
              setOpenShare(false);
            }}
          />
        </CustomModal>
        <form onSubmit={handleSubmit}>
          <Box className={classes.modalHeader}>
            <Box className={classes.avatarBox}>
              <IconButton
                onClick={() => setOpenModal(false)}
                className={classes.iconButton}
              >
                <CloseIcon />
              </IconButton>
              <List className={classes.list}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      {shareType === "Teams" ? (
                        <img src={teams} alt="teams" />
                      ) : (
                        <SlackIconWithColor />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.listItemText}
                    primary={shareType.toUpperCase()}
                    secondary="Datanomist"
                  />
                </ListItem>
              </List>
            </Box>
            <TextButton type="submit" startIcon={<SendIcon />}>
              Send
            </TextButton>
          </Box>
          <Box p={2.5}>
            {isLoadingUsers ? (
              <Typography variant="h3" color="primary">
                Loading...
              </Typography>
            ) : (
              <>
                <Box mb={4}>
                  <Typography color="primary" variant="body1">
                    Share url
                  </Typography>
                  <Box className={classes.urlBox}>
                    <Box>
                      {url ? url : "http://localhost:3000/my-collections"}
                    </Box>

                    {/* <IconButton onClick={copyUrl}>
                        
                        <CopyIcon  onClick={handleToggle}/>
                      </IconButton> */}
                    <Tooltip title={toggle ? "Copy" : "Copied"}>
                      <IconButton
                        onClick={() => {
                          copyUrl();
                          handleToggle();
                        }}
                      >
                        <CopyIcon />
                      </IconButton>
                    </Tooltip>
                    {toggle ? <Typography></Typography> : <></>}
                  </Box>
                </Box>
                <TextAreaInput
                  id="message"
                  name="message"
                  value={message}
                  onChange={handleChange}
                  placeholderText="Type a message here"
                  formik={formik}
                  label="Message"
                />
                <Box mt={4}>
                  <AutoComplate
                    id="users"
                    name="users"
                    options={options}
                    handleChange={handleMultiSelectChange}
                    placeholder="Select users"
                    defaultValue={[]}
                    formik={formik}
                    label="user"
                  />
                </Box>
              </>
            )}
          </Box>
        </form>
      </Box>
    </div>
  );
};
export default Messanger;
