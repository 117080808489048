 import React, { useState } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';
import FacetsSubContainer from '../facets-sub-container/FacetsSubContainer';
import '../filter-accardion/AccordionPlus.scss'

const FacetAccordion = ({ filter, continents }) => {
    const [showInfo, setShowInfo] = useState(false);
    const { accordionLabel } = filter;

    return (
        <div className={`accordion text-white ${showInfo && 'accordion-active'}`}>
            <div className="d-flex justify-content-between align-items-center">
                <p className='mb-0'>{accordionLabel}</p>
                <button className='accordion-toggle-btn text-white' onClick={() => setShowInfo(!showInfo)}>
                    {showInfo ? <AiOutlineMinus className='ms-2' /> : <AiOutlinePlus className='ms-2' />}
                </button>
            </div>
            {
                showInfo && <FacetsSubContainer continents={continents} showInfo={showInfo} setShowInfo={setShowInfo} />
            }
        </div>
    )
}

export default FacetAccordion;
