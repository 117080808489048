import { Box, Container, Typography, makeStyles } from '@material-ui/core';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Jobs from '../../components/cart/your-order/Jobs';
import CurrentJobs from '../../components/cart/your-order/OutdatedJobs';
import MyWorkTabs from '../../components/my-work/MyWorkTabs';
import Breadcrumbs from "../../components/shared/breadcrumb/Breadcrumbs";
import { SearchService } from '../../services/SearchService';

// Function to fetch dropdown options from the server
const getDropDownOptions = () => {
    return SearchService.fetchOrdersDropdownOptions()
}

// Custom CSS styles for components
const useStyles = makeStyles(theme => ({
    container: {
        minHeight: 'calc(100vh - 150px)'
    },
    wrapper: {
        minHeight: 'calc(100vh - 150px)',
        padding: "20px 0 100px"
    },
    title: {
        textAlign: 'center',
        margin: '26px 0 30px'
    },
    buttonWapper: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        }
    }
}))

// Options for tabs
const tabOptions = [
    {
        label: 'Archieved Jobs',
        value: 'My Jobs'
    },
    {
        label: 'Current Jobs',
        value: 'Archieved jobs'
    },
]

// Main component for displaying user's jobs
function MyJobs() {
    // Using custom styles
    const classes = useStyles()

    // State variables for managing data and loading states
    const [options, setOptions] = useState([])
    const [jobs, setJobs] = useState([])
    const [currentjobs, setCurrentJobs] = useState([])
    const [lastOrdered, setLastOrdered] = useState('')
    const [option, setOption] = useState('All')
    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [isLoadingJobs, setIsLoadingJobs] = useState(true)
    const [isLoadingOptions, setIsLoadingOptions] = useState(true)
    const [isLoadingCurrentJobs, setIsLoadingCurrentJobs] = useState(true)

    // Fetching dropdown options using react-query
    useQuery('your-orders-options', getDropDownOptions, {
        onSuccess: data => {
            let options = data.data[0].map(option => {
                return { label: option, value: option }
            })
            setOptions(options)
            setIsLoadingOptions(false)
        },
        onError: () => {
            setIsLoadingOptions(false)
        }
    })

    // Function to update screen width
    const updatDimensions = () => {
        setScreenWidth(window.innerWidth)
    }

    // Effect hook for fetching jobs data when component mounts
    useEffect(() => {
        getJobs()
        getCurrentJobs()
    }, [])

    // Function to fetch all jobs
    const getJobs = async () => {
        try {
            const res = await axios.get('http://103.59.152.154:8089/jobHistory/load')
            setJobs(res.data.content)
            setIsLoadingJobs(false)
        } catch {
            setIsLoadingJobs(false)
        }
    }

    // Function to fetch current jobs
    const getCurrentJobs = async () => {
        try {
            const res = await axios.get('http://103.59.152.154:8089/job/load')
            setCurrentJobs(res.data.content)
            setIsLoadingCurrentJobs(false)
        } catch {
            setIsLoadingCurrentJobs(false)
        }
    }

    // Effect hook to update last ordered option and screen width
    useEffect(() => {
        if (option.length) {
            setLastOrdered(options[options.length - 1]?.value)
        }
        window.addEventListener("resize", updatDimensions)
        return () => window.removeEventListener("resize", updatDimensions)
    }, [options])

    return (
        <Container className={classes.container}>
            <Box className={classes.wrapper}>
                <Breadcrumbs yourOrder="you-order" />
                <Typography className={classes.title} variant="h1" color="primary">
                    My Jobs
                </Typography>
                <Box>
                    <MyWorkTabs
                        screenWidth={screenWidth}
                        setSelectedIndex={setSelectedIndex}
                        selectedIndex={selectedIndex}
                        isDuality={true}
                        tabLabel={tabOptions}
                        tabPanelContents={{
                            contentOne: 
                            <Jobs
                                setLastOrdered={setLastOrdered}
                                lastOrdered={lastOrdered}
                                rows={jobs}
                                options={options}
                                isLoadingOptions={isLoadingOptions}
                                isLoadingYourOrder={isLoadingJobs}
                            />,
                            contentTwo:
                                // <OutdatedJobs
                                //     rows={expiredOrderRows}
                                //     options={options}
                                //     setOption={setOption}
                                //     option={option}
                                // />
                                <CurrentJobs
                                    setLastOrdered={setLastOrdered}
                                    lastOrdered={lastOrdered}
                                    rows={currentjobs}
                                    options={options}
                                    isLoadingOptions={isLoadingOptions}
                                    isLoadingYour Order={isLoadingCurrentJobs}
                                />,
                        }}
                    />
                </Box>
            </Box>
        </Container>
    );
}

export default MyJobs;
