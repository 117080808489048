import { Box, makeStyles, TableContainer } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import React, { useState } from "react";
import { CustomCheckbox } from "../../../../shared/inputs/CheckBox";
import KebabMenu from "../../../../shared/popover/KebabMenu";
import { StyledTableCell } from "../../../../shared/table/semantics-table/table-details/TableDetails";
import TableHeadTitle from "../../../../shared/table/table-head-title/TableHeadTitle";
import Row2 from "./row2/Row2";

const useStyles = makeStyles({
  tableContainer: {
    maxHeight: 770,
    overflowY: "auto",
    borderRadius: "5px 8px 0 0",
    "&::-webkit-scrollbar": {
      width: 4,
      height: 4,
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: 3,
      backgroundColor: "#E0E7EC",
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 10,
      backgroundColor: "#123b64",
    },
    "&::-webkit-scrollbar-button:end:increment": {
      height: 10,
      display: "block",
    },
    "&::-webkit-scrollbar-button:start:increment": {
      height: 5,
      display: "block",
    },
  },
  tableRow: {
    background: "#E0E7EC",
  },
  tableCell: {
    borderBottom: "none",
  },
  kebabMenuContent: {
    width: 123,
    display: "flex",
    flexDirection: "column",
    background: "#fff",
    boxShadow: "0px 4px 20px rgba(1, 25, 54, 0.1)",
    borderRadius: 10,
    padding: 12,
    zIndex: 999,
    marginRight: "auto",
  },
  checkboxWrapper: {
    "& .MuiCheckbox-root": {
      padding: "0 15px 0 25px",
    },
  },
});

const SemanticsTable3 = ({
  getTermDetails,
  updateStatus,
  semanticData,
  page,
  columns,
  getDescriptionData,
  getClassificationData,
  getSemanticsData,

  // keyProp,
}) => {
  const classes = useStyles();
  const [searchByColumn, setSearchByColumn] = useState("columnName");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("businessName");
  const [searchText, setSearchText] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);

  const Search = (rows) => {
    return rows.filter(
      (row) =>
        row[searchByColumn].toString().toLowerCase().indexOf(searchText) > -1
    );
  };

  const rows = Search(semanticData.length > 0 ? semanticData : []);
  const rowsCount = rows.length;
  const numSelectedRows = selectedRows.length;

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    array.sort((a, b) => {
      const order = comparator(a, b);
      return order;
    });
    const sortedArray = array.map((el) => el);
    return sortedArray;
  }

  const handleRequestSort = (event, property, sortName) => {
    setOrder(sortName);
    setOrderBy(property);
  };

  const handleAllChecked = (event) => {
    if (event.target.checked) {
      let newSelectedRows = rows.map((row) => row.columnName);
      setSelectedRows(newSelectedRows);
      return;
    } else {
      setSelectedRows([]);
    }
  };

  const handleChange = (event, id) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    setSelectedRows(newSelected);
  };
  const isSelected = (colName) => {
    const checked = selectedRows.indexOf(colName) !== -1;
    return checked;
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow className={classes.tableRow}>
            {columns.map((column) => (
              <StyledTableCell
                key={column.id}
                style={
                  column.id === "confidenceScore"
                    ? { width: 195 }
                    : column.id === "columnName"
                    ? { width: 320 }
                    : column.id === "Status"
                    ? { width: 150 }
                    : { width: 260 }
                }
                sortDirection={orderBy === column.id ? order : false}
              >
                {column.id === "columnName" ? (
                  <Box display="flex">
                    {/* <Box className={classes.checkboxWrapper}>
                      <CustomCheckbox
                        checked={rowsCount > 0 && numSelectedRows === rowsCount}
                        onChange={handleAllChecked}
                      />
                    </Box> */}
                    <TableHeadTitle
                      setSearchByColumn={setSearchByColumn}
                      column={column}
                      searchText={searchText}
                      setSearchText={setSearchText}
                      clickHandler={handleRequestSort}
                    />
                  </Box>
                ) : (
                  <TableHeadTitle
                    setSearchByColumn={setSearchByColumn}
                    column={column}
                    searchText={searchText}
                    setSearchText={setSearchText}
                    clickHandler={handleRequestSort}
                  />
                )}
              </StyledTableCell>
            ))}
            <StyledTableCell className={classes.tableCell}>
              <KebabMenu
                button={
                  <>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 3C10.9 3 10 3.9 10 5C10 6.1 10.9 7 12 7C13.1 7 14 6.1 14 5C14 3.9 13.1 3 12 3ZM12 17C10.9 17 10 17.9 10 19C10 20.1 10.9 21 12 21C13.1 21 14 20.1 14 19C14 17.9 13.1 17 12 17ZM12 10C10.9 10 10 10.9 10 12C10 13.1 10.9 14 12 14C13.1 14 14 13.1 14 12C14 10.9 13.1 10 12 10Z"
                        fill="#123B64"
                      />
                    </svg>
                  </>
                }
              >
                <Box className={classes.kebabMenuContent}>
                  <div className={classes.exportFile}>
                    <span style={{ marginRight: 10 }}>
                      <svg
                        width="14"
                        height="16"
                        viewBox="0 0 14 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.25 2H1.75V14H12.25V5H9.25V2ZM0.25 1.244C0.25 0.833 0.58525 0.5 0.99925 0.5H10L13.75 4.25V14.7448C13.7507 14.8432 13.732 14.9409 13.6949 15.0322C13.6579 15.1234 13.6032 15.2065 13.534 15.2766C13.4649 15.3468 13.3826 15.4026 13.2919 15.4409C13.2011 15.4792 13.1037 15.4993 13.0052 15.5H0.99475C0.797784 15.4986 0.609263 15.4198 0.469913 15.2806C0.330563 15.1414 0.251571 14.953 0.25 14.756V1.244ZM7 7.25V5L10 8L7 11V8.75H4V7.25H7Z"
                          fill="#123B64"
                        />
                      </svg>
                    </span>
                    <span>Export file</span>
                  </div>
                  <div className={classes.exportFile}>
                    <span style={{ marginRight: 10 }}>
                      <svg
                        width="14"
                        height="16"
                        viewBox="0 0 14 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.25 2H1.75V14H12.25V5H9.25V2ZM0.25 1.244C0.25 0.833 0.58525 0.5 0.99925 0.5H10L13.75 4.25V14.7448C13.7507 14.8432 13.732 14.9409 13.6949 15.0322C13.6579 15.1234 13.6032 15.2065 13.534 15.2766C13.4649 15.3468 13.3826 15.4026 13.2919 15.4409C13.2011 15.4792 13.1037 15.4993 13.0052 15.5H0.99475C0.797784 15.4986 0.609263 15.4198 0.469913 15.2806C0.330563 15.1414 0.251571 14.953 0.25 14.756V1.244ZM7 7.25V5L10 8L7 11V8.75H4V7.25H7Z"
                          fill="#123B64"
                        />
                      </svg>
                    </span>
                    <span>Import file</span>
                  </div>
                </Box>
              </KebabMenu>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            stableSort(rows, getComparator(order, orderBy)).map(
              (row, index) => {
                const isItemSelected = isSelected(row.columnName);
                return (
                  <Row2
                    key={index}
                    index={index}
                    row={row}
                    columns={columns}
                    //keyProp={key}
                    updateStatus={updateStatus}
                    isItemSelected={isItemSelected}
                    onChange={handleChange}
                    semanticData={semanticData}
                    getDescriptionData={getDescriptionData}
                    getClassificationData={getClassificationData}
                    getSemanticsData={getSemanticsData}
                    getTermDetails={getTermDetails}
                    page={page}
                  />
                );
              }
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default SemanticsTable3;
