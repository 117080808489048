import React from 'react';
import { TextField, makeStyles, Typography, Chip } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
    chip: {
        fontSize: 14,
        color: '#fff'
    },
    textField: {
        "& .MuiFilledInput-underline:before": {
            content: "unset"
        },
        "& .MuiFilledInput-underline:after": {
            content: "unset"
        },
        '& .MuiFilledInput-root': {
            background: '#c4c4c41a',
            borderRadius: 8
        },
        '& .MuiInputBase-root': {
            minHeight: 48,
            padding: '7px 16px',
            '& input': {
                padding: 0
            },
            '& .MuiAutocomplete-endAdornment': {
                display: 'none'
            },
            '& .MuiChip-root': {
                height: 28,
                borderRadius: 12,
                fontSize: 14,
                color: '#123b64',
                "& svg": {
                    marginRight: 10,
                    width: 14,
                    color: '#123b64'
                }
            }
        },

        "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 8,
                border: '1px solid #808C9A'
            },
            '& fieldset': {
                top: 0
            },
            "&.Mui-focused fieldset": {
                top: 0,
                borderColor: "#F79F19!important",
            }
        },
    },
}));

function AutoComplate({
    options,
    defaultValue,
    formik,
    name,
    id,
    handleChange,
    placeholder,
    label
}) {
    const classes = useStyles()
    const errorHandler = () => {
        if (formik) {
            return (
                <>
                    {formik.errors[name] && formik.touched[name] ? (
                        <Typography
                            className={classes.typography}
                            variant="body2"
                            color="secondary"
                        >
                            {formik.errors[name]}
                        </Typography>
                    ) : null}
                </>
            )
        } else {
            return null
        }
    }
   
    return (
        <div>
            {
                options?.length > 0 &&
                <Autocomplete
                    multiple
                    id={id}
                    name={name}
                    onChange={handleChange}
                    getOptionLabel={option => option?.name ? option.name : `Select ${label}`}
                    className={classes.autoCopmlate}
                    options={options}
                    defaultValue={defaultValue}
                    disableCloseOnSelect
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                className={classes.chip}
                                deleteIcon={<ClearIcon />}
                                label={option?.name ? option.name : `Select ${label}`}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            className={classes.textField}
                            {...params}
                            variant="outlined"
                            InputProps={{ ...params.InputProps }}
                            placeholder={placeholder}
                        />
                    )}
                />
            }
            {errorHandler()}
        </div>
    );
}

export default AutoComplate;