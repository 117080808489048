
export const FACETS_DATA = [
    {
        value: 'africa',
        label: "Africa",
        children: [
            { value: 'egypt', label: "Egpyt" },
            { value: 'morocco', label: "Morocco" }
        ]
    },
    {
        value: 'europe',
        label: "Europe",
        children: [
            { value: 'austria', label: "Austria" },
            { value: 'albania', label: "Albania" },
            { value: 'united kingdom', label: "United Kingdom" },
            { value: 'lower normandy', label: "Lower Normandy" },
            {
                value: 'france',
                label: "France",
                children: [
                    { value: 'alsace', label: "Alsace" },
                    { value: 'burgundy', label: "Burgundy" },
                    { value: 'french guiana ', label: "French Guiana" }
                ]
            },
        ]
    },
    {
        value: 'central asia',
        label: "Central Asia",
        children: [
            { value: 'kazakhstan', label: "Kazakhstan" },
            { value: 'afghanistan', label: "Afghanistan" },
            {
                value: 'uzbekistan',
                label: "Uzbekistan",
                children: [
                    { value: 'tashkent', label: 'Tashkent' },
                    { value: 'samarkand', label: 'Samarkand' },
                    { value: 'bukhara', label: 'Bukhara' },
                ]
            },
        ]
    }
]

