import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { useState } from "react";
import { OutlinedButton, PrimaryButton } from "../../buttons/custom-buttons/CustomButtons";
import SearchBar from "../../inputs/SearchBar";
import UserList from "./UserList";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#fff",
    width: 508,
    boxShadow: "0px 2px 20px rgba(23, 87, 123, 0.2)",
    borderRadius: 8,
  },
  title: {
    marginBottom: 15,
    fontWeight: 500,
    lineHeight: 1.1,
  }, 
  clearIcon: {
    color: "#123b64",
    width: 14,
    height: 14,
    marginRight: 10,
  },
  btnWrapper: {
    padding: 16,
    boxShadow: "0px -1px 5px rgba(0, 0, 0, 0.1)",
  },
  button: {
    height: 32,
    width: 132,
    fontWeight: 500,
    fontSize: 14,
    borderRadius: 8,
    padding: "6px 20px",
  },
}));

const ChoiceUserForRole = (props) => {
  const {
    users,
    setOpen,
    checkedUsers,
    setCheckedUsers,
    index,
    formFields,
    setFormFields,
    field,
    type,
  } = props;
  const classes = useStyles();
  const [filteredUsers, setFilteredUsers] = useState(users);
  const [value, setValue] = useState("");

  const handleSearch = (e) => {
    let targetValue = e.target.value;
    setValue(targetValue);
    let newUsers = users.filter((user) => {
      return user.name.toLowerCase().indexOf(targetValue.toLowerCase()) !== -1;
    });
    setFilteredUsers(newUsers);
  };

  const handleClear = () => {
    let copyFormFields =
      type === "matrix-team" ? [...formFields.roles] : [...formFields];
    copyFormFields[index][users] = [];
    type === "matrix-team"
      ? setFormFields((prev) => {
          return {
            ...prev,
            roles: [...copyFormFields],
          };
        })
      : setFormFields(copyFormFields);
    setCheckedUsers({});
  };

  return (
    <div className={classes.root}>
      <Box style={{ padding: 16 }}>
        <Typography className={classes.title} variant="body2">
          Choice user for role
        </Typography>
        <SearchBar
          value={value}
          setValue={setValue}
          handleChange={handleSearch}
          forRole="for-role"
          placeholderText="Search user"
        />
        <UserList
          field={field}
          setCheckedUsers={setCheckedUsers}
          checkedUsers={checkedUsers}
          users={filteredUsers}
          formFields={formFields}
          setFormFields={setFormFields}
          index={index}
          type={type}
        />
      </Box>
      <Box className={classes.btnWrapper}>
        <PrimaryButton
          onClick={() => setOpen(false)}
          style={{ marginRight: 15 }}
          className={classes.button}
          disableRipple
          disabled={field.users.length === 0}
        >
          Add position
        </PrimaryButton>
        <OutlinedButton
          onClick={handleClear}
          className={classes.button}
          disableRipple
        >
          Clear
        </OutlinedButton>
      </Box>
    </div>
  );
};

export default ChoiceUserForRole;
