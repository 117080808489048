import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './filter-region.scss'


const FilterButton = (props) => {
    const { popperName, children } = props
    const TooltipOverlay = (props) => (
        <Tooltip className={`my-tooltip fw-bold`} id="button-tooltip"  {...props}>
            {popperName}
        </Tooltip>
    )

    return (
        <OverlayTrigger
            className="text-secondary-100 mt-2 bg-gray-350'"
            placement="bottom"
            delay={{ show: 100, hide: 100 }}
            overlay={TooltipOverlay}
        >
            {children}   
        </OverlayTrigger>
    )
}

export default FilterButton;