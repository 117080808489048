import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

function CustomPoper({ button, children, setPopperAnc, popperAnc }) {

    const popperOpen = Boolean(popperAnc);

    return (
        <div className="App">
            <div onClick={e => setPopperAnc(e.currentTarget)}>
                {button}
            </div>
            <div style={{ position: "absolute" }}>
                <Popper open={popperOpen} anchorEl={popperAnc}>
                    <ClickAwayListener
                        onClickAway={e => {
                            setPopperAnc(null);
                        }}
                    >
                        <div>
                            {children}
                        </div>
                    </ClickAwayListener>
                </Popper>
            </div>
        </div>
    );
}

export default CustomPoper