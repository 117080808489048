import React from 'react';
import { AddedToCartButton, AddToCartButton } from "../buttons/custom-buttons/CustomButtons";
// import

function CardIconButton({ isInCart }) {
    return (
        <>
            {
                !isInCart ?
                    <AddToCartButton disableRipple endIcon={
                        <svg width="24" height="23" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0.919922 1.07617C0.919922 0.523887 1.36764 0.0761719 1.91992 0.0761719H5.91992C6.39655 0.0761719 6.80693 0.412557 6.90047 0.879915L7.74035 5.07617H23.9199C24.2179 5.07617 24.5004 5.2091 24.6904 5.43873C24.8804 5.66835 24.958 5.97075 24.9022 6.2635L23.3008 14.661C23.0184 16.0829 21.7598 17.0989 20.3113 17.0762H10.6086C9.16036 17.0989 7.902 16.0833 7.61925 14.6618C7.61919 14.6616 7.61931 14.6621 7.61925 14.6618L5.94871 6.31537C5.94194 6.28777 5.9363 6.25971 5.93187 6.23125L5.10024 2.07617H1.91992C1.36764 2.07617 0.919922 1.62846 0.919922 1.07617ZM8.14065 7.07617L9.58076 14.2714C9.67513 14.7465 10.0965 15.0856 10.5808 15.0764L10.5999 15.0762H20.3199L20.3391 15.0764C20.8223 15.0856 21.2428 14.748 21.3384 14.2746L22.7112 7.07617H8.14065ZM7.91992 21.0762C7.91992 19.9716 8.81535 19.0762 9.91992 19.0762C11.0245 19.0762 11.9199 19.9716 11.9199 21.0762C11.9199 22.1807 11.0245 23.0762 9.91992 23.0762C8.81535 23.0762 7.91992 22.1807 7.91992 21.0762ZM18.9199 21.0762C18.9199 19.9716 19.8154 19.0762 20.9199 19.0762C22.0245 19.0762 22.9199 19.9716 22.9199 21.0762C22.9199 22.1807 22.0245 23.0762 20.9199 23.0762C19.8154 23.0762 18.9199 22.1807 18.9199 21.0762Z" fill="#123B64" />
                        </svg>
                    }>
                        Add to cart
                    </AddToCartButton> :
                    <AddedToCartButton disableRipple endIcon={
                        <svg width="24" height="23" viewBox="0 0 25 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.6774 3.66143L15.5058 6.48986L21.1627 0.833008L22.5769 2.24722L15.7484 9.07568H23.9199C24.2179 9.07568 24.5004 9.20861 24.6904 9.43824C24.8804 9.66787 24.958 9.97026 24.9022 10.263L23.3008 18.6605C23.0184 20.0824 21.7598 21.0984 20.3113 21.0757H10.6086C9.16036 21.0984 7.902 20.0828 7.61925 18.6613L5.94871 10.3149C5.94194 10.2873 5.9363 10.2592 5.93187 10.2308L5.10024 6.07568H1.91992C1.36764 6.07568 0.919922 5.62797 0.919922 5.07568C0.919922 4.5234 1.36764 4.07568 1.91992 4.07568H5.91992C6.39655 4.07568 6.80693 4.41207 6.90047 4.87943L7.74035 9.07568H15.2632L11.2632 5.07565L12.6774 3.66143ZM7.91992 25.0757C7.91992 23.9711 8.81535 23.0757 9.91992 23.0757C11.0245 23.0757 11.9199 23.9711 11.9199 25.0757C11.9199 26.1803 11.0245 27.0757 9.91992 27.0757C8.81535 27.0757 7.91992 26.1803 7.91992 25.0757ZM18.9199 25.0757C18.9199 23.9711 19.8154 23.0757 20.9199 23.0757C22.0245 23.0757 22.9199 23.9711 22.9199 25.0757C22.9199 26.1803 22.0245 27.0757 20.9199 27.0757C19.8154 27.0757 18.9199 26.1803 18.9199 25.0757Z" fill="#123B64" />
                        </svg>
                    }>
                        Added to cart
                    </AddedToCartButton>
            }
        </>
    );

}

export default CardIconButton;
