import React from 'react';
import './list-view-type-button.scss'
import { FiGrid } from 'react-icons/fi';

const ListViewTypeButton = (props) => {
    const {
        handleListViewChange,
        isListView
    } = props

    return (
        <div className="btn-group" role="group" aria-label="Basic example">
            <button
                onClick={() => handleListViewChange(false)}
                type="button"
                className={`btn ${!isListView ? 'btn-secondary-100' : 'bg-white'} full-info-card-btn d-flex align-items-center`}
            >
                <FiGrid className={`${!isListView ? 'text-white' : 'text-secondary-100'}`} />
            </button>
            <button
                onClick={() => handleListViewChange(true)}
                type="button"
                className={`btn ${isListView ? 'btn-secondary-100' : 'bg-white '} info-card-btn d-flex align-items-center`}
            >
                <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path id="view-2" d="M1.75 1H7.58333M1.75 4.33333H7.58333M1.75 7.66667H7.58333M1.75 11H7.58333M10.9167 1H16.75M10.9167
                            4.33333H16.75M10.9167 7.66667H16.75M10.9167 11H16.75"  stroke={`${isListView ? '#fff' : '#123b64'} `} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </button>
        </div>
    );
};


export default ListViewTypeButton;