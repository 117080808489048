import {AppBar, Box, IconButton} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { Link } from "react-router-dom";
import {CircleButton, TextButton} from "../shared/buttons/custom-buttons/CustomButtons";
import DropDownButton from "../shared/DropDownButton";
import NodeTooltip from "../shared/tooltip/NodeTooltip";
import TabPanel from "./Tabpanel";
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { FilterIcon } from "../icons";
import { ORDERTYPES, useStyles, StyledTabs, StyledTab, tabPropsGenerator } from "./myWorks";

const MyWorkTabs = (props) => {
  const classes = useStyles(props);
  const {
    tabLabel,
    tabPanelContents,
    myCollections,
    setOpenSidebar,
    setSelectedIndex,
    selectedIndex,
    screenWidth,
    setFilterType,
    setOrder, order
  } = props;
  const { asc, desc } = ORDERTYPES

  const handleChange = (event, newValue) => {
    setSelectedIndex(newValue);
  };

  const sortOrder = () => {
    setFilterType('displayName')
    order === asc ? setOrder(desc) : setOrder(asc)
  }
  
  return (
    <Box className={classes.root}>
      {screenWidth > 992 ? (
        <Box className={classes.AppBarWrapper}>
          {
            myCollections &&
            <>
              <CircleButton onClick={() => setOpenSidebar(true)}>
                <FilterIcon />
              </CircleButton>
              <Box className={classes.sortBtnwrapper}>
                <IconButton disabled={order !== 'ASC'} onClick={sortOrder} className={classes.sortBtn}>
                  <ArrowDropUpIcon />
                </IconButton>
                <IconButton disabled={order !== "DESC"} onClick={sortOrder} className={classes.sortBtn}>
                  <ArrowDropDownIcon />
                </IconButton>
              </Box>
            </>
          }
          <AppBar className={classes.Appbar} position="static">
            <StyledTabs
              className={classes.tabs}
              value={selectedIndex}
              onChange={handleChange}
              aria-label="simple tabs example"
              centered
            >
              {tabLabel.map((item, index) => {
                return (
                  <StyledTab
                    key={index}
                    className={selectedIndex === index ? classes.active : ""}
                    label={item.label}
                    style={{ fontSize: '21px' }}
                    {...tabPropsGenerator(index)}
                  />
                );
              })}
            </StyledTabs>
          </AppBar>
          {myCollections && (
            <NodeTooltip
              placement="bottom-end"
              title={
                <TextButton
                  style={{ padding: "8px 16px" }}
                  disableRipple
                  startIcon={<AddIcon />}
                >
                  Create new collection
                </TextButton>
              }
            >
              <Link to="/my-collections/create-collection">
                <CircleButton disableRipple>
                  <AddIcon />
                </CircleButton>
              </Link>
            </NodeTooltip>
          )}
        </Box>
      ) : (
        <Box className={classes.buttonWapper}>
          <DropDownButton
            cart={props.cart}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            options={tabLabel}
            screenWidth={screenWidth}
          />
        </Box>
      )}
      <TabPanel value={selectedIndex} index={0}>
        {tabPanelContents.contentOne}
      </TabPanel>
      <TabPanel value={selectedIndex} index={1}>
        {tabPanelContents.contentTwo}
      </TabPanel>
      <TabPanel value={selectedIndex} index={2}>
        {tabPanelContents?.contentTree && tabPanelContents.contentTree}
      </TabPanel>
      <TabPanel value={selectedIndex} index={3}>
        {tabPanelContents?.contentFour && tabPanelContents.contentFour}
      </TabPanel>
      <TabPanel value={selectedIndex} index={4}>
        {tabPanelContents?.contentFive && tabPanelContents.contentFive}
      </TabPanel>
    </Box>
  );
};
export default MyWorkTabs;
