import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(3),
        },
    },
    chip: {
        fontSize: 14,
        color: '#fff'
    },
    textField: {
        "& .MuiFilledInput-underline:before": {
            content: "unset"
        },
        "& .MuiFilledInput-underline:after": {
            content: "unset"
        },
        '& .MuiFilledInput-root': {
            background: '#c4c4c41a',
            borderRadius: 8
        },
        '& .MuiInputBase-root': {
            minHeight: 48,
            padding: '7px 16px',
            '& input': {
                padding: 0
            },
            '& .MuiAutocomplete-endAdornment': {
                display: 'none'
            },
            '& .MuiChip-root': {
                height: 28,
                borderRadius: 12,
                fontSize: 14,
                color: '#123b64',
                "& svg": {
                    marginRight: 10,
                    width: 14,
                    color: '#123b64'
                }
            }
        },

        "& .MuiOutlinedInput-root": {
            "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 8,
                border: '1px solid #808C9A'
            },
            '& fieldset': {
                top: 0
            },
            "&.Mui-focused fieldset": {
                top: 0,
                borderColor: "#F79F19!important",
            }
        },
    },
}));

const MultiSelect1 = ({
    id,
    name,
    handleChange,
    options,
    formik,
    defaultValue,
    label,
    placeholder,
    ...props
}) => {
    const classes = useStyles();

    const errorHandler = () => {
        if (formik) {
            return (
                <>
                    {formik.errors[name] && formik.touched[name] ? (
                        <Typography
                            className={classes.typography}
                            variant="body2"
                            color="secondary"
                        >
                            {formik.errors[name]}
                        </Typography>
                    ) : null}
                </>
            )
        } else {
            return null
        }
    }

    return (
        <div className={classes.root}>
            {
                <Autocomplete
                    multiple
                    id={id}
                    name={name}
                    onChange={handleChange}
                    getOptionLabel={option => option?.name ? option.name : `Select ${label}`}
                    className={classes.autoCopmlate}
                    options={options}
                    defaultValue={defaultValue}
                    value={defaultValue || null || []}
                    getOptionSelected={(option, value) => {
                        if (option.key) {
                            return option.key === value?.key
                        } else {
                            return option.id === value?.id
                        }
                    }}
                    disableCloseOnSelect
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                className={classes.chip}
                                deleteIcon={<ClearIcon />}
                                label={option?.name ? option.name : `Select ${label}`}
                                {...getTagProps({ index })}
                            />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField
                            className={classes.textField}
                            {...params}
                            variant={!props.page ? 'outlined' : 'filled'}
                            InputProps={{ ...params.InputProps }}
                            placeholder={placeholder}
                        />
                    )}
                />
            }
            {errorHandler()}
        </div>
    );
}
export default MultiSelect1


