import ShortNodeCard from "../short-node-card/ShortNodeCard";
import FullNodeCard from "../full-node-card/FullNodeCard";
import ProgressCard from "../full-node-card/progress-card/ProgressCard";

const NodeCard = (props) => {
    const { node, showShortCard, selectedNodes, setSelectedNodes, currentNodes, isInprogress } = props
    return (
        isInprogress ?
            <ProgressCard node={node} /> :
            showShortCard ?
                <ShortNodeCard
                    data={node}
                    selectedNodes={selectedNodes}
                    setSelectedNodes={setSelectedNodes}
                    currentNodes={currentNodes}
                /> :
                <FullNodeCard
                    data={node}
                    selectedNodes={selectedNodes}
                    setSelectedNodes={setSelectedNodes}
                    currentNodes={currentNodes}
                />
    )
}

export default NodeCard;