

import { useState, useEffect, useContext } from "react";
import { Box, Typography, makeStyles } from "@material-ui/core";
import TagsTooltip from "../shared/tooltip/TagsTooltip";
import CategoryBtn from "../save-in-collection/categories-tooltip/CategoryBtn";
import { TeamService } from "../../services/TeamService";
import { InfoIcon } from "../icons";
import AddIcon from "@material-ui/icons/Add";
import MatrixTable from "../add-section/section-roles/collapse/collapse-content/MatrixTable";
import Warning from "../shared/warning/Warning";
import TooltipContent from "../save-in-collection/categories-tooltip/TooltipContent";
import { CustomTooltip } from "../shared/tooltip/NodeTooltip";
import { PrimaryButton } from "../shared/buttons/custom-buttons/CustomButtons";
import { CATEGORY_NAME } from "../profile/profile-details/your-preferences/YourPreferencesDetails";
import MatrixTableResponsibility from "../add-section/section-roles/collapse/collapse-content/MatrixResponsibility";
import { SearchService } from "../../services/SearchService";
import DataContext from "../../contexts/Context";

const useStyles = makeStyles({
    typography: {
        lineHeight: 1.1,
        marginBottom: 8
    },
    selectedCategories: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: 20,
        background: "#c4c4c41a",
        maxWidth: 545,
        width: '100%',
        minHeight: 100
    },
    category: {
        margin: 4
    },
    labelWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20
    },
    responsibility: {
        fontWeight: 700,
        marginRight: 6.5
    },
})

const position = { left: 1 };
const geograpyPosition = { right: 0 };

function CategoryCreation(props) {
    const {
        categoryError, setCategoryError,
        tableData, setTableData,
        categories, setCategories,
        deleteContext
    } = props
    const classes = useStyles()
    const [businessOptions, setBusinessOptions] = useState([]);
     const [dataDomainOptions, setDataDomainOptions] = useState([]);
    const [geographyOptions, setGeographyOptions] = useState([]);
    const [productOptions, setProductOptions] = useState([]);
    const [openCollapse, setOpenCollapse] = useState(false);
    const [business, setBusiness] = useState([]);
    const [dataDomain, setDataDomain] = useState(null);
    const [product, setProduct] = useState([]);
    const [geography, setGeography] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState({});
    const [openBusiness, setOpenBusiness] = useState(false);
    const [profileData, setProfileData] = useState([]);
    const [openDatadomain, setOpenDatadomain] = useState(false);
    const [openGeography, setOpenGeography] = useState(false);
    const [openProduct, setOpenProduct] = useState(false);
    const { loggedInUser } = useContext(DataContext);

  

    useEffect(() => {
         getBusinessOptions();
        // getGeographyOptions();
        // getDataDomainOptions();
        // getProductOptions();
    }, []);
    // "Region","Product","Line of Business"
    const domianNames =[{name:"Data Domain",state:setDataDomainOptions},
    {name:"Region",state:setGeographyOptions},
    {name:"Product",state:setProductOptions},
    {name:"Line of Business",state:setBusinessOptions},
]
    const getBusinessOptions = () => {
        domianNames.map((domain)=>{
            TeamService.fetchBusiness1(domain.name)
            .then((res) => {
                console.log(res,"ressss")
                const response= res.data
            // const names =    response.map((item)=>{return {
            //     name: item.name,key:item.key
            // }})
            domain.state(response)
              
            })
            .catch(() => {
                // setBusinessLoading(false);
            });
        })
       
    };
    

    // const getGeographyOptions = () => {
    //     TeamService.fetchGeography()
    //         .then((res) => {
    //             const data = res.data.response;
    //             const transformedData = data.map((item) => {
    //                 return { ...item, type: "geography" };
    //             });
    //             console.log(transformedData,"transformedData")
    //             setGeographyOptions(transformedData);
    //             // setGeographyLoading(false);
    //         })
    //         .catch(() => {
    //             // setGeographyLoading(false);
    //         });
    // };

    // const getDataDomainOptions = () => {
    //     TeamService.fetchDataDomain()
    //         .then((res) => {
    //             const data = res.data.response;
    //             const transformedData = data.map((item) => {
    //                 return { ...item, type: "dataDomain" };
    //             });
    //             setDataDomainOptions(transformedData);
    //             // setDataDomainLoading(false);
    //         })
    //         .catch(() => {
    //             // setDataDomainLoading(false);
    //         });
    // };

    const addCategory = (categoryName, category, setOpenCategory) => {
        let selectedCategories = categories
        const isExist = selectedCategories.some(selectedCategory => selectedCategory.type === categoryName)
        if (isExist) {
            selectedCategories = selectedCategories.filter(category => category.type !== categoryName)
        }
        if (categoryName === CATEGORY_NAME.dataDomain) {
            selectedCategories = [...selectedCategories, category]

        } else {
            selectedCategories = [...selectedCategories, ...category]
        }
        // privent duplicating categories
        selectedCategories = selectedCategories.filter((category, index, itself) => {
            return index === itself.findIndex(item => item.key === category.key)
        })

        setCategories(selectedCategories)
       // console.log("mnbn ,/njbh",selectedCategories)
        setOpenCategory(false)
    }

    const handleApply = (categoryName) => {
        if (categoryName === CATEGORY_NAME.business) {
            addCategory(CATEGORY_NAME.business, business, setOpenBusiness)
        } else if (categoryName === CATEGORY_NAME.dataDomain) {
            addCategory(CATEGORY_NAME.dataDomain, dataDomain, setOpenDatadomain)
        } else if (categoryName === "product") {
            addCategory(CATEGORY_NAME.product, product, setOpenProduct)
        } else {
            addCategory(CATEGORY_NAME.geography, geography, setOpenGeography)
        }
    };
    const getMore = () => {
        const payloadData = {
          userId: loggedInUser.userId,
          userEmailId:loggedInUser.email,
         profileData:categories
        //  "business": [
        //     {
        //         "choiceDomain": "Line of Business",
        //         "name": "Reinsurance",
        //         "choiceCount": 229,
        //         "key": "24581797"
        //     }
        // ],
        // "dataDomain": [
        //     {
        //         "choiceDomain": "Data Domain",
        //         "name": "Account Management Activity",
        //         "choiceCount": 178,
        //         "key": "4905767"
        //     }
        // ],
        // "geography": [
        //     {
        //         "choiceDomain": "Region",
        //         "name": "Abkhazia",
        //         "choiceCount": 146,
        //         "key": "4905763"
        //     }
        // ],
        // "product": [
        //     {
        //         "choiceDomain": "Product",
        //         "name": "medicines and vitamins",
        //         "choiceCount": 23,
        //         "key": "24581910"
        //     }
        // ]
        };
        return SearchService.yourCategories(payloadData)
          .then((response) => {
            if (response.data) {
              setCategoryData(response.data);
            }
          })
          .catch((error) => {
            console.error("Error fetching more from user:", error);
          });
      };


    const handleOpenTable = () => {
        
        if (!product.length || !business.length || !dataDomain || !geography.length) {
            setCategoryError(true);
            console.error("Please select products, business, dataDomain, and geography.");
            return;
        }

        const context = {
            products: product,
            business: business,
            dataDomain: [dataDomain],
            geography: geography
        }
    
        if (categories.length > 3) {
            let matrixTableData = [...tableData, context]
            matrixTableData.map((element, index) => element.context = `Context${index + 1}`)
            setTableData(matrixTableData)
            setBusiness([])
            setGeography([])
            setProduct([])
            setCategories([])
            setDataDomain(null)
            setSelectedCategories({})
            setCategoryError(false)
        // } else {
        //     setCategoryError(false)
        }
        getMore()
    }
    
    

    // const getProductOptions = () => {
    //     TeamService.fetchProducts()
    //         .then((res) => {
    //             const data = res.data.response;
    //             const transformedData = data.map((item) => {
    //                 return { ...item, type: "product" };
    //             });
    //             setProductOptions(transformedData);
    //             // setProductLoading(false);
    //         })
    //         .catch(() => {
    //             // setProductLoading(false);
    //         });
    // };

    return (
        <Box>
            <Typography style={{ lineHeight: 1.1 }} color="primary" variant="body2">
                Categories
            </Typography>
            <Box sx={{ display: 'flex' }}>
                <TagsTooltip
                    position={position}
                    open={openBusiness}
                    setOpen={setOpenBusiness}
                    input={<CategoryBtn name="Business" number={businessOptions.length} />}
                >
                    <TooltipContent
                        name="business"
                        options={businessOptions}
                        setCheckedValues={setBusiness}
                        checkedValues={business}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        handleApply={handleApply}
                    />
                </TagsTooltip>
                <TagsTooltip
                    position={position}
                    setOpen={setOpenDatadomain}
                    open={openDatadomain}
                    input={<CategoryBtn name="Data Domain" number={dataDomainOptions.length} />}
                >
                    <TooltipContent
                        name="dataDomain"
                        options={dataDomainOptions}
                        type="radio"
                        setCheckedValues={setDataDomain}
                        checkedValues={dataDomain}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        handleApply={handleApply}
                    />
                </TagsTooltip>
                <TagsTooltip
                    position={position}
                    setOpen={setOpenProduct}
                    open={openProduct}
                    input={<CategoryBtn name="Product" number={productOptions.length} />}
                >
                    <TooltipContent
                        name="product"
                        options={productOptions}
                        setCheckedValues={setProduct}
                        checkedValues={product}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        handleApply={handleApply}
                    />
                </TagsTooltip>
                <TagsTooltip
                    position={geograpyPosition}
                    setOpen={setOpenGeography}
                    open={openGeography}
                    input={<CategoryBtn name="Geography" number={geographyOptions.length} />}
                >
                    <TooltipContent
                        name="geography"
                        options={geographyOptions}
                        setCheckedValues={setGeography}
                        checkedValues={geography}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        handleApply={handleApply}
                    />
                </TagsTooltip>
            </Box>
            {/* <Box mb={3}>
                {
                    categoryError ?
                        <Typography variant="body2" color="secondary">
                            All categories should be selected
                        </Typography> : null
                }
            </Box> */}
            {categoryError ? (
    <Box mb={3}>
        <Typography variant="body2" color="secondary">
            All categories should be selected
        </Typography>
    </Box>
) : null}
            <Box mb={3}>
                <Typography className={classes.typography} variant="body2" color="primary">
                    Selected categories
                </Typography>
                <Box className={classes.selectedCategories}>
                    {categories.length ? (
                        categories.map((category) => (
                            <Typography key={category.key} className={classes.category} color="primary" variant="h6">
                                {
                                    categories[categories.length - 1].name === category.name ?
                                        category.name : category.name + ','
                                }
                            </Typography>
                        ))
                    ) : (
                        <Typography color="primary" variant="h6">
                            No selected categories
                        </Typography>

                    )}
                </Box>
            </Box>
            <PrimaryButton onClick={handleOpenTable} startIcon={<AddIcon />}>
                Add
            </PrimaryButton>
            <>
                {tableData.length ? 
                    <Box mt={4} mb={4}>
                        <Box className={classes.labelWrapper}>
                            <Typography color="primary" variant="body1" className={classes.responsibility}>
                                Added Categories
                            </Typography>
                            <CustomTooltip bottomStart="bottom-start" title={<Warning />}>
                                <InfoIcon />
                            </CustomTooltip>
                        </Box>
                        <MatrixTableResponsibility
                            openCollapse={openCollapse}
                            setOpenCollapse={setOpenCollapse}
                            data={tableData}
                            deleteContext={deleteContext}
                        />
                    </Box>
                    : null
                }
            </>
        </Box>
    );
}

export default CategoryCreation;
