import { useEffect, useState } from 'react';
import SliderPlaceholder from '../../shared/placeholder/SliderPlaceholder';
import Pagination from '../../filter-region/pagination/Pagination';
import { makeStyles, Box, Typography } from '@material-ui/core';
import CustomizedSelect from '../../shared/inputs/CustomizedSelect';
import SearchInput from '../../shared/inputs/SearchInput';
import Loader from '../../shared/loader/Loader';
import { HeadCells } from '../../../constants/Jobs';
import JobsTable from './oreder-table/JobsTable';

const useStyles = makeStyles(theme => ({
    inputWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '30px 0',
        [theme.breakpoints.down('992')]: {
            flexDirection: 'column',
            margin: '16px 0 10px'
        }
    },
    numberOfOrder: {
        fontSize: 14,
        fontWeight: 700,
        marginRight: 8
    },
    selectWrapper: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 275,
        [theme.breakpoints.down('992')]: {
            order: 1
        }
    },
    searchInputBox: {
        maxWidth: 503,
        width: '100%',
        [theme.breakpoints.down('992')]: {
            marginBottom: 33
        }
    },
    box: {
        width: 154,
        marginTop: 0,
        "& .MuiInputBase-root": {
            marginTop: 0
        },
        '& .MuiSelect-root': {
            padding: '5.5px 14px'
        }
    }
}))

const Jobs = (props) => {
    const classes = useStyles()
    const [pageNumberLimit] = useState(5) // Limit of pages shown in pagination
    const [filteredRows, setFilteredRows] = useState([]) // State for filtered rows
    const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(7) // Maximum page number limit for pagination
    const [minPageNumberLimit, setMinPageNumberLimit] = useState(0) // Minimum page number limit for pagination
    const [currentPage, setCurrentPage] = useState(1); // Current page number
    const [itemsPerPage] = useState(5); // Number of items per page
    const numberOfPages = [] // Array to hold the total number of pages
    const totalPage = filteredRows.length // Total number of pages based on filtered rows
    const [searchNodeName, setSearchNodeName] = useState('') // State for search input value
    const indexOfLastNode = currentPage * itemsPerPage; // Index of the last item on the current page
    const indexOfFirstNode = indexOfLastNode - itemsPerPage; // Index of the first item on the current page
    const currentNodes = filteredRows.slice(indexOfFirstNode, indexOfLastNode) // Current nodes to display on the current page
    const {
        options, // Options for the select input
        rows, // Rows of jobs data
        setLastOrdered, // Function to set last ordered date
        lastOrdered, // Last ordered date
        isLoadingOptions, // Loading state for options
        isLoadingYourOrder // Loading state for your order
    } = props

    useEffect(() => {
        // Update filtered rows when rows change
        setFilteredRows(rows)
    }, [rows])

    const onChange = async (e) => {
        // Handle change in select input for last ordered date
        const orderDate = e.target.value
        setLastOrdered(orderDate)
    }

    const handleChange = (e) => {
        // Handle change in search input
        const value = e.target.value
        setSearchNodeName(value);
        const filteredRows = rows.filter(job => {
            return job.jobName.toLowerCase().indexOf(value.toLowerCase()) !== -1
        })
        setFilteredRows(filteredRows)
    };

    for (let i = 0; i <= Math.ceil(totalPage / itemsPerPage); i++) {
        // Populate numberOfPages array with page numbers
        numberOfPages.push(i)
    }

    const paginate = (pageNum) => {
        // Function to handle pagination
        setCurrentPage(pageNum)
    }

    const nextPage = () => {
        // Function to handle next page navigation
        if (numberOfPages.length === currentPage) {
            setCurrentPage(currentPage)
        } else {
            setCurrentPage(currentPage + 1)
        }
        if (currentPage + 1 > maxPageNumberLimit) {
            setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
        }
    }

    const prevPage = () => {
        // Function to handle previous page navigation
        if (currentPage === 1) {
            setCurrentPage(currentPage)
        } else {
            setCurrentPage(currentPage - 1)
        }
        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
            setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
        }
    }
 
    if (isLoadingOptions || isLoadingYourOrder) {
        // Display loader if options or your order is loading
        return <Loader />
    }
    return (
        <div>
            {
                <>
                    <Box className={classes.inputWrapper}>
                        <Box className={classes.selectWrapper}>
                            <Typography color='primary' className={classes.numberOfOrder}>
                                {filteredRows.length} jobs placed in
                            </Typography>
                            <Box className={classes.box}>
                                <CustomizedSelect
                                    id="lastOrder"
                                    name="lastOrder"
                                    value={lastOrdered}
                                    options={options}
                                    handleChange={onChange}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.searchInputBox}>
                            <SearchInput
                                value={searchNodeName}
                                setValue={setSearchNodeName}
                                handleChange={handleChange}
                                placeholder="Search Jobs"
                            />
                        </Box>
                    </Box>
                    {
                        filteredRows.length > 0 ?
                            <>
                                <JobsTable
                                    options={options}
                                    headCells={HeadCells}
                                    currentRows={currentNodes}
                                    rows={filteredRows}
                                /> 
                                <Pagination
                                    numberOfPages={numberOfPages}
                                    currentPage={currentPage}
                                    paginate={paginate}
                                    nextPage={nextPage}
                                    prevPage={prevPage}
                                    maxPageNumberLimit={maxPageNumberLimit}
                                    minPageNumberLimit={minPageNumberLimit}
                                />
                            </> : <SliderPlaceholder text="You have not placed any Jobs in the past 3 months" />
                    }

                </>
            }
        </div>
    );
}

export default Jobs;