import { useContext, useState } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core'
import shortNode from './../../../assets/images/collection-card/shortNode.png'
import shortNode2 from './../../../assets/images/collection-card/shortNode2.png'
import view from './../../../assets/images/collection-card/view.png'
import camera from './../../../assets/images/collection-card/camera.png'
import share from './../../../assets/images/collection-card/share.png'
import pen from './../../../assets/images/collection-card/pen.png'
import { OverlayButton } from "../buttons/custom-buttons/CustomButtons";
import { Link, useNavigate } from 'react-router-dom';
import * as moment from 'moment'
import DataContext from '../../../contexts/Context';
import KebabMenu from '../popover/KebabMenu';
import { FavouriteCheckbox } from '../inputs/CheckBox';
import TooltipContent from '../full-node-card/TooltipContent';
import { GovernmentIcon, PrivateIcon, PublicIcon } from '../../icons';

const useStyles = makeStyles({
    root: {
        width: 292,
        borderRadius: 16,
        boxShadow: '0px 2px 20px rgba(23, 87, 123, 0.2)',
        color: '#123b64'
    },
    overlay: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: '#464d6133',
        borderRadius: '16px 16px 0 0',
        display: 'none',
        padding: 16
    },
    imagesWrapper: {
        position: 'relative',
        display: 'flex',
        borderBottom: '2px solid #DBDEE7',
        "&:hover": {
            '& #overlay': {
                display: 'block'
            }
        }
    },
    imgWrapper: {
        width: '100%',
        height: 90,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden',
        '& img': {
            height: '100%',
        }
    },
    firstImage: {
        borderRight: '2px solid #DBDEE7',
        width: '100%',
        maxWidth: 168
    },
    title: {
        fontSize: 18,
        lineHeight: '24px',
        fontWeight: 700,
        cursor: 'pointer'
    },
    pinWrapper: {
        display: 'flex',
        alignItems: 'center'
    },
    pin: {
        fontSize: 14,
        fontWeight: 700
    },
    dot: {
        width: 4,
        height: 4,
        background: '#123b64e6',
        margin: '0 7px',
        borderRadius: '50%'
    },
    overlayButtonWrapper: {
        display: 'flex',
        justifyContent: 'end',
        '& button': {
            margin: 5,
            opacity: 0.8
        }
    }
})

const ShortCollectionCard = ({ data, selectedCols, setSelectedCols }) => {
    const classes = useStyles()
    const date = new Date(data.lastmodifiedon)
    const [imgStatus, setImgStatus] = useState(true)
    const apiUrl = process.env.REACT_APP_API_URL;
    const numberOfPins = data.pinNodes ? data.pinNodes.length : data.numberofpins
    const collectionKey = data.key
    const { addToFavCollection, checkedCollections } = useContext(DataContext)
    const position = { top: 50, right: -14, left: 'unset', bottom: 'unset' }
    const navigate = useNavigate()

    const handleChange = (collection) => {
        addToFavCollection(collection)
    }

    const handleErrorImge = () => {
        setImgStatus(false)
    }

    const isSelected = selectedCols && selectedCols.some((id) => id === data.key || id === data._key);

    const handelSelectCollection = (event, collectionId) => {
        if (event.target.id !== "image" && event.target.tagName !== "INPUT") {
            if (selectedCols) {
                const isCollSelected = selectedCols.some((id) => id === collectionId);
                let copySelectedCols = [...selectedCols];
                if (isCollSelected) {
                    copySelectedCols = copySelectedCols.filter(
                        (id) => id !== collectionId
                    );
                    setSelectedCols(copySelectedCols);
                } else {
                    copySelectedCols.push(collectionId);
                    setSelectedCols(copySelectedCols);
                }
            }
        }
    }

    const handleOpenCollection = () => {
        navigate(`/my-collections/${data.displayName}/${collectionKey}`)
    }

    return (
        <div
            className={classes.root}
            style={isSelected ? { border: "1px solid #f79f19" } : { border: "none" }}
            onClick={(e) => handelSelectCollection(e, data._key ? data._key : data.key)}
            id={collectionKey}
        >
            <Box className={classes.imagesWrapper}>
                {
                    imgStatus ?
                        <Box className={classes.imgWrapper}>
                            <img onError={handleErrorImge} src={`${apiUrl}/getPinImage?file=${data?.cover}`} alt="collection" />
                        </Box> :
                        <>
                            <img className={classes.firstImage} src={shortNode} alt="node1" />
                            <img src={shortNode2} alt="node2" />
                        </>
                }
                <Box id="overlay" className={classes.overlay}>
                    <Box className={classes.overlayButtonWrapper}>
                        {/* <OverlayButton disableRipple>
                            <img src={view} alt="view" />
                        </OverlayButton> */}
                        {/* <OverlayButton disableRipple>
                            <img src={camera} alt="camera" />
                        </OverlayButton> */}
                        {/* <OverlayButton disableRipple>
                            <img src={share} alt="share" />
                        </OverlayButton> */}
                        {/* <Link to={`/my-collections/${data.displayName}/add-section`}>
                            <OverlayButton disableRipple>
                                <img src={pen} alt="pen" />
                            </OverlayButton>
                        </Link> */}
                        {/* <OverlayButton disableRipple>
                            <KebabMenu position={position} checked={checkedCollections[collectionKey]} button={
                                <FavouriteCheckbox
                                    checked={checkedCollections[collectionKey] === true}
                                    onChange={() => handleChange(data)} />}
                            >
                                <TooltipContent />
                            </KebabMenu>
                        </OverlayButton> */}
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 15 }}>
                <Box>
                    <Typography className={classes.title} onClick={handleOpenCollection}>
                        {data.displayName}
                    </Typography>
                    <Box className={classes.pinWrapper}>
                        <Typography className={classes.pin} component='span'>
                            {numberOfPins} pin
                        </Typography>
                        <Box className={classes.dot}></Box>
                        <Typography style={{ fontSize: 14 }} component='span'>
                            {moment(date).format('D MMM YY')}
                        </Typography>
                    </Box>
                </Box>
                {
                    data.classification === 'Governed' ? <GovernmentIcon /> :
                        data.classification === 'public' ?
                            <PublicIcon /> : <PrivateIcon />
                }
            </Box>
        </div>
    );
}

export default ShortCollectionCard;
