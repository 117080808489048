import { FilterAttributes } from "./filter-attributes"
import { SORT_FIELDS, SORT_TYPES, NODE_TITLE_TYPE, NODE_TYPES } from './searchResults'

// ****************Node Filter Logic****************************
export const savedFiltersHelper = (selectedFilters = []) => {
    let filterPaylaod = []
    const hasNodeType = selectedFilters.some(filterType => filterType.label === NODE_TYPES.nodeType)
    const hasStatus = selectedFilters.some(filterType => filterType.label === NODE_TYPES.status)

    const otherNodeTypes = selectedFilters.filter(filterType => {
        return filterType.label !== NODE_TYPES.nodeType && filterType.label !== NODE_TYPES.status
    })
    if (hasNodeType) {
        const nodeTypeFilters = getCheckedFilters(selectedFilters, NODE_TYPES.nodeType)
        filterPaylaod = [...filterPaylaod, nodeTypeFilters]
    }
    if (hasStatus) {
        const nodeStatusFilters = getCheckedFilters(selectedFilters, NODE_TYPES.status)
        filterPaylaod = [...filterPaylaod, nodeStatusFilters]
    }
    if (otherNodeTypes.length) {
        const otherFilterTypes = otherNodeTypes.map(filterType => getSelectedFilters(filterType))
        filterPaylaod = [...filterPaylaod, ...otherFilterTypes]
    }
    delete filterPaylaod[0].condition
    return filterPaylaod
}

// export const filterHelper = (selectedFilters = [], lastFilterValue = '', filters = []) => {
//     let searchFilters = []

//     if (!selectedFilters.length) { return [] }

//     if (lastFilterValue === NODE_TYPES.nodeType || lastFilterValue === NODE_TYPES.status) {
//         const checkedFilter = getCheckedFilters(selectedFilters, lastFilterValue)

//         if (lastFilterValue === NODE_TYPES.nodeType) {
//             searchFilters = filters.filter(filter => filter.fieldName !== FilterAttributes.nodeType.type)
//         } else if (lastFilterValue === NODE_TYPES.status) {
//             searchFilters = filters.filter(filter => filter.fieldName !== FilterAttributes.status.type)
//         }

//         const checkedFilters = checkedFilter.fieldValue ? [...searchFilters, checkedFilter] : searchFilters
//         return checkedFilters
//     } else {
//         const filteredFilterTypes = selectedFilters.filter(filterType => {
//             return filterType.label !== NODE_TYPES.nodeType && filterType.label !== NODE_TYPES.status
//         })
//         const filteredValues = filters.filter(filterType => {
//             return (filterType.fieldName === FilterAttributes.nodeType.type || filterType.fieldName === FilterAttributes.status.type)
//         })

//         const filterTypes = filteredFilterTypes.map(filterType => getSelectedFilters(filterType))

//         const checkedFilters = [...filteredValues, ...filterTypes]
//         return checkedFilters
//     }
// }

export const filterHelper = (selectedFilters = [], lastFilterValue = '', filters = []) => {
    let searchFilters = [];

    if (!selectedFilters.length) { return [] }

    if (lastFilterValue === NODE_TYPES.nodeType || lastFilterValue === NODE_TYPES.status) {
        const checkedFilter = getCheckedFilters(selectedFilters, lastFilterValue)

        if (lastFilterValue === NODE_TYPES.nodeType) {
            searchFilters = filters.filter(filter => filter.fieldName !== FilterAttributes.nodeType.type)
        } else if (lastFilterValue === NODE_TYPES.status) {
            searchFilters = filters.filter(filter => filter.fieldName !== FilterAttributes.status.type)
        }

        const checkedFilters = checkedFilter.fieldValue ? [...searchFilters, checkedFilter] : searchFilters
        return checkedFilters
    } else {
        const filteredFilterTypes = selectedFilters.filter(filterType => {
            return filterType.label !== NODE_TYPES.nodeType && filterType.label !== NODE_TYPES.status
        })
        const filteredValues = filters.filter(filterType => {
            return (filterType.fieldName === FilterAttributes.nodeType.type || filterType.fieldName === FilterAttributes.status.type)
        })

        const filterTypes = filteredFilterTypes.map(filterType => getSelectedFilters(filterType))

        const checkedFilters = [...filteredValues, ...filterTypes]
        
        // Combine multiple filter values for the same field into a single filter
        const groupedFilters = groupFiltersByField(checkedFilters);
        
        return groupedFilters;
    }
}

// Function to group filters with the same field into a single filter
const groupFiltersByField = (filters) => {
    const groupedFilters = {};
    
    filters.forEach(filter => {
        if (groupedFilters[filter.fieldName]) {
            groupedFilters[filter.fieldName].fieldValue += `,${filter.fieldValue}`;
        } else {
            groupedFilters[filter.fieldName] = { ...filter };
        }
    });
    
    return Object.values(groupedFilters);
};


const getCheckedFilters = (selectedFilters, filterType) => {
    const { type, condition } = FilterAttributes[filterType]
    const filter = { fieldName: type, fieldValue: '', searchType: "FILTER", op: condition, condition: 'and' };

    const filterTypes = selectedFilters.filter(selectedFilter => selectedFilter.label === filterType)
    filter.fieldValue = filterTypes.map(nodeType => nodeType.value).join()

    return filter
}



const getSelectedFilters = ({ label, value }) => {
    console.log('label:', label);

    if (!label || !FilterAttributes[label]) {
        
        return {}; // Return an empty object for unknown labels or when label is undefined
    }
    const { type, condition, typeConverter } = FilterAttributes[label]

    const filter = { fieldName: type, fieldValue: value, searchType: "FILTER", op: "in", condition: 'and' };

    if (label === "averageRating") {
        filter.typeConverter = typeConverter
    }
    
   
    if (label === "certified" && value === "true") {
      filter["searchType"] = "FILTER";
      filter["fieldValue"] = "Certified,true";
    } else if (label === "certified" && value === "false") {
      filter["searchType"] = "FILTER";
      filter["fieldValue"] = "Certified,false";
    } 
    

    //Updated by Gobi Baskaran
   
    if (label === "freshness") {
      filter["searchType"] = "FILTER";
      filter["fieldValue"] = "LastModifiedOn," + value;
    }
    if (label === "tags") {
      filter["searchType"] = "FILTER";
      filter["fieldValue"] = "Tags," + value;
    }

    return filter
}

export const getFilterOptionsPayload = (payload) => {
    // Check if payload.filters is defined and is an array
    if (!payload.filters || !Array.isArray(payload.filters)) {
        return { ...payload, filters: [] }; // or handle it according to your logic
    }

    // If payload.filters is defined and is an array, proceed with the filter operation
    const filters = payload.filters.filter(filterType => {
        return filterType.searchType === 'FILTER' || filterType.searchType === 'SEARCH';
    });

    return { ...payload, filters };
};








// export const getFilterOptionsPayload = (payload) => {
//     delete payload.sortFields
//     const filters = payload.filters.filter(filterType => {
//         return filterType.searchType === 'FILTER' || filterType.searchType === 'SEARCH'
//     })
//     return { ...payload, filters }
// }

// ************************** Node Sort Logic ****************************************
export const setNodeSortType = (order, sortType) => {
    SORT_FIELDS.sort = order
    if (sortType === "displayName") {
        SORT_FIELDS.field = SORT_TYPES.name
    } else if (sortType === "lastmodified") {
        SORT_FIELDS.field = SORT_TYPES.lastmodified
    } else if (sortType === "createdon") {
        SORT_FIELDS.field = SORT_TYPES.createdon
    } else {
        SORT_FIELDS.field = SORT_TYPES.avgRating
    }
    return SORT_FIELDS
}

export const createSearchPayload = (currentPayload, currentPage, pageSize, sort = { type: '', order: 'asc' }) => {
    const nodeSortType = setNodeSortType(sort.order, sort.type)

    const payload = { ...currentPayload, pagination: { pageNumber: currentPage, pageSize: pageSize } }
    if (sort.type) {
        payload['sortFields'] = [nodeSortType]
    }

    return payload
}

const setNodeTitleType = (titleType, currText) => {
    if (titleType === "ExactMatch") {
        return { ...NODE_TITLE_TYPE, fieldValue: currText, searchType: 'PHRASE_FILTER_EXACT', op: 'eq' }
    } else if (titleType === 'AllWords') {
        return { ...NODE_TITLE_TYPE, fieldValue: currText, searchType: 'PHRASE_FILTER_AND', op: 'in' }
    } else {
        return { ...NODE_TITLE_TYPE, fieldValue: currText, searchType: 'PHRASE_FILTER_OR', op: 'in' }
    }
}

export const createSearchPayloadForFilterbar = (currentPayload, radioBtnValue, currText, pageSize = 9) => {
    const withoutPhraseFilters = currentPayload.filters.filter(filter => !filter?.searchType?.includes('PHRASE'));
    const payload = { ...currentPayload, pagination: { pageNumber: 1, pageSize: pageSize } }

    if (radioBtnValue === '') {
        payload.filters = withoutPhraseFilters
    } else {
        const nodeTitleType = setNodeTitleType(radioBtnValue, currText)
        payload.filters = [...withoutPhraseFilters, nodeTitleType]
    }

    return payload
}








// import { FilterAttributes } from "./filter-attributes";
// import {
//   SORT_FIELDS,
//   SORT_TYPES,
//   NODE_TITLE_TYPE,
//   NODE_TYPES,
// } from "./searchResults";

// // ****************Node Filter Logic****************************
// export const savedFiltersHelper = (selectedFilters = []) => {
//   let filterPaylaod = [];
//   const hasNodeType = selectedFilters.some(
//     (filterType) => filterType.label === NODE_TYPES.nodeType
//   );
//   const hasStatus = selectedFilters.some(
//     (filterType) => filterType.label === NODE_TYPES.status
//   );

//   const otherNodeTypes = selectedFilters.filter((filterType) => {
//     return (
//       filterType.label !== NODE_TYPES.nodeType &&
//       filterType.label !== NODE_TYPES.status
//     );
//   });
//   if (hasNodeType) {
//     const nodeTypeFilters = getCheckedFilters(
//       selectedFilters,
//       NODE_TYPES.nodeType
//     );
//     filterPaylaod = [...filterPaylaod, nodeTypeFilters];
//   }
//   if (hasStatus) {
//     const nodeStatusFilters = getCheckedFilters(
//       selectedFilters,
//       NODE_TYPES.status
//     );
//     filterPaylaod = [...filterPaylaod, nodeStatusFilters];
//   }
//   if (otherNodeTypes.length) {
//     const otherFilterTypes = otherNodeTypes.map((filterType) =>
//       getSelectedFilters(filterType)
//     );
//     filterPaylaod = [...filterPaylaod, ...otherFilterTypes];
//   }
//   delete filterPaylaod[0].condition;
//   return filterPaylaod;
// };

// export const filterHelper = (
//   selectedFilters = [],
//   lastFilterValue = "",
//   filters = []
// ) => {
//   let searchFilters = [];

//   if (!selectedFilters.length) {
//     return [];
//   }

//   if (
//     lastFilterValue === NODE_TYPES.nodeType ||
//     lastFilterValue === NODE_TYPES.status
//   ) {
//     const checkedFilter = getCheckedFilters(selectedFilters, lastFilterValue);

//     if (lastFilterValue === NODE_TYPES.nodeType) {
//       searchFilters = filters.filter(
//         (filter) => filter.fieldName !== FilterAttributes.nodeType.type
//       );
//     } else if (lastFilterValue === NODE_TYPES.status) {
//       searchFilters = filters.filter(
//         (filter) => filter.fieldName !== FilterAttributes.status.type
//       );
//     }

//     const checkedFilters = checkedFilter.fieldValue
//       ? [...searchFilters, checkedFilter]
//       : searchFilters;
//     return checkedFilters;
//   } else {
//     const filteredFilterTypes = selectedFilters.filter((filterType) => {
//       return (
//         filterType.label !== NODE_TYPES.nodeType &&
//         filterType.label !== NODE_TYPES.status
//       );
//     });

//     // Check if there are any filteredFilterTypes before calling getSelectedFilters
//     if (filteredFilterTypes.length) {
//       const filterTypes = filteredFilterTypes.map((filterType) =>
//         getSelectedFilters(filterType)
//       );

//       return [...filterTypes];
//     } else {
//       // No filteredFilterTypes, return empty array
//       return [];
//     }
//   }
// };


// // export const filterHelper = (
// //   selectedFilters = [],
// //   lastFilterValue = "",
// //   filters = []
// // ) => {
// //   let searchFilters = [];

// //   if (!selectedFilters.length) {
// //     return [];
// //   }

// //   if (
// //     lastFilterValue === NODE_TYPES.nodeType ||
// //     lastFilterValue === NODE_TYPES.status
// //   ) {
// //     const checkedFilter = getCheckedFilters(selectedFilters, lastFilterValue);
    

// //     if (lastFilterValue === NODE_TYPES.nodeType) {
// //       searchFilters = filters.filter(
// //         (filter) => filter.fieldName !== FilterAttributes.nodeType.type
// //       );
// //     } else if (lastFilterValue === NODE_TYPES.status) {
// //       searchFilters = filters.filter(
// //         (filter) => filter.fieldName !== FilterAttributes.status.type
// //       );
// //     }

// //     const checkedFilters = checkedFilter.fieldValue
// //       ? [...searchFilters, checkedFilter]
// //       : searchFilters;
// //     return checkedFilters;
// //   } else {
// //     const filteredFilterTypes = selectedFilters.filter((filterType) => {
// //       return (
// //         filterType.label !== NODE_TYPES.nodeType &&
// //         filterType.label !== NODE_TYPES.status
// //       );
// //     });
// //     const filteredValues = filters.filter((filterType) => {
// //       return (
// //         filterType.fieldName === FilterAttributes.nodeType.type ||
// //         filterType.fieldName === FilterAttributes.status.type
// //       );
// //     });

// //     const filterTypes = filteredFilterTypes.map((filterType) =>
// //       getSelectedFilters(filterType)
// //     );

// //     const checkedFilters = [...filteredValues, ...filterTypes];
// //     return checkedFilters;
// //   }
// // };

// const getCheckedFilters = (selectedFilters, filterType) => {
//   // const { type, condition } = FilterAttributes[filterType];
//   const { type, condition } = FilterAttributes[filterType] || {};

//   const filter = {
//     fieldName: type,
//     fieldValue: "",
//     searchType: "FILTER",
//     op: condition,
//     condition: "and",
//   };

//   const filterTypes = selectedFilters.filter(
//     (selectedFilter) => selectedFilter.label === filterType
//   );
//   filter.fieldValue = filterTypes.map((nodeType) => nodeType.value).join();

//   return filter;
// };

// const getSelectedFilters = ({ label, value }) => {
//   if (FilterAttributes[label]) {
//     const { type, condition, typeConverter } = FilterAttributes[label];

//     const filter = {
//       fieldName: type,
//       fieldValue: value,
//       searchType: "FILTER",
//       op: condition,
//       condition: "and",
//     };

//     if (label === "averageRating") {
//       filter.typeConverter = typeConverter;
//     }

//     if (label === "certifed" && value === "true") {
//       filter["searchType"] = "FILTER";
//       filter["fieldValue"] = "Certified,true";
//     } else if (label === "certifed" && value === "false") {
//       filter["searchType"] = "FILTER";
//       filter["fieldValue"] = "Certified,false";
//     }

//     //Updated by Gobi Baskaran
//     if (label === "freshness") {
//       filter["searchType"] = "FILTER";
//       filter["fieldValue"] = "LastModifiedOn," + value;
//     }
//     if (label === "tags") {
//       filter["searchType"] = "SUB_QUERY_ATTRIBUTE_FILTER";
//       filter["fieldValue"] = "tag," + value;
//     }

//     return filter;
//   } else {
//     console.error(`FilterAttributes does not contain a property for label: ${label}`);
//   }
// };


// // const getSelectedFilters = ({ label, value }) => {
// //   const { type, condition, typeConverter } = FilterAttributes[label];

// //   const filter = {
// //     fieldName: type,
// //     fieldValue: value,
// //     searchType: "FILTER",
// //     op: condition,
// //     condition: "and",
// //   };

// //   if (label === "averageRating") {
// //     filter.typeConverter = typeConverter;
// //   }

// //   // if (label === "curated" && value === "true") {
// //   //   filter["fieldValue"] = "%curated%";
// //   // } else if (label === "curated" && value === "false") {
// //   //   filter["fieldValue"] = "%curated%";
// //   //   filter["op"] = "not like";
// //   //   // filter[ "not like"] = "op";
// //   // }

// //   if (label === "certifed" && value == "true") {
// //     filter["searchType"] = "FILTER";
// //     filter["fieldValue"] = "Certified,true";
// //   } else if (label === "certifed" && value == "false") {
// //     filter["searchType"] = "FILTER";
// //     filter["fieldValue"] = "Certified,false";
// //   }

// //   //Updated by Gobi Baskaran
// //   // if (label === "frequency") {
// //   //   filter["searchType"] = "SUB_QUERY_ATTRIBUTE_FILTER";
// //   //   filter["fieldValue"] = "Frequency," + value;
// //   // }
// //   if (label === "freshness") {
// //     filter["searchType"] = "FILTER";
// //     filter["fieldValue"] = "LastModifiedOn," + value;
// //   }
// //   if (label === "tags") {
// //     filter["searchType"] = "SUB_QUERY_ATTRIBUTE_FILTER";
// //     filter["fieldValue"] = "tag," + value;
// //   }

// //   return filter;
// // };

// export const getFilterOptionsPayload = (payload) => {
//   delete payload.sortFields;
//   const filters = payload.filters.filter((filterType) => {
//     return (
//       filterType.searchType === "FILTER" || filterType.searchType === "SEARCH"
//     );
//   });
//   return { ...payload, filters };
// };

// // ************************** Node Sort Logic ****************************************
// export const setNodeSortType = (order, sortType) => {
//   SORT_FIELDS.sort = order;
//   if (sortType === "displayName") {
//     SORT_FIELDS.field = SORT_TYPES.name;
//   } else if (sortType === "lastmodified") {
//     SORT_FIELDS.field = SORT_TYPES.lastmodified;
//   } else if (sortType === "createdon") {
//     SORT_FIELDS.field = SORT_TYPES.createdon;
//   } else {
//     SORT_FIELDS.field = SORT_TYPES.avgRating;
//   }
//   return SORT_FIELDS;
// };

// export const createSearchPayload = (
//   currentPayload,
//   currentPage,
//   pageSize,
//   sort = { type: "", order: "asc" }
// ) => {
//   const nodeSortType = setNodeSortType(sort.order, sort.type);

//   const payload = {
//     ...currentPayload,
//     pagination: { pageNumber: currentPage, pageSize: pageSize },
//   };
//   if (sort.type) {
//     payload["sortFields"] = [nodeSortType];
//   }

//   return payload;
// };

// const setNodeTitleType = (titleType, currText) => {
//   if (titleType === "ExactMatch") {
//     return {
//       ...NODE_TITLE_TYPE,
//       fieldValue: currText,
//       searchType: "PHRASE_FILTER_EXACT",
//       op: "eq",
//     };
//   } else if (titleType === "AllWords") {
//     return {
//       ...NODE_TITLE_TYPE,
//       fieldValue: currText,
//       searchType: "PHRASE_FILTER_AND",
//       op: "in",
//     };
//   }
//   //   else if (titleType === 'AllWords') {
//   //     return { ...NODE_TITLE_TYPE, fieldValue: currText, searchType: 'PHRASE_FILTER_AND', op: 'and' }
//   // }
//   else {
//     return {
//       ...NODE_TITLE_TYPE,
//       fieldValue: currText,
//       searchType: "PHRASE_FILTER_OR",
//       op: "in",
//     };
//   }
// };

// export const createSearchPayloadForFilterbar = (
//   currentPayload,
//   radioBtnValue,
//   currText,
//   pageSize = 9
// ) => {
//   const withoutPhraseFilters = currentPayload.filters.filter(
//     (filter) => !filter?.searchType?.includes("PHRASE")
//   );
//   const payload = {
//     ...currentPayload,
//     pagination: { pageNumber: 1, pageSize: pageSize },
//   };

//   if (radioBtnValue === "") {
//     payload.filters = withoutPhraseFilters;
//   } else {
//     const nodeTitleType = setNodeTitleType(radioBtnValue, currText);
//     payload.filters = [...withoutPhraseFilters, nodeTitleType];
//   }

//   return payload;
// };
