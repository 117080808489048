import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { CustomCheckbox } from "../shared/inputs/CheckBox";
import Checkbox from '@mui/material/Checkbox';

const useRowStyles = makeStyles({

  root: {

    "& > *": {

      borderBottom: "none",

    },

  },

  tableContainer: {

    maxHeight: "400px",  // Adjust the height as needed

    overflowY: "auto",

    "&::-webkit-scrollbar": {

      width: "8px",

    },

    "&::-webkit-scrollbar-thumb": {

      backgroundColor: "#F79F19",

      borderRadius: "10px",

    },

    "&::-webkit-scrollbar-track": {

      backgroundColor: "#808C9A",

      borderRadius: "10px",

    },

  },

  textColor: {

    color: "#123b64",

  },

});

function Row(props) {
  const [openRows, setOpenRows] = useState(false);
  const classes = useRowStyles();
  const { tableData, setSelectedRows, selectedRows } = props;
  const displayTableData = props.tableData?.map((items) => items.schemaList);

    const checkboxClickHandler = (action, name, parentId, tableListItemId, columnListItemId) => {
      console.log("Action:", action);
      console.log("Name:", name);
      console.log("Parent ID:", parentId);
      console.log("Table List Item ID:", tableListItemId);
      console.log("Column List Item ID:", columnListItemId);
      console.log("Selected Rows:", props.selectedRows);
    let listOfNames = [];
    let removeNames = [];
    let chipNames = [];




if (name === "parent") {
  const allData = [parentId.name];
  if (parentId?.tableList?.length > 0) {
    parentId.tableList.map((tableItem) => {
      console.log(tableItem,"tableItem")
      allData.push(tableItem.name);
      tableItem?.columnList?.length > 0 &&
        tableItem.columnList.map((columnItem) => {
          allData.push(columnItem.name);
        });
    });
  }
  if (action === "remove") {
    removeNames = allData;
  } else {
    listOfNames = allData;
  }
}



if (name === "tableList") {
  const allData = [tableListItemId.name];
  if (tableListItemId?.columnList?.length > 0) {
    tableListItemId.columnList.map((item) => allData.push(item.name));
  }

  if (action === "remove") {
    // Check whether all the table parents has to be removed
    const getTableListParents =
      parentId?.tableList?.map((item) => item.name) || [];

    const selectedTableList = getTableListParents.some(
      (value) =>
        value !== tableListItemId.name && selectedRows.includes(value)
    );

    if (!selectedTableList) allData.push(parentId.name);
    removeNames = allData;
  } else {
    listOfNames = [parentId.name, ...allData];
  }
}

if (name === "columnList" && columnListItemId) {
  const allData = [columnListItemId.name];

  if (action === "remove") {
    const getColumnListParents =
      tableListItemId.columnList.map((item) => item.name) || [];
    const selectedColumnList = getColumnListParents.some(
      (value) =>
        value !== columnListItemId.name && selectedRows.includes(value)
    );
    if (!selectedColumnList) allData.push(tableListItemId.id);

    const getTableListParents =
      parentId.tableList.map((item) => item.name) || [];
    const selectedTableList = getTableListParents.some(
      (value) =>
        value !== tableListItemId.name && selectedRows.includes(value)
    );
    if (!selectedColumnList && !selectedTableList)
      allData.push(parentId.name);
    removeNames = allData;
  } else {
    listOfNames = [parentId.name, tableListItemId.name, ...allData];
  }
}

if (action === "add") {
  setSelectedRows([...new Set([...selectedRows, ...listOfNames])]);
} else {
  const uniqueNames = [...new Set(removeNames)];
  const result = selectedRows.filter(
    (element) => !uniqueNames.includes(element)
  );
  setSelectedRows(result);
}
};


  return (
    <>
      {displayTableData[0]?.map((item, index) => {
        const isParentListChecked =
          (props.selectedRows.length > 0 &&
            props.selectedRows.includes(item.name) &&
            item.tableList.every((value) =>
              props.selectedRows.includes(value.name) &&
              value.columnList.every((column) => props.selectedRows.includes(column.name))
            )) ||
          false;

        const isParentPartialChecked =
          (!isParentListChecked &&
            props.selectedRows.length > 0 &&
            props.selectedRows.includes(item.name) &&
            item.tableList.some((value) => props.selectedRows.includes(value.name)) &&
            item.tableList.every((table) =>
              table.columnList.some((column) => props.selectedRows.includes(column.name))
            )) ||
          false;

        return (
          <React.Fragment key={index}>
            <TableRow key={index} sx={{ "& > *": { borderBottom: "unset" } }}>
              <TableCell>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() =>
                      setOpenRows((prevState) => ({
                        ...prevState,
                        [index]: !prevState[index],
                      }))
                    }
                  >
                    {openRows[index] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                  <CustomCheckbox
                    checked={isParentListChecked}
                    indeterminate={isParentPartialChecked}
                    onChange={() =>
                      checkboxClickHandler(
                        !isParentListChecked || isParentPartialChecked ? 'add' : 'remove',
                        'parent',
                        item
                      )
                    }
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    style={{ marginLeft: '-15px' }} // Adjust the margin here
                  />
                </div>
              </TableCell>
              <TableCell className={classes.textColor} component="th" scope="row">{item.name}</TableCell>
              <TableCell className={classes.textColor}>{item.Description}</TableCell>
              <TableCell className={classes.textColor}>{item.dataType}</TableCell>
              <TableCell className={classes.textColor}>{item.length}</TableCell>
              <TableCell className={classes.textColor}>{item.precision}</TableCell>
              <TableCell className={classes.textColor}>{item.nodeType}</TableCell>
            </TableRow>

            {item.tableList.map((tableItems, tableIndex) => {
    const tableListChecked =
        (Array.isArray(selectedRows) &&
            selectedRows.length > 0 &&
            selectedRows.includes(tableItems.name) &&
            tableItems.columnList?.every((column) =>
                selectedRows.includes(column.name)
            )) ||
        false;

    const tableListIndeterminate =
        (!tableListChecked &&
            Array.isArray(selectedRows) &&
            selectedRows.length > 0 &&
            selectedRows.includes(tableItems.name) &&
            tableItems.columnList?.some((column) =>
                selectedRows.includes(column.name)
            )) ||
        false;

    return (
        <React.Fragment key={tableIndex}>
            <TableRow>
                <TableCell style={{ padding: 0 }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => {
                            setOpenRows((prevState) => ({
                                ...prevState,
                                [tableIndex]: !prevState[tableIndex],
                            }));
                        }}
                    >
                        {openRows[tableIndex] ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                    <CustomCheckbox
                        checked={tableListChecked}
                        indeterminate={tableListIndeterminate}
                        onChange={() =>
                          
                            checkboxClickHandler(
                                !tableListChecked || tableListIndeterminate ? "add" : "remove",
                                "tableList",
                                item,
                                tableItems
                            )
                        }
                        inputProps={{ "aria-label": "primary checkbox" }}
                    />
                </TableCell>
                <TableCell component="th" scope="row" style={{ width: "auto" }}>
                    {tableItems.name}
                </TableCell>
                <TableCell style={{ width: '20%' }}>{tableItems.Description}</TableCell>
                <TableCell>{tableItems.dataType}</TableCell>
                <TableCell>{tableItems.length}</TableCell>
                <TableCell>{tableItems.precision}</TableCell>
                <TableCell>{tableItems.nodeType}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={7}>
                    <Collapse in={openRows[tableIndex]} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 0 }}>
                            <Table size="medium" aria-label="purchases">
                                <TableBody>
                                    {tableItems?.columnList?.map((columnItems, columnIndex) => (
                                        <TableRow key={columnIndex}>
                                            <TableCell style={{ padding: 0 ,width:118}}>
                                                <CustomCheckbox
                                                    checked={Array.isArray(selectedRows) && selectedRows.includes(columnItems?.name)}
                                                    onChange={() =>
                                                        checkboxClickHandler(
                                                            Array.isArray(selectedRows) && selectedRows.includes(columnItems?.name) ? "remove" : "add",
                                                            "columnList",
                                                            item,
                                                            tableItems,
                                                            columnItems
                                                        )
                                                    }
                                                    inputProps={{ "aria-label": "primary checkbox" }}
                                                />
                                            </TableCell>
                                            <TableCell style={{ paddingLeft: '16px', width:261 }} align="left">{columnItems?.name}</TableCell>
                                            <TableCell style={{ width: '20%', paddingLeft: 10 }} align="left">{columnItems?.Description}</TableCell>
                                            <TableCell style={{ width:132 }}  >{columnItems?.dataType}</TableCell>
                                            <TableCell style={{ width:121 }} >{columnItems?.length}</TableCell>
                                            <TableCell style={{ width:150 }} >{columnItems?.precision}</TableCell>
                                            <TableCell style={{ width:138}}>{columnItems?.nodeType}</TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
})}

          </React.Fragment>
        );
      })}
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};
const ElementsDataGrid = ({ tableData, setSelectedRows, selectedRows }) => {
  const classes = useRowStyles();
  const ref = useRef(null);
  return (
     <TableContainer className={classes.tableContainer}>
       <Table aria-label="collapsible table" stickyHeader>
        <TableHead>
          <TableRow ref={ref}>
            <TableCell />
            <TableCell style={{ color: '#123b64', fontSize: '19px' }}>Name</TableCell>
            <TableCell style={{ color: '#123b64', fontSize: '19px' }}>Description</TableCell>
            <TableCell style={{ color: '#123b64', fontSize: '19px' }}>Data Type</TableCell>
            <TableCell style={{ color: '#123b64', fontSize: '19px' }}>Length</TableCell>
            <TableCell style={{ color: '#123b64', fontSize: '19px' }}>Precision</TableCell>
            <TableCell style={{ color: '#123b64', fontSize: '19px' }}>Node Type</TableCell>


          </TableRow>
        </TableHead>
        <TableBody>
          <Row
            tableData={tableData}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ElementsDataGrid;
