// import "./SuccessfullLogin.scss";
import { Box, Typography, makeStyles } from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { YellowButton } from "../shared/buttons/custom-buttons/CustomButtons";

const useStyles = makeStyles({
    root: {
        minHeight: "calc(100vh - 81px)",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    mainContent: {
        width: 616,
        padding: '50px 75px 70px',
        boxShadow: '0px 2px 20px rgba(23, 87, 123, 0.2)',
        borderRadius: 16
    },
    imgBoxWrapper: {
        display: 'flex',
        justifyContent: 'center'
    }, 
    imgBox: {
        width: 110,
        height: 110,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'linear-gradient(155.29deg, #90d1cc 3.46%, #418e84 110.38%)',
        borderRadius: "50%",
        marginBottom: 40
    },
    title:{
        marginBottom: 40
    },
    yellowButton:{
        textTransform: 'uppercase'
    }
})

function SuccessLogin() {
  const classes = useStyles()  
  const navigate = useNavigate();

  return (
    <Box className={classes.root}>
      <div className={classes.mainContent}>
        <div className={classes.imgBoxWrapper}>
          <div className={classes.imgBox}>
            <svg width="43" height="43" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5713 12.4754L20.1974 4.18066L21.455 5.5443L11.2145 14.4691L11.2136 14.4682L10.5264 15.0898L4.63684 9.35693L6.06192 8.06806L10.5713 12.4754Z" fill="white" />
              <path d="M10.3184 2.62635C5.82061 2.62635 2.17444 6.27251 2.17444 10.7703C2.17444 15.2681 5.82061 18.9142 10.3184 18.9142C14.8162 18.9142 18.4623 15.2681 18.4623 10.7703C18.4623 10.4952 18.4487 10.2236 18.4222 9.95589L19.8708 8.6981C20.0152 9.3667 20.0911 10.0601 20.0911 10.7703C20.0911 16.1676 15.7157 20.543 10.3184 20.543C4.92105 20.543 0.545654 16.1676 0.545654 10.7703C0.545654 5.37296 4.92105 0.997559 10.3184 0.997559C12.9149 0.997559 15.2765 2.01128 17.026 3.66295L15.7796 4.73419C15.7796 4.73419 15.5352 4.51583 15.4762 4.4675C14.0714 3.3163 12.2762 2.62635 10.3184 2.62635Z" fill="white" />
            </svg>
          </div>
        </div>
        <Typography className={classes.title} variant="h1" color="primary" align="center">
          Everthing was set up successfully!
        </Typography>
        <Link to="/home" className="text-center text-decoration-none">
          <YellowButton
            className={classes.yellowButton}
            fullWidth={true}
            disableRipple  
            onClick={() => navigate("/")}
          >
            Go to Home
          </YellowButton>
        </Link>
      </div>
    </Box>
  );
}
export default SuccessLogin;
