import { useId } from "react";
import api from "../core/api";
// http://localhost:8081/removePinNodesFromPinCollection?key=26594046&holderId=2557956(delete)
// export const CollectionService = {
//   fetchCollections: (userId, order, pinFilter) => {
//     return api.get(`/myCollections`, {
//       params: {
//         createdById: userId,
//         order,
//         pinFilter,
//       },
//     });
//   },
export const CollectionService = {
  fetchCollections: (userId, order, pinFilter) => {
    return api.get(`/myCollections`, {
      params: {
        createdById: userId,
        order,
        pinFilter, // Include the pinFilter parameter
      },
    });
  },

  fetchCollectionFilters: () => {
    return api.get('/collectionFilters'); // Assuming you have an endpoint to fetch collection filters
  },

  pinFiltersSearch: (domainName, selected) => {
    return api.post('/api/pin/pinFiltersSearch', {
      domain: domainName,
      selected: selected,
    });
  },
  fetchCollCreatedByMe: (userId, order, pinFilter) => {
    return api.get(`/createdByYouCollections`, {
      params: {
        createdById: userId,
        order,
        pinFilter,
      },
    });
  },
  fetchCollectionData: (data) => {
    return api.post(`/api/pin/getPinCollectionByKey`, data);
  },
  fetchSystemCollectionData: () => {
    return api.get(`/systemCollections`);
  },
  fetchCollectionFilters: () => {
    return api.get(`/api/pin/pinFilters`);
  },
  // fetchCollectionRecent: (key) => {
  //   return api.post(`/updateviewedcollection?key=${key}`);
  // },
  updateCollection: (key, data) => {
    return api.post(`/updatePinCollection?key=${key}`, data);
  },
  fetchCollectionValues: (key) => {
    return api.get(`/pinCollection`, {
      params: { key },
    });
  },

  deleteCollection: (key) => {
    return api.delete(`/removepinCollection`, {
      params: { key },
    });
  },
  // fetchCollectionList: () => {
  //     return api.get(`/myCollectionsDropDownL`),{
  //         params: { loginId: userId },
  //     }
  // },
  fetchCollectionList: (userId) => {
    return api.get(`/myCollectionsDropDownList`, {
      params: { loginId: userId },
    });
  },
  movePinNodesToPinCollection: (firstKey, secondKey, userId, data) => {
    return api.post(
      `/movePinNodeToPinCollection?to=${secondKey}&from=${firstKey}&holderId=${userId}`,
      data
    );
  },
  movePinCollToPinColl: (firstKey, secondKey, userId, collectionKeys) => {
    return api.post(
      `/movePinCollectionToPinCollection?to=${secondKey}&from=${firstKey}&holderId=${userId}`,
      collectionKeys
    );
  },
  copyPinNodesToPinCollection: (firstKey, secondKey, nodeIds, userId) => {
    return api.post(
      `/copyPinNodeToPinCollection?to=${secondKey}&from=${firstKey}&holderId=${userId}`,
      nodeIds
    );
  },
  copyPinCollToPinColl: (firstKey, secondKey, userId, collectionKeys) => {
    return api.post(
      `/copyPinCollectionToPinCollection?to=${secondKey}&from=${firstKey}&holderId=${userId}`,
      collectionKeys
    );
  },
  createCollection: (data) => {
    return api.post(`/createNewCollection`, data);
  },
  createNewSection: (data, key) => {
    return api.post(`/createAddSection?key=${key}`, data);
  },
  downloadFile: (key) => {
    return api.get(`/downloadFile?key=${key}`, {
      responseType: "blob",
    });
  },
  uploadFile: (key, data) => {
    return api.post(`/uploadFile?key=${key}`, data);
  },
  fetchSharedWithMe: (userId) => {
    return api.get(`/pinCollectionSharedWithMe`, {
      params: { loginId: userId },
    });
  },
  fetchMySharedCollection: (userId) => {
    return api.get(`/pinSharedByMe`, {
      params: { loginId: userId },
    });
  },
  fetchToBeCuratedCollections: (userId) => {
    return api.get(`/tobeCuratedCollection`, {
      params: { userId: userId },
    });
  },
  sendPhoto: (key) => {
    return api.post(`/pinPhoto?pinkey=${key}`);
  },
  //   showSnapshots: () => {
  //     return api.get(`/displaySnapshot`);
  //   },

  //   sendPhoto: (key) => {
  //     return api.post(`/snapshot?key=${key}`);
  //   },
  removeNodesFromCollection: (key, userId, data) => {
    return api.delete(
      `/removePinNodesFromPinCollection?key=${key}&holderId=${userId}`,
      { data: data }
    );
  },
  removeCollectionsFromCollection: (key, userId, data) => {
    return api.delete(
      `/removePinCollectionFromPinCollection?key=${key}&holderId=${userId}`,
      { data: data }
    );
  },
  addNodeToCollection: (key, userId, data) => {
    return api.post(
      `/addPinNodeToPinCollection?key=${key}&holderId=${userId}`,
      data
    );
  },
  addCollectionToCollection: (key, data) => {
    return api.post(`/addPinCollectionToPinCollection?key=${key}`, data);
  },
  // removeCollectionFromCollection: (key, data) => {
  //   return api.delete(`/removePinCollectionFromPinCollection?key=${key}`, {
  //     data: data,
  //   });
  // },
  addCollectionToFovirite: (email, userId, data) => {
    return api.post(
      `/addPinCollectionKeyToFavoriteCollections?favHolder=${email}&favHolderId=${userId}`,
      data
    );
  },
  removeCollectionFromFavorite: (email, userId, key) => {
    return api.delete(
      `/removePinCollectionKeyFromFavoriteCollections?favHolder=${email}&favHolderId=${userId}&arangoPinCollectionKey=${key}`
    );
  },
  fetchFavoriteCollection: (email) => {
    return api.get(`/getFavoritePinCollections?favHolder=${email}`);
  },
  addTeamToCollection: (key, teamId) => {
    api.post(`updateTeamDetailsInPinCollection?pinkey=${key}&teamId=${teamId}`);
  },
  fetchRecentCollections: () => {
    return api.post(`/getRecentCollections`);
  },
  fetchSnap: () => {
    return api.get(`/displaySnapshot`);
  },
  fetchSnapStore: (key) => {
    return api.get(`/snapshot?key=${key}`);
  },
};
