import { useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomModal from "./CustomModal";
import { useState } from "react";
import GotoCreation from "./GotoCreation";
import TextAreaInput from "../../../../../shared/inputs/TextAreaInput";
import CustomizedInput from "../../../../../shared/inputs/CustomizedInput";
import { NODE_TYPE } from "../../../../../../pages/node-creating/nodeCreation";
import { YellowButton } from "../../../../../shared/buttons/custom-buttons/CustomButtons";
import DataContext from "../../../../../../contexts/Context";
import { NodeCreationService } from "../../../../../../services/NodeCreationService";
import {
  classificationService,
  semanticsApis,
} from "../../../../../../services/custom-hooks/CustomHooks";
import { CustomTooltip } from "../../../../../shared/tooltip/NodeTooltip";
import { InfoIcon } from "../../../../../icons";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "568px",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 10,
    boxShadow: "0px 4px 20px rgba(1, 25, 54, 0.1)",
  },
  form: {
    width: "100%",
  },
  startbtn: {
    color: "#fff",
    background: "#F79F19",
    width: "182px",
    height: "48px",
    marginLeft: "0px",
    borderRadius: 12,
    fontWeight: 700,
    lineHeight: "150%",
    textTransform: "capitalize",
    position: "absolute",
    top: 268,
    left: 25,
    "&.MuiButton-root:hover": {
      backgroundColor: "#F79F19",
    },
  },
  addtooltip: {
    backgroundColor: "#fff",
    color: "#123b64",
    fontSize: "15px",
    boxShadow: "0px 4px 20px rgba(23, 87, 123, 0.2)",
    padding: 10,
    borderRadius: 12,
  },
  titleBox: {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 30px",
    borderBottom: "2px solid #E0E7EC",
  },
  mainContentBox: {
    padding: "20px 30px 30px",
  },
  addtooltip: {
    backgroundColor: "#fff",
    color: "#123b64",
    fontSize: "15px",
    boxShadow: "0px 4px 20px rgba(23, 87, 123, 0.2)",
    padding: 10,
    borderRadius: 12,
  },
  doneBtn: {
    width: "182px",
    height: "48px",
  },
  closeBtn: {
    color: "#123b64",
    padding: 0,
  },
  typography: {
    marginTop: 8,
  },
}));

const validationSchema = yup.object({
  name: yup.string("Enter your name").required("name is required"),
  description: yup
    .string("Enter your description")
    .required("Term is required"),
});

const initialValues = { name: "", description: "" };

function NewTermModal({
  handleClose,
  keyProp,
  getSemanticsData,
  semanticData,
  selectedColumn,
}) {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [modalColname, setModalColname] = useState([]);
  const [responseId, setResponseId] = useState("");
  const { loggedInUser } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const { key, title } = useParams();

  const onSubmit = (values) => {
    NodeCreationService.addColumnNameandRecommendedterm(
      keyProp,
      values.name,
      values.description
    )
      .then((res) => {
        setShow(false);
        setResponseId(res.data.key);
        handleClose();
        getSemanticsData();
      })

      .catch(() => console.log("error is occured!"));
  };
  //const optioncol = modalColname;

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const { handleSubmit, handleChange, values, isValid } = formik;

  const [data, setData] = useState({});
  useEffect(() => {
    formik.setValues({ name: selectedColumn });
  }, [selectedColumn]);

  return (
    <div className={classes.paper}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Box className={classes.titleBox}>
          <Typography variant="h5" color="primary">
            Add new term
          </Typography>
          <IconButton
            onClick={handleClose}
            className={classes.closeBtn}
            disableRipple
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className={classes.mainContentBox}>
          <Box className={classes.labelWrapper}>
            {/* <Box mb={2.5}>
              <CustomizedInput
                id="name"
                name="name"
                value={values.selectedColumn}
                disabled={true}
                label="Column Name"
                labelIcon={true}
                formik={formik}
              />
            </Box> */}

            <Box
              className={classes.formLabel}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                style={{ marginRight: 7 }}
                variant="h6"
                color="primary"
              >
                {" "}
                Column Name
              </Typography>
              <CustomTooltip
                bottomStart="bottom-start"
                title="Give a column name here "
                classes={{ tooltip: classes.addtooltip }}
              >
                <InfoIcon />
              </CustomTooltip>
            </Box>

            <Box mb={2.5}>
              <CustomizedInput
                id="name"
                name="name"
                value={values.selectedColumn}
                disabled={true}
                onChange={handleChange}
                formik={formik}
              />
            </Box>

            {/* <Box mb={2.5}>
              <TextAreaInput
                id="description"
                name="description"
                value={values.colName}
                label="Recommended Term"
                labelIcon={true}
                placeholderText="Enter text"
                onChange={handleChange}
                formik={formik}
              />
            </Box> */}

            <Box className={classes.labelWrapper}>
              <Box
                className={classes.formLabel}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  style={{ marginRight: 7 }}
                  variant="h6"
                  color="primary"
                >
                  {" "}
                  Recommended Term
                </Typography>

                <CustomTooltip
                  bottomStart="bottom-start"
                  title="You can enter the recommended term here "
                  classes={{ tooltip: classes.addtooltip }}
                >
                  <InfoIcon />
                </CustomTooltip>
              </Box>
            </Box>

            <Box mb={2.5}>
              <TextAreaInput
                id="description"
                name="description"
                value={values.colName}
                placeholderText="Enter text"
                onChange={handleChange}
                formik={formik}
              />
            </Box>
          </Box>
          <YellowButton
            disabled={!isValid}
            disableRipple
            className={classes.doneBtn}
            type="submit"
            show={show}
          >
            Save
          </YellowButton>
        </Box>
      </form>
    </div>
  );
}

export default NewTermModal;
