import { Box, Typography, Container, makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import MyWorkTabs from "../../components/my-work/MyWorkTabs";
import Breadcrumbs from "../../components/shared/breadcrumb/Breadcrumbs";
import { TeamService } from "../../services/TeamService";
import MyTeams from "../../components/my-work/teams/MyTeams";
import AllTeams from "../../components/my-work/teams/AllTeams";

const useStyles = makeStyles((theme) => ({
    container: {
        minHeight: "calc(100vh - 150px)",
    },
    wrapper: {
        minHeight: "calc(100vh - 150px)",
        padding: "20px 0 100px",
    },
    title: {
        textAlign: "center",
        margin: "26px 0 30px",
    },
    buttonWapper: {
        display: "block",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
}));

const tabOptions = [
  {
    label: "My Teams",
    value: "My teams",
  },
  {
    label: " All Teams",
    value: "All teams",
  },
];

function Teams() {
  const classes = useStyles();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [myTeams, setMyteams] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const [isLoadingMyTeam, setIsLoadingMyTeam] = useState(true);
  const [isLoadingAllTeam, setIsLoadingAllTeam] = useState(true);

  useEffect(() => {
    // fetching my teams data
    const signedInUser = localStorage.getItem("logged-in-user");
    const user = JSON.parse(signedInUser);
    TeamService.fetchMyTeam(user.userId)
      .then((res) => {
        const transfomedData = res.data.map((team) => {
          return {
            ...team,
            participants: team.participants.map((participant) => ({
              name: participant,
              id: participant,
            })),
          };
        });
        setMyteams(transfomedData);
        setIsLoadingMyTeam(false);
      })
      .catch(() => setIsLoadingMyTeam(false));

    // fetching all teams data
    TeamService.fetchAllTeams()
      .then((res) => {
        const transfomedTeams = res.data.map((team) => {
          return {
            ...team,
            participants: team.participants.map((participant) => ({
              name: participant,
              id: participant,
            })),
          };
        });
        setAllTeams(transfomedTeams);
        setIsLoadingAllTeam(false);
      })
      .catch(() => setIsLoadingAllTeam(false));

    window.addEventListener("resize", updatDimensions);
    return () => window.removeEventListener("resize", updatDimensions);
  }, []);

  const updatDimensions = () => {
    setScreenWidth(window.innerWidth);
  };

  return (
    <Container className={classes.container}>
      <Box className={classes.wrapper}>
        <Breadcrumbs yourOrder="you-order" />
        <Typography className={classes.title} variant="h1" color="primary">
          My Teams
        </Typography>
        <Box>
          <MyWorkTabs
            screenWidth={screenWidth}
            setSelectedIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
            isDuality={true}
            tabLabel={tabOptions}
            tabPanelContents={{
              contentOne: <MyTeams isLoading={isLoadingMyTeam} myTeams={myTeams} />,
              contentTwo: <AllTeams isLoading={isLoadingAllTeam} allTeams={allTeams} />
            }}
          />
        </Box>
      </Box>
    </Container>
  );
}

export default Teams;
